import { ApiMethod } from "./useApi";

export function test() {
    return { url: `/ofertas`, method: ApiMethod.GET, body: {} };
}

export function getDatosContacto() {
    return { url: `/datosContacto?idPortal=3`, method: ApiMethod.GET, body: {}, candidatos: true };
}

export function getDatosBasicos(idIdioma) {
    return { url: `/datosBasicos?idioma=${idIdioma}&portal=3`, method: ApiMethod.GET, body: {}, ignoreSnackbar: true, candidatos: true };
}

export function postUser() {
    return { url: `/candidatoIberempleo`, method: ApiMethod.POST, body: {}, ignoreSnackbar: true, candidatos: true};
}

export function putDatosBasicos(body) {
    return { url: `/datosBasicos`, method: ApiMethod.PUT, body, candidatos: true};
}

export function getDatosPersonales(idIdioma) {
    return { url: `/datosPersonales?idioma=${idIdioma}`, method: ApiMethod.GET, body: {}, candidatos: true,noSpinner:false };
}

export function updateDatosPersonales(idIdioma,body) {
    return { url: `/datosPersonales?idioma=${idIdioma}`, method: ApiMethod.PUT, body, candidatos: true ,noSpinner:false};
}

export function downloadDocumentInform(idDocumento) {
    return { url: `/documentos/${idDocumento}`, method: ApiMethod.GET, body: {}, maestros:true,noSpinner:true };
}

export function uploadCandidateDocuments(idIdioma,body) {
    return { url: `/listaDocumentos?idioma=${idIdioma}`, method: ApiMethod.PUT, body, candidatos: true,noSpinner:true };
}

export function deleteCandidateDocuments(idIdioma,documentId) {
    return { url: `/documentos/${documentId}?idioma=${idIdioma}`, method: ApiMethod.DELETE, body: {}, candidatos: true ,noSpinner:true};
}

export function getOffer(offerId) {
    return { url: `/ofertas/${offerId}`, method: ApiMethod.GET, body: {}};
}

export function getFormations(idIdioma) {
    return { url: `/formacion?idioma=${idIdioma}`, method: ApiMethod.GET, body: {}, candidatos: true };
}

export function getLanguages(idIdioma) {
    return { url: `/idioma?idioma=${idIdioma}`, method: ApiMethod.GET, body: {}, candidatos: true };
}

export function getWorkExperience(languageId) {
    return { url: `/experienciaLaboral?idioma=${languageId}`, method: ApiMethod.GET, body: {}, candidatos: true };
}

export function getCandidateKnowledge(languageId) {
    return { url: `/conocimiento?idioma=${languageId}`, method: ApiMethod.GET, body: {}, candidatos: true,noSpinner:true };
}


export function addCandidateKnowledge(languageId,body) {
    return { url: `/conocimiento?idioma=${languageId}`, method: ApiMethod.POST, body, candidatos: true ,noSpinner:true};
}

export function deleteCandidateKnowledge(languageId,idConocimiento) {
    return { url: `/conocimiento/${idConocimiento}?idioma=${languageId}`, method: ApiMethod.DELETE, body: {}, candidatos: true ,noSpinner:true};
}


export function getCandidateDocuments(idioma, noSpinner = false) {
    return { url: `/documentos?idioma=${idioma}`, method: ApiMethod.GET, body: {}, candidatos: true, noSpinner };
}

export function updateIndicadoresCvCandidato(idioma,body) {
    return { url: `/indicadoresCvCandidato?idioma=${idioma}`, method: ApiMethod.PUT, body, candidatos: true,noSpinner:true };
}

export function getOtrosDatos(idioma) {
    return { url: `/otrosDatos?idioma=${idioma}`, method: ApiMethod.GET, body: {}, candidatos: true };
}

export function deleteProfileCandidate() {
    return { url: `/candidato`, method: ApiMethod.DELETE, body: {}, candidatos: true };
}

export function getKnowledge(idKnowledge = "",filter = "",) {
    return { url: `/conocimientos?conocimiento=${idKnowledge}&filtro=${filter}`, method: ApiMethod.GET, body: {}, maestros:true ,noSpinner:true};
}

export function getPlace(idIdioma, idPosition = "", des = "") {
    return { url: `/puestos?puesto=${idPosition}&filtro=${des}&idioma=${idIdioma}`, method: ApiMethod.GET, body: {}, maestros:true };
}

export function getPuestos(idIdioma, idPosition = "", des = "") {
    return { url: `/puestos?puesto=${idPosition}&filtro=${des}&idioma=${idIdioma}`, method: ApiMethod.GET, body: {}, maestros: true};
}

export function saveBasicData(body) {
    return { url: `/datosBasicos`, method: ApiMethod.PUT, body, candidatos: true};
}

export function enrollToOffer(offerId, body) {
    return { url: `/candidaturas?idOferta=${offerId}`, method: ApiMethod.POST, body};
}

export function getKQ(offerId) {
    return { url: `/ofertas/${offerId}/killerquestions`, method: ApiMethod.GET, body: {}};
}

export function isCandidateEnrollInOffer(offerId) {
    return { url: `/candidaturas/exists?idOferta=${offerId}`, method: ApiMethod.GET, body: {}};
}

export function getDirectManger(idManager = "", filter = "") {
    return { url: `/nivelesResponsable?nivelResponsable=${idManager}&filtro=${filter}`, method: ApiMethod.GET, body: {}, maestros: true};
}

export function obtenerAlertasDashboard() {
    return { url: `/alerta/alertasDashboard?idioma=88`, method: ApiMethod.GET, body: {}, candidatos: true};
}

export function getNotificaciones() {
    return { url: `/notificaciones`, method: ApiMethod.GET, body: {}};
}

export function deleteNotificaciones(idNotificacion) {
    return { url: `/notificaciones/${idNotificacion}`, method: ApiMethod.DELETE, body: {}};
}

export function getCandidaturasRecientes() {
    return { url: `/candidatura/candidaturasSimple?idioma=88`, method: ApiMethod.GET, body: {}, candidatos: true};
}

export function getOffers(noSpinner, query = "") {
    return { url: `/ofertas?${query}`, method: ApiMethod.GET, body: {}, noSpinner};
}
 
export function getLevelExperiencie(idLevel = "", filter = "") {
    return { url: `/niveles?nivel=${idLevel}&filtro=${filter}`, method: ApiMethod.GET, body: {}, maestros: true};
}

export function getSectors(idIdioma, idSector = "", des = "") {
    return { url: `/sectores?sector=${idSector}&filtro=${des}&idioma=${idIdioma}`, method: ApiMethod.GET, body: {}, maestros: true};
}

export function getFunction(idFunction = "", filter = "") {
    return { url: `/funciones?funcion=${idFunction}&filtro=${filter}`, method: ApiMethod.GET, body: {}, maestros: true};
}

export function getStaffInCharge(idStaff = "", filter = "") {
    return { url: `/personalACargo?personalACargo=${idStaff}&filtro=${filter}`, method: ApiMethod.GET, body: {}, maestros: true};
}

export function getEtts() {
    return { url: `/ett`, method: ApiMethod.GET, body: {}, maestros: true};
}


export function updateWorkExperience(languageId, experienceId, body) {
    return { url: `/experienciaLaboral/${experienceId}?idioma=${languageId}`, method: ApiMethod.PUT, body, candidatos: true };
}

export function addWorkExperience(languageId,body) {
    return { url: `/experienciaLaboral?idioma=${languageId}`, method: ApiMethod.POST, body, candidatos: true };
}

export function deleteWorkExperience(languageId,experienceId) {
    return { url: `/experienciaLaboral/${experienceId}?idioma=${languageId}`, method: ApiMethod.DELETE, body: {}, candidatos: true };
}

export function addFormation(body, idioma) {
    return { url: `/formacion?idioma=${idioma}`, method: ApiMethod.POST, body, candidatos: true };
}

export function updateFormation( idFormacion, body,idioma) {
    return { url: `/formacion/${idFormacion}?idioma=${idioma}`, method: ApiMethod.PUT, body, candidatos: true };
}

export function deleteFormation(idFormacion, idioma) {
    return { url: `/formacion/${idFormacion}?idioma=${idioma}`, method: ApiMethod.DELETE, body:{}, candidatos: true };
}

export function getCertificate(idCertificate = "",filter = "") {
    return { url: `/certificados?certificado=${idCertificate}&filtro=${filter}`, method: ApiMethod.GET, body: {}, maestros: true};
}

export function updateFechaUltimamodificacion() {
    return { url: `/ultimaModificacion`, method: ApiMethod.PUT, body: {}, candidatos: true};
}

export function getCandidaturas(periodo, query = "") {
    return { url: `/candidaturas?periodo=${periodo}${query}`, method: ApiMethod.GET, body: {}};
}

export function addLanguage(body,idioma) {
    return { url: `/idioma?idioma=${idioma}`, method: ApiMethod.POST, body, candidatos: true };
}

export function updateLanguage(body,idioma) {
    return { url: `/idioma?idioma=${idioma}`, method: ApiMethod.PUT, body, candidatos: true };
}

export function deleteLanguage(idIdioma,idioma) {
    return { url: `/idioma/${idIdioma}?idioma=${idioma}`, method: ApiMethod.DELETE, body:{}, candidatos: true };
}

export function getCertificateLanguages( idIdioma,idCertificate = "",filter = "") {
    return { url: `/certificadosIdiomas?certificado=${idCertificate}&filtro=${filter}&idioma=${idIdioma}`, method: ApiMethod.GET, body: {}, maestros: true,noSpinner:true};
}

export function actualizarOtrosDatos(body,languageId) {
    return { url: `/otrosDatos?idioma=${languageId}&portal=3`, method: ApiMethod.PUT, body, candidatos: true };
}

export function postCompany(body) {
    return { url: `/empresas`, method: ApiMethod.POST, body, noSpinner: true };
}

export function getCountries() {
    return { url: `/paises`, method: ApiMethod.GET, body: {}, maestros: true, noAuth: true };
}

export function getRegions() {
    return { url: `/localidad`, method: ApiMethod.GET, body: {}, maestros: true, noAuth: true };
}

export function getIdentificationType() {
    return { url: `/tipoIdentificacion`, method: ApiMethod.GET, body: {}, maestros: true, noAuth: true };
}

export function getTimetableType() {
    return { url: `/tipoHorario`, method: ApiMethod.GET, body: {}, maestros: true, noAuth: true };
}