import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "../styles";

function Text({
    fontSize,
    lineHeight,
    fontWeight,
    text,
    classes
}: any) {
    return (
        <Typography component="div">
            <Box
            style={{display: "inline-block", fontSize, lineHeight, fontWeight: fontWeight ?? "", margin: "4px"}}
                sx={{...styles.no_margin_white_space, ...classes}}
            >
                {text}
            </Box>
        </Typography>
    );
}

export default Text;