import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Template from "../../../../shared/components/GeneratePDF/template";
import { print } from "../../../../shared/components/GeneratePDF/buttonPDF";
import { downloadDocumentInform} from "../../../../shared/hooks/userApi";
import { base64ToArrayBuffer } from "../../../../shared/utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../../../shared/hooks/useApi";
import { Routes } from "../../../../shared/enums/enums";
import {CircularProgress } from "@mui/material";
/* eslint-disable */
const useStyles = makeStyles(() => ({
  container: {
    height: "calc(var(--vh, 1vh)*100)",
    minHeight: "calc(var(--vh, 1vh)*100)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loading_container: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  template_container: {
    visibility: "hidden",
    height: "0px",
    width: "0px",
    overflow: "hidden",
  },
}));

function CvGenerator() {
  const classes = useStyles();
  const location = useLocation();
  const [stateData, setStateData] = useState(location.state);
  const [height, setHeight] = useState(window.innerHeight);
  const [exporting, setExporting] = useState(true);
  const [picSetted, setPicSetted] = useState(false);
  const [pic, setPic] = useState(null);


  const tempRef = useRef(null);
  const innerBody = useRef(null);

  const callApi = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (location.state) {  
      setStateData(location.state);
    } else {
      navigate(Routes.CURRICULUM);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!exporting) {
      navigate(Routes.CURRICULUM, {
        state: {
          ...stateData,
        }
      });
    }
  }, [exporting]);


  useEffect(() => {
    if (stateData) {
      getProfilePic();
    }
  }, [stateData]);

  useEffect(() => {
    if (picSetted) {
      handleExport();
    
    }
  }, [picSetted]);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  async function getProfilePic() {
    if (stateData?.datosPersonales?.idFotografia) {
      callApi(downloadDocumentInform(stateData?.datosPersonales?.idFotografia)).then((result) => {
        const obj_url = URL.createObjectURL(
          new Blob([base64ToArrayBuffer(result)], {
            type: `application/image/*`,
          })
        );
        setPic(obj_url);
        setPicSetted(true);
         
        }).catch(() => {
          setPicSetted(null);
          setPicSetted(true);
      });
    } else {
      setPicSetted(null);
      setPicSetted(true);
    }
  }

  function handleExport() {
    const file = `${stateData.datosPersonales.nombre}${
      stateData.datosPersonales.primerApellido
    }${
      stateData.datosPersonales.segundoApellido &&
      stateData.datosPersonales.segundoApellido.trim() !== ""
        ? stateData.datosPersonales.segundoApellido
        : ""
    }`;
    setTimeout(() => print(file, setExporting), 500);
  }

  return (
      <div
        className={classes.container}
        ref={innerBody}
        style={{ "--vh": height * 0.01 + "px" }}
      >      
        {stateData ? (
          <div className={classes.template_container}>
            <div id="myMm" style={{ height: "1mm", zIndex: 1 }} />
            <Template
              reference={tempRef}
              personalData={stateData.datosPersonales}
              experiences={stateData.experience}
              formations={stateData.formaciones}
              languages={stateData.idiomas}
              knowledges={stateData.candidateKnowledges}
              knowledgesList={stateData.allKnowledges}
              picture={pic}
              otherData={stateData.otherData}
            />
          </div>
        ) : (
          <div />
        )}
      {<div style={{ position: 'absolute', width: "100%", height: "100%", display: 'flex', backgroundColor: "white", zIndex: 1 }}>
        <CircularProgress sx={{ margin: 'auto' }} /></div>}
      </div>
  );
}
/* eslint-enable */
export default CvGenerator;

