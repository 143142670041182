import React from "react";
import Button from '@mui/material/Button';
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
  buttonFull: (props) => ({
    width: props.size,
    minHeight: 36,
    maxHeight: 36,
    borderRadius: 4,
  }),
  buttonFull_label: {
    textTransform: "none",
    fontWeight: 800,
    fontSize: "16px",
    lineHeight: "16px",
    [theme.breakpoints.up("md")]: {
      fontWeight: "18px",
    },
  },
}));


function ButtonFull(props) {
  const { text, disabled = false, onClick, customClass = "", color, fontSize, fontWeight} = props;

  const classes = useStyles(props);

  return (
    <Button
      classes={{
        root: classes.buttonFull + " " + customClass,
        label: classes.buttonFull_label,
      }}
      disabled={disabled}
      variant="contained"
      style={{ backgroundColor: color ?? theme.palette.primary.main, 
               borderRadius: "4px", 
               color: "white", 
               fontSize: fontSize ?? "16px", 
               lineHeight: "16px",
               fontWeight: fontWeight ?? 800 ,
               textTransform: "none",}}
      disableElevation
      onClick={onClick}
      sx={{...props.customClassSx}}
    >
      {text}
    </Button>
  );
}

export default ButtonFull;

