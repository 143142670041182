import React from "react";
import AuthWrapper from "../PortalCandidato/auth/AuthWrapper";
import DialogHandler from "./DialogHandler";
import { LanguageProvider } from "./LanguageContext";
import SnackHandler from "./SnackHandler";
import SpinnerHandler from "./SpinnerHandler";
import MaestrosContext from "./MaestroContext";

type Props = {
    children: React.ReactNode
};

/**
 * Este componente sirve, simplemente, como wrapper para todos los contextos que utilicemos a nivel de raiz en la aplicación,
 * de forma que evitamos un bloating excesivo en el componente principal.
 */
export default function ContextProvider({ children }: Props) {
    return (
            <LanguageProvider>
                <SpinnerHandler>
                    <SnackHandler>
                        <DialogHandler>
                            <AuthWrapper>
                                <MaestrosContext>
                                    {children}
                                </MaestrosContext>
                            </AuthWrapper>
                        </DialogHandler>
                    </SnackHandler>
                </SpinnerHandler>
            </LanguageProvider>
    );
}