const propiedadIntelectualSection = [
    { id: 0, name: "legalRequirements.content7" },
    { id: 1, name: "legalRequirements.content8" }
];
const firstDescription = [
    { id: 0, name: "legalRequirements.content78" },
    { id: 1, name: "legalRequirements.content14" },
    { id: 2, name: "legalRequirements.content15" },
    { id: 3, name: "legalRequirements.content16" },
    { id: 5, name: "legalRequirements.content17" },
    { id: 6, name: "legalRequirements.content18" },
    { id: 7, name: "legalRequirements.content19" },

];

const condicionesAccesoSection = [
    { id: 0, name: "legalRequirements.content9" },
    { id: 1, name: "legalRequirements.content10" },
    { id: 2, name: "legalRequirements.content11" },
    { id: 3, name: "legalRequirements.content12" },
];

const secondSectionDescriptionOne = [
    { id: 0, name: "legalRequirements.content59" },
    { id: 1, name: "legalRequirements.content20" },
    { id: 3, name: "legalRequirements.content21" },
    { id: 4, name: "legalRequirements.content79" },

];

const secondSectionDescriptionThree = [
    { id: 0, name: "legalRequirements.content80" },
    { id: 1, name: "legalRequirements.content81" },
    { id: 2, name: "legalRequirements.content82" }
];
const secondSectionDescriptionFour = [
    { id: 0, name: "legalRequirements.content83" },
    { id: 1, name: "legalRequirements.content84" },
    { id: 2, name: "legalRequirements.content85" },
];

const secondSectionDescriptionFifth = [
    { id: 0, name: "legalRequirements.content86" },
    { id: 1, name: "legalRequirements.content87" },
    { id: 2, name: "legalRequirements.content88" },
];

const secondSectionDescriptionSixth = [
    { id: 0, name: "legalRequirements.content89" },
    { id: 1, name: "legalRequirements.content90" },
    { id: 2, name: "legalRequirements.content91" },
];

const secondSectionDescriptionSeventh = [
    { id: 0, name: "legalRequirements.content76" },
    { id: 1, name: "legalRequirements.content77" },
];

const secondSectionDescriptionEighth = [
    { id: 0, name: "legalRequirements.content37" },
    { id: 1, name: "legalRequirements.content38" },
    { id: 2, name: "legalRequirements.content39" },
    { id: 3, name: "legalRequirements.content40" },
    { id: 4, name: "legalRequirements.content41" },
    { id: 5, name: "legalRequirements.content42" },
    { id: 6, name: "legalRequirements.content43" },
    { id: 7, name: "legalRequirements.content44" },
    { id: 8, name: "legalRequirements.content45" },
    { id: 9, name: "legalRequirements.content46" },
];

const secondSectionDescriptionNineth = [
    { id: 0, name: "legalRequirements.content47" },
    { id: 1, name: "legalRequirements.content48" },
];

const secondSectionDescriptionTenth = [
    { id: 0, name: "legalRequirements.content50" },
    { id: 1, name: "legalRequirements.content51" },
    { id: 2, name: "legalRequirements.content52" },
];

const responsabilidadesSection = [
    { id: 0, name: "legalRequirements.content53" },
    { id: 1, name: "legalRequirements.content54" },
    { id: 3, name: "legalRequirements.content55" },
    { id: 4, name: "legalRequirements.content56" },
    { id: 5, name: "legalRequirements.content57" },
];

//Pinta subtitulos dentro de una seccion con sus parrafos
const politicaPrivacidadSectionSubtitles = [
    { id: 0, subtitle: "", description: firstDescription },
    { id: 1, subtitle: "legalRequirements.subtitle4", description: secondSectionDescriptionOne },
    { id: 2, subtitle: "legalRequirements.subtitle5", description: [] },
    { id: 3, subtitle: "legalRequirements.content22", description: secondSectionDescriptionThree },
    { id: 4, subtitle: "legalRequirements.content63", description: secondSectionDescriptionFour },
    { id: 5, subtitle: "legalRequirements.content26", description: secondSectionDescriptionFifth },
    { id: 6, subtitle: "legalRequirements.content30", description: secondSectionDescriptionSixth },
    { id: 7, subtitle: "legalRequirements.subtitle6", description: secondSectionDescriptionSeventh },
    { id: 8, subtitle: "legalRequirements.subtitle7", description: [{ id: 0, name: "legalRequirements.content36" }] },
    { id: 9, subtitle: "legalRequirements.subtitle8", description: secondSectionDescriptionEighth },
    { id: 10, subtitle: "legalRequirements.subtitle9", description: secondSectionDescriptionNineth },
    { id: 11, subtitle: "legalRequirements.subtitle10", description: [{ id: 0, name: "legalRequirements.content49" }] },
    { id: 12, subtitle: "legalRequirements.subtitle11", description: secondSectionDescriptionTenth },
];

//Pinta el titulo de cada seccion con sus parrafos
export const sections = [
    { id: 0, title: "legalRequirements.subtitle1", elements: propiedadIntelectualSection },
    { id: 1, title: "legalRequirements.subtitle2", elements: condicionesAccesoSection },
    { id: 2, title: "legalRequirements.subtitle3", elements: politicaPrivacidadSectionSubtitles },
    { id: 3, title: "legalRequirements.subtitle12", elements: responsabilidadesSection},
];

export const introductionList = [
    { id: 0, name: "legalRequirements.company1" },
    { id: 1, name: "legalRequirements.company2" },
    { id: 2, name: "legalRequirements.company3" },
    { id: 3, name: "legalRequirements.company4" },
    { id: 4, name: "legalRequirements.company5" },
    { id: 5, name: "legalRequirements.company6" },
    { id: 6, name: "legalRequirements.company7" },
    { id: 7, name: "legalRequirements.company8" },
    { id: 8, name: "legalRequirements.company9" },
    { id: 9, name: "legalRequirements.company10" },
    { id: 10, name: "legalRequirements.company11" },
    { id: 11, name: "legalRequirements.company12" },
    { id: 12, name: "legalRequirements.company13" },
];

export const introductionDescription = [
    { id: 0, name: "legalRequirements.content2" },
    { id: 1, name: "legalRequirements.content3" },
    { id: 2, name: "legalRequirements.content4" },
    { id: 3, name: "legalRequirements.content5" },
    { id: 4, name: "legalRequirements.content6" },
];