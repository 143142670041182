import React  from "react";
import ButtonFull from "../Buttons/ButtonFull";
import { useTranslation } from "react-i18next";
import styles from "./ButtonsStyles";
import { Box } from "@mui/material";
import ButtonOutlined from "../../PortalCandidato/components/Dashboard/buttonOutlined";

interface IAddToCVActionButtons{
    onSaveClick?:any, 
    onSaveAndActionClick?:any, 
    onCancelClick?:any, 
    isUpdate?:any,
    id?: any,
    saveAndActionText?:string,
}

function AddToCVActionButtons(
    { 
    id,
    onSaveClick, 
    onCancelClick, 
    isUpdate ,
    saveAndActionText,
    onSaveAndActionClick
   }:IAddToCVActionButtons
    
) {
    const { t } = useTranslation();

    return (
        <Box sx={styles.action_buttons_grid}>
            <Box
                style={{ gridArea: "cancel" }}>
                <ButtonOutlined
                    id={id}
                    size="100%"
                    text={t("common.cancel")}
                    onClick={onCancelClick}
                />
           </Box>
            <Box
                style={{ gridArea: "save" }}>
                <ButtonFull
                    id={id}
                    size="100%"
                    text={ isUpdate ? t("common.update") : t("common.save")}
                    onClick={onSaveClick}
                />
           </Box>
           <Box
                style={{ gridArea: "save_and_attach" }}>
                <ButtonOutlined
                    size="100%"
                    text={saveAndActionText}
                    onClick={onSaveAndActionClick}
                />
            </Box>
       </Box>
    );
}

export default AddToCVActionButtons;