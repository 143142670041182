import React, { useCallback, useContext, useState } from 'react';
import { Box, Icon, CircularProgress, Typography } from "@mui/material";
import styles from './styles';
import { base64ToArrayBuffer, getDocTypes, returnMimeData } from '../../../../utils/functions';
import TrashRed from '../../../../../assets/icons/trashRed';
import DownloadDocument from '../../../../../assets/icons/downloadDocument';
import { deleteCandidateDocuments, downloadDocumentInform } from '../../../../hooks/userApi';
import { useApi } from '../../../../hooks/useApi';
import { SnackContext, SnackSeverity } from '../../../../contexts/SnackHandler';
import { useTranslation } from 'react-i18next';
import AlertDialog from '../../../../components/AlertDialog/alertDialog';
import { idAplicacionGestor } from '../../../../../constants';
import { IDocumentItemListProps, IDocumentType } from '../../../../interfaces/DocumentsInterfaces';

function DocumentoListItem(props: IDocumentItemListProps) {
    const callApi = useApi();
    const { t } = useTranslation();
    const { documento, langId, getDocuments, multiDocument = null } = props as IDocumentItemListProps;
    const documentTypes = getDocTypes(langId) as IDocumentType[];
    const showSnack = useContext(SnackContext);

    const [loadingIcons, setloadingIcons] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const deleteDocument = useCallback(() => {
        setloadingIcons(true);
        setIsDialogOpen(false);
        if (multiDocument) {
            callApi(deleteCandidateDocuments(langId, documento.idDocumento)).then(() => {
                    getDocuments();
            }).catch(() => {
                showSnack(t("documents.error_delete"), SnackSeverity.ERROR);
                setloadingIcons(false);
            });
        } else {
        callApi(deleteCandidateDocuments(langId, documento.idDocumento)).then(() => {
            if (documento.idDocumento2) {
                callApi(deleteCandidateDocuments(langId, documento?.idDocumento2)).then(() => {getDocuments();});
            } else {
                getDocuments();
            }
        }).catch(() => {
            showSnack(t("documents.error_delete"), SnackSeverity.ERROR);
            setloadingIcons(false);
        });
        }
    }, [documento]);

    const openDialog = useCallback(() => {setIsDialogOpen(true);}, []);

    function downloadDoc(id: number, nombre: string | undefined) {
        callApi(downloadDocumentInform(id)).then((result) => {
            const url = URL.createObjectURL(
                new Blob([base64ToArrayBuffer(result)], {
                    type: returnMimeData(nombre),
                })
            );
            const fileLink = document.createElement("a");
            fileLink.href = url;
            fileLink.download = nombre || "";
            fileLink.click();
            setloadingIcons(false);
          }).catch(() => {
            showSnack(t("curriculum.error_download"), SnackSeverity.ERROR);
            setloadingIcons(false);
        });
    }
    const handleDownloadDocument = useCallback(() => {
        setloadingIcons(true);
        if (multiDocument) {
            multiDocument.forEach((element: any) => {
                downloadDoc(element.idDocumento, element.nombre);
            });
        } else {
            downloadDoc(documento.idDocumento, documento.nombre);
            if (documento.idDocumento2) {
                downloadDoc(documento.idDocumento2, documento.nombre2);
            }
        }
    }, [documento]);

    return (
        <Box sx={styles.item}>
            <Box sx={styles.upperRow}>
                <Box sx={styles.title}>
                    {documentTypes.find((document: IDocumentType) => document.id === documento.idTipoDocumento)?.name}
                </Box>
                <Box sx={styles.buttons}>
                    {loadingIcons ?
                        <CircularProgress size={"18px"}/>
                        :
                        <>
                            <Icon color="primary" sx={styles.icon} onClick={handleDownloadDocument}>
                                <DownloadDocument width={"16px"} height={"16px"}/>
                            </Icon>
                            {documento.idAplicacion !== idAplicacionGestor ?
                                <Icon color="primary" sx={styles.icon} onClick={openDialog}>
                                    <TrashRed width={"16px"} height={"16px"}/>
                                </Icon> : null
                            }
                            
                        </>}
                </Box>
            </Box>
            {multiDocument?.length ? multiDocument?.map((doc: any) => 
                <Box key={doc.nombre} sx={styles.lowerRow}>
                    <Typography sx={styles.lowerText}>{doc.nombre}</Typography>
                </Box>
            ) : <>
                <Box sx={styles.lowerRow}>
                    <Typography sx={styles.lowerText}>{documento.nombre}</Typography>
                </Box>
                {documento.nombre2 ? <Box sx={styles.lowerRow}>
                    <Typography sx={styles.lowerText}>{documento.nombre2}</Typography>
                </Box> : null}
            </>
            }
            <AlertDialog
                title={t("documents.delete_document")}
                open={isDialogOpen}
                handleClose={() => {setIsDialogOpen(false);}}
                handleAccept={deleteDocument}
            />
        </Box>
    );
}
export default DocumentoListItem;