import React, { useState, useCallback, useEffect, useContext } from "react";
import styles from "./styles";
import { Button, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SearchMenuIcon from "../../../../../assets/icons/search_menu_icon";
import ReactGA from 'react-ga4';
import Home from "@mui/icons-material/HomeOutlined";
import Clear from "@mui/icons-material/ClearRounded";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CVMenuIcon from "../../../../../assets/icons/cv_menu_icon";
import DocumentsMenu from "../../../../../assets/icons/documents_menu_icon";
import CloseMenu from "../../../../../assets/icons/closesession_menu_item";
import { useTranslation } from "react-i18next";
import { AuthService } from "../../../auth/AuthService";
import { MenuAction, Routes } from "../../../../../shared/enums/enums";
import { getToken } from "../../../../../shared/hooks/useApi";
import ButtonFull from "../../../../components/Buttons/ButtonFull";
import ButtonOutlined from "../../Dashboard/buttonOutlined";
import { Languages } from "../../../../components/Languages/Languages";
import home from '../../../../images/home.svg';
import { AuthContext } from "../../../auth/AuthWrapper";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { sendGAEvent } from "../../../../utils/functions";
import CookieDialog from "../../../../components/Cookies/cookieDialog";

const auth = new AuthService();

declare global {
  interface Window { _env_: any; }
}

window._env_ = window._env_ || {};


function Header() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookie = JSON.parse(localStorage.getItem("cookie") as any);
  const cookieb2c = localStorage.getItem("cookieb2c") as any;
  const cookiesAnalytics = cookie?.statistics && cookie?.marketing;
  const location = useLocation();
  const theme = useTheme<any>();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));
  const matchesMobileSx = useMediaQuery('(max-width: 500px)');
  const xuntaLogoMediaQuery = useMediaQuery('(max-width:1170px)');
  const { basicDataFilled } = useContext(AuthContext); 

  const [openMenu, setOpenMenu] = useState(false);
  const [logged, setLogged] = useState(false);
  const [openDialogCookie, setOpenDialogCookie] = useState<boolean | string | null>(localStorage.getItem("cookie"));
  const [configurateCookies, setConfigurateCookies] = useState<boolean>(false);

  useEffect(() => {
    getLogged().then((log) => setLogged(!!log));
  }, []);

  const cleanCookieb2cNotAccepted = () =>{
    if((!cookie && cookie === false)|| (cookie && !cookiesAnalytics) && cookieb2c){
      localStorage.removeItem("cookieb2c");
    }
  };

  useEffect(() => {
    if (cookie === true || cookie?.preference || cookiesAnalytics) {
      ReactGA.initialize(window._env_.REACT_APP_GA_MEASUREMENT_ID, {
        gaOptions: { send_page_view: false },
      });
      if (cookieb2c && (cookie === true || cookiesAnalytics)) {
        sendGAEvent(cookieb2c);
        localStorage.removeItem("cookieb2c");
      }
    }
    cleanCookieb2cNotAccepted();
  }, [cookie]);

  
  const getLogged = useCallback(async () => {
    const data = await getToken();
    return (data);
  }, []);

  const onMenuClick = useCallback((e: React.MouseEvent<HTMLElement>) => setOpenMenu(e.currentTarget.getAttribute("id") === MenuAction.OPEN ? true : false), []);
  const onNavClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    navigate(e.currentTarget.getAttribute("id") || Routes.RETORNA);
    setOpenMenu(e.currentTarget.getAttribute("id") === MenuAction.OPEN ? true : false);
  }, []);
  const logIn = useCallback(() => {
    sendGAEvent("Acceso candidatos (login o registro)");
    auth.login();
  }, []);
  const logOut = useCallback(() => auth.logout(), []);
  const goToDashboard = useCallback(() => (location.pathname != Routes.BASIC_DATA) && chooseDashboardOrRetorna(), [logged]);

  const chooseDashboardOrRetorna = () => {
    return logged ? navigate(Routes.DASHBOARD) : navigate(Routes.RETORNA);
  };

  const returnButtonBackgroundCompanies = ()=>{
    return location?.pathname === Routes.EMPRESAS_RETORNA ? "#D50000": "#F4FBFF";
  };

  const returnButtonColorCompanies = ()=>{
    return location?.pathname === Routes.EMPRESAS_RETORNA ? "white": "#86b5e2";
  };

  const dialogs = [
    {
      id: 0,
      title: t("cookies.cookie_title"),
      open: !openDialogCookie,
      description: t("cookies.cookie_message"),
      handleClose: () => setOpenDialogCookie(true),
      setConfigurateCookies,
      isCookies: true,
      isConfigurationCookies: false

    },
    {
      id: 1,
      title: t("cookies.cookie_config"),
      open: configurateCookies,
      description: t("cookies.cookie_config"),
      handleClose: () => setConfigurateCookies(false),
      setConfigurateCookies,
      isCookies: false,
      isConfigurationCookies: true
    },
  ];

  function returnMenuItems() {
    if (matchesMobile) {
      if (logged) {
        return (
          <>
            {openMenu ? (
              <Button title={"icons_tooltips.clear"} id={MenuAction.CLOSE} onClick={onMenuClick}><Clear color="primary" /></Button>
            ) : (
              <Button style={{alignSelf: "center"}} title={"icons_tooltips.menu"} id={MenuAction.OPEN} onClick={onMenuClick}><MenuRoundedIcon color="primary" /></Button>
            )}
            <Box
              sx={{ ...styles.mobileMenuContainer, height: openMenu ? "200px" : 0 }}
            >
              {location.pathname !== Routes.BASIC_DATA &&
                <>
                  <Box
                    sx={styles.mobileMenuItemContainer}
                    id={Routes.DASHBOARD}
                    onClick={onNavClick}
                  >
                    <Box title={"icons_tooltips.home"}><Home style={{ fill: "#DA291C" }} /></Box>
                    <Typography variant="body1" sx={styles.menuItem}>
                      {t("header.menu_item_0")}
                    </Typography>
                  </Box>
                  <Box
                    sx={styles.mobileMenuItemContainer}
                    id={Routes.DATOS_PERSONALES}
                    onClick={onNavClick}
                    style={{paddingLeft: "18px"}}
                  >
                    <PersonOutlineOutlinedIcon color="primary" style={{stroke: "white"}}/>
                    <Typography variant="body1" sx={styles.menuItem} style={{paddingLeft: "3px"}}>
                      {t("header.menu_item_8")}
                    </Typography>
                  </Box>
                  <Box
                    sx={styles.mobileMenuItemContainer}
                    id={Routes.CURRICULUM}
                    onClick={onNavClick}
                  >
                    <CVMenuIcon title={"icons_tooltips.curriculum"} />
                    <Typography variant="body1" sx={styles.menuItem}>
                      {t("header.menu_item_2")}
                    </Typography>
                  </Box>
                  {/* <Box
                    sx={styles.mobileMenuItemContainer}
                    id={Routes.CANDIDATURAS}
                    onClick={onNavClick}
                  >
                    <CandidaturaMenu title={"icons_tooltips.candidaturas"} />
                    <Typography variant="body1" sx={styles.menuItem}>
                      {t("header.menu_item_1")}
                    </Typography>
                  </Box> */}
                  <Box
                    sx={styles.mobileMenuItemContainer}
                    id={Routes.SEARCH_OFFERS}
                    onClick={onNavClick}
                  >
                    <SearchMenuIcon title={"icons_tooltips.search"} />
                    <Typography variant="body1" sx={styles.menuItem}>
                      {t("header.menu_item_6")}
                    </Typography>
                  </Box>
                  <Box
                    sx={styles.mobileMenuItemContainer}
                    id={Routes.DOCUMENTS}
                    onClick={onNavClick}
                  >
                    <DocumentsMenu title={"icons_tooltips.search"} />
                    <Typography variant="body1" sx={styles.menuItem}>
                      {t("header.menu_item_10")}
                    </Typography>
                  </Box>
                </>
              }

              <Box
                sx={styles.mobileMenuItemContainer}
                onClick={logOut}
              >
                <CloseMenu title={"icons_tooltips.close_menu"} />
                <Typography variant="body1" sx={styles.menuItem}>
                  {t("header.menu_item_5")}
                </Typography>
              </Box>
            </Box>
          </>
        );
      } else {
        return <Box m={"4px 0"} sx={{width: "100%", display: "flex", justifyContent: "flex-end", padding: "0 6px"}}>        
                <ButtonFull
                    text={t("header.menu_item_7")}
                    onClick={logIn}
                    color={"#3173B1"}
                    fontSize={"13px"}
                    fontWeight={400}
                    customClassSx={{border: "1px solid white",
                    margin: "0 4px 0 2px",
                    width: matchesMobileSx ? '50%' : 'unset'}}
                  />
                  <ButtonOutlined
                    fontWeight={400}
                    text={t("header.menu_item_9")}
                    color={returnButtonColorCompanies()}
                    backgroundColor={returnButtonBackgroundCompanies()}
                    onClick={() => navigate(Routes.EMPRESAS_RETORNA)}
                    fontSize={"13px"}
                    customClassSx={{
                      letterSpacing: "0.5px",
                      fontWeight: 400,
                      width: matchesMobileSx ? '50%' : 'unset'
                    }}
                  />
            </Box>;
      }
    } else {
      if (logged) {
        return (
          <Box sx={styles.menuLangContainer}>
            <Box sx={styles.menuContainer}>
              {location.pathname !== Routes.BASIC_DATA &&
                <>
                  <Typography
                    variant="body1"
                    sx={styles.menuItem}
                    id={Routes.DASHBOARD}
                    onClick={onNavClick}
                  >
                    {t("header.menu_item_0")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={styles.menuItem}
                    id={Routes.DATOS_PERSONALES}
                    onClick={onNavClick}
                  >
                    {t("header.menu_item_8")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={styles.menuItem}
                    id={Routes.CURRICULUM}
                    onClick={onNavClick}
                  >
                    {t("header.menu_item_2")}
                  </Typography>
                  {/* <Typography
                    variant="body1"
                    sx={styles.menuItem}
                    id={Routes.CANDIDATURAS}
                    onClick={onNavClick}
                  >
                    {t("header.menu_item_1")}
                  </Typography> */}
                  <Typography
                    variant="body1"
                    sx={styles.menuItem}
                    id={Routes.SEARCH_OFFERS}
                    onClick={onNavClick}
                  >
                    {t("header.menu_item_6")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={styles.menuItem}
                    id={Routes.DOCUMENTS}
                    onClick={onNavClick}
                  >
                    {t("header.menu_item_10")}
                  </Typography>
                </>}
              <Typography
                variant="body1"
                sx={styles.menuItem}
                onClick={logged ? logOut : logIn}
              >
                {t(logged ? "header.menu_item_5" : "header.menu_item_7")}
              </Typography>
            </Box>
          </Box>
        );
      } else {
        return (
          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: "14px" }}>
            <Box sx={{mr: "12px"}}>        
                <ButtonFull
                    text={t("header.menu_item_7")}
                    onClick={logIn}
                    color={"#3173B1"}
                    fontSize={"13px"}
                    fontWeight={400}
                    customClassSx={{border: "1px solid white",
                    margin: "0 4px 0 2px",
                    width: 'unset'}}
                  />
                  <ButtonOutlined
                    fontWeight={400}
                    text={t("header.menu_item_9")}
                    color={returnButtonColorCompanies()}
                    backgroundColor={returnButtonBackgroundCompanies()}
                    onClick={() => navigate(Routes.EMPRESAS_RETORNA)}
                    fontSize={"13px"}
                    customClassSx={{
                      marginLeft: "16px",
                      letterSpacing: "0.5px",
                      marginRight: "10px",
                      fontWeight: 400,
                    }}
                  />
            </Box>
            <Box sx={styles.languages}><Languages/></Box>
            <Box sx={styles.home_separator}>
              <Box sx={styles.home_icon} onClick={() => navigate(Routes.RETORNA)} component="img" src={home} alt="Home Logo" />
            </Box>
          </Box>
        );
      }
    }
  }

  function returnLogout() {
    return (
      <Box sx={styles.menuLangContainer}>
        <Typography
          variant="body1"
          sx={styles.menuItem}
          onClick={logOut}
        >
          {t("header.menu_item_5")}
        </Typography>
      </Box>
    );
  }

  function returnImage() {
    if (matchesMobile) {
      if (openMenu) {
        return null;
      } else {
        return (
            <Box sx={{display: "flex", width: "100%", justifyContent: "space-between"}}>
              <Box sx={!logged ? { ...styles.alignTitleLanguage } : {}} >
                <Box sx={{...styles.headerTitleClaim, width: "unset"}}
                onClick={goToDashboard}>
                  <Box component="img" alt="Xunta de Galicia" src="/images/cpei-positivo.png" sx={styles.xuntaLogo} style={{ minWidth: '200px', height: "32px", marginTop: "10px"}} />
                </Box>
              </Box>
              {!logged &&  <Box sx={{display: "flex", justifyContent: "center", paddingRight: "6px"}}><Languages matchesMobile={matchesMobile} marginTop={"0px"} /></Box>  }
            </Box>
        );
      }
    } else {
      return (
        logged ? <Box sx={{...styles.headerTitleClaim, display: "flex", flexDirection: "row", margin: "20px 20px"}} onClick={goToDashboard}>
          <Box component="img" alt="Xunta de Galicia" src="/images/cpei-positivo.png" sx={styles.xuntaLogo} style={{ width: xuntaLogoMediaQuery ? '100%' : '320px', marginRight: "20px", minWidth: xuntaLogoMediaQuery ? 'unset' : '300px', height: xuntaLogoMediaQuery ? "28px" : "unset" }} />
        </Box> : 
        <Box sx={{...styles.headerTitleClaim, display: "flex", flexDirection: "row", margin: "20px 20px"}} onClick={goToDashboard}>
          <Box component="img" alt="Xunta de Galicia" src="/images/cpei-positivo.png" sx={styles.xuntaLogo} />
          <Box component="img" alt="Nortempo" src="/images/nortempo_fundation.svg" sx={styles.nortempoLogo} />
      </Box>
);
    }
  }

  function getItems() {
    return location.pathname === Routes.BASIC_DATA || (logged && !basicDataFilled) ? returnLogout() : returnMenuItems();
  }

  function returnDialogsCookies() {
    return dialogs.map((d) => {
      return (
        <Box key={d.id}>
          <CookieDialog
            title={d.title}
            open={d.open}
            description={d.description}
            handleClose={d.handleClose}
            setConfigurateCookies={d.setConfigurateCookies}
            isCookies={d.isCookies}
            isConfigurationCookies={d.isConfigurationCookies} />
        </Box>
      );
    });
  }

  return (
    <Box sx={{display: "flex", zIndex: "20", width: "100%", flexDirection: "column", position: "fixed"}}>
      <Box>
        <Box sx={{...styles.headerContainer, flexDirection: matchesMobile && logged || !matchesMobile ? 'row' : 'column'}}>
          {returnImage()}
          {getItems()}
        </Box>
      </Box>
      {returnDialogsCookies()}
    </Box>
  );
}
export default Header;
/* eslint-enable */