import React, {useContext} from "react";
import { makeStyles } from "@mui/styles";
import { formatToLocalDate, getAreasEstudio, getDiscapacityOptions, 
         getIdiomasList, getTiposVia, getTiposCambioResidencia, 
         getTiposDesplazamientos, 
         getNationalityOptions, getNivelesEstudio, getNivelesIdioma, 
         getPermisosConduccion, getProvincias, getYear } from "../../utils/functions";
         import i18next from "../../../i18n";
import { useTranslation } from "react-i18next";
import { MaestroContext } from "../../contexts/MaestroContext";
import { SPAIN } from "../../../constants";

const provinces=getProvincias(i18next.languages[0]);
const drivingLicenses=getPermisosConduccion(i18next.languages[0]);
const niveles_de_estudio=getNivelesEstudio(i18next.languages[0]);
const areas_estudio=getAreasEstudio(i18next.languages[0]);
const languagesList=getIdiomasList(i18next.languages[0]);
const languagesLevels=getNivelesIdioma(i18next.languages[0]);
const typeStreet=getTiposVia(i18next.languages[0]);const nationalities= getNationalityOptions(i18next.languages[0]);
const disabled=getDiscapacityOptions(i18next.languages[0]);
const tipos_cambio_residencia=getTiposCambioResidencia(i18next.languages[0]);
const tipos_desplazamientos=getTiposDesplazamientos(i18next.languages[0]);

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "grey",
    paddingLeft: 20,
    overflowX: "hidden",
  },
  container_grid: {
    display: "flex",
    flexDirection: "column",
  },
  base: {
    paddingTop: "50px",
    backgroundColor: "white",
    maxWidth: "792px",
    minHeight: "1188px",
    display: "grid",
    gridTemplateColumns: ".5fr 1fr",
    gridColumnGap: "26px",
    gridTemplateAreas: "'left right'",
  },
  left_col_data_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    gridArea: "left",
  },
  picture_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "180px",
    width: "180px",
    borderRadius: "6px",
    backgroundImage: "var(--bg-url)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#c5c5c5",
    position: "relative",
    marginBottom: "19px",
  },
  left_col_data: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  title_small: {
    fontSize: "11px",
    fontWeight: 600,
    padding: "0px",
    margin: "0px",
    lineHeight: "16px",
  },
  text_small: {
    fontSize: "11px",
    padding: "2px",
    margin: "0px",
    lineHeight: "16px",
    textTransform: "capitalize",
  },
  digital_profile_link: {
    fontSize: "11px",
    padding: "0px",
    margin: "0px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    wordBreak: "break-all",
  },
  perfiles_digitales: {
    marginTop: "12px",
    display: "flex",
    flexDirection: "column",
  },
  personal_data_grid: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: ".4fr .6fr",
    columnGap: "20px",
    rowGap: "20px",
  },
  fullname_style: {
    fontSize: "24px",
    fontWeight: 900,
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  font_base: {
    fontSize: "11px",
  },
  contact_grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "8px",
  },
  title_section: {
    fontSize: "18px",
    fontWeight: 900,
    marginBottom: "16px",
    padding: "0px",
  },
  experiences_grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "10px",
  },
  experience_header: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "3px",
  },
  title_section_1: {
    fontSize: "14px",
    fontWeight: 900,
    lineHeight: "21px",
  },
  inner_grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "4px",
  },
  title_section_2: {
    fontSize: "11px",
    fontWeight: 600,
    lineHeight: "21px",
  },
  experiences_dates: {
    fontSize: "11px",
    color: "#949494",
  },
  referencia_nombre: {
    fontWeight: 900,
  },
  your_data_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "24px",
    rowGap: "24px",
  },
  your_data_inner_container: {
    fontSize: "11px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "5px",
  },
  formacion_header: {
    fontSize: "11px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  formacion_title: {
    fontSize: "11px",
    fontWeight: 900,
  },
  languages_inner_grid: {
    fontSize: "11px",
    display: "grid",
    gridTemplateColumns: ".35fr .65fr",
    rowGap: "3px",
  },
  certificate_inner_list: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "3px",
    maxWidth: "100%",
    overflow: "hidden",
  },
  other_data_grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "12px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  header_img: {
    width: "107px",
    height: "24px",
  },
  list_element: {
    fontSize: "11px",
    marginLeft: "7px",
    overflowWrap: "break-word",
    listStylePosition: "inside",
  },
  knowledge_text: {
    margin: "0px",
    padding: "0px",
    overflowWrap: "break-word",
  },
  no_word_break: {
    whiteSpace: "nowrap",
  },
  footer_left_part: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content min-content",
    rowGap: "6px",
  },
}));

function TemplatePDF(props) {
  const classes = useStyles();
  const {
    reference,
    personalData,
    experiences,
    formations,
    languages,
    knowledges,
    picture,
    otherData,
  } = props;
  const { t } = useTranslation();
  const { localidades, paises, tipoIdentficacion , tipoHorario} = useContext(MaestroContext);

  const returnCityOrRegion = () => {
    const city =  (personalData.pais !== SPAIN && personalData.ciudad) && personalData.ciudad;
    const localidad = (personalData.localidad) &&  localidades?.find((r) => Number(r.id) === Number(personalData.localidad))?.name;
    return localidad || city; 
  };

  const returnCountry = ()=>{
   return personalData.pais && paises?.find((c) => Number(c.id) === Number(personalData.pais))?.name;
  };

  const getProvincesWithoutName = () => {
    const getCurrentProvince = provinces?.find((r) => Number(r.id) === Number(personalData.provincia))?.name;
    const findProvinceChoosed = provinces.filter((prov) => Number(prov.id) === personalData.provincia);
    const idCountry = findProvinceChoosed[0].id_country;
    const getNameforProvince = paises.filter((n) => Number(n.id) === Number(idCountry))[0]?.name;

    return getCurrentProvince !== "" ? getCurrentProvince : getNameforProvince;
  };

  const hasDirection = () => {
    return (
      (personalData.localidad &&
        personalData.localidad.toString().trim() !== "") ||
      (personalData.codigoPostal && personalData.codigoPostal.trim() !== "") ||
      (personalData.provincia && personalData.provincia !== "") ||
      (personalData.tipoVia && personalData.tipoVia.trim() !== "") ||
      (personalData.nombreVia && personalData.nombreVia.trim() !== "")
    );
  };

  const handleIdentificationType = () =>{
    return personalData.tipoIdentificacion && 
          tipoIdentficacion?.find((c) => Number(c.id) === Number(personalData.tipoIdentificacion))?.name;
  };

  function renderProfilePicAndMore() {
    return (
      <div id="element_left_col" className={classes.left_col_data_container}>
        <div
          className={classes.picture_container}
          style={{
            "--bg-url": picture
              ? `url(${picture})`
              : "url(/images/defaultProfile.jpg)",
          }}
        />
        <div className={classes.left_col_data}>
          {hasDirection() && (
            <div>
              <p className={classes.title_small}>{t("templateCv.direction")}</p>
              <p className={classes.text_small}>
                {returnCityOrRegion()}
              </p>
              <p className={classes.text_small}>{`${
                personalData.codigoPostal ? personalData.codigoPostal : ""
              }
              ${ personalData.provincia ? getProvincesWithoutName() : ""} (${returnCountry()})`}
              </p>
              <p className={classes.text_small}>{`${
                personalData.tipoVia
                  ? typeStreet
                      .find((r) => r.id.trim() === personalData.tipoVia.trim())
                      ?.name.toLowerCase() ?? ""
                  : ""
              }
            ${
              personalData.nombreVia
                ? personalData.nombreVia.trim().toLowerCase()
                : ""
            }`}</p>
              {personalData.numeroVia &&
              personalData.numeroVia.trim() !== "" ? (
                <p className={classes.text_small}>
                  {`nº${personalData.numeroVia.trim()}${
                    personalData.piso && personalData.piso.trim() !== ""
                      ? `, ${personalData.piso.trim()}${
                          personalData.puerta &&
                          personalData.puerta.trim() !== ""
                            ? personalData.puerta.trim()
                            : ""
                        }`
                      : ""
                  }${
                    personalData.bloque && personalData.bloque.trim() !== ""
                      ? `, Bloque: ${personalData.bloque.trim()}`
                      : ""
                  }`}
                </p>
              ) : null}
            </div>
          )}
          {personalData.nacionalidad &&
            personalData.nacionalidad.toString().trim() !== "" && (
              <div style={{ marginTop: "12px" }}>
                <p className={classes.title_small}>{t("templateCv.nationality")}</p>
                <p className={classes.text_small}>
                  {personalData.nacionalidad
                    ? nationalities.find(
                        (r) =>
                          Number(r.id) === Number(personalData.nacionalidad)
                      )?.name ?? ""
                    : ""}
                </p>
              </div>
            )}
          <div style={{ marginTop: "12px" }}>
            <p className={classes.title_small}>{handleIdentificationType()}</p>
            <p className={classes.text_small} style={{ textTransform: "none" }}>
              {personalData.identificacion}
            </p>
          </div>
          {personalData.idDiscapacidad && personalData.idDiscapacidad !== 1 ? (
            <div style={{ marginTop: "12px" }}>
              <p className={classes.text_small}>{`Discapacidad: ${
                disabled.find(
                  (r) => Number(r.id) === Number(personalData.idDiscapacidad)
                )?.name ?? ""
              }`}</p>
            </div>
          ) : null}
          {personalData.perfilesDigitales &&
          personalData.perfilesDigitales.length > 0 ? (
            <div className={classes.perfiles_digitales}>
              {renderDigitalProfiles(personalData.perfilesDigitales)}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  function renderDigitalProfiles(arr = []) {
    return arr.map((p) => (
      <a href={p.url} key={p.id} className={classes.digital_profile_link}>
        {p.url}
      </a>
    ));
  }

  function renderPersonalData() {
    return (
      <div className={classes.personal_data_grid} id="personal_data">
        <div className={classes.fullname_style}>{`${
          personalData.nombre || ""
        } ${personalData.primerApellido || ""} ${
          personalData.segundoApellido || ""
        }`}</div>
        {personalData.presentacion && personalData.presentacion.trim() && (
          <p className={classes.font_base} style={{ width: "225px" }}>
            {personalData.presentacion}
          </p>
        )}
        <div className={classes.contact_grid}>
          <a href="#" className={classes.digital_profile_link}>
            {personalData.correoElectronico}
          </a>
          {personalData.telefono && (
            <div
              className={classes.font_base + " " + classes.no_word_break}
            >{`+${personalData.telefono.prefijo} ${personalData.telefono.numero}`}</div>
          )}
          {personalData.otroTelefono && personalData.otroTelefono.prefijo && (
            <div
              className={classes.font_base}
            >{`+${personalData.otroTelefono.prefijo} ${personalData.otroTelefono.numero}`}</div>
          )}
          {personalData.telefonoInternacional && (
            <div
              className={classes.font_base}
            >{`${getPlusInternationalPhone(personalData.telefonoInternacional)}${personalData.telefonoInternacional}`}</div>
          )}
          {personalData.fechaNacimiento && (
            <div className={classes.font_base}>
              {formatToLocalDate(new Date(personalData.fechaNacimiento))}
            </div>
          )}
        </div>
      </div>
    );
  }

  function getPlusInternationalPhone(phone){
   return !phone.includes("+") ? "+" :"";
  }

  function formatMonth(date) {
    return new Date(date).toLocaleString("default", { month: "long" });
  }

  
  function printMaxOrMinSalary(exp) {
    const textSalaryMin = `${"Salario mínimo:"} ${exp.salarioMinimo?.nombre} ${exp.tipoSalario?.nombre}`;
    const textSalaryMax = `${"Salario máximo:"} ${exp.salarioMaximo?.nombre} ${exp.tipoSalario?.nombre}`;

    if (exp.salarioMinimo) return textSalaryMin;
    else return textSalaryMax;
  }

  function getSalary(exp) {
    const salaryPerceived = `${exp.salarioMinimo?.nombre} - ${exp.salarioMaximo?.nombre} ${exp.tipoSalario?.nombre}`;

    if (exp.salarioMinimo && exp.salarioMaximo) return salaryPerceived;
    else return printMaxOrMinSalary(exp);
  }
  
  function renderExperience(experience) {
    return (
      <div
        className={`${classes.experiences_grid} ${classes.font_base}`}
        id="Experiences"
      >
        <div className={classes.experience_header}>
          <div className={classes.title_section_1}>
            {experience.puesto.nombre}
          </div>
          <div className={classes.nombre_empresa}>{experience.empresa}</div>
        </div>
        {experience.descripcionPuesto && (
          <div className={classes.inner_grid}>
            <div className={classes.title_section_2}>{t("templateCv.jobDescription")}</div>
            {experience.descripcionPuesto}
          </div>
        )}
        {experience.tipoSalario &&
          (experience.salarioMinimo || experience.salarioMaximo) && (
            <div className={classes.inner_grid}>
              <div className={classes.title_section_2}>{t("templateCv.salaryReceived")}</div>
              {getSalary(experience)}
            </div>
          )
        }
        {experience.nivel && (
          <>
            <div className={classes.languages_inner_grid}>
              <div className={classes.title_section_2}>{t("templateCv.level")}</div>
              <div className={classes.title_section_2}>{experience.personalACargo && `${"Personal a cargo"}`}</div>
              <div>{experience.nivel.nombre}</div>
              <div>{experience.personalACargo && experience.personalACargo.nombre}</div>
            </div>
            {experience.nivelResponsable &&
              <div className={classes.inner_grid}>
                <div className={classes.title_section_2}>{t("templateCv.level_manager")}</div>
                <div>{experience.nivelResponsable.nombre}</div>
              </div>
            }
          </>
        )}
        {experience.funciones && experience.funciones.length > 0 ? (
          <div className={classes.inner_grid}>
            <div className={classes.title_section_2}>{t("templateCv.task")}</div>
            {experience.funciones.map((fun) => (
              <li key={fun.idFuncion} className={classes.list_element}>{fun.nombre}</li>
            ))}
          </div>
        ) : null}
        <div className={classes.experiences_dates}>{`${formatMonth(
          experience.fechaInicio
        )} ${new Date(experience.fechaInicio).getFullYear()} - ${
          experience.fechaFin &&
          new Date(experience.fechaFin).getFullYear() !== 1
            ? formatMonth(experience.fechaFin) +
              " " +
              new Date(experience.fechaFin).getFullYear()
            : "Actualmente"
        }`}</div>
        {experience.conocimientos && experience.conocimientos.length > 0 ? (
          <div className={classes.inner_grid}>
            <div className={classes.title_section_2}>{t("templateCv.knowledge")}</div>
            {experience.conocimientos.map((fun, index) =>
              experience.conocimientos.length === index + 1 ? (
                <p key={fun.idConocimiento} className={classes.knowledge_text}>{fun.nombre}</p>
              ) : (
                <p key={fun.idConocimiento} className={classes.knowledge_text}>{`${fun.nombre}, `}</p>
              )
            )}
          </div>
        ) : null}
        {experience.referencias && experience.referencias.length > 0 ? (
          <div className={classes.experiences_grid}>
            <div>{t("templateCv.references")}</div>
            {experience.referencias.map((r) => (
              <div key={r.idReferencia}>
                <div  className={classes.referencia_nombre}>{r.nombre}</div>
                {r.telefono.numero ? (
                  <div>
                    {t("templateCv.phone")} +{r.telefono.prefijo} {r.telefono.numero}
                  </div>
                ) : (
                  <div />
                )}
                <a href="#" className={classes.digital_profile_link}>
                  {r.correoElectronico}
                </a>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }

  function renderExperiencesSegment1() {
    return (
      <div id="Experiences_segment_1">
        <div className={classes.title_section}>{t("templateCv.experience")}</div>
        {experiences && experiences.length > 0
          ? renderExperience(experiences[0])
          : null}
      </div>
    );
  }

  function renderExperiencesSegment2() {
    const auxArr = experiences.slice(1);
    return auxArr && auxArr.length > 0
      ? auxArr.map((experience) => renderExperience(experience))
      : null;
  }

  const nivelEstudio = (idNivelEstudios) => {
    const nivel = niveles_de_estudio.find(
      (nivel) => Number(nivel.id) === Number(idNivelEstudios)
    );
    return nivel ? nivel.name : "";
  };

  const areaEstudio = (idAreaEstudios) => {
    const area = areas_estudio.find(
      (area) => Number(area.id) === Number(idAreaEstudios)
    );
    return area ? area.name : "";
  };

  function renderSingleFormacionReglada(formacion) {
    return (
      <div
        className={classes.your_data_inner_container}
        id="singleFormacionReglada"
      >
        <div className={classes.formacion_header}>
          <div className={classes.formacion_title}>
            {nivelEstudio(formacion.idNivelEstudios)}
          </div>
        </div>
        <div>{formacion.titulo}</div>
        <div>{areaEstudio(formacion.idAreaEstudios)}</div>
        {formacion.centro && formacion.centro.trim() !== "" && (
          <div>{formacion.centro}</div>
        )}
        <div>{`${getYear(formacion.fechaInicio)} - ${
          formacion.esFormacionActual
            ? "Cursando actualmente"
            : getYear(formacion.fechaFin)
        }`}</div>
      </div>
    );
  }

  function renderSingleCertificacion(formacion) {
    return (
      <div className={classes.formacion_container} id="singleCertificación">
        <div className={classes.formacion_header}>
          <div className={classes.formacion_title}>
            {Number(formacion.idNivelEstudios) === 15
              ? formacion?.certificacion?.nombre ?? ""
              : formacion.titulo}
          </div>
        </div>
        {formacion.centro && formacion.centro.trim() !== "" && (
          <div className={classes.font_base}>{formacion.centro}</div>
        )}
        {formacion.tieneFechaValidez && formacion.fechaFinValidez && (
          <div className={classes.font_base}>{`Válido hasta ${getYear(
            formacion.fechaFinValidez
          )}`}</div>
        )}
      </div>
    );
  }

  function renderFormationsSegment1() {
    const formacionesRegladas = formations.filter(
      (t) => [15, 16, 17].indexOf(Number(t.idNivelEstudios)) === -1
    );
    const certificados = formations.filter(
      (t) => [15, 16, 17].indexOf(Number(t.idNivelEstudios)) !== -1
    );
    return (
      <div id="Formation_segment_1">
        <div style={{ marginTop: "50px"}} className={classes.title_section}>
        {t("templateCv.training")}
        </div>
        {formacionesRegladas && formacionesRegladas.length > 0 ? (
          <>
            <div className={classes.title_section_1}>{t("templateCv.regulated_trainings")}</div>
            {formacionesRegladas.length >= 2 ? (
              <div style={{ marginTop: "12px" }} className={classes.your_data_grid}>
                {renderSingleFormacionReglada(formacionesRegladas[0])}
                {renderSingleFormacionReglada(formacionesRegladas[1])}
              </div>
            ) : (
              <div style={{ marginTop: "12px" }} className={classes.your_data_grid}>
                {renderSingleFormacionReglada(formacionesRegladas[0])}
              </div>
            )}
          </>
        ) : certificados && certificados.length > 0 ? (
          <>
            <div className={classes.title_section_1}>
            {t("templateCv.certifications_And_Others")}
            </div>
            {certificados.length >= 2 ? (
              <div className={classes.your_data_grid}>
                {renderSingleCertificacion(certificados[0])}
                {renderSingleCertificacion(certificados[1])}
              </div>
            ) : (
              <div className={classes.your_data_grid}>
                {renderSingleCertificacion(certificados[0])}
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  }

  function renderFormationsSegment2() {
    const formacionesRegladas = formations.filter(
      (t) => [15, 16, 17].indexOf(Number(t.idNivelEstudios)) === -1
    );
    const auxFormacionesRegladas =
      formacionesRegladas && formacionesRegladas.length > 2
        ? formacionesRegladas.slice(2)
        : [];
    const itemsFormacionesRegladas = [];
    let i = 0;
    do {
      if (auxFormacionesRegladas[i] && auxFormacionesRegladas[i + 1]) {
        itemsFormacionesRegladas.push(
          <div
            style={{ marginTop: "12px" }}
            className={classes.your_data_grid}
            id="Formation_segment_2_1"
          >
            {renderSingleFormacionReglada(auxFormacionesRegladas[i])}
            {renderSingleFormacionReglada(auxFormacionesRegladas[i + 1])}
          </div>
        );
        i += 2;
      } else if (auxFormacionesRegladas[i]) {
        itemsFormacionesRegladas.push(
          <div
            style={{ marginTop: "12px" }}
            className={classes.your_data_grid}
            id="Formation_segment_2_2"
          >
            {renderSingleFormacionReglada(auxFormacionesRegladas[i])}
          </div>
        );
        i++;
      }
    } while (i < auxFormacionesRegladas.length);
    return itemsFormacionesRegladas;
  }

  function renderFormationsSegment3() {
    const formacionesRegladas = formations.filter(
      (t) => [15, 16, 17].indexOf(Number(t.idNivelEstudios)) === -1
    );
    const certificados = formations.filter(
      (t) => [15, 16, 17].indexOf(Number(t.idNivelEstudios)) !== -1
    );
    const auxCertificados =
      formacionesRegladas && formacionesRegladas.length > 0
        ? certificados
        : certificados && certificados.length > 2
        ? certificados.slice(2)
        : [];
    const itemsCertificaciones = [];
    let i = 0;
    do {
      if (auxCertificados[i] && auxCertificados[i + 1]) {
        itemsCertificaciones.push(
          <div
            style={{ marginTop: "12px" }}
            className={classes.your_data_grid}
            id="Formation_segment_3_1"
          >
            {renderSingleCertificacion(auxCertificados[i])}
            {renderSingleCertificacion(auxCertificados[i + 1])}
          </div>
        );
        i += 2;
      } else if (auxCertificados[i]) {
        itemsCertificaciones.push(
          <div
            style={{ marginTop: "12px" }}
            className={classes.your_data_grid}
            id="Formation_segment_3_2"
          >
            {renderSingleCertificacion(auxCertificados[i])}
          </div>
        );
        i++;
      }
    } while (i < auxCertificados.length);
    return certificados && certificados.length > 0 ? (
      <>
        {certificados.length === auxCertificados.length && (
          <div className={classes.title_section_1} id="Formation_segment_3_3">
            {t("templateCv.certifications_And_Others")}
          </div>
        )}
        {itemsCertificaciones && itemsCertificaciones.length > 0
          ? itemsCertificaciones
          : null}
      </>
    ) : null;
  }

  const getNombreIdioma = (idIdioma) => {
    const result = languagesList.find(
      (lang) => Number(lang.id) === Number(idIdioma)
    );
    return result ? result.name : "";
  };

  const getNombreNivel = (idNivel) => {
    const result = languagesLevels.find((n) => Number(n.id) === Number(idNivel));
    return result ? result.name : "";
  };

  function renderSingleIdioma(idioma) {
    return (
      <div className={classes.your_data_inner_container} id="singleIdioma">
        <div className={classes.title_section_1}>
          {getNombreIdioma(idioma.idIdioma)}
        </div>
        <div className={classes.languages_inner_grid}>
          <div style={{ fontWeight: 600 }}>{t("templateCv.language_write")}</div>
          <div>{getNombreNivel(idioma.idNivelEscrito)}</div>
          <div style={{ fontWeight: 600 }}>{t("templateCv.language_read")}</div>
          <div>{getNombreNivel(idioma.idNivelLeido)}</div>
          <div style={{ fontWeight: 600 }}>{t("templateCv.language_speak")}</div>
          <div>{getNombreNivel(idioma.idNivelHablado)}</div>
          {idioma.certificados && idioma.certificados.length > 0 && (
            <div style={{ fontWeight: 600 }}>{t("templateCv.certification")}</div>
          )}
          {idioma.certificados && idioma.certificados.length > 0 && (
            <div className={classes.certificate_inner_list}>
              {idioma.certificados.map((certificado) => (
                <div key={certificado.idIdioma }
                  className={classes.certificate}
                >{`${certificado.nombreCertificacion} (${certificado.año})`}</div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderLanguagesFirstSegment() {
    return (
      <div id="languagesFirstSegment">
        <div style={{ marginTop: "50px"}} className={classes.title_section}>
        {t("templateCv.language")}
        </div>
        {languages && languages.length > 0 ? (
          <div className={classes.your_data_grid}>
            {languages.slice(0, 2).map((idioma) => renderSingleIdioma(idioma))}
          </div>
        ) : null}
      </div>
    );
  }

  function renderLanguagesSecondSegment() {
    const auxLanguages = languages.slice(2);
    let i = 0;
    const itemLanguages = [];
    do {
      if (auxLanguages[i] && auxLanguages[i + 1]) {
        itemLanguages.push(
          <div
            style={{ marginTop: "12px" }}
            className={classes.your_data_grid}
            id="languages"
          >
            {renderSingleIdioma(auxLanguages[i])}
            {renderSingleIdioma(auxLanguages[i + 1])}
          </div>
        );
        i += 2;
      } else if (auxLanguages[i]) {
        itemLanguages.push(
          <div
            style={{ marginTop: "12px" }}
            className={classes.your_data_grid}
            id="languages"
          >
            {renderSingleIdioma(auxLanguages[i])}
          </div>
        );
        i++;
      }
    } while (i < auxLanguages.length);
    return itemLanguages;
  }

  function renderKnowledgesSegment1() {
    return (
      <div id="knowledgeSegment1">
        <div style={{ marginTop: "50px"}} className={classes.title_section}>
        {t("templateCv.knowledge")}
        </div>
        <li className={classes.list_element}>{knowledges[0].nombre}</li>
      </div>
    );
  }

  function renderKnowledgesSegment2() {
    return knowledges.slice(1).map((k) => (
      <li key={k.id}
        style={{ marginTop: "5px" }}
        className={classes.list_element}
        id="knowledgeSegment2"
      >
        {k.nombre}
      </li>
    ));
  }

  function renderOtherData() {
    const tiposCarnetConducir = otherData.tiposCarnetConducir
      ? drivingLicenses.filter((permiso) =>
          otherData.tiposCarnetConducir.includes(Number(permiso.id))
        )
      : [];
      const {tieneVehiculoPropio} = otherData;
      const {trabajaActualmente} = otherData;
    const tipoDisponibilidadViajar = otherData.tipoDisponibilidadViajar
      ? tipos_desplazamientos.find(
          (desplazamientos) =>
            Number(desplazamientos.id) === otherData.tipoDisponibilidadViajar
        )
      : tipos_desplazamientos.find(
          (desplazamientos) => Number(desplazamientos.id) === 4
        );
    const tipoDisponibilidadCambioResidencia =
      otherData.tipoDisponibilidadCambioResidencia
        ? tipos_cambio_residencia.find(
            (cambio_residencia) =>
              Number(cambio_residencia.id) ===
              otherData.tipoDisponibilidadCambioResidencia
          )
        : tipos_cambio_residencia.find(
            (cambio_residencia) => Number(cambio_residencia.id) === 1
          );
    const tiposHorario = otherData.tiposHorario
      ? tipoHorario.filter((jornada) =>
          otherData.tiposHorario.includes(Number(jornada.id))
        )
      : [];
    const {tienePermisoTrabajo} = otherData;
    const fechaFinPermisoTrabajo = otherData.fechaFinPermisoTrabajo
      ? formatToLocalDate(new Date(otherData.fechaFinPermisoTrabajo.trim()))
      : null;
    return (
      <div id="OtherData">
        <div style={{ marginTop: "50px"}} className={classes.title_section}>
        {t("templateCv.other_data")}
        </div>
        <div className={`${classes.font_base} ${classes.other_data_grid}`}>
          {tiposCarnetConducir.length > 0 ? (
            <li className={classes.list_element}>
              {`Carnet de conducir: ${tiposCarnetConducir.map(
                (carnet) => ` ${carnet.name}`
              )}`}
            </li>
          ) : (
            <li className={classes.list_element}>{t("templateCv.no_driving_lincese")}</li>
          )}
          {tienePermisoTrabajo && (
            <li className={classes.list_element}>
              {`Permiso de trabajo hasta el ${fechaFinPermisoTrabajo}`}
            </li>
          )}
          <li className={classes.list_element}>
            {tieneVehiculoPropio ? "Vehículo propio" : "Sin vehículo propio"}
          </li>
          <li className={classes.list_element}>
            {trabajaActualmente
              ? "Trabaja actualmente"
              : "No trabaja actualmente"}
          </li>
          <li className={classes.list_element}>
            {`Disponibilidad para viajar: ${tipoDisponibilidadViajar.name}`}
          </li>
          <li className={classes.list_element}>
            {`Disponibilidad para cambio de residencia: ${tipoDisponibilidadCambioResidencia.name}`}
          </li>
          {tiposHorario.length > 0 ? (
            <li className={classes.list_element}>
              {`Preferencia en horarios: ${tiposHorario.map(
                (horario) => ` ${horario.name}`
              )}`}
            </li>
          ) : (
            <li className={classes.list_element}>
              {t("templateCv.no_workday_type")}
            </li>
          )}
        </div>
      </div>
    );
  }


  return (
    <div className={classes.container}>
      <div className={classes.base} id="contenedor_total" ref={reference}>
        {renderProfilePicAndMore()}
        <div
          style={{ gridArea: "right" }}
          id="right_col_template"
          className={classes.container_grid}
        >
          {renderPersonalData()}
          {experiences && experiences.length > 0
            ? renderExperiencesSegment1()
            : null}
          {experiences && experiences.length > 1
            ? renderExperiencesSegment2()
            : null}
          {formations && formations.length > 0
            ? renderFormationsSegment1()
            : null}
          {formations && formations.length > 0
            ? renderFormationsSegment2()
            : null}
          {formations && formations.length > 0
            ? renderFormationsSegment3()
            : null}
          {languages && languages.length > 0
            ? renderLanguagesFirstSegment()
            : null}
          {languages && languages.length > 2
            ? renderLanguagesSecondSegment()
            : null}
          {knowledges && knowledges.length > 0
            ? renderKnowledgesSegment1()
            : null}
          {knowledges && knowledges.length > 1
            ? renderKnowledgesSegment2()
            : null}
          {otherData ? renderOtherData() : null}
        </div>
      </div>
    </div>
  );
}

export default TemplatePDF;
