import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import CandidatePhoto from "../../../../assets/icons/candidatePhoto";
import { base64ToArrayBuffer } from "../../../../shared/utils/functions";
import ProfileProgressPath from "../../../../assets/icons/profileProgressPath";
import CurriculumIcon from "../../../../assets/icons/curriculumIcon";
import DocumentosIcon from "../../../../assets/icons/documentosIcon";
import OffersSearcher from "../Busqueda/offersSearcher";
import { useApi } from "../../../../shared/hooks/useApi";
import { SnackContext, SnackSeverity } from "../../../../shared/contexts/SnackHandler";
import { getDatosContacto, getCandidaturas, downloadDocumentInform } from "../../../../shared/hooks/userApi";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../../shared/enums/enums";
import { Box } from "@mui/material";
import AlertDialog from "../../../components/AlertDialog/alertDialog";

const useStyles = makeStyles((theme) => ({
  body_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateRows: ".6fr .4fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateRows: "1fr",
    },
  },
  body_info_container: {
    display: "grid",
    gridTemplateColumns: "30% minMax(0,1fr)",
    [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "34% minMax(0,1fr)",
    },
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "auto",
    },
  },
  background_header: {
    backgroundColor: "rgb(0, 0, 0, 0.3)",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "relative",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      background:
        "linear-gradient(bottom, #FBFBFB, #FBFBFB 40%, transparent 40%, transparent 100%)",
      height: 130,
    },
  },
  body_header: {
    backgroundImage: "url(/images/header-background.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      backgroundImage: "none",
      height: 130,
    },
  },
  body_content: {
    gridArea: "body_content",
    display: "grid",
    gridTemplateColumns: "30% minMax(0,1fr)",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "auto",
    },
  },
  body_info_left_col: {
    marginTop: 47,
    display: "grid",
    gridTemplateRows: "120px auto",
    rowGap: "12px",
    [theme.breakpoints.down("md")]: {
      gridTemplateRows: "190px auto",
      marginTop: 0
    },
  },
  candidate_info_container: {
    display: "grid",
    padding: 20,
    paddingLeft: 35,
    paddingTop: 20,
    background:"#FBFBFB",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
      paddingTop: 27,
      background: "#FBFBFB",
    },
  },
  candidate_photo_container: {
    position: "absolute",
    bottom: -20,
    left: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 127,
    width: 127,
    borderRadius: 6,
    background: "linear-gradient(180deg, #EEEEEE 0%, #D8D8D8 100%)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    [theme.breakpoints.down("md")]: {
      left: 22,
    },
  },
  photo: {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    objectFit: "cover",
  },
  candidate_last_update_container: {
    display: "flex",
    direction: "rows",
    justifyContent: "space-between",
  },
  candidate_fullname: {
    color: "rgba(0,0,0,0.87)",
    fontSize: 18,
    fontWeight: 900,
    wordBreak: "break-word",
  },
  candidate_last_update: {
    display: "inline-block",
    color: "rgba(0,0,0,0.87)",
    fontSize: 12,
    fontWeight: 500,
  },
  candidate_update_date: {
    display: "inline-block",
    color: "#DA291C",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: 12,
    fontWeight: 500,
  },
  candidate_phone_and_email: {
    fontSize: 16,
    fontWeight: 600,
    color: "rgba(0,0,0,0.87)",
    wordBreak: "break-all",
    overflowWrap: "break-word",
  },
  email: (props) => ({
    overflow: "hidden",
    whiteSpace: props.email < 95 ? "break-spaces" : "nowrap",
    textOverflow: "ellipsis",
    width: props.email < 95 ? "auto" : "290px",
  }),
  linkbutton_14px: {
    color: "#DA291C",
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
    border: "none",
    background: "none",
    textAlign: "start",
  },
  linkbutton_black_14px: {
    color: "black",
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
  },
  profile_completed_container: {
    height: 323,
    padding: 20,
    rowGap: 20,
    paddingLeft: 35,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
      paddingTop: 20,
    },
    background: "#FBFBFB",
    display: "grid",
  },
  profile_completed_container_minimized: {
    padding: 20,
    height: 70,
    background: "#FBFBFB",
    display: "grid",
    cursor: "pointer",
  },
  profile_completed_info_container: {
    display: "grid",
    gridTemplateColumns: "auto 75%",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  profile_completed_info_grid: {
    position: "relative",
    left: -33,
    height: "100%",
    width: "100%",
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: 9,
    gridTemplateColumns: "auto",
    gridTemplateRows: "15% 30% 10% 40%",
    gridTemplateAreas: `
        '. .'
        'percentage text1'
        '. text2'
        '. .'`,
  },
  profile_completed_percentage_text: {
    gridArea: "percentage",
    color: "#DA291C",
    fontSize: 28,
    fontWeight: 900,
  },
  profile_completed_profile_text1: {
    gridArea: "text1",
    fontSize: 18,
    fontWeight: 900,
    color: "black",
  },
  profile_completed_profile_text2: {
    gridArea: "text2",
    fontSize: 18,
    fontWeight: 900,
    color: "black",
  },
  profile_completed_profile_text_16px: {
    whiteSpace: "pre-line",
    fontSize: 18,
    fontWeight: 900,
    color: "black",
  },
  profile_completed_percentage_text_16px: {
    color: "#DA291C",
    fontSize: 16,
    fontWeight: 900,
  },
  padding_grid: {
    paddingLeft: 15,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  profile_info_text_black_16px: {
    fontSize: 16,
    fontWeight: 400,
    color: "black",
  },
  profile_info_text_bold_16px: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
  },
  rectangleProgressBar: {
    height: 2,
    width: "100%",
    background: "#DDDDDD",
  },
  rectangleProgressBarProgress: {
    height: "100%",
    background: "#DA291C",
  },
  menu_rapido_container: {
    display: "grid",
    padding: 20,
    paddingTop: 40,
    gridTemplateRows: "min-content min-content min-content min-content",
    rowGap: "19px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "215px auto auto",
      paddingTop: 20,
    },
  },
  menu_rapido_buttons: {
    display: "grid",
    gridTemplateColumns: "25% 25% 25% 25%",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "50% 50%",
    },
  },
  menu_rapido_button_container: {
    cursor: "pointer",
    display: "grid",
    rowGap: "20px",
    height: "fit-content",
    alignItems: "start",
    gridTemplateRows: ".6fr .4fr",
    justifyItems: "center",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      rowGap: 0,
      height: "auto",
    },
  },
  menu_rapido_button_text_style: {
    color: "#DA291C",
    fontSize: 18,
    fontWeight: 900,
    textAlign: "center",
    whiteSpace: "pre-line",
    alignSelf: "top",
    lineHeight: 1,
    margin: 0,
    padding: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  seccion_principal_headers: {
    fontSize: 22,
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    marginBottom: "10px",
  },
  seccion_principal_descriptions: {
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  grid_nominations_searches: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: 16,
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "auto",
      rowGap: "33px",
    },
  },
  searcher_container: {
    minWidth: 630,
    marginRight: 55,
    marginBottom: 36,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  searcher_link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  your_data_list_style: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "auto",
    margin: "-5px",
    fontSize: 14,
    width: "95%",
  },
  data_list_row: {
    cursor: "pointer",
    margin: "5px",
    "&:hover": {
      background: "#FCFCFC",
    },
  },
  inactive_alert: {
    color: "#B8B8B8",
  },
  candidate_data_grid: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 15,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  rotate_icon: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  formation: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    width: "50%",
    marginBottom: "20px",
  },
}));

function CandidateDashboard() {
  const theme = useTheme();
  const { t } = useTranslation();
  const callApi = useApi();
  const navigate = useNavigate();
  const [nombre, setName] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [idFotoPerfil, setidFotoPerfil] = useState(null);
  const [avancePerfil, setAvancePerfil] = useState("0");
  const [refPicture, setRefPicture] = useState(null);
  const [loadingPic, setLoadingPic] = useState(false);
  const [minimizado, setMinimizado] = useState(false);
  const [candidaturas, setCandidaturas] = useState(null);
  const showSnack = useContext(SnackContext);
  const [opeDialogCompleteData, setOpeDialogCompleteData] = useState(false);

  const classes = useStyles(theme);
  const {state} = useLocation();

  useEffect(() => {
      handleDatosContacto();
  }, []);

  useEffect(()=>{
   if(state?.isNewUser){
    setOpeDialogCompleteData(true); 
   }  
  },[]);

  useEffect(() => {
    if (!refPicture && idFotoPerfil) {
      getProfilePic();
    }
  }, [idFotoPerfil]);


  useEffect(() => {
    if (!candidaturas) {
      handleGetCandidaturas();
    }
  }, [candidaturas]);

  function handleDatosContacto() {
    callApi(getDatosContacto()).then((data) => {
      handleSetDatosContacto(data);
    }).catch(()=>{
      showSnack(t("candidateDashboard.error"), SnackSeverity.ERROR);
    });
  }

  function handleSetDatosContacto(data) {
    setName(data.nombre ? data.nombre : "");
    setPrimerApellido(
      data.primerApellido ? data.primerApellido : ""
    );
    setSegundoApellido(
      data.segundoApellido ? data.segundoApellido : ""
    );
    setCorreo(
      data.correoElectronico ? data.correoElectronico : ""
    );
    setAvancePerfil(
      data.avancePerfil ? data.avancePerfil : 0
    );
    setidFotoPerfil(
      data.idFotoPerfil ? data.idFotoPerfil : null
    );
    setTelefono(
      data.telefono
        ? data.telefono.numero
          ? data.telefono.numero.trim()
          : ""
        : ""
    );
    setPrefijo(
      data.telefono
        ? data.telefono.prefijo
          ? data.telefono.prefijo.trim()
          : ""
        : ""
    );
  }



  function getProfilePic() {
    setLoadingPic(true);
    callApi(downloadDocumentInform(idFotoPerfil)).then((data) => {
      const obj_url = URL.createObjectURL(
        new Blob([base64ToArrayBuffer(data)], {
          type: `application/image/*`,
        })
      );
      setRefPicture(obj_url);
      setLoadingPic(false);
    });
  }

  function profilePicture() {
    return loadingPic ? (
      <div></div>
    ) : refPicture ? (
      <img src={refPicture} className={classes.photo} alt="" />
    ) : (
      <CandidatePhoto title={t("icons_tooltips.candidate_picture")} />
    );
  }

  function handleGetCandidaturas() {
    callApi(getCandidaturas(1, 1)).then((data) => {
      setCandidaturas(data);
    }).catch(()=>{
      showSnack(t("candidateDashboard.error"), SnackSeverity.ERROR);
    });
  }

  function minimizar() {
    setMinimizado(true);
  }

  function maximizar() {
    setMinimizado(false);
  }

  function completedProfile() {
    return minimizado ? (
      <div
        className={classes.profile_completed_container_minimized}
        onClick={maximizar}
      >
        <div>
          <span className={classes.profile_completed_percentage_text_16px}>
            {`${avancePerfil}% `}{" "}
          </span>
          <span className={classes.profile_completed_profile_text_16px}>{`${t(
            "candidateDashboard.completed_profile_text1"
          )} ${t("candidateDashboard.completed_profile_text2")}`}</span>
        </div>
        <div className={classes.rectangleProgressBar}>
          <div
            className={classes.rectangleProgressBarProgress}
            style={{ width: `${avancePerfil}%` }}
          />
        </div>
      </div>
    ) : (
      <div className={classes.profile_completed_container}>
        <div className={classes.profile_completed_info_container}>
          <div className={classes.img_container}>
            <ProfileProgressPath
              title={t("icons_tooltips.progress_path")}
              percentage={avancePerfil / 100}
            />
          </div>
          <div className={classes.profile_completed_info_grid}>
            <div className={classes.profile_completed_percentage_text}>
              {`${avancePerfil}%`}{" "}
            </div>
            <div className={classes.profile_completed_profile_text1}>
              {t("candidateDashboard.completed_profile_text1")}
            </div>
            <div className={classes.profile_completed_profile_text2}>
              {t("candidateDashboard.completed_profile_text2")}
            </div>
          </div>
        </div>
        <div className={classes.profile_info_text_black_16px}>
          {t("candidateDashboard.completed_profile_info_text")}
        </div>
        <div>
          <span className={classes.profile_info_text_black_16px}>
            {t("candidateDashboard.completed_profile_suggestion1")}
          </span>
          <span className={classes.profile_info_text_bold_16px}>
            {t("candidateDashboard.completed_profile_suggestion2")}
          </span>
        </div>
        <a className={classes.linkbutton_black_14px} onClick={minimizar}>
          {t("candidateDashboard.minimize")}
        </a>
      </div>
    );
  }

  function candidateInfo() {
    return (
      <div className={classes.candidate_info_container}>
        <div className={classes.candidate_fullname}>
          {`${nombre} ${primerApellido} ${segundoApellido}`}
        </div>
        <div className={classes.candidate_phone_and_email}>
          <div className={classes.email}>{correo}</div>
          <div> {prefijo && `+${prefijo} ${telefono}`}</div>
        </div>
      </div>
    );
  }

  function menuRapido() {
    return (
      <div className={classes.menu_rapido_buttons}>
        <div
          className={classes.menu_rapido_button_container}
          onClick={() => {
            navigate(Routes.DATOS_PERSONALES);
          }}
        >
          <DocumentosIcon title={t("Datos personales")} />
          <p className={classes.menu_rapido_button_text_style}>
            {"Datos personales"}
          </p>
        </div>
        <div
          className={classes.menu_rapido_button_container}
          onClick={() => {
            navigate(Routes.CURRICULUM);
          }}
        >
          <CurriculumIcon title={t("icons_tooltips.curriculum")} />
          <p className={classes.menu_rapido_button_text_style}>
            {t("candidateDashboard.curriculumButton")}
          </p>
        </div>
        {/* <div
          className={classes.menu_rapido_button_container}
          onClick={() => {
            navigate(Routes.CANDIDATURAS);
          }}
        >
          <CandidaturasIcon title={t("icons_tooltips.candidaturas")} />
          <p className={classes.menu_rapido_button_text_style}>
            {t("candidateDashboard.candidaturasButton")}
          </p>
        </div> */}
      </div>
    );
  }

  function goToCV(){
    navigate(Routes.CURRICULUM);
  }

  function yourNominations() {
    return (
      <div className={classes.candidate_data_grid}>
        <div className={classes.seccion_principal_headers}>
          {t("candidateDashboard.nominations")}
        </div>
        {candidaturas && candidaturas.length > 0 ? (
          <div className={classes.your_data_list_style}>
            {candidaturas.map((candidatura) => (
              <div
                className={classes.data_list_row}
                onClick={() =>{
                  navigate(Routes.OFERTA + "?codigo=" + candidatura.idOferta);
                }
              }
                key={candidatura.idOferta}
              >
                {candidatura.titulo}
              </div>
            ))}
          </div>
        ) : (
          <div className={classes.seccion_principal_descriptions}>
            {t("candidateDashboard.empty_nominations")}
          </div>
        )}
      </div>
    );
  }

  function seccionPrincipal() {
    return (
      <div className={classes.menu_rapido_container}>
        {menuRapido()}
        <div className={classes.grid_nominations_searches}>
          {yourNominations()}
        </div>
      </div>
    );
  }

  return (
    <Box sx={{padding: {xs: "unset", lg: "0 10%"},mt:{xs:"50px",md:"unset"}}}>
      <div className={classes.body_container}>
        <div className={classes.body_header}>
          <div className={classes.background_header}>
            <div className={classes.candidate_photo_container}>
              {profilePicture()}
            </div>
            <div className={classes.searcher_container}>
              <OffersSearcher fullWidth
                whiteTitle whiteLabel displayInline customClass={{backgroundColor: "black", marginBottom: 0}}
              />
            </div>
          </div>
        </div>
        <div className={classes.body_info_container}>
          <div className={classes.body_info_left_col}>
            {candidateInfo()}
            {completedProfile()}
          </div>
          {seccionPrincipal()}
        </div>
      </div>
      <AlertDialog
        title={t("candidateDashboard.increase_chances")}
        open={opeDialogCompleteData}
        handleClose={() => {
          setOpeDialogCompleteData(false);
        }}
        description={t("candidateDashboard.increase_chances_description")}
        handleAccept={() => {
          setOpeDialogCompleteData(false);
          goToCV();
        }}
      />
    </Box>
  );
}

export default CandidateDashboard;