import React from "react";
import { useTranslation } from "react-i18next";

function CAdd(props) {
  const { t } = useTranslation();
  const {onClick=null, title=t(`icons_tooltips.add`), sizeIcon="16px"} = props;
  return(
    <svg onClick={onClick} width={sizeIcon} height={sizeIcon} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Recursos" transform="translate(-186.000000, -1787.000000)" fill="#DA291C" fillRule="nonzero">
              <g id="c-add" transform="translate(186.000000, 1787.000000)">
                  <path d="M8,0 C3.6,0 0,3.6 0,8 C0,12.4 3.6,16 8,16 C12.4,16 16,12.4 16,8 C16,3.6 12.4,0 8,0 Z M12,9 L9,9 L9,12 L7,12 L7,9 L4,9 L4,7 L7,7 L7,4 L9,4 L9,7 L12,7 L12,9 Z" id="Shape"></path>
              </g>
          </g>
      </g>
  </svg>
  );
}

export default CAdd;