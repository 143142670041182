
const styles = {
    main: {
      width: "100%",
      height: {xs: "unset", md:"700px"},
      backgroundColor:"#fbfbfb",
      margin: "0",
      padding: "0",
      WebkitBoxSizing: "border-box",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: {xs: "bottom", xl: "right center"},
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: {xs: "column", md: "row"}
    },
    header_description: {
      color: "white",
      fontSize: {xs:"18px",md:"22px"},
      fontWeight: "500"
    },
    mainSection: {
      padding: {xs: "unset", md: "50px"},
      width: "100%",
      display: "flex",
      justifyContent: {xs:"center",md:"flex-end"},
    },
    policityBackground: {
      backgroundColor: "rgb(0, 123, 196)",
      display: "flex",
      marginLeft: {xs: "unset", md: "25px"},
      padding: {xs: "80px 30px 20px 30px", md: "25px"},
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: {xs: "unset", md: "610px"},
    },
    title: {
      color: "white",
      fontSize: {xs:"30px",md:"35px"},
      fontWeight: "800",
      marginBottom: "10px",
      width: "100%",
    },
    subtitle:{
        fontWeight: "800",
        maxWidth: "800px",
        padding: {xs: "0 20px", md: "unset"},
        width: "100%",
    },
    nortempoLogo: {
      position: "absolute",
      top: "110px",
      left: "6px",
      display: {xs: "none", md: "unset"},
      cursor:"pointer"
    },
    description: {
      color: "white",
      fontSize: "16px",
      marginBottom: "10px"
    },
    section: {
      width: "100%",
      maxWidth: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent:"flex-start",
      margin: "30px 0",
      paddingRight:{xs:"20px"}
    },
    sub_section: {
      width: "100%",
      padding: { xs: "0 20px", md: "unset"},
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10px",
      textAlign:{xs:"justify"},

    },
    sectionTitle: {
      color: "#007BC3",
      fontSize: {xs:"25px",md:"35px"},
      fontWeight: "800",
      marginBottom: "10px",
      width:{xs:"100%",md:"800px"},
      marginLeft: {xs:'37px',md:"unset"},
    },
    sectionDescription: {
      maxWidth: {xs: "unset", md: "800px"},
      width:{xs:"100%",md:"800px"},
      padding: {xs: "0px 20px", md: "unset"},
      justifyContent:"flex-start",
    },
  };
  
  export default styles;
  