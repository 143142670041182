import React from "react";
import InputWrapper from "../InputWrapper/InputWrapper";
import { validateDate } from "../../utils/functions";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@mui/styles";
import 'dayjs/locale/es';

const useStyles = makeStyles((theme) => ({
  dateWrapper: {
    overflow: "hidden",
    root: {
      paddingLeft: "0px"
    }
  },
  root: (props) => ({
    width: "100%",
    "& .MuiInputBase-input": {
      padding: "12px 0px",
      "-moz-appearance": "textfield",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: "13px",
      },
      fontSize: "14px",
      lineHeight: "16px",
      color: props.whiteLabel ? "white" : "black",
      transform: "translate(4px, -20px) scale(1)",
      marginBottom: "4px",
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      transform: "translate(10px, 10px) scale(1)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "90%",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#c4c4c4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: "1px",
        top: 0,
      },
      "& legend": {
        display: "none",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        top: 0,
      },
      "&.Mui-focused legend": {
        display: "none",
      },
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: "0px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: "0px",
    },
  }),
  section_with_title: {
    fontSize: "14px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  section_with_title_title: {
    marginBottom: "5px",
    fontSize: "14px",
  },
  inputRoot_textField: (props) => ({
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "19px",
    },
    fontSize: "12px",
    lineHeight: "16px",
    height: "100%",
    minHeight: "32px",
    "-webkit-appearance": "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
    padding: props.moveLabel
      ? moveLabelStyle(props)
      : "0px !important",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  }),
}));

function moveLabelStyle() {
  return props.multiline
  ? "6px 0px 6px 6px !important"
  : "2px 6px !important";
}

function DatePickerWithLabel(props) {
  const {
    placeholder = "dd/mm/yyyy",
    views,
    value = null,
    setter = () => {
      return true;
    },
    label = "",
    disabled = false,
    minDate = null,
    maxDate = null,
    customClass = "",
    required = false,
    border = false,
    wrong = false,
    onBlur,
    onChange = () => {
      return true;
    },
    yearOnly = false,
  } = props;

  const classes = useStyles();

  function handleSetDate(date) {
    let newDate;
    if (date) {
      const dateChosen = new Date(date);
      newDate = new Date(dateChosen.getTime() - dateChosen.getTimezoneOffset() * 60000);
    } else {
      newDate = date;
    }
    setter(newDate);
    onChange(newDate);
  }

  function renderDatePicker() {
    return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
        <DatePicker
          autoOk
          className={classes}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          inputFormat={yearOnly ? "YYYY" : "DD/MM/YYYY"}
          readOnly={false}
          label="Seleccione una fecha"
          variant="dialog"
          views={views}
          onChange={(date) => {
            handleSetDate(date);
          }}
          {...(minDate ? { minDate } : {})}
          {...(maxDate ? { maxDate } : {})}
          onBlur={onBlur}
          renderInput={(params) => 
                        <TextField {...params} 
                          size="small"
                          classes={{ root: `${classes.root} ${customClass}` }}
                          label=""
                          variant="standard"
                          />}
        />
    </LocalizationProvider>
    );
  }

  return (
    <InputWrapper
      label={label}
      customClass={classes.dateWrapper}
      disabled={disabled}
      wrong={
        wrong
          ? wrong
          : value && !validateDate( value,minDate, maxDate) && value !== ""
      }
      required={required}
      border={border}
      padding="none"
    >
      {renderDatePicker()}
    </InputWrapper>
  );
}

export default DatePickerWithLabel;