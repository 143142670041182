/* eslint-disable */
import { Box, Button, Typography } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import AutocompleteWithLabelAnimation from '../SelectCustoms/autocompleteWithLabelAnimation';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { getDocTypes, returnLangId } from '../../utils/functions';
import i18next from 'i18next';
import FileUploader from './FileUploader';
import { IDocToUpload, IDocumentType, IDocuments, IUploadDocuments } from '../../interfaces/DocumentsInterfaces';
import { uploadCandidateDocuments } from '../../hooks/userApi';
import { useApi } from '../../hooks/useApi';
import { SnackContext, SnackSeverity } from '../../contexts/SnackHandler';
import { languages } from '../../PortalCandidato/auth/AuthWrapper';
import { tiposDocumentos } from '../../enums/enums';

function UploadDocuments(props: IUploadDocuments) {
  const { documentos,
          getDocuments, 
          basicData = false 
        } = props;
  const { t } = useTranslation();
  const [docType, setDocType] = useState<IDocuments | any>({});
  const [docToUpload, setDocToUpload] = useState<IDocToUpload | any>({});
  const [docBackToUpload, setDocBackToUpload] = useState<IDocuments | any>({});
  const [documents, setDocuments] = useState<any>([]);
  const [loadingSaveDocuments, setLoadingSaveDocuments] = useState(false);
  const galicianDoctypeIds = [18, 12, 28, 29] as number[];
  const docTypes = getDocTypes(i18next.languages[0]).filter((type: IDocumentType) => {
    if (basicData) {
      return galicianDoctypeIds.includes(type.id);
    }
    return true;
  }) as IDocumentType[];
  const [listDocs, setListDocs] = useState<IDocuments[] | any>([{
    id: 1,
    label: t("my_documents.back"),
    documentToUpload: docBackToUpload,
    docType,
    loadingDocuments:loadingSaveDocuments,
    setDocumentToUpload: setDocBackToUpload,
  }]);
  const [docListToUpload, setDocListToUpload] = useState<any>([]);
  const callApi = useApi();
  const showSnack = useContext(SnackContext);

  useEffect(() => {
    setDocuments(documentos);
  }, [documentos]);

  useEffect(() => {
    sortDocTypes(docTypes);
  }, []);

  const doesTheDocumentExistAlready = () => {
    return documents.filter((doc: any) => doc.idTipoDocumento === docType?.id).length > 0;
  };

  const backAndObverseDocument = [
    {
      id: 1,
      label: t("my_documents.back"),
      disabled: doesTheDocumentExistAlready(),
      documentToUpload: docBackToUpload,
      docType,
      loadingDocuments:loadingSaveDocuments,
      setDocumentToUpload: setDocBackToUpload,
    },
    {
      id: 2,
      label: t("my_documents.obverse"),
      disabled: doesTheDocumentExistAlready(),
      documentToUpload: docToUpload,
      docType,
      loadingDocuments:loadingSaveDocuments,
      setDocumentToUpload: setDocToUpload,
    }
  ];

  useEffect(() => {
    if (
      docType &&
      (docType.docs === 2 || docType.docs === 1) &&
      doesTheDocumentExistAlready()
    ) {
      showSnack(
        `${t("my_documents.doc_already_uploaded")} ${docType.name}. ${t(
        "my_documents.doc_already_uploaded2")}`, SnackSeverity.WARNING
      );
    }
  }, [docType]);

  const filterDocTypes = () => {
    return docTypes.filter((d: any) =>
      Number(d.id) > tiposDocumentos.CERTIFICADO_ANTECEDENTES_PENALES ||
      Number(d.id) === tiposDocumentos.IDENTIFICACION);
  };

  const disabledButton = () => {
    const typeEmpty = !docType || !Object.keys(docType)?.length;
    const docEmpty = docToUpload === null || !Object.keys(docToUpload)?.length;
    const doc2Empty = docBackToUpload === null || !Object.keys(docBackToUpload)?.length;
    return (
      typeEmpty ||
      (docType.docs === 1 && docEmpty) ||
      (docType.docs === 2 &&
        (docEmpty || doc2Empty)) ||
      ((docType.docs === 2 || docType.docs === 1) &&
      doesTheDocumentExistAlready()) ||
      loadingSaveDocuments ||
      (docType.id === 19 && docEmpty)
    );
  };

  function sortDocTypes(docTypes:IDocumentType[]) {
    docTypes.sort((a:IDocumentType, b:IDocumentType) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  
    return docTypes;
  }

  function handleSaveDocuments() {
    setLoadingSaveDocuments(true);
    let docsToUpload = [docToUpload];
    if (docListToUpload.length) {
      docsToUpload = JSON.parse(JSON.stringify(docListToUpload));
    }
    if (Object.keys(docBackToUpload).length > 0) {
      docsToUpload = [...docsToUpload, docBackToUpload];
    }
    callApi(uploadCandidateDocuments(
      returnLangId(i18next.languages[0] as keyof typeof languages),
      { idTipoDocumento: docType.id, documentos: docsToUpload })).then((result: any) => {
        const uploadedDocuments = result.documentos.map((uploadedDoc: any) => ({
          idDocumento: uploadedDoc.idDocumento,
          nombre: uploadedDoc.nombre,
          idTipoDocumento: result.idTipoDocumento,
        }));
        if (docType.id !== tiposDocumentos.FOTOGRAFIA) {
          setDocuments([...uploadedDocuments, ...documents]);
        } else {
          const newDocuments = documents.filter(
            (doc: any) => doc.idTipoDocumento !== tiposDocumentos.FOTOGRAFIA
          );
          setDocuments([...uploadedDocuments, ...newDocuments]);
        }
        setDocType(null);
        setLoadingSaveDocuments(false);
        getDocuments();
        clearDocsToUpload();
        setDocType({});
      }).catch((response) => {
        if (response.message) {
          showSnack(response.message, SnackSeverity.ERROR);
        } else {
          switch (response.status) {
            case 400:
              showSnack(t("curriculum.error_bad_request_update_documents"), SnackSeverity.ERROR);
              break;
            case 404:
              showSnack(t("curriculum.error_not_found_update_documents"), SnackSeverity.ERROR);
              break;
            default:
              showSnack(t("curriculum.update_error"), SnackSeverity.ERROR);
              break;
          }
        }
        setLoadingSaveDocuments(false);
        clearDocsToUpload();
        setDocType({});
      });
  }

  function handleSaveNewDoc(data: any, id: number) {
    let copyObj = JSON.parse(JSON.stringify(listDocs[listDocs.length - 1]));
    copyObj.id = copyObj.id + 1;
    setListDocs([...listDocs, copyObj]);
    setDocListToUpload([...docListToUpload, data])
  }

  function remapIds(list: any) {
    const mapped = list.map((item: any, index: number) => {
      item.id = index + 1;
      return item;
    })
    return mapped;
  }

  function deleteDoc(id: number, nombre: string) {
    setListDocs(remapIds(listDocs.filter((doc: any)=> doc.id !== id)));
    let tempList = JSON.parse(JSON.stringify(docListToUpload));
    setDocListToUpload(tempList.filter((i: any)=>i.nombre !== nombre));
  }

  const returnAttacherForFiles = () => {
    const hasMoreThanOneDocument = docType && docType.docs !== 2;
    return (
      docType?.id == 29 ? 
      <Box sx={styles.attach_section_container_2}>
                    {listDocs.map((document: any) => {
                      return (
                        <Box key={document.id}>
                          <FileUploader
                            docId={document.id}
                            label={t("my_documents.attach_document")}
                            documentToUpload={docListToUpload[document.id - 1] as any}
                            setDocumentToUpload={handleSaveNewDoc as any}
                            deleteDoc={deleteDoc}
                            docType={docType}
                            loadingDocuments={document.loadingDocuments}
                          />
                        </Box>
                      );
                    })}
        </Box>
      :
      (hasMoreThanOneDocument ?
        <Box sx={styles.attach_section_container_1}>
          <FileUploader
            label={t("my_documents.attach_document")}
            disabled={doesTheDocumentExistAlready() && docType.id !== tiposDocumentos.TITULO_FORMACION_REGLADA}
            documentToUpload={docToUpload}
            setDocumentToUpload={setDocToUpload}
            docType={docType}
            loadingDocuments={loadingSaveDocuments}
          />
        </Box>
        :
        <Box sx={styles.attach_section_container_2}>
          {backAndObverseDocument.map((document) => {
            return (
              <Box key={document.id}>
                <FileUploader
                  label={document.label}
                  disabled={document.disabled}
                  documentToUpload={document.documentToUpload}
                  docType={docType}
                  loadingDocuments={document.loadingDocuments}
                  setDocumentToUpload={document.setDocumentToUpload}
                />
              </Box>
            );
          })}
        </Box>)
    );
  };

  const renderAttacher = () => {
    if (!docType || !Object.keys(docType)?.length) {
      return (
        <Box
          sx={
            documents.length === 0
              ? styles.attach_section_empty
              : styles.attach_section_empty_2
          }
        />
      );
    }else{
     return returnAttacherForFiles();
    }
  };

  const clearDocsToUpload = () => {
    setDocToUpload({});
    setDocBackToUpload({});
    setListDocs([{
                  id: 1,
                  label: t("my_documents.back"),
                  documentToUpload: docBackToUpload,
                  docType,
                  loadingDocuments:loadingSaveDocuments,
                  setDocumentToUpload: setDocBackToUpload,
                }]);
    setDocListToUpload([]);
  };

  const renderMainTitle = () => {
    return (
      <Box sx={styles.uploader_title}>
        <Box
          display="inline-block"
          fontSize={22}
          lineHeight={"30px"}
          fontWeight="900"
          m={1}
          sx={styles.titlemain}
        >
          {basicData ? t("basicData.add_document") : t("my_documents.add_new")}
        </Box>
      </Box>
    );
  };

  const documentUploader = () => {
    return (
      <>
        {renderMainTitle()}
        <AutocompleteWithLabelAnimation
          id="doc_types_autocomplete"
          options={filterDocTypes()}
          getLabel={(option: any) => option.name ?? ""}
          disabled={loadingSaveDocuments}
          label={t("my_documents.doc_type")}
          value={docType}
          moveLabel
          setter={(newValue: any) => {
            setDocType(newValue);
            clearDocsToUpload();
          }}
        />
        {docType?.id == 29 ? <Typography sx={styles.galician_desc}>{t("my_documents.galician_desc")}</Typography> : null}
        {renderAttacher()}
        <Button
          variant="contained"
          color="primary"
          sx={styles.upload_button}
          disabled={disabledButton()}
          onClick={() => handleSaveDocuments()}
        >
          {t("my_documents.save")}
        </Button>
      </>
    );
  };

  return (
    documentUploader()
  );
}
export default UploadDocuments;