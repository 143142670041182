import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { useApi } from "../../../../shared/hooks/useApi";
import { getDatosPersonales, updateDatosPersonales } from "../../../../shared/hooks/userApi";
import styles from "./styles";
import { SnackContext, SnackSeverity } from "../../../../shared/contexts/SnackHandler";
import { useTranslation } from "react-i18next";
import DatePickerWithLabel from "../../../../shared/components/DatePicker/datePickerWithLabel";
import { FormControl, useTheme } from "@mui/material";
import {
  getDiscapacityOptions,
  getGenderOptions,
  getTiposPerfilDigital,
  getTiposVia,
  getNationalityOptions,
  getProvincias,
  optionEquality,
  getProvinceOptions,
  handleProvince,
  validateInternationalPhone,
  returnLangId,
  capitalize,
  renderSwitchDigitalProfile,
  formatDateInput,
  validateDocument,
  validatePhone,
  validateEmail,
  validateSocialSecurityNumber,
  validatePostalCode,
  formatToLocalDate,
  getProvinciasGalicia,
  sendGAEvent,
} from "../../../../shared/utils/functions";
import Add from "../../../../assets/icons/cAdd";
import Trash from "../../../../assets/icons/trash";
import PersonalData from "../../../../assets/icons/personalData";
import CurriculumIcon from "../../../../assets/icons/curriculumIcon";
import i18next from "../../../../i18n.js";
import TuCurriculumHeader from "../../../../shared/components/TuCurriculumHeader/TuCurriculumHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProfilePicture from "../../../../shared/components/ProfilePicture/ProfilePicture";
import SelectCustom from "../../../../shared/components/SelectCustoms/SelectCustom";
import { OTRO_TIPO_PARENTESCO, PORTAL_RETORNA, SPAIN, relationShipOptions, travelAvailabilityOptions } from "../../../../constants";
import InputCustom from "../../../../shared/components/Inputs/InputCustom";
import { languages } from "../../auth/AuthWrapper";
import { useNavigate } from "react-router";
import { Routes } from "../../../../shared/enums/enums";
import ButtonFull from "../../../../shared/components/Buttons/ButtonFull";
import ButtonOutlined from "../Dashboard/buttonOutlined";
import { MaestroContext } from "../../../contexts/MaestroContext";
import AutocompleteWithLabelAnimation from "../../../components/SelectCustoms/autocompleteWithLabelAnimation";
import AutocompleteWithChips from "../../../components/Inputs/autocompleteWithChips";
import { PreguntasDatosBasicos } from "../DatosBasicos/PreguntasDatosBasicos";

const nacionalidades = getNationalityOptions(i18next.languages[0]);
const certificadosDiscapacidad = getDiscapacityOptions(i18next.languages[0]);
const provincias = getProvincias(i18next.languages[0]);
const sexos = getGenderOptions(i18next.languages[0]);
const tiposVia = getTiposVia(i18next.languages[0]);

const tiposPerfilDigital = getTiposPerfilDigital(i18next.languages[0]);

function DatosPersonalesRetorna(props: any) {
  const { actions } = props;
  const showSnack = useContext(SnackContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { paises, prefijos, localidades, tipoIdentficacion } = useContext(MaestroContext);

  const [datosPersonales, setDatosPersonales] = useState("" as any);

  const [nombre, setNombre] = useState("" as any);
  const [nuevoNombre, setNuevoNombre] = useState("");

  const [primerApellido, setPrimerApellido] = useState("" as any);
  const [nuevoPrimerApellido, setNuevoPrimerApellido] = useState("");

  const [segundoApellido, setSegundoApellido] = useState("" as any);
  const [nuevoSegundoApellido, setNuevoSegundoApellido] = useState("");

  const [presentacion, setPresentacion] = useState("");
  const [sexo, setSexo] = useState("" as any);
  const [fechaNacimiento, setFechaNacimiento] = useState(true as any);
  const [nacionalidad, setNacionalidad] = useState("" as any);
  const [tipoIdentificacion, setTipoIdentificacion] = useState(null as any);
  const [identificacion, setIdentificacion] = useState("");
  const [numeroSeguridadSocial, setNumeroSeguridadSocial] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [correoElectronicoNotificacion, setCorreoElectronicoNotificacion] =
    useState("");

  const [prefijo, setPrefijo] = useState("");
  const [nuevoPrefijo, setNuevoPrefijo] = useState("");

  const [telefono, setTelefono] = useState("" as any);
  const [nuevoTelefono, setNuevoTelefono] = useState("");

  const [prefijoOtroTelefono, setPrefijoOtroTelefono] = useState("");
  const [otroTelefono, setOtroTelefono] = useState("");
  const [tipoVia, setTipoVia] = useState("");
  const [nombreVia, setNombreVia] = useState("");
  const [numeroVia, setNumeroVia] = useState("");
  const [bloque, setBloque] = useState("");
  const [piso, setPiso] = useState("");
  const [puerta, setPuerta] = useState("");
  const [pais, setPais] = useState("" as any);
  const [paisOrigen, setPaisOrigen] = useState("" as any);
  const [localidad, setLocalidad] = useState("" as any);
  const [codigoPostal, setCodigoPostal] = useState("");
  const [provincia, setProvincia] = useState("" as any);
  const [discapacidad, setDiscapacidad] = useState("" as any);

  const [perfilesDigitales, setPerfilesDigitales] = useState([] as any);
  const [fechaModificacion, setFechaModificacion] = useState("" as any);
  const [ciudad, setCiudad] = useState("" as any);
  const [telefonoInternacional, setTelefonoInternacional] = useState("");
  const isNuevoPrefijoValid = true;

  const [newPerfilDigital, setNewPerfilDigital] = useState("" as any);
  const [newUriPerfilDigital, setNewUriPerfilDigital] = useState("");
  const callApi = useApi();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isNuevoNombreValid, setisNuevoNombreValid] = useState(true);
  const [isNuevoPrimerApellidoValid, setIsNuevoPrimerApellidoValid] =
    useState(true);
  const [isFechaDeNacimientoValid, setIsFechaDeNacimientoValid] =
    useState(true);
  const [isTipoIdentificacionValid, setIsTipoIdentificacionValid] =
    useState(true);
  const [isIdentificacionValid, setIsIdentificacionValid] = useState("" as any);
  const [isNumeroSeguridadSocialValid, setIsNumeroSeguridadSocialValid] =
    useState(true);
  const [
    isCorreoElectronicoNotificacionValid,
    setIsCorreoElectronicoNotificacionValid,
  ] = useState(true);
  const [isNuevoTelefonoValid, setIsNuevoTelefonoValid] = useState(true);
  const [idFotografia, setIdFotografia] = useState(null);
  const [isOtroTelefonoValid, setIsOtroTelefonoValid] = useState(true);
  const [isCodigoPostalValid, setIsCodigoPostalValid] = useState("" as any);

  const [travelAvailability, setTravelAvailability] = useState<any>(null);
  const [travelWith, setTravelWith] = useState<any>(null);
  const [provinciaPref, setProvinciaPref] = useState<any>(null);
  const [ciudadPref, setCiudadPref] = useState<any>(null);
  const [lugarOrigen, setLugarOrigen] = useState<any>(null);
  const [relationship, setRelationship] = useState<any>([]);
  const [otherRelationship, setOtherRelationship] = useState<any>(null);
  const [selectNationality, setSelectNationality] = useState<number | null>(null);
  const [isVinculate, setIsVinculate] = useState<number | null>(null);
  const [spanishPassport, setSpanishPassport] = useState<number | null>(null);
  const [otherVinculadoGalicia, setOtherVinculadoGalicia] = useState<string | null>("");

  const galicianProvinces = getProvinciasGalicia(i18next.languages[0]);
  const isPrefijoOtroTelefonoValid = true;
  const OTROS_VINCULADO_GALICIA = 3;

  const updateIsNuevoNombreValid = (value: any) => {
    setisNuevoNombreValid(value && value.trim() !== "");
  };

  const updateIsNuevoPrimerApellidoValid = (value: any) => {
    setIsNuevoPrimerApellidoValid(value && value.trim() !== "");
  };

  const updateIsFechaDeNachimientoValid = (value: any) => {
    setIsFechaDeNacimientoValid(value && new Date(value) <= new Date());
  };

  const updateIsTipoIdentificacionValid = (value: any) => {
    setIsTipoIdentificacionValid(value !== null);
  };

  const updateIsIdentificacionValid = (value: any, tipoId: any) => {
    setIsIdentificacionValid(
      value &&
      value.trim() !== "" &&
      validateDocument(
        value,
        tipoIdentficacion.find((ti: any) => Number(ti.id) === Number(tipoId))
          ?.name ?? ""
      )
    );

  };


  const updateIsNumeroSeguridadSocialValid = (value: string) => {
    setIsNumeroSeguridadSocialValid(
      value ? validateSocialSecurityNumber(value) : true
    );
  };

  const updateIsCorreoElecetronicoNotificacionValid = (value: string) => {
    setIsCorreoElectronicoNotificacionValid(
      value ? validateEmail(value) : false
    );
  };

  const updateIsNuevoTelefonoValid = (value: any) => {
    setIsNuevoTelefonoValid(
      value && value.trim() !== "" && validatePhone(value)
    );
  };

  const updateIsOtroTelefonoValid = (value: any) => {
    setIsOtroTelefonoValid(
      value ? value && value.trim() !== "" && validatePhone(value) : true
    );
  };

  const updateIsCodigoPostalValid = (value: any) => {
    setIsCodigoPostalValid(value ? validatePostalCode(value, pais) : true);
  };

  useEffect(() => {
    updateIsCodigoPostalValid(codigoPostal);
  }, [pais, codigoPostal]);

  const minDate = new Date().setFullYear(Number(new Date().getFullYear()) - 90);

  useEffect(() => {
    if (!datosPersonales) {
      handleDatosPersonales();
    } else {
      handleSetDatosPersonales();
    }
  }, [datosPersonales]);

  useEffect(()=>{
    if(!relationship?.find((r: any) => (r.idParentescoGalicia || r.id) == OTRO_TIPO_PARENTESCO)){
      setOtherRelationship("");
    }
  },[relationship]);

  useEffect(()=>{
    handleOtherRelationShip(otherRelationship);
  },[otherRelationship]);


  const handleDatosPersonales = useCallback(() => {
    callApi(getDatosPersonales(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      setDatosPersonales(data);
    }).catch(() => {
      showSnack(t("edit_personal_data.personal_data_error"), SnackSeverity.ERROR);
      setDatosPersonales(null);
    });
  }, []);

  function validateLocalidadOrRegion() {
    return (pais && pais == SPAIN) ? localidad : ciudad;
  }

  function validateActualizarDatosPersonales() {
    const validateProvince = pais ? provincia : true;
    const validateLocalidadRegion = provincia ? validateLocalidadOrRegion() : true;
    if (
      isNuevoNombreValid &&
      isNuevoPrimerApellidoValid &&
      isFechaDeNacimientoValid &&
      isNumeroSeguridadSocialValid &&
      isTipoIdentificacionValid &&
      isCorreoElectronicoNotificacionValid &&
      isNuevoTelefonoValid &&
      isOtroTelefonoValid &&
      isPrefijoOtroTelefonoValid &&
      isCodigoPostalValid &&
      isIdentificacionValid &&
      validateProvince &&
      validateLocalidadRegion &&
      paisOrigen &&
      ((relationship.find((r: any) => r.id == OTRO_TIPO_PARENTESCO) && otherRelationship) ||  !relationship.find((r: any) => r.id == OTRO_TIPO_PARENTESCO)) &&
      isVinculate &&
      ((isVinculate == OTROS_VINCULADO_GALICIA && otherVinculadoGalicia) || isVinculate != OTROS_VINCULADO_GALICIA) &&
      (spanishPassport!= null) &&
      (selectNationality != null) &&
      pais &&
      validateInternationalPhone(telefonoInternacional) &&
      (!nombreVia || (nombreVia.replace(/\s/g, "") !== "" && tipoVia)) &&
      (!tipoVia || (nombreVia && nombreVia.replace(/\s/g, "") !== "")) &&
      (!newUriPerfilDigital ||
        (newUriPerfilDigital.replace(/\s/g, "") !== "" && newPerfilDigital)) &&
      (!newPerfilDigital ||
        (newUriPerfilDigital && newUriPerfilDigital.replace(/\s/g, "") !== ""))
    ) {
      actualizarDatosPersonales();
    } else {
      showSnack(t("common.data_error"), SnackSeverity.ERROR);
    }
  }

  function validateNuevoPrefijo() {
    if (nuevoPrefijo && !nuevoTelefono) {
      setNuevoPrefijo("");
      return null;
    }
    if (!nuevoPrefijo && nuevoTelefono) {
      setNuevoPrefijo("34");
      return "34";
    }
    return nuevoPrefijo;
  }

  function validateOtroPrefijo() {
    if (prefijoOtroTelefono && !otroTelefono) {
      setPrefijoOtroTelefono("");
      return null;
    }
    if (!prefijoOtroTelefono && otroTelefono) {
      setPrefijoOtroTelefono("34");
      return "34";
    }
    return prefijoOtroTelefono;
  }

  async function actualizarDatosPersonales() {
    const fNacimiento = new Date(fechaNacimiento);

    const data = {
      nombre: nuevoNombre,
      primerApellido: nuevoPrimerApellido,
      segundoApellido: nuevoSegundoApellido,
      presentacion: presentacion && presentacion !== "" ? presentacion : null,
      sexo: sexo ? sexo : 0,
      fechaNacimiento: new Date(
        fNacimiento.getTime() - fNacimiento.getTimezoneOffset() * 60000
      ),
      nacionalidad: nacionalidad && nacionalidad !== "" ? nacionalidad : null,
      tipoIdentificacion,
      identificacion: identificacion.toUpperCase(),
      numeroSeguridadSocial:
        numeroSeguridadSocial && numeroSeguridadSocial !== ""
          ? numeroSeguridadSocial
          : null,
      correoElectronico,
      correoElectronicoNotificacion,
      telefono: { prefijo: validateNuevoPrefijo(), numero: nuevoTelefono },
      otroTelefono: { prefijo: validateOtroPrefijo(), numero: otroTelefono },
      tipoVia: tipoVia && tipoVia !== "" ? tipoVia : null,
      nombreVia: nombreVia && nombreVia !== "" ? nombreVia : null,
      numeroVia: numeroVia && numeroVia !== "" ? numeroVia : null,
      bloque: bloque && bloque !== "" ? bloque : null,
      piso: piso && piso !== "" ? piso : null,
      puerta: puerta && puerta !== "" ? puerta : null,
      localidad: localidad && localidad !== "" ? localidad : null,
      codigoPostal: codigoPostal && codigoPostal !== "" ? codigoPostal : null,
      provincia: provincia && provincia !== "" ? provincia : null,
      pais: pais && pais !== "" ? pais : null,
      ciudad: ciudad && ciudad !== "" ? ciudad : null,
      idDiscapacidad: discapacidad,
      perfilesDigitales:
        perfilesDigitales && perfilesDigitales.length > 0
          ? perfilesDigitales
          : null,
      telefonoInternacional: telefonoInternacional ?? null,
      idPortal:PORTAL_RETORNA,
      datosRetorna: {
        idTieneNacionalidad: null,
        idPaisOrigen: Number(paisOrigen) || null,
        idTienePasaporte: (spanishPassport != null) ? Number(spanishPassport) : null,
        idVinculadoGalicia: Number(isVinculate) ? Number(isVinculate) : null,
        vinculadoGalicia: isVinculate == OTROS_VINCULADO_GALICIA ? otherVinculadoGalicia : null,
        lugarOrigen,
        idPeriodoDisponibilidadViajar: travelAvailability,
        idProvinciaPreferente: provinciaPref,
        idLocalidadPreferente: ciudadPref,
        acompanante: travelWith?.length ? travelWith : null,
        parentescosGalicia: [] as any[]
      },
    };

    relationship.forEach((r: any) => {
      data.datosRetorna.parentescosGalicia.push({
          idParentescoGalicia: r.id,
          parentescoGalicia: r.id === OTRO_TIPO_PARENTESCO ? otherRelationship : null,
      });
    });

    if (selectNationality != null) data.datosRetorna.idTieneNacionalidad = Number(selectNationality) as any;

    callApi(updateDatosPersonales(returnLangId(i18next.languages[0] as keyof typeof languages), data)).then(() => {
      setNombre(nuevoNombre);
      setPrimerApellido(nuevoPrimerApellido);
      setSegundoApellido(nuevoSegundoApellido);
      setPrefijo(nuevoPrefijo);
      setTelefono(nuevoTelefono);
      setIdentificacion(identificacion.toUpperCase());
      showSnack(t("edit_personal_data.personal_data_saved"), SnackSeverity.SUCCESS);
      sendGAEvent("Guardados datos personales", {
        userEmail: correoElectronico
      });
    }).catch((response) => {
      if (response.status === 418) {
        showSnack(t("edit_personal_data.personal_data_save_error_ss"), SnackSeverity.ERROR);
      } else {
        showSnack(t("edit_personal_data.personal_data_save_error"), SnackSeverity.ERROR);
      }
    });
  }



  function handleSetDatosPersonales() {
    const nombreData = datosPersonales.nombre
      ? datosPersonales.nombre.trim()
      : "";
    setNombre(nombreData);
    setNuevoNombre(nombreData);
    updateIsNuevoNombreValid(nombreData);
    const primerApellidoData = datosPersonales.primerApellido
      ? datosPersonales.primerApellido.trim()
      : "";
    setPrimerApellido(primerApellidoData);
    setNuevoPrimerApellido(primerApellidoData);
    updateIsNuevoPrimerApellidoValid(primerApellidoData);
    const segundoApellidoData = datosPersonales.segundoApellido
      ? datosPersonales.segundoApellido.trim()
      : "";
    setSegundoApellido(segundoApellidoData);
    setNuevoSegundoApellido(segundoApellidoData);
    setPresentacion(
      datosPersonales.presentacion ? datosPersonales.presentacion.trim() : null
    );
    setSexo(datosPersonales.sexo ? Number(datosPersonales.sexo) : 0);
    const fechaNacimientoData = datosPersonales.fechaNacimiento
      ? formatDateInput(datosPersonales.fechaNacimiento.trim())
      : "";
    setFechaNacimiento(fechaNacimientoData);
    updateIsFechaDeNachimientoValid(fechaNacimientoData);
    setNacionalidad(
      datosPersonales.nacionalidad ? Number(datosPersonales.nacionalidad) : 0
    );
    const tipoIdentificacionData = datosPersonales.tipoIdentificacion
      ? Number(datosPersonales.tipoIdentificacion)
      : "";
    setTipoIdentificacion(tipoIdentificacionData);
    updateIsTipoIdentificacionValid(tipoIdentificacionData);
    const identificacionData = datosPersonales.identificacion
      ? datosPersonales.identificacion.trim()
      : null;
    setIdentificacion(identificacionData);
    updateIsIdentificacionValid(identificacionData, tipoIdentificacionData);
    const numeroSeguridadSocialData = datosPersonales.numeroSeguridadSocial
      ? datosPersonales.numeroSeguridadSocial.trim()
      : null;
    setNumeroSeguridadSocial(numeroSeguridadSocialData);
    updateIsNumeroSeguridadSocialValid(numeroSeguridadSocialData);
    setCorreoElectronico(
      datosPersonales.correoElectronico
        ? datosPersonales.correoElectronico.trim()
        : ""
    );
    const correoElectronicoNotificacionData =
      datosPersonales.correoElectronicoNotificacion
        ? datosPersonales.correoElectronicoNotificacion.trim()
        : null;
    setCorreoElectronicoNotificacion(correoElectronicoNotificacionData);
    updateIsCorreoElecetronicoNotificacionValid(
      correoElectronicoNotificacionData
    );
    const telefonoData = datosPersonales.telefono
      ? datosPersonales.telefono.numero
        ? datosPersonales.telefono.numero.trim()
        : ""
      : null;
    setTelefono(telefonoData);
    setNuevoTelefono(telefonoData);
    updateIsNuevoTelefonoValid(telefonoData);
    const prefijoData = datosPersonales.telefono
      ? datosPersonales.telefono.prefijo
        ? datosPersonales.telefono.prefijo.trim()
        : ""
      : null;
    setPrefijo(prefijoData);
    setNuevoPrefijo(prefijoData);
    const otroTelefonoData = datosPersonales.otroTelefono
      ? datosPersonales.otroTelefono.numero
        ? datosPersonales.otroTelefono.numero.trim()
        : ""
      : null;
    setOtroTelefono(otroTelefonoData);
    const handlePrefijo = datosPersonales.otroTelefono?.prefijo
      ? datosPersonales.otroTelefono?.prefijo.trim()
      : "";

    const otroPrefijoData = datosPersonales.otroTelefono
      ? handlePrefijo
      : null;
    setPrefijoOtroTelefono(otroPrefijoData);
    updateIsOtroTelefonoValid(otroTelefonoData);
    setTipoVia(datosPersonales.tipoVia ? datosPersonales.tipoVia.trim() : "");
    setNombreVia(
      datosPersonales.nombreVia ? datosPersonales.nombreVia.trim() : ""
    );
    setNumeroVia(
      datosPersonales.numeroVia ? datosPersonales.numeroVia.trim() : ""
    );
    setBloque(datosPersonales.bloque ? datosPersonales.bloque.trim() : "");
    setPiso(datosPersonales.piso ? datosPersonales.piso.trim() : "");
    setPuerta(datosPersonales.puerta ? datosPersonales.puerta.trim() : "");
    setPais(
      datosPersonales.pais
        ? datosPersonales.pais
        : parseInt(paises.find((p: any) => p.id === "724")?.id)
    );
    setProvincia(datosPersonales.provincia ? datosPersonales.provincia : "");
    setLocalidad(datosPersonales.localidad ? datosPersonales.localidad : "");
    const cp = datosPersonales.codigoPostal
      ? datosPersonales.codigoPostal.trim()
      : null;
    setCiudad(datosPersonales.ciudad ? datosPersonales.ciudad : "");
    setCodigoPostal(cp);
    setDiscapacidad(
      datosPersonales.idDiscapacidad ? datosPersonales.idDiscapacidad : 1
    );
    const idFoto = datosPersonales.idFotografia
      ? datosPersonales.idFotografia
      : null;

    setIdFotografia(idFoto);
    actions?.setCandidateIdPhotography(datosPersonales?.idFotografia);
    setPerfilesDigitales(
      datosPersonales.perfilesDigitales ? datosPersonales.perfilesDigitales : []
    );
    setFechaModificacion(
      datosPersonales.fechaModificacion
        ? formatToLocalDate(new Date(datosPersonales.fechaModificacion))
        : ""
    );
    setTelefonoInternacional(
      datosPersonales.telefonoInternacional
        ? datosPersonales.telefonoInternacional.trim()
        : ""
    );
    setTravelAvailability(datosPersonales.datosRetorna.idPeriodoDisponibilidadViajar
      ? datosPersonales.datosRetorna.idPeriodoDisponibilidadViajar
      : null);
    setTravelWith(datosPersonales.datosRetorna.acompanante
      ? datosPersonales.datosRetorna.acompanante.trim()
      : null);
    setProvinciaPref(datosPersonales.datosRetorna.idProvinciaPreferente
      ? datosPersonales.datosRetorna.idProvinciaPreferente
      : null);
    setCiudadPref(datosPersonales.datosRetorna.idLocalidadPreferente
      ? datosPersonales.datosRetorna.idLocalidadPreferente
      : null);
    setRelationship(datosPersonales.datosRetorna.parentescosGalicia
      ? handleChipsValueRelationships( datosPersonales.datosRetorna.parentescosGalicia)
      : []);
    setOtherRelationship(datosPersonales.datosRetorna.parentescosGalicia.find((r: any) => r.idParentescoGalicia == OTRO_TIPO_PARENTESCO)
      ? datosPersonales.datosRetorna.parentescosGalicia.find((r: any) => r.idParentescoGalicia == OTRO_TIPO_PARENTESCO).parentescoGalicia.trim()
      : null);
    setLugarOrigen(datosPersonales.datosRetorna.lugarOrigen
      ? datosPersonales.datosRetorna.lugarOrigen.trim()
      : null);
    setSelectNationality(datosPersonales.datosRetorna?.idTieneNacionalidad);
    setIsVinculate(datosPersonales.datosRetorna.idVinculadoGalicia 
      ? datosPersonales.datosRetorna.idVinculadoGalicia 
      : null);
    setOtherVinculadoGalicia(datosPersonales.datosRetorna.vinculadoGalicia
      ? datosPersonales.datosRetorna.vinculadoGalicia.trim()
      : "");
    setSpanishPassport(datosPersonales?.datosRetorna.idTienePasaporte);
    setPaisOrigen(datosPersonales.datosRetorna.idPaisOrigen
      ? datosPersonales.datosRetorna.idPaisOrigen
      : null);
  }

  function handleGetLabel(x: any, dataList: any) {
    return (x
      ? dataList.find((n: any) => Number(n.id) === Number(x))?.name ?? ""
      : "");
  }

  function addPerfilDigital() {
    if (
      newUriPerfilDigital.toLowerCase().includes("http://") ||
      newUriPerfilDigital.toLowerCase().includes("https://")
    ) {
      setPerfilesDigitales([
        ...perfilesDigitales,
        { url: newUriPerfilDigital, tipoPerfilDigital: newPerfilDigital },
      ]);
    } else {
      setPerfilesDigitales([
        ...perfilesDigitales,
        {
          url: `https://${newUriPerfilDigital}`,
          tipoPerfilDigital: newPerfilDigital,
        },
      ]);
    }
    setNewPerfilDigital("");
    setNewUriPerfilDigital("");
  }

  const handleSetName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateIsNuevoNombreValid(e.target.value);
    setNuevoNombre(e.target.value);
  }, []);

  const handleSetFirstSurname = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateIsNuevoPrimerApellidoValid(e.target.value);
    setNuevoPrimerApellido(e.target.value);
  }, []);

  const handleSetSecondSurname = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNuevoSegundoApellido(e.target.value);
  }, []);

  const handleSetSeguridadSocial = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.trim().length < 13 &&
      setNumeroSeguridadSocial(e.target.value);
    updateIsNumeroSeguridadSocialValid(e.target.value);
  }, []);

  const handleSetPresentacion = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPresentacion(e.target.value);
  }, []);

  const handleSetNewPhone = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateIsNuevoTelefonoValid(e.target.value);
    setNuevoTelefono(e.target.value);
  }, []);

  const handleSetOtherPhone = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateIsOtroTelefonoValid(e.target.value);
    setOtroTelefono(e.target.value);
  }, []);

  const handleSetInternationalPhone = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTelefonoInternacional(e.target.value);
  }, []);

  const handleSetRoadName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNombreVia(e.target.value);
  }, []);

  const handleSetBloq = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setBloque(e.target.value);
  }, []);

  const handleSetFloor = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPiso(e.target.value);
  }, []);

  const handleSetDoor = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPuerta(e.target.value);
  }, []);

  const handleSetCity = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCiudad(e.target.value);
  }, []);

  const handleSetPostalCode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCodigoPostal(e.target.value);
  }, []);

  const handleSetNumberRoad = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNumeroVia(e.target.value);
  }, []);

  const handleSetCorreoElecetronico = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateIsCorreoElecetronicoNotificacionValid(e.target.value);
    setCorreoElectronicoNotificacion(e.target.value);
  }, []);


  const handleSetNewUriPerfilDigital = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUriPerfilDigital(e.target.value);
  }, []);

  const handleSetNacionalidad = useCallback((value: any) => {
    setNacionalidad(value ? Number(value.id) : null);
  }, []);

  const handleSetDiscapacidad = useCallback((value: any) => {
    setDiscapacidad(value ? Number(value.id) : null);
  }, []);

  const handleSetNuevoPrefijo = useCallback((value: any) => {
    setNuevoPrefijo(value ? value.prefix.trim() : null);
  }, []);

  const handleSetPrefijoOtroTelefono = useCallback((value: any) => {
    setPrefijoOtroTelefono(value ? value.prefix.trim() : null);
  }, []);

  const handleSetTipoVia = useCallback((value: any) => {
    setTipoVia(value ? value.id.trim() : null);
  }, []);

  const handlePais = useCallback((value: any) => {
    handleSetPais(value ? Number(value.id) : null);
  }, []);

  const handleSetProvincia = useCallback((value: any) => {
    setProvincia(value ? Number(value.id) : null);
    setLocalidad(null);
  }, []);

  const handleSetLocalidad = useCallback((value: any) => {
    setLocalidad(value ? Number(value.id) : null);
  }, []);


  function renderFullName() {
    return (
      <Box sx={styles.fullname_grid}>
        <InputCustom
          fullWidth
          wrong={!isNuevoNombreValid}
          label={t("edit_personal_data.name")}
          value={nuevoNombre}
          setter={handleSetName}
          customClassWrapper={styles.name}
          maxLength={100}
          moveLabel
          customClass={`${styles.input} ${styles.name}`}

        />
        <InputCustom
          customClassWrapper={styles.firstName}
          fullWidth
          wrong={!isNuevoPrimerApellidoValid}
          label={t("edit_personal_data.first_surname")}
          value={nuevoPrimerApellido}
          setter={handleSetFirstSurname}
          maxLength={100}
          moveLabel
          customClass={`${styles.input} ${styles.firstName}`}

        />
        <InputCustom
          fullWidth
          label={t("edit_personal_data.second_surname")}
          value={nuevoSegundoApellido}
          setter={handleSetSecondSurname}
          maxLength={100}
          moveLabel
          customClass={`${styles.input} ${styles.secondName}`}
          customClassWrapper={styles.secondName}
        />
      </Box>
    );
  }

  function renderBasicData() {
    return (
      <Box sx={styles.basic_data_grid} style={{ marginBottom: 0 }}>
        <FormControl variant="outlined" fullWidth>
          <SelectCustom
            moveLabel
            useDefaultIcon
            value={sexo}
            setter={(value: any) => {
              setSexo(value ? Number(value.id) : null);
            }}
            options={sexos}
            label={t("edit_personal_data.gender_placeholder")}
            getOption={(x: any, v: any) => optionEquality(x, v)}
            getLabel={(x: any) =>
              typeof x === "object" && x !== null
                ? x.name
                : handleGetLabel(x, sexos)
            }

          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <SelectCustom
            useDefaultIcon
            moveLabel
            value={nacionalidad}
            setter={handleSetNacionalidad}
            options={nacionalidades}
            label={t("edit_personal_data.nationality_placeholder")}
            getOption={(x: any, v: any) => optionEquality(x, v)}
            getLabel={(x: any) =>
              typeof x === "object" && x !== null
                ? x.name
                : handleGetLabel(x, nacionalidades)
            }
          />
        </FormControl>
        <DatePickerWithLabel
          customClass={styles.date_picker_class}
          label={t("edit_personal_data.birthdate")}
          value={fechaNacimiento}
          setter={(value: any) => {
            updateIsFechaDeNachimientoValid(value);
            setFechaNacimiento(value);
          }}
          onBlur={(value: any) => updateIsFechaDeNachimientoValid(value)}
          minDate={minDate}
          maxDate={new Date()}
        />
        <Box sx={styles.section_selector_input}>
          <FormControl variant="outlined" fullWidth>
            <SelectCustom
              useDefaultIcon
              wrong={!isTipoIdentificacionValid}
              value={tipoIdentificacion}
              setter={(value: any) => {
                const tipoId = value ? Number(value.id) : null;
                updateIsTipoIdentificacionValid(tipoId);
                updateIsIdentificacionValid(identificacion, tipoId);
                setTipoIdentificacion(tipoId);
              }}
              onBlur={(value: any) => {
                const tipoId = value ? Number(value.id) : null;
                updateIsIdentificacionValid(identificacion, tipoId);
              }}
              label={t("edit_personal_data.identification_type")}
              options={tipoIdentficacion}
              getOption={(x: any, v: any) => optionEquality(x, v)}
              getLabel={(x: any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                    ? tipoIdentficacion.find((n: any) => Number(n.id) === Number(x))
                      ?.name ?? ""
                    : ""
              }
              moveLabel
            />
          </FormControl>
          <InputCustom
            wrong={!isIdentificacionValid}
            fullWidth
            customClass={styles.input}
            label={t("edit_personal_data.identification_number")}
            value={identificacion}
            setter={(e: any) => {
              setIdentificacion(e.target.value.toLocaleUpperCase());
            }}
            onBlur={(e: any) => updateIsIdentificacionValid(e.target.value, tipoIdentificacion)}
            moveLabel
            maxLength={20}
          />
        </Box>
        <InputCustom
          wrong={!isNumeroSeguridadSocialValid}
          fullWidth
          type="number"
          placeholder={t(
            "edit_personal_data.social_security_number_placeholder"
          )}
          label={t("edit_personal_data.social_security_number")}
          value={numeroSeguridadSocial}
          setter={handleSetSeguridadSocial}
          maxLength={12}
        />
        <FormControl variant="outlined" fullWidth>
          <SelectCustom
            moveLabel
            useDefaultIcon
            value={discapacidad}
            setter={handleSetDiscapacidad}
            options={certificadosDiscapacidad}
            getOption={(x: any, v: any) => optionEquality(x, v)}
            getLabel={(x: any) =>
              typeof x === "object" && x !== null
                ? x.name
                : handleGetLabel(x, certificadosDiscapacidad)
            }
            label={t("edit_personal_data.disabilty_certificate")}
          />
        </FormControl>
      </Box>
    );
  }



  function renderDatosPersonales() {
    return (
      <Box sx={styles.data_section_container}>
        <Box sx={styles.data_section_icon_and_title}>
          <PersonalData />
          <Box sx={styles.data_section_title_principal}>
            {t("edit_personal_data.personal_data")}
          </Box>
        </Box>
        {matchesMobile && (
          <ProfilePicture
            isEditAvailable
            propsMargin="0 0 20px 0"
            idFoto={idFotografia} />
        )}
        {renderFullName()}
        <Box sx={{ marginBottom: "25px" }}>
          <InputCustom
            multiline
            rows={3}
            fullWidth
            label={t("edit_personal_data.presentation")}
            value={presentacion}
            setter={handleSetPresentacion}
            maxLength={2000}
            moveLabel
            customClassWrapper={styles.multiline}
          />
        </Box>
        {renderBasicData()}
      </Box>
    );
  }

  function handleSetPais(value: any) {
    setPais(value);
    if (value !== SPAIN) {
      const provincia = provincias.find(
        (prov: any) => prov.id_country === value?.toString()
      );
      setProvincia(Number(provincia?.id));
      setLocalidad("");
      setCiudad("");
    } else {
      setProvincia("");
      setLocalidad("");
      setCiudad("");
    }
  }

  function handleSetProvinciaPreference(value:number | null){
    setProvinciaPref(value);
    setCiudadPref(null);
  }

  function renderDatosContacto() {
    return (
      <Box sx={styles.data_section_container}>
        <Box sx={styles.data_section_title}>
          {t("edit_personal_data.contact_data")}
        </Box>
        <Box sx={styles.basic_data_grid}>
          <InputCustom
            fullWidth
            customClass={styles.input}
            value={correoElectronico}
            disabled
          />
          <InputCustom
            wrong={!isCorreoElectronicoNotificacionValid}
            fullWidth
            label={t("edit_personal_data.notifications_email")}
            value={correoElectronicoNotificacion}
            setter={handleSetCorreoElecetronico}
            maxLength={100}
            customClass={styles.input}
            moveLabel

          />
          <Box sx={styles.section_selector_input}>
            <FormControl variant="outlined" fullWidth>
              <SelectCustom
                moveLabel
                useDefaultIcon
                wrong={!isNuevoPrefijoValid}
                value={nuevoPrefijo}
                setter={handleSetNuevoPrefijo}
                options={prefijos}
                multiple={false}
                placeholder={t("edit_personal_data.prefix")}
                label={t("edit_personal_data.principal_telephone")}
                freeSolo={false}
                getOption={(x: any, v: any) => optionEquality(x, v)}
                getLabel={(x: any) =>
                  typeof x === "object" && x !== null
                    ? `+${x.prefix}`
                    : x
                      ? `+${prefijos.find((pr: any) => pr.prefix.trim() === x.trim())
                        ? prefijos.find((pr: any) => pr.prefix.trim() === x.trim())
                          ?.prefix ?? ""
                        : ""
                      }`
                      : `+${x}`
                }
              />
            </FormControl>
            <InputCustom
              customClass={`${styles.input} ${styles.marginAuto}`}
              customClassWrapper={styles.marginAuto}
              wrong={!isNuevoTelefonoValid}
              fullWidth
              label={t("edit_personal_data.telephone")}
              value={nuevoTelefono}
              setter={handleSetNewPhone}
              maxLength={10}
              type="tel"
              moveLabel
            />
          </Box>
          <Box sx={styles.section_selector_input}>
            <FormControl variant="outlined" fullWidth>
              <SelectCustom
                useDefaultIcon
                moveLabel
                wrong={!isPrefijoOtroTelefonoValid}
                value={prefijoOtroTelefono}
                setter={handleSetPrefijoOtroTelefono}
                options={prefijos}
                placeholder={t("edit_personal_data.prefix")}
                label={t("edit_personal_data.secondary_telephone")}
                getOption={(x: any, v: any) => optionEquality(x, v)}
                getLabel={(x: any) =>
                  typeof x === "object" && x !== null
                    ? `+${x.prefix}`
                    : x
                      ? `+${prefijos.find((pr: any) => pr.prefix.trim() === x.trim())
                        ? prefijos.find((pr: any) => pr.prefix.trim() === x.trim())
                          .prefix
                        : ""
                      }`
                      : `+${x}`
                }
              />
            </FormControl>
            <InputCustom
              wrong={!isOtroTelefonoValid}
              fullWidth
              label={t("edit_personal_data.telephone")}
              value={otroTelefono}
              setter={handleSetOtherPhone}
              maxLength={10}
              type="tel"
              moveLabel
              customClass={`${styles.input} ${styles.marginAuto}`}
              customClassWrapper={styles.marginAuto}
            />
          </Box>
          <InputCustom
            fullWidth
            label={t("edit_personal_data.international_telephone")}
            value={telefonoInternacional}
            setter={handleSetInternationalPhone}
            maxLength={20}
            wrong={!validateInternationalPhone(telefonoInternacional)}
            customClass={`${styles.input} ${styles.marginAuto}`}
            customClassWrapper={styles.marginAuto}
            moveLabel
          />
        </Box>
        {renderDireccion()}
      </Box>
    );
  }


  function renderDireccion() {
    return (
      <Box sx={styles.data_section_container}>
        <Box sx={styles.direction_grid}>
          <Box sx={styles.section_30_70}>
            <FormControl variant="outlined" fullWidth>
              <SelectCustom
                moveLabel
                useDefaultIcon
                value={tipoVia}
                setter={handleSetTipoVia}
                options={tiposVia}
                placeholder={t("edit_personal_data.street_type_placeholder")}
                label={t("edit_personal_data.address")}
                getOption={(x: any, v: any) => optionEquality(x, v)}
                getLabel={(x: any) =>
                  typeof x === "object" && x !== null
                    ? capitalize(x.name)
                    : x
                      ? capitalize(
                        tiposVia.find((n: any) => n.id.trim() === x.trim())?.name ??
                        ""
                      )
                      : ""
                }
                wrong={
                  nombreVia && nombreVia.replace(/\s/g, "") !== "" && !tipoVia
                }
              />
            </FormControl>
            <InputCustom
              variant="outlined"
              label={t("edit_personal_data.street_name")}
              value={nombreVia}
              setter={handleSetRoadName}
              maxLength={100}
              wrong={
                tipoVia && (!nombreVia || nombreVia.replace(/\s/g, "") === "")
              }
              fullWidth
              moveLabel
              customClass={styles.input}
            />
          </Box>
          <Box sx={styles.address_grid}>
            <InputCustom
              type="number"
              fullWidth
              label={t("edit_personal_data.number")}
              value={numeroVia}
              setter={handleSetNumberRoad}
              customClass={styles.input}
              maxLength={4}
              moveLabel

            />
            <InputCustom
              fullWidth
              customClass={styles.input}
              label={t("edit_personal_data.block")}
              value={bloque}
              setter={handleSetBloq}
              maxLength={2}
              moveLabel

            />
            <InputCustom
              customClass={styles.input}
              moveLabel
              fullWidth
              label={t("edit_personal_data.floor")}
              value={piso}
              setter={handleSetFloor}
              maxLength={2}
            />
            <InputCustom
              customClass={styles.input}
              moveLabel
              fullWidth
              label={t("edit_personal_data.door")}
              value={puerta}
              setter={handleSetDoor}
              maxLength={1}
            />
            <InputCustom
              customClass={styles.input}
              moveLabel
              wrong={!isCodigoPostalValid}
              fullWidth
              type={pais && Number(pais) !== 724 ? "text" : "number"}
              onInput={(event: any) => {
                if (event.target.value.length > 10)
                  event.target.value = event.target.value.slice(0, 10);
              }}
              label={t("edit_personal_data.postal_code")}
              value={codigoPostal}
              setter={handleSetPostalCode}
              maxLength={10}
            />
          </Box>
        </Box>
        <Box sx={styles.location_grid}>
          <SelectCustom
            moveLabel
            useDefaultIcon
            value={pais}
            setter={handlePais}
            label={t("edit_personal_data.country_placeholder")}
            options={paises}
            getOption={(x: any, v: any) => optionEquality(x, v)}
            wrong={!pais}
            getLabel={(x: any) =>
              typeof x === "object" && x !== null
                ? x.name
                : handleGetLabel(x, paises)
            }

          />
          <SelectCustom
            moveLabel
            useDefaultIcon
            value={pais ? provincia : ""}
            disabled={!pais}
            setter={handleSetProvincia}
            label={pais == SPAIN || !pais ? t("edit_personal_data.province_placeholder") : t("edit_personal_data.region")}
            options={getProvinceOptions(provincias, pais, paises)}
            getOption={(x: any, v: any) => optionEquality(x, v)}
            wrong={pais && !provincia}
            getLabel={(x: any) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                  ? handleProvince(provincias, paises, x, pais)
                  : ""
            }
          />
          {pais == SPAIN || !pais ?
            <SelectCustom
              moveLabel
              useDefaultIcon
              disabled={!pais || !provincia || pais !== 724}
              value={pais && provincia ? localidad : null}
              setter={handleSetLocalidad}
              label={t("edit_personal_data.city_placeholder")}
              options={localidades.filter(
                (loc: any) => Number(loc.id_province) === Number(provincia)
              )}
              getOption={(x: any, v: any) => optionEquality(x, v)}
              wrong={pais && provincia && !localidad}
              getLabel={(x: any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : handleGetLabel(x, localidades)
              }

            />
            :
            <InputCustom
              variant="outlined"
              customClass={styles.input}
              label={t("edit_personal_data.city_abroad")}
              value={ciudad}
              setter={handleSetCity}
              moveLabel
              wrong={pais && !ciudad}
            />
          }
        </Box>
      </Box>
    );
  }

  function deletePerfilDigital(perfil: number) {
    const auxList = [...perfilesDigitales];
    const index = perfilesDigitales.indexOf(perfil);
    if (index !== -1) {
      auxList.splice(index, 1);
      setPerfilesDigitales(auxList);
    }
  }

  function renderPerfilDigital(perfil: any) {
    return (
      <Box sx={styles.single_perfil_digital}>
        <Box
          sx={styles.icon_text_perfil_digital}
          onClick={() => window.open(perfil.url)}
          style={{ cursor: "pointer" }}
        >
          {renderSwitchDigitalProfile(perfil.tipoPerfilDigital)}
          <Box sx={styles.icon_text_perfil_digital_texto}>{perfil.url} </Box>
        </Box>
        <Box
          onClick={() => deletePerfilDigital(perfil)}
          style={{ cursor: "pointer" }}
        >
          <Trash title={t("icons_tooltips.delete")} />
        </Box>
      </Box>
    );
  }

  function handleButtonAddDigitalProfiles() {
    const visibilityButton = newUriPerfilDigital &&
      newPerfilDigital &&
      newUriPerfilDigital.replace(/\s/g, "") !== "" ? "visible" : "hidden";
    return (
      !matchesMobile ?
        <Box sx={styles.button_add_profiles}
          onClick={() => addPerfilDigital()}
          style={{
            visibility: visibilityButton
          }}
        >
          <Add title={t("icons_tooltips.add")} />
          <Typography color="primary" sx={styles.text_add_profiles}
          > {t("edit_personal_data.add_digital_profiles")}
          </Typography>
        </Box >
        : <Box style={{ visibility: visibilityButton }}>
          <ButtonOutlined
            disabled={!visibilityButton}
            size="100%"
            text={t("common.add")}
            onClick={() => addPerfilDigital()} />
        </Box>
    );
  }

  function handleChipsValueRelationships(relacion:any) {
      const newObject = [] as any;
      relacion.forEach((c: any) => {
        newObject.push({
          id: c.idParentescoGalicia,
          name: relationShipOptions.find((r: any) => r.id == c.idParentescoGalicia)?.name,
          parentescoGalicia: c.parentescoGalicia
        });
      });
      return newObject;
  }

  function handleOtherRelationShip(value:any){
    let deleteOtroParentesco = relationship.filter((r: any) => r.id != OTRO_TIPO_PARENTESCO);
    const isOtherRelation = relationship.find((r: any) => r.id == OTRO_TIPO_PARENTESCO);
    if (isOtherRelation) {
      deleteOtroParentesco = [...deleteOtroParentesco, { ...isOtherRelation, name: `Otros ${value !== "" ? `(${value})` : ""}` }];
      setRelationship(deleteOtroParentesco);
    }
  }

  function renderRetornaInfo() {
    return (
      <Box sx={styles.data_section_container}>
        <Box sx={styles.data_section_title}>
          {t("edit_personal_data.preference_data")}
        </Box>
        <Box sx={styles.fullname_grid}>
          <SelectCustom
            moveLabel
            useDefaultIcon
            value={paisOrigen}
            setter={setPaisOrigen}
            label={t("edit_personal_data.home_country_placeholder")}
            options={paises}
            getOption={(x: any, v: any) => optionEquality(x, v)}
            wrong={!paisOrigen}
            getLabel={(x: any) =>
              typeof x === "object" && x !== null
                ? x.name
                : handleGetLabel(x, paises)
            }
          />
          <AutocompleteWithLabelAnimation
            id="autocomplete-travel-availability-types"
            options={travelAvailabilityOptions}
            getLabel={(x: any) => typeof x === "object" && x !== null
                ? x.name
                : travelAvailabilityOptions.find((c: any) => Number(c.id) == Number(x))?.name ?? ""}
            getOption={(x: any, v: any) => optionEquality(x, v)}
            label={t("basicData.travelAvailability")}
            value={travelAvailability}
            setter={(value: any) => {
              setTravelAvailability( value ? Number(value.id) : null);
            }}
            moveLabel
          />
          <InputCustom
            name="travelWith"
            label={t("basicData.travelWith")}
            value={travelWith}
            setter={(e: any) => {
              setTravelWith(e.target.value);
            }}
            moveLabel
            maxLength={100}
          />
          <AutocompleteWithLabelAnimation
            id={"provincia_preferencia_autocomplete"}
            autoComplete={"new-password"}
            useDefaultIcon
            value={provinciaPref}
            setter={(value: any) => {
              handleSetProvinciaPreference(value ? Number(value.id) : null);
            }}
            label={t("basicData.provincePref")}
            options={galicianProvinces}
            getOption={(x: any, v: any) => optionEquality(x, v)}
            getLabel={(x: any) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                  ? handleProvince(provincias, paises, x, pais)
                  : ""
            }
            moveLabel
          />

          {provinciaPref ?
            <AutocompleteWithLabelAnimation
              id={"provincia_preferencia_autocomplete"}
              autoComplete={"new-password"}
              useDefaultIcon
              value={ciudadPref}
              setter={(value: any) => {
                setCiudadPref(value ? Number(value.id) : null);
              }}
              label={t("basicData.cityPref")}
              options={localidades.filter((c: any) => c.id_province == provinciaPref)}
              getOption={(x: any, v: any) => optionEquality(x, v)}
              getLabel={(x: any) => typeof x === "object" && x !== null
                ? x.name
                : localidades.find((c: any) => Number(c.id) == Number(x))?.name || ""}
              moveLabel
            />
            : <div></div>}
        </Box>

        <Box sx={styles.data_subsection_title}>
          {t("edit_personal_data.galician_vinculation")}
        </Box>
        <Box sx={styles.location_grid}>
        <AutocompleteWithChips
            options={relationShipOptions}
            values={relationship}
            label={t("basicData.relationship")}
            setter={setRelationship}
            moveLabel
            maxLength={100}
            noAdd 
            wrong={false} 
            setterOptions={undefined} required={undefined} disabled={undefined} placeholder={undefined} onOpen={undefined} container={undefined} />
          
           {relationship.find((r: any) => (r.idParentescoGalicia || r.id) == OTRO_TIPO_PARENTESCO) ?
            <Box sx={{ alignSelf: "flex-start", width: { xs: "100%", md: "unset" } }}>
              <InputCustom
                wrong={relationship.find((r: any) => (r.idParentescoGalicia || r.id) == OTRO_TIPO_PARENTESCO) && !otherRelationship}
                name="otherRelationship"
                label={t("basicData.otherRelationship")}
                value={otherRelationship}
                setter={(e: any) => {
                  setOtherRelationship(e.target.value);
                  handleOtherRelationShip(e.target.value);
                }}
                moveLabel
                maxLength={100}
              />
            </Box>
            : null}
          <Box sx={{ alignSelf: "flex-start", width: { xs: "100%", md: "unset" }, marginTop: { xs: "10px", md: "unset" } }}>
            <InputCustom
              name="lugar_origen"
              value={lugarOrigen}
              setter={(e: any) => setLugarOrigen(e.target.value)}
              label={t("basicData.originPlace")}
              moveLabel
              maxLength={100}
            />
          </Box>
        </Box>
        <Box mt={"20px"}>
          <PreguntasDatosBasicos
                selectNationality={selectNationality}
                isVinculate={isVinculate}
                spanishPassport={spanishPassport}
                setSelectNationality={setSelectNationality}
                setIsVinculate={setIsVinculate}
                setOtherVinculadoGalicia={setOtherVinculadoGalicia}
                otherVinculadoGalicia={otherVinculadoGalicia}
                showErrors={isVinculate == OTROS_VINCULADO_GALICIA && !otherVinculadoGalicia}
                setSpanishPassport={setSpanishPassport} />
        </Box>
      </Box>
    );
  }

  function renderPerfilesDigitales() {
    return (
      <Box sx={styles.data_section_container}>
        <Box sx={styles.data_section_title}>
          {t("edit_personal_data.digital_profiles")}
        </Box>
        <Box sx={styles.digital_profiles_grid}>
          <FormControl variant="outlined">
            <SelectCustom
              useDefaultIcon
              value={newPerfilDigital}
              setter={(value: any) => {
                setNewPerfilDigital(value ? Number(value.id) : null);
              }}
              options={tiposPerfilDigital}
              label={t("edit_personal_data.social_network_placeholder")}
              getOption={(x: any, v: any) => optionEquality(x, v)}
              getLabel={(x: any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : handleGetLabel(x, tiposPerfilDigital)
              }
              moveLabel
              wrong={
                newUriPerfilDigital &&
                newUriPerfilDigital.replace(/\s/g, "") !== "" &&
                !newPerfilDigital
              }
            />
          </FormControl>
          <InputCustom
            fullWidth
            wrong={
              newPerfilDigital &&
              (!newUriPerfilDigital ||
                newUriPerfilDigital.replace(/\s/g, "") === "")
            }
            moveLabel
            label={t("edit_personal_data.url")}
            value={newUriPerfilDigital}
            setter={handleSetNewUriPerfilDigital}
            maxLength={500}
            customClass={styles.input}
          />
          {handleButtonAddDigitalProfiles()}
          <Box />
          <Box sx={styles.digital_profiles_list_container}>
            {perfilesDigitales &&
              perfilesDigitales.map((perfil: number) => renderPerfilDigital(perfil))}
          </Box>
        </Box>
      </Box>
    );
  }

  return datosPersonales ? (
    <Box sx={styles.principal_container}>
      {!matchesMobile ? (
        <TuCurriculumHeader
          nombre={nombre}
          primerApellido={primerApellido}
          segundoApellido={segundoApellido}
          correoElectronico={correoElectronico}
          prefijo={prefijo}
          telefono={telefono}
          fechaModificacion={fechaModificacion}
          isPhotoEditable
          idPhoto={idFotografia}
        />
      ) : (
        <Box sx={styles.summary_icon_container}>
          <CurriculumIcon
            title={t("icons_tooltips.curriculum_space")}
            color="#DA291C"
            noShrink
          />
        </Box>
      )}
      <Box sx={styles.col2}>
        <Box sx={styles.data_sections_container}>
          {renderDatosPersonales()}
          {renderDatosContacto()}
          {renderRetornaInfo()}
          {renderPerfilesDigitales()}
        </Box>
        <Box sx={styles.action_buttons_grid}>
          <Box
            style={{ gridArea: "save" }}>
            <ButtonFull
              size="100%"
              text={t("basicData.save")}
              fullWidth={matchesMobile}
              onClick={() => validateActualizarDatosPersonales()}
            />

          </Box>
          <Box
            style={{ gridArea: "cancel" }}>
            <ButtonOutlined
              size="100%"
              text={t("common.cancel")}
              fullWidth={matchesMobile}
              onClick={() => navigate(Routes.DASHBOARD)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : <Box></Box>;
}

export default DatosPersonalesRetorna;