import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: "#DA291C"},
    secondary: { main: "#FFFFFF" },
    text: { primary: "#000000" },
    info:{main:"rgb(49, 115, 177)",contrastText:"#FFFFFF"}
  },
  typography: {
    fontFamily: ["Mulish", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
});

export default theme;

type ThemeConfig = typeof theme;
declare module '@emotion/react' {
  export interface Theme extends ThemeConfig {}
}