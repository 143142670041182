import { Box } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonsGroup from '../../../components/RadioButtons/RadioButtons';

export interface IPreguntasRequisitos {
    setSelectNationality: Dispatch<SetStateAction<number | null>>;
    setIsVinculate: Dispatch<SetStateAction<number | null>>;
    setSpanishPassport: Dispatch<SetStateAction<number | null>>
    setOtherVinculadoGalicia: Dispatch<SetStateAction<string | null>>
    otherVinculadoGalicia: string | null;
    selectNationality: any;
    isVinculate: any;
    spanishPassport: any;
    showErrors: boolean;
}

export const PreguntasDatosBasicos = (props: IPreguntasRequisitos) => {
    const {
        setSelectNationality,
        setIsVinculate,
        setSpanishPassport,
        otherVinculadoGalicia,
        setOtherVinculadoGalicia,
        selectNationality,
        showErrors,
        isVinculate,
        spanishPassport
    } = props;
    const { t } = useTranslation();

    const [values, setValues] = useState<any>({
        1: 0, 2: 0, 3: 0
    });

    let isVinculateToGaliciaOptions = [
        { id: 1, value: t("basicData.Galicia_emigrant") },
        { id: 4, value: t("Ascendencia gallega") },
        { id: 2, value: t("basicData.No_Galicia_emigrant") },
        { id: 3, value: t("basicData.others") },
    ] as any;

    const commonOptions = [
        { id: 1, value: "Sí" },
        { id: 0, value: "No" },
        { id: 2, value: "En trámite" }
    ] as any;

    function handleViculate(value: any) {
        isVinculateToGaliciaOptions = isVinculateToGaliciaOptions.map((option: any) => {
            if (option.id === 3) {
                option.input = value === 3;
            }
            return option;
        });
        setIsVinculate(value);
    }

    const questions = [{ id: 1, label: t("basicData.spanish_nacionality"), options: commonOptions, setter: setSelectNationality, required: true },
    { id: 2, label: t("basicData.Galicia_linked"), options: isVinculateToGaliciaOptions, setter: handleViculate, required: true },
    { id: 3, label: t("basicData.spanish_passport"), options: commonOptions, setter: setSpanishPassport, required: true }];

    useEffect(() => {
        setValues({ 1: selectNationality, 2: isVinculate, 3: spanishPassport });
    }, [isVinculate, selectNationality, spanishPassport]);

    function handleQuestions() {
        return <Box>{questions.map((question: any) => {
            return (
                <Box key={question.id} style={{ marginBottom: "25px" }}>
                    <RadioButtonsGroup
                        valueDef={values[question.id]}
                        label={question.label}
                        radioOptions={question.options}
                        changeValue={question.setter}
                        required={question.required}
                        setOtherData={setOtherVinculadoGalicia}
                        otherData={otherVinculadoGalicia}
                        showErrors={showErrors}
                    />
                </Box>
            );
        })
        }</Box>;
    }

    return (
        handleQuestions()
    );
};