import React  from "react";
import format from "date-fns/format";
import PerfilDigitalBlog from "../../assets/icons/perfilDigitalBlog";
import PerfilDigitalFacebook from "../../assets/icons/perfilDigitalFacebook";
import PerfilDigitalInstagram from "../../assets/icons/perfilDigitalInstagram";
import PerfilDigitalLinkedin from "../../assets/icons/perfilDigitalLinkedin";
import PerfilDigitalOtro from "../../assets/icons/perfilDigitalOtro";
import PerfilDigitalPortfolio from "../../assets/icons/perfilDigitalPortfolio";
import PerfilDigitalTwitter from "../../assets/icons/perfilDigitalTwitter";
import { Countries, perfilesDigitalesEnum, Programas, Routes } from "../../shared/enums/enums";
import ReactGA from 'react-ga4';

interface IDataListCountry {
    id: string,
    name: string,
    prefix: string
}

interface IDataListProvince{
    id: string,
    name: string,
    id_country: string,
    codigo_ine: string
}

export const getPrograma = (pathname: string) => {
  switch (pathname.split('/')[1]) {
    case Routes.RETORNA.substring(1):
      return Programas.RETORNA;
    default:
      return Programas.IBEREMPLEO;
  }
};

export const handleCustomValid = (e:any, required:boolean, handleMessageValidate:any) => {
    e.target.setCustomValidity(handleMessageValidate(e.target.value, required));
  };

  export const resetCustomValid = (e:any) => {
    e.target.setCustomValidity("");
  };

export function formatMaestro(maestro: any) {
  return maestro.map((item: any) => {
    Object.keys(item).forEach((key) => {
      switch (key) {
        case "nombre":
          item.name = item.nombre;
          break;
        case "prefijo":
          item.prefix = item.prefijo;
          break;
        case "idProvincia":
          item.id_province = item.idProvincia;
          break;
        case "codigoIne":
          item.codigo_ine = item.codigoIne;
          break;
        default:
          break;
      }
    });
    return item;
  });
}

  export const getDiscapacityOptions = (language : string) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_discapacidades.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_discapacidades.json");
      default:
        return require("../../assets/dataLists/es/discapacidades.json");
    }
  };

  export const getGenderOptions = (language : string) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_sexo.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_sexo.json");
      default:
        return require("../../assets/dataLists/es/sexo.json");
    }
  };

  export const getNivelesEstudio = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_niveles.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_niveles.json");
      default:
        return require("../../assets/dataLists/es/niveles.json");
    }
  };
  
  export const getAreasEstudio = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_areas.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_areas.json");
      default:
        return require("../../assets/dataLists/es/areas.json");
    }
  };
  
  export const getIdiomasList = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_idiomas.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_idiomas.json");
      default:
        return require("../../assets/dataLists/es/idiomas.json");
    }
  };
  
  export const getNivelesIdioma = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_nivelesIdioma.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_nivelesIdioma.json");
      default:
        return require("../../assets/dataLists/es/nivelesIdioma.json");
    }
  };
  
  export const getPermisosConduccion = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_permisoConducir.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_permisoConducir.json");
      default:
        return require("../../assets/dataLists/es/permisoConducir.json");
    }
  };
  
  export const getTiposJornadas = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tipoHorario.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tipoHorario.json");
      case "gl":
        return require("../../assets/dataLists/gl/GL_tipoHorario.json");
      default:
        return require("../../assets/dataLists/es/tipoHorario.json");
    }
  };
  
  export const getTiposSalario = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tipos_salario.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tipos_salario.json");
      default:
        return require("../../assets/dataLists/es/tipos_salario.json");
    }
  };
  
  export const getTiposCambioResidencia = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tipoCambio.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tipoCambio.json");
      default:
        return require("../../assets/dataLists/es/tipoCambio.json");
    }
  };
  
  export const getTiposDesplazamientos = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tipoDesplazamiento.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tipoDesplazamiento.json");
      default:
        return require("../../assets/dataLists/es/tipoDesplazamiento.json");
    }
  };
  
  export const getPermitTypeOptions = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_permisoTrabajo.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_permisoTrabajo.json");
      default:
        return require("../../assets/dataLists/es/permisoTrabajo.json");
    }
  };
  
  

  export const getTiposPerfilDigital = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tipoPerfilDigital.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tipoPerfilDigital.json");
      default:
        return require("../../assets/dataLists/es/tipoPerfilDigital.json");
    }
  };
  
  export const getTiposVia = (language : string) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tipoVia.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tipoVia.json");
      default:
        return require("../../assets/dataLists/es/tipoVia.json");
    }
  };

  export const getNationalityOptions = (language : string) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_nacionalidad.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_nacionalidad.json");
      default:
        return require("../../assets/dataLists/es/nacionalidad.json");
    }
  };

  export const getProvincias = (language : any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_provincias.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_provincias.json");
      default:
        return require("../../assets/dataLists/es/provincias.json");
    }
  };

  export const getProvinciasGalicia = (language : string) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_provincias_galicia.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_provincias_galicia.json");
      default:
        return require("../../assets/dataLists/es/provincias_galicia.json");
    }
  };

  export const getCiudadesGalicia = (language : string) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_ciudades_gallegas.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_ciudades_gallegas.json");
      default:
        return require("../../assets/dataLists/es/ciudades_gallegas.json");
    }
  };

  export function validateInternationalPhone(value:any) {
    const pattern_telefonoInternacional = /^[+]?\d{9,20}$/;
    return value ? pattern_telefonoInternacional.test(value): true;
  }

  export function returnLangId(lang:string) {
    switch (lang) {
      case "es":
        return 88;
      case "en":
        return 21;
      case "pt":
        return 74;
      default:
        return 88;
    }
  }

  export function capitalize(str:string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  export function formatDateInput(date:string) {
    const d = date.split("T");
    const [fistDate] = d;
    return fistDate;
  }

  export function validateSocialSecurityNumber(ss:string) {
    if (ss) {
      const doc = ss.trim();
      const variable = parseInt(String(doc).slice(0, 2));
      const numberAffiliate = parseInt(String(doc).slice(2, -2));
      const controlDigits = parseInt(String(doc).slice(-2));
      let result;
      if (numberAffiliate < 10000000) {
        result = numberAffiliate + variable * 10000000;
      } else {
        result = parseInt(`${variable}${numberAffiliate}`);
      }
      const mod = result % 97;
      return doc.length === 12 && mod === controlDigits;
    } else {
      return true;
    }
  }

  export function validatePostalCode(postalCode:number, country:number) {
    if (country && Number(country) === 724) {
      return (
        postalCode &&
        postalCode.toString().length === 5 &&
        Number(postalCode) > 1000 &&
        Number(postalCode) <= 52999
      );
    } else {
      return (
        postalCode &&
        postalCode.toString().length > 1 &&
        postalCode.toString().length <= 11
      );
    }
  }

  export function formatToLocalDate(date:any) {
    //returns string with proper format for input type date "dd/mm/yyyy"
  
    return `${format(date, "dd/MM/yyyy")}`;
  }

  export function validateFile(file:any, size = 20) {
    const fileSize = file.size / 1024 / 1024;
    return fileSize <= size;
  }

  export function renderSwitchDigitalProfile(tipoPerfilDigital:number) {
    switch (tipoPerfilDigital) {
      case perfilesDigitalesEnum.LINKEDIN:
        return <PerfilDigitalLinkedin/>;
      case perfilesDigitalesEnum.FACEBOOK:
        return <PerfilDigitalFacebook />;
      case perfilesDigitalesEnum.INSTAGRAM:
        return <PerfilDigitalInstagram />;
      case perfilesDigitalesEnum.TWITTER:
        return <PerfilDigitalTwitter />;
      case perfilesDigitalesEnum.BLOG:
        return <PerfilDigitalBlog />;
      case perfilesDigitalesEnum.PORTFOLIO:
        return <PerfilDigitalPortfolio />;
      default:
        return <PerfilDigitalOtro />;
    }
  }

  export function base64ToArrayBuffer(base64:any) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  export function returnMimeData(value:any) {
    const dividedName = value.split(".");
    const type = dividedName[dividedName.length - 1];
    switch (type) {
      case "pdf":
        return "application/pdf";
      case "doc":
      case "docx":
        return "application/msword";
      case "jpeg":
      case "jpg":
        return "image/jpeg";
      case "png":
        return "image/png";
      default:
        return "";
    }
  }
  

  export const getDocTypes = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tipoDocumento.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tipoDocumento.json");
      default:
        return require("../../assets/dataLists/es/tipoDocumento.json");
    }
  };
  
  export const getContractTypes = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tipoContrato.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tipoContrato.json");
      case "gl":
        return require("../../assets/dataLists/gl/GL_tipoContrato.json");
      default:
        return require("../../assets/dataLists/es/tipoContrato.json");
    }
  };

  export const sendGAEvent = (text: string, data?: any) => {
    const cookie = JSON.parse(localStorage.getItem("cookie") as any);
    if (cookie === true || cookie?.statistics && cookie?.marketing) {
      ReactGA.event(text, data ?? null);
    }
  };
  
  export const getFrecuenciasNotificacion = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_frecuenciaNotificacionAlerta.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_frecuenciaNotificacionAlerta.json");
      default:
        return require("../../assets/dataLists/es/frecuenciaNotificacionAlerta.json");
    }
  };
  
  export const getTimeFilters = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tiempo_candidatura.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tiempo_candidatura.json");
      default:
        return require("../../assets/dataLists/es/tiempo_candidatura.json");
    }
  };
  
  export const getStatusFilters = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_estado_candidatura.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_estado_candidatura.json");
      default:
        return require("../../assets/dataLists/es/estado_candidatura.json");
    }
  };
  
  export const getSalaryRange = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_rangoSalarial.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_rangoSalarial.json");
      default:
        return require("../../assets/dataLists/es/rangoSalarial.json");
    }
  };
  
  export const getSalaryType = (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_tipoSalario.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_tipoSalario.json");
      default:
        return require("../../assets/dataLists/es/tipoSalario.json");
    }
  };
  
  export const getMinimumExperience= (language: any) => {
    switch (language) {
      case "en":
        return require("../../assets/dataLists/en/EN_experienciaMinima.json");
      case "pt":
        return require("../../assets/dataLists/pt/PT_experienciaMinima.json");
      default:
        return require("../../assets/dataLists/es/experienciaMinima.json");
    }
  };

  export const getYear = (stringDate:string) => {
    const formattedDate = new Date(stringDate);
    return formattedDate ? formattedDate.getFullYear() : "";
  };

  export function optionEquality(x: any, v: any) {
    if (x.id) {
      return x.id === v.id || Number(x.id) === Number(v);
    } else {
      return x === v;
    }
  }

  export const getProvinceOptions = (provincias: any,idPais: any,dataLiscountry: any)=> {
    const provinces = provincias.filter(
      (prov: any) => Number(prov.id_country) === idPais || Number(prov.id_country) === Number(idPais?.id));
  
    if (provinces.length === 1 && provinces[0]?.name === '') {
      provinces[0].name = dataLiscountry.find((n: any) => Number(n.id) === Number(idPais))?.name || "";
    }
      return provinces;
  };

  export function handleProvince(dataListProv: any, dataListCountry: any, idProvincie: any, idPais: any) {
    if (dataListProv.find((n: any) => Number(n.id) === Number(idProvincie))?.name && idProvincie !== idPais) {
      return dataListProv.find((n: any) => Number(n.id) === Number(idProvincie))?.name;
    } else {
      return dataListCountry.find((n: any) => Number(n.id) === Number(idPais))?.name ?? "";
    }
  }

  export function validateDocument(doc: any, type = "NIF") {
    const DNI_REGEX = /^(\d{8})([A-Za-z])$/;
    const NIE_REGEX = /^[XYZxyz]\d{7}[A-Za-z]$/;
  
    if (doc.lenth > 20) {
      return false;
    }
  
    const validDNI = function (nif = doc) {
      const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
      const letter = dni_letters.charAt(parseInt(nif, 10) % 23);
    return letter === nif.charAt(8).toUpperCase();
    };
  
    const validNIE = function () {
      // Change the initial letter for the corresponding number and validate as DNI
      let nie_prefix = doc.charAt(0).toUpperCase();
      // eslint-disable-next-line
      switch (nie_prefix) {
        case "X":
          nie_prefix = 0;
          break;
        case "Y":
          nie_prefix = 1;
          break;
        case "Z":
          nie_prefix = 2;
          break;
      }
  
      return validDNI(nie_prefix + doc.substr(1));
    };
  
    switch (type) {
      case "NIF":
        return DNI_REGEX.test(doc) && validDNI();
      case "NIE":
        return NIE_REGEX.test(doc) && validNIE();
      default:
        return true;
    }
  }

  export function validatePhone(phone: any) {
    return phone && phone.toString().length <= 10;
  }

  export function validateEmail(mail: any) {
    // eslint-disable-next-line
    if (mail && mail.length <= 100) {
      return /^[\w\-\.]+@([\w\-]+\.)+[\w\-]{2,4}$/.test( //eslint-disable-line
        mail
      );
    }
    return false;
  }

  export function validateDate(value: any, minDate?:number | Date, maxDate?:number | Date) {
    if (minDate && maxDate) {
      return (
        new Date(value).setHours(0, 0, 0, 0) >=
        new Date(minDate).setHours(0, 0, 0, 0) &&
        new Date(value).setHours(0, 0, 0, 0) <=
        new Date(maxDate).setHours(0, 0, 0, 0)
      );
    } else if (minDate) {
      return (
        new Date(value).setHours(0, 0, 0, 0) >=
        new Date(minDate).setHours(0, 0, 0, 0)
      );
    } else if (maxDate) {
      return (
        new Date(value).setHours(0, 0, 0, 0) <=
        new Date(maxDate).setHours(0, 0, 0, 0)
      );
    } else {
      return true;
    }
  }

  export function getLabelForSelectComponent(field:any, nameProperty:any) {
    return field[nameProperty] || "";
  }


  export const filterSalaryRangeOptions = (DataListsalarios:any, minimumSalary:any) => {
    return DataListsalarios.filter((s:any) => minimumSalary ? Number(s.order) >= Number(minimumSalary.order || minimumSalary.orden) 
                                                        : DataListsalarios);
  };
  
  export function normalizeString(item: any) {
    return item?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f\s]/g, "");
  }

  export function isForeingCountry(dataListProvincies: any, alertProvinces: any) {
    const isForeing = dataListProvincies.find((prov: any) => alertProvinces.includes(Number(prov.id)) && prov.id_country != Countries.SPAIN) ? -1 : 0;
    return isForeing;
  }

  export function optionEqualityString(x:any, v:any) {
    if (x.id) {
      return x.id === v.id;
    } else {
      return x === v;
    }
  }

export function findIdInDataList(dataList: any, idToFind: string | number) {
    return dataList.find(
      (location: any) => Number(location.id) === idToFind
    )?.name ?? "";
  }