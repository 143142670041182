const styles = {
    dialog_cookies_container: (isCookies) => ({
        "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                position: isCookies ? "absolute": "relative",
                right: "0px",
                bottom: "0px",
                width: {xs:"90%",sm:"480px"},
                padding: {xs: "20px",sm:"50px"},
                borderRadius: "12px",
                maxHeight:{xs:"70%",sm:"unset"},
                margin: isCookies && {xs:'0 19px 23px 0',sm:"unset"}
            },
        },
    }),
    cookieIcon: {
        marginTop: { xs: "15px", sm: "unset" },
        maxWidth: { xs: "100%", sm: "unset" }
    },
    dialog_title: {
        marginBottom:"30px",
        padding: 0,
        fontWeight: 800,
        '& h2':{
          fontSize: "22px",
          lineHeight: "30px",
          color: "black",
        },
        marginTop: { xs: "15px", sm: "unset" },
        maxWidth: { xs: "100%", sm: "unset" }
        
      },
      dialog_content: {
        padding: 0,
        marginBottom:"30px",
      },
      notContent:{
        display:"flex",
        alignItems:"center",
        padding:0,
        overflow:"hidden",
        justifyContent:"center",
      }

};
export default styles;