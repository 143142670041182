import React, { useEffect } from "react";

function ResetPassword() {

  useEffect(() => {
    const timeout = setTimeout(() => {      
      window.location.replace(window._env_.REACT_APP_OIDC_REDIRECT_URI_AFTER_RESET);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return <>Will redirect in 3 seconds...</>;
}

export default ResetPassword;