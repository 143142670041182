
const styles = {
    principal_container: {
      gridArea: "body_container",
      display: "grid",
      gridTemplateColumns:{xs:"auto",md:"30% 65%"} ,
      columnGap: "20px",
    },
    col2: {
      marginTop:{xs:"0px",md:"37px"},
      padding:{xs:"36px",md:"90px"},
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "60px",
    },
    data_sections_container: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap:{xs:"30px",md:"50px"}  ,
    },
    data_section_icon_and_title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      marginTop: {xs:'30px',lg:"unset"}
    },
    date_picker_class: {
      width: "100%",
    },
    data_section_title_principal: {
      fontSize: {xs:"18px",md:"22px"},
      fontWeight: 900,
      marginLeft: "10px",
    },
    text_switch_grid_style: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    text_switch_style: {
      fontSize: "14px",
    },
    text_section_title_style: {
      fontSize: "16px",
      fontWeight: 900,
      gridColumnStart: {xs:"unset",md:1},
      gridColumnEnd:{xs:"unset",md:3} ,
    },
    general_grid: {
      display: "grid",
      gridTemplateColumns:{xs:"1fr",md:".5fr .5fr"} ,
      columnGap: "24px",
      rowGap: "30px",
      alignItems: "end",
    },
    preferences_header_grid: {
      overflow: "hidden",
    },
    preferences_grid: {
      display: "grid",
      gridTemplateColumns: {xs:"1fr",md:".5fr .5fr"},
      columnGap:{md:"24px"} ,
      alignItems: "end",
      marginTop: "30px",
      rowGap:{xs:"30px"}
    },
    preferences_grid_2: {
      display: "grid",
      gridTemplateColumns: {xs:"1fr",md:"2.05fr 1fr"},
      columnGap: "24px",
      marginTop:{xs:"30px",md:"24px"} ,
    },
    action_buttons_grid: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: {xs:"1fr",md:".25fr .25fr .25fr"},
      gridTemplateAreas: {xs:`'save'
                              'cancel'`  ,
                          md:`'cancel . save'`},
      columnGap:{md:"24px"},
      rowGap:{xs:"10px"},
      marginTop:{md:"30px"}

    },
    customChip: {
      fontSize: "0.8125rem",
      display: "inline-flex",
      height: "32px",
      boxSizing: "border-box",
      padding: "0px",
      alignItems: "center",
      borderRadius: "16px",
      margin: "10px 5px 0px 5px",
      backgroundColor: "#EFEFEF",
      maxWidth: "100%",
    },
    nameChip: {
      paddingLeft: "12px",
      paddingRight: "12px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    iconGeneral: {
      margin: "0 5px 0 -3px",
      width: "22px",
      display: "flex",
      cursor: "pointer",
    },
  };

  export default styles;