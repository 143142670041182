import React from "react";
import Switch from '@mui/material/Switch';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "48px",
    height: "24px",
    padding: "0px",
    marginLeft: "auto",
    display: "inline-flex",
    alignItems: "center"
  },
  switchBase: {
    padding: "0px",
    paddingLeft: "2px",
    position: "absolute",
    color: "#FFFFFF",//theme.palette.primary.main,
    "&$checked": {
      transform: "translateX(24px)",
      color: "white",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#DA291C"
      }
    },
    "&$disabled": {
      color: "white",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#DDDDDD"
      }
    }
  },
  thumb: {
    width: "20px",
    height: "20px",
    marginTop: "2px",
    boxShadow: "none"
  },
  track: {
    // border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    opacity: 1,
    backgroundColor: "#DDDDDD"
  },
  checked: {},
  disabled: {}
}));

function AntSwitch(props) {
  const { checked, setter, disabled } = props;
  const classes = useStyles();

  return <Switch classes={classes} checked={checked} onChange={setter} disabled={disabled}/>;
}

export default AntSwitch;
