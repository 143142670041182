const styles = {
    uploader_title: {
        marginBottom: "25px",
    },
    no_margin: {
      margin: "0px",
    },
    upload_button: {
        marginTop: "20px",
        width: "100%",
        height: "36px",
        textTransform: "none",
        fontWeight: "bold",
    },
    attach_section_empty: {
        margin: "20px 0px",
      },
      attach_section_empty_2: {
        margin: "20px 0px",
      },
      attach_section_container_1: {
      },
      attach_section_container_2: {
      },
      attach_section: {
        margin: "20px 0px",
        display:"flex"
      },
      attach_icon: {
        float: "right",
        cursor: "pointer",
      },
      galician_desc: {
        fontSize: "13px",
        fontWeight: 600,
        paddingTop: "8px",
        paddingLeft: "4px"
      },
      attach_label: {
        textDecoration: "underline",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        margin: "0px",
        cursor: "pointer"
      },
      attach_label_black: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        margin: "0px",
        color: "black"
      },
      attach_label_disabled: {
        color: "grey",
        textDecoration: "underline",
      },
      attach_icon_disabled: {
        float: "right",
      },
      attach_pointer: {
        cursor: "pointer",
      },
      titlemain: {
      }
};

export default styles;