import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useApi } from "../hooks/useApi";
import { getCountries, getRegions, getIdentificationType, getTimetableType } from "../hooks/userApi";
import { formatMaestro } from "../utils/functions";

type Props = {
    children: React.ReactNode,
    paises?: any
};

type MaestroController = {
    paises: any,
    prefijos: any,
    localidades: any,
    tipoIdentficacion:any,
    tipoHorario:any
};

export const MaestroContext = createContext<MaestroController>({} as MaestroController);

function MaestrosContext({ children }: Props) {
    const [paises, setPaises] = useState<any>([]);
    const [prefijos, setPrefijos] = useState<any>([]);
    const [localidades, setLocalidades] = useState<any>([]);
    const [tipoIdentficacion, setTipoIdentficacion] = useState<any>([]);
    const [tipoHorario, setTipoHorario] = useState<any>([]);
    const callApi = useApi();

    useEffect(() => {
        getMaestros();
        getLocalidades();
        getTipoIdentificacion();
        getTipoHorario();
    }, []);

    const getMaestros = useCallback(async () => {
        if (!paises.length) {
            callApi(getCountries()).then((data) => {
                setPaises(formatMaestro(data));
                setPrefijos(formatMaestro(data));
            });
        }
    }, []);

    const getLocalidades = useCallback(async () => {
        if (!localidades.length) {
            callApi(getRegions()).then((data) => {
                setLocalidades(formatMaestro(data));
            });
        }
    }, []);

    const getTipoIdentificacion = useCallback(async () => {
        if (!tipoIdentficacion.length) {
            callApi(getIdentificationType()).then((data) => {
                setTipoIdentficacion(formatMaestro(data));
            });
        }
    }, []);

    const getTipoHorario = useCallback(async () => {
        if (!tipoHorario.length) {
            callApi(getTimetableType()).then((data) => {
                setTipoHorario(formatMaestro(data));
            });
        }
    }, []);

    const value = useMemo(() => { 
        return { paises, prefijos, localidades , tipoIdentficacion ,tipoHorario};
    }, [paises, prefijos,localidades, tipoIdentficacion,tipoHorario]);

    return (
        <MaestroContext.Provider
            value={value as any}>
            {children}
        </MaestroContext.Provider>
    );
}

export default MaestrosContext;