const styles = {
    action_buttons_grid: {
      display: "grid",
      gridTemplateColumns: {xs:"1fr",md: ".25fr .25fr .5fr"},
      columnGap: "25px",
      rowGap:{xs:"20px" ,md:"24px"},
      gridTemplateAreas: {
      md:` 'cancel . save'
      '. . save_and_attach'`,
      xs:`'save'
          'save_and_attach'
          'cancel'`},
      fontSize:{xs:"16px"} ,
    },

};

export default styles;