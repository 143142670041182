import React from "react";
import { useTranslation } from "react-i18next";

function DocumentMenuItem(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.documents")} = props;

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <title>{title}</title>
            <g id="Portal-de-empleo---móvil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-70.000000, -924.000000)">
                    <g id="icon-menu-tus-documentos" transform="translate(70.000000, 924.000000)">
                        <rect id="Rectangle-Copy-8" x="0" y="0" width="20" height="20"/>
                        <g id="Group-9" transform="translate(2.000000, 3.000000)">
                            <g id="folder-user"/>
                            <path d="M2.75862069,14 L1.37931034,14 C0.617538276,14 0,13.3973064 0,12.6538462 L0,0 L5.79310345,0 L7.44827586,2.69230769 L16,2.69230769 L16,12.6538462 C16,13.3973064 15.3824617,14 14.6206897,14 L13.2413793,14" id="Path" stroke="#DA291C" strokeLinecap="square"/>
                            <ellipse id="Oval" stroke="#DA291C" strokeLinecap="square" cx="8" cy="7.53846154" rx="1.65517241" ry="1.61538462"/>
                            <path d="M8,10.5 C6.01939262,10.5 4.4137931,12.0670034 4.4137931,14 L11.5862069,14 C11.5862069,12.0670034 9.98060738,10.5 8,10.5 Z" id="Path" stroke="#DA291C" strokeLinecap="square"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
  );
}

export default DocumentMenuItem;