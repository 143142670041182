/* eslint-disable */
import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputCustom from "../Inputs/InputCustom";
import { Box } from "@mui/material";


export interface IRadioButtonsGroup {
  label: string
  radioOptions: Array<{ id: string; value: string; }>
  changeValue: Dispatch<SetStateAction<number | null>>
  required: boolean,
  valueDef: number,
  setOtherData: Dispatch<SetStateAction<string | null>>,
  otherData: string | null,
  showErrors: boolean
}

export default function RadioButtonsGroup(props: IRadioButtonsGroup) {
  const { label, radioOptions, changeValue, required, valueDef, setOtherData, otherData, showErrors } = props;
  const [value, setValue] = useState<boolean | number | null>(valueDef);
  const OTROS_VINCULADO_GALICIA = 3;

  useEffect(() => {
    setValue(valueDef);
  }, [valueDef]);

  function handleClick(event: any) {
    let newValue = null;

    if (event.target.value !== value) {
      newValue = event.target.value;
    }
    setValue(newValue);
    changeValue(newValue);
  }

  const printAsterisk = () => {
    return (required && <span style={{ color: "red" }}>{"*"}</span>);
  };

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label" style={{ color: "black", marginBottom: "10px" }}>
        {label} {printAsterisk()}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        style={{ marginLeft: "11px" }}
        value={value}
      >
        {radioOptions.map((option: any) => (
          <Box key={option.id} sx={{ display: "flex", alignItems: "flex-end" }}>
            <FormControlLabel key={option.id}
              style={{ marginLeft: "10px" }}
              value={option.id}
              control={<Radio size="small" onClick={handleClick} />} label={option.value} />
            {option.value === "Otros" && value == OTROS_VINCULADO_GALICIA ?
              <InputCustom
                label={""}
                value={otherData}
                setter={(e: any) => setOtherData(e.target.value)}
                moveLabel
                maxLength={100}
                wrong={showErrors}
              /> : null}
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
}