import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Routes } from "../enums/enums";
import { getTiposJornadas, sendGAEvent } from "../utils/functions";
import i18next from "../../i18n";
import { getOffers } from "../hooks/userApi";
import { useApi } from "../hooks/useApi";
import OffersSearcher from "./components/Busqueda/offersSearcher";
import icon_maleta from "../images/icon_ maleta.svg";
import icon_maleta_white from "../images/icon_ maleta_white.svg";
import icon_doc from "../images/icon_doc.svg";
import icon_doc_white from "../images/icon_doc_white.svg";
import icon_persona_1 from "../images/icon_persona_1.svg";
import icon_persona_1_white from "../images/icon_persona_1_white.svg";
import icon_persona_2 from "../images/icon_persona_2.svg";
import icon_persona_2_white from "../images/icon_persona_2_white.svg";

import Button from "@mui/material/Button";
import { MaestroContext } from "../contexts/MaestroContext";
import { AuthService } from "./auth/AuthService";

const auth = new AuthService(); 

const styles =
{
    bannerText: {
        padding: {xs:"18px 2px 18px 18px",md: "unset"},
        display:"flex",
        flexDirection: 'column',
        alignItems: 'center',
        width: "100%", 
        margin: {lg:"53px 19% 25px 28%"},
    },
    mobileTextBanner: {width: "100%", height: "100%", padding: "14px", display: "flex", justifyContent: "center", flexDirection: "column"},
    text: {fontSize: "17px", lineHeight: "2.5", fontFamily: "Mulish",color:"#3173B1",fontWeight: {md:700}},
    mobileText: {fontSize: "18px", paddingTop: "6px", fontFamily: "Mulish",color:"white"},
    row: {display: "flex", height: "33px", marginBottom: {xs:"10px",md:"15px"},marginRight:{lg:"40px",xl:"135px"}},
    mobileRow: {
        display: "flex",
        height: "40px",
        marginBottom: "16px"
    },
    register: {
        '&:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
        backgroundColor: "white",
        color: "black",
        textTransform: "none",
        height: "30px",
        fontWeight: 700,
        fontSize: "16px",
        fontFamily: "Mulish",
        width: "28.5%",
        marginLeft: "10px",
        mt: "6px"
    },
    registermobile: {
        '&:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
        backgroundColor: "white",
        color: "black",
        textTransform: "none",
        height: "40px",
        fontWeight: 700,
        fontFamily: "Mulish",
        fontSize: "16px",
        width: "100%",
        mt: "15px",
        maxWidth: "460px", 
    },
    alignRight: {
        textAlign: 'right'
    },
    banner: {
        width: '100%',
    },
    offer: {
        borderBottom: "1px solid #DDDDDD",
        paddingTop: "16px",
        paddingBottom: "16px",
        cursor: "pointer",
    },
    text_float_right: {
        float: "right",
        fontFamily: 'Mulish,sans-serif',
        fontSize: '15px',
        fontWeight:500,
        letterSpacing: "1.05px",
        paddingTop:{xs:"unset",md:'35px'} 
    },
    offers_list: {
        paddingLeft: "0px",
        marginTop: "-16px",
        "list-style-type": "none",
    },
    offer_subtitle: {
        marginTop: "0px",
    },
    offers_showed: {
        color: "#DA291C",
        paddingBottom: "10px",
        display: "flex",
        justifyContent: "flex-end"
    },

    body_right_col: {
        minWidth: {xs: "unset", md: "772px"},
        display: "flex",
        flex: 1,
        width: {xs: "unset", md:'960px'}
    },

    body_right_col_container: {
        width: "100%",
        padding: {xs: "40px 0", md: "20px 0"},
    },
    cell: {
        border: '#ccc 0px solid'
    },
    offerList: {
        padding: {xs: '32px 20px', md: '0 19% 67px 19%'},
        display: 'flex',
        flexDirection: 'column',
        alignItems: {xs:"unset",md:'center'},
        width: '100%',
        "& title": {
            fontSize: '1.5em',
            fontWeight: 'bold'
        },
        "& searchForm": {
            paddingTop: '8px',
            paddingLeft: '12px',
            paddingBottom: '16px',
            "& input": {
                width: '255px',
                height: '32px',
                marginRight: '12px'
            },
            "& button": {
                background: 'black',
                width: '128px',
                height: '32px',
                color: 'white'
            }
        },
        "& offer": {
            display: 'grid',
            padding: '24px 12px',
            width: '100%',
            gridTemplateColumns: '85% 15%',
            borderBottom: '#ccc 1px solid',
            cursor: 'pointer',
            "&:hover": {
                background: '#fcfcfc'
            },
            "& title": {
                fontWeight: 'bold',
                fontSize: '1.3em'
            },
            "& desc": {
                fontWeight: 'normal',
                fontSize: '1em'
            },
            "& date": {
                fontWeight: 'normal',
                fontSize: '.8em',
            }
        },
        "& actions": {
            width: '100%',
            textAlign: 'right',
            color: '#000000',
            fontSize: '1em',
            cursor: 'pointer',
            marginTop: '20px'
        }
    },
    programa: {
        "& icon": {
            height: '16px',
        }
    },
    headerTitleClaim:{
        display: "flex",
        flexDirection:"column", 
        width: "240px",
        cursor: 'pointer'
    },
    nortempoLogo: {
        margin: {xs:"12px 0 6px 0", md: "unset"}
      },
    logoProgramaRetorna: {
        width: "232px",
        textAlign: "center",
        font: "Mulish",
        fontWeight:"bold",
        letterSpacing: "0px",
        color: "#3173B1",
        height: '76px',
    },
    logoProgramaTextBig:{
        cursor: "pointer", 
        fontSize: "23px", 
        fontWeight: "700", 
        lineHeight: "25px", 
        marginBottom: "4px" ,
        transform: "scale(1) rotate(0deg) translate(-6px, 0px) skew(-6deg, 0deg)"
    },
    logoProgramaTextSmall:{
        fontSize: "12px", 
        paddingRight: '17px',
        fontWeight: "700" 
    },
    bannerOffer: {
        width: "100%",
        height: "138px",
        background: "#3173B1",
        display: 'flex',
        justifyContent: 'center'
    },
    offerInputs:{
        paddingTop: '35px',
    },
    welcomeTextMovile:{
         display: "flex", 
         fontFamily: "Mulish", 
         fontSize: "37px", 
         lineHeight: "40px", 
         marginBottom: "12px" 
    },
    welcomeTextDesk:{
            width: "634px", height: "95px", textalign: "left",
            color: "#D50000",
            fontSize: "32px",
            paddingLeft: '24px',
            borderLeft: '1px solid white',
            marginLeft: '66px',
            fontWeight: 600,
            display: "flex",
            flexDirection: "column",
        
    }
};

interface IPortalCandidatoHome {
    programa: any,
}

function PortalCandidatoHome({programa}:IPortalCandidatoHome ) {
    const theme = useTheme<any>();
    const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [filteredOffers, setFilteredOffers] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true);
    const [query, setQuery] = useState<any>("");
    const goToOfferList = useCallback(() => navigate(Routes.SEARCH_OFFERS + query), [query]);
    const goToOffer = useCallback((codigo: string) => navigate(`/retorna/oferta?codigo=${codigo}`), []);
    const { t } = useTranslation();
    const callApi = useApi();
    
    const { localidades } = useContext(MaestroContext);

    const tiposHorario = getTiposJornadas(i18next.languages[0]);
    
    const Register = useCallback(() => {
        sendGAEvent("Click botón Regístrate");
        sessionStorage.setItem("fromOferta", `${location.pathname}${location.search}`);
        sessionStorage.setItem("fromRegister", "true");
        auth.register();
    }, []);

    const getFormattedDate = (date: any) => {
        const d = date.split("T")[0].split("-");
        return `${d[2]}/${d[1]}/${d[0]}`;
    };

    const getNombreLocalidad = (idLocalidad: any) =>
        localidades.find(
            (localidad: any) => Number(localidad.id) === Number(idLocalidad)
        )?.name ?? "";
    
    const getNombreTipoHorario = (idTipoHorario: any) => 
    tiposHorario.find(
      (tipoHorario: any) => Number(tipoHorario.id) === Number(idTipoHorario)
    )?.name ?? "";

    useEffect(() => {
        handleGetOffers();
    }, []);

    function handleGetOffers(prov = 0, puest = "") {
        setLoading(true);
        let queryString = `&numeroOfertas=5&provincia=${prov}`;
        let querySave = `?provincia=${prov}`;
        if (puest.length) {
            const keyWords = puest.split(" ");
            keyWords.forEach((k: string) => {
                queryString = queryString + `&palabrasClave=${k}`;
                querySave = querySave + `&palabrasClave=${k}`;
            });
        }
        setQuery(querySave);
        callApi(getOffers(true, queryString)).then((data) => {
            setFilteredOffers(data);
            setLoading(false);
        });
    }

    function handleOfferSearcher(colorButton: string, hasWhiteTitle?: boolean, width?: string, marginLeft?: string,isFromHomePage?:boolean, height?:string) {
        return (
        <OffersSearcher
            programa={programa}
            home
            whiteLabel
            search={handleGetOffers}
            noRedirect
            whiteTitle={hasWhiteTitle}
            displayInline
            customClass={{
                backgroundColor: colorButton, marginBottom: 0, width, marginLeft, height,
                    '&:hover': {
                        backgroundColor: '#3173B1',
                        border: "1px solid white"
                    }
            }}
            isFromHomePage={isFromHomePage}
        />);
    }

    return (
        <Box>
            <Box
                id="banner"
                component="div"
                sx={styles.banner}>
                <Box sx={matchesMobile ? { maxWidth: "100%", height: "620px" , backgroundColor: 'rgb(0, 123, 196)'} : { maxWidth: "100%", width: "100%",height: "450px", background: ' transparent url(/images/faro_4.jpg)',  backgroundPosition: {md:'28%',lg:"unset"} ,backgroundSize: 'cover', backgroundRepeat: "no-repeat", display: "flex", alignItems: "center" }}>
                    
                    <Box sx={matchesMobile ? styles.mobileTextBanner : styles.bannerText}>
                    <Box sx={styles.headerTitleClaim} onClick={()=>window.open(Routes.FUNDACION_NORTEMPO)}>
                    </Box>
                            <Box sx={{ display: "flex" }}>
                            {!matchesMobile &&
                                <Box sx={styles.logoProgramaRetorna}>
                                    <Typography color="#3173B1" sx={styles.logoProgramaTextBig} onClick={() => window.open(Routes.RETORNA_XUNTA, '_blank', 'noreferrer')}>Programa Retorna Cualifica Emprego</Typography>
                                    <Typography color="#3173B1" sx={styles.logoProgramaTextSmall}>{t("cadidatePortalHome.claimRetorna")}</Typography>
                                </Box>
                            }
                            <Box color="white" sx={matchesMobile ? styles.welcomeTextMovile : styles.welcomeTextDesk}>
                                <span >
                                    <b>{t("cadidatePortalHome.welcome")} </b>
                                    <b style={{ fontWeight: 900 }}>{t("cadidatePortalHome.fundacionName")}</b>
                                </span>
                            </Box>
                            </Box>
                      
                    <Box sx={{ display: { xs: "unset", md: "flex" } }}>
                        <Box sx={{width:"245px"}}></Box>
                            <Box sx={{ width: { xs: "unset", md: "634px" }, marginLeft: { xs: "unset", md:'20px'},paddingTop: { xs: "unset", md:'36px'},paddingBottom:{ xs: "unset", md:'25px'}}}>
                                <Box sx={matchesMobile ? styles.mobileRow : styles.row}>
                                    <img src={!matchesMobile ? icon_persona_1 : icon_persona_1_white} style={{ marginRight: "8px", maxWidth: 'inherit'}} alt="vaquita" />
                                    <Typography  sx={matchesMobile ? styles.mobileText : styles.text}>{t("cadidatePortalHome.foundPerfectJob")}</Typography>
                                </Box>
                                <Box sx={matchesMobile ? styles.mobileRow : styles.row}>
                                    <img src={!matchesMobile ? icon_maleta : icon_maleta_white} style={{ marginRight: "8px", maxWidth: 'inherit' }} alt="vaquita" />
                                    <Typography sx={matchesMobile ? styles.mobileText : styles.text}>{t("cadidatePortalHome.TripAndProcessmanage")}</Typography>
                                </Box>
                                <Box sx={matchesMobile ? styles.mobileRow : styles.row}>
                                    <img src={!matchesMobile ?icon_persona_2:icon_persona_2_white } style={{ marginRight: "8px", maxWidth: 'inherit' }} alt="vaquita" />
                                    <Typography sx={matchesMobile ? styles.mobileText : styles.text}>{t("cadidatePortalHome.CustomizedTraining")}</Typography>
                                </Box>
                                <Box sx={matchesMobile ? styles.mobileRow : styles.row}>
                                    <img src={!matchesMobile ? icon_doc : icon_doc_white} style={{ marginRight: "8px", maxWidth: 'inherit' }} alt="vaquita" />
                                    <Typography sx={matchesMobile ? styles.mobileText : styles.text}>{t("cadidatePortalHome.IndefiniteTermContract")}</Typography>
                                </Box>
                                <Box style={!matchesMobile ? { paddingLeft: "45px", width: "100%" } : {}}>
                                    <Typography sx={{ color: { xs: "white", md: "#D50000" }}} fontWeight={"bold"}>{t("cadidatePortalHome.requisites_title")}</Typography>
                                    <Box sx={{ color: { xs: "white", md: "#3173B1" }}}>
                                        <span>
                                        <span style={{ fontWeight: 700 }}>{t("cadidatePortalHome.requisites_text")} </span>
                                        <span>{t("cadidatePortalHome.requisites_text2")}</span>
                                        </span>
                                    </Box>
                                </Box>
                                {matchesMobile &&
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                    <Button onClick={Register} sx={matchesMobile ? styles.registermobile : styles.register}>{t("cadidatePortalHome.registerMobile")}</Button>
                                </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={!matchesMobile ? styles.bannerOffer : {}}>
                    {!matchesMobile &&
                        <Box sx={styles.offerInputs}>
                            {handleOfferSearcher("#D50000", true, "164px", "14px", true, "42px")}
                        </Box>}
                        
                </Box>
                
                {!matchesMobile ? <Box sx={{ position: "absolute", top: 0, left: "6px", width: "210px", height: "222px" , display: "flex", justifyContent: "flex-end", flexDirection: "column", pl: "10px" }}>
                  
                </Box> : null}
            </Box>
            <Box sx={styles.offerList}>
                {matchesMobile && <Box sx={{ maxWidth: "800px", minWidth: { xs: "100%", md: "772px" }, }}>
                    {handleOfferSearcher("#D50000",false,"100%")}
                </Box>}
                <Box sx={styles.body_right_col}>
                    <Box sx={styles.body_right_col_container}>
                        {filteredOffers.length === 0 ?
                            <Box display="inline-block" fontSize={30} fontWeight="900" mb={"8px"}>
                                {t("offers.noOffers")}
                            </Box>
                            :
                            <>
                                <Box sx={styles.offers_list}>
                                    {loading &&
                                    <CircularProgress sx={{left: '50%', marginTop: "100px", position: "absolute"}} />}
                                    {filteredOffers
                                        .map((offer: any) => (
                                            <li key={offer.idOferta}>
                                                <Box sx={styles.offer}>
                                                    <Box
                                                        onClick={() => {
                                                            goToOffer(offer?.idOferta);
                                                        }}
                                                    >
                                                        <Box
                                                            display="inline-block"
                                                            fontSize={"22px"}
                                                            fontWeight="900"
                                                        >
                                                            {offer.titulo}
                                                        </Box>
                                                        <Box sx={styles.text_float_right as object}>
                                                            {getFormattedDate(offer.fechaPublicacion)}
                                                        </Box>
                                                        
                                                        <Box
                                                            fontSize="subtitle1.fontSize"
                                                            mb={"8px"}
                                                            sx={styles.offer_subtitle}
                                                        >
                                                            {offer?.comunidadAutonoma ? offer.comunidadAutonoma?.descripcion  : getNombreLocalidad(offer.localidad?.id)} ·{" "}
                                                            {getNombreTipoHorario(offer.tipoHorario?.id)}
                                                        </Box>
                                                      
                                                    </Box>
                                                </Box>
                                            </li>
                                        ))}
                                </Box>
                            </>}
                        <Box sx={styles.offerList["& actions"]}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#3173B1", textTransform: "none" }}
                                onClick={goToOfferList}
                            >
                                {t("offers.seeMore_Offers")}
                            </Button>
                        </Box>
                    </Box>
                    
                </Box>

             
            </Box>
        </Box>

    );
}
export default PortalCandidatoHome;