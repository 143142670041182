import React, { createContext, useCallback, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

export enum SnackSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success'
}

type Props = {
  children: React.ReactNode,
};

export const SnackContext = createContext<Function>(() => {});

/**
 * Este Handler, al igual que el del dialogo, sirve para proveer a toda la aplicación de una funcionalidad básica: mostrar snacks de resultado
 * al hacer operaciones. Además, se consume desde el hook "useApi" para mostrar el resultado de una llamada al back en caso de ser necesario.
 * De la misma forma, esta completamente aislado del resto de arbol de componentes y no causa mutaciones en el.
 */
function SnackHandler({children}: Props) {
  const [snack, setSnack] = useState({open: false, severity:SnackSeverity.WARNING, message:'Esto es un mensaje de alerta por defecto.'});

  //Memorizamos la funcion con useCallback, para que cuando cambie el estado de la modal no se regenere la funcion
  //y notifique a los componentes subscritos
  const showSnack = useCallback(
    (message: string, severity: SnackSeverity) => {
      setSnack({open: true, message, severity});
    },
    []
  );

  const handleClose = useCallback(() => {
    setSnack(prev => ({...prev, open:false}));
  }, []);

  return (
    <SnackContext.Provider value={showSnack}>
      {children}
      <Snackbar open={snack.open} anchorOrigin={{vertical:'top', horizontal: 'right'}} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity={snack.severity} sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </SnackContext.Provider>
  );
}

export default SnackHandler;
