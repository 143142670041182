import React, { ReactElement, createContext, useCallback, useState } from "react";
import { Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";

type DialogData = {
    open?: boolean
    message?: string,
    additionalInfo?: string,
    onAccept?: Function,
    displayCancelBtn?: boolean,
    icon?: ReactElement
};


export const ModalContext = createContext<Function>(() => {});

const defaultIcon = <WarningIcon  sx={{ width: '100%', fontSize: 48}} color="warning" />;

/**
 * Este Handler utiliza un contexto para proveer de dialogos a toda la aplicación. La ventaja de esto, es la capacidad de abrir modales genericas
 * de información, aviso o confirmación en cualquier parte de la aplicación sin tener que repetir código o arrastrar props desde el componente raíz
 * gracias al uso del contexto. Otra ventaja añadida, es que es un componente aislado del contenido renderizado en pantalla y no causa repintados
 * ni recargas en el.
 */
export default function DialogHandler(props: any) {

    const [modal, setModal] = useState({ open: false } as DialogData);
    const theme = useTheme();
    const { t } = useTranslation();

    //Memorizamos la funcion con useCallback, para que cuando cambie el estado de la modal no se regenere la funcion
    //y notifique a los componentes subscritos
    const showModal = useCallback(
        ({message, additionalInfo, onAccept, displayCancelBtn = true, icon = defaultIcon}: DialogData) => {
            setModal(
                {
                    open: true,
                    message,
                    additionalInfo,
                    onAccept,
                    displayCancelBtn, 
                    icon
                }
            );
        },
        [] //A diferencia del useEffect, en este caso se puede dejar el array de dependecias vacio ya que no depende de ningun valor externo y
        // por tanto nunca se volvera stale.
    );

    const handleClose = useCallback(() => setModal({ open: false }), []);
    const handleAccept = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        modal.onAccept && modal.onAccept();
        setModal({ open: false });
    }, []);

    return (
        <ModalContext.Provider value={showModal}>
            {props.children}
            <CssBaseline />
            {modal.open &&
                <Dialog open={modal.open} onClose={handleClose} maxWidth="xs">
                    <Box  
                        sx={{
                            textAlign: 'center',        
                            mt: theme.spacing(3)
                        }}
                    >
                        {modal.icon}
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                fontSize: 24,
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                opacity:0.5
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogTitle sx={{ width: '100%', pb: theme.spacing(0)}}>
                        <Typography  color="primary" align="center"> {modal.message} </Typography>
                    </DialogTitle>
                    <Box >
                        <DialogContent sx={{ width: '100%', pb: theme.spacing(5), pt: theme.spacing(0), mt: theme.spacing(1)}}>
                            <Box sx={{ width: '100%'}}>
                                <Typography  align="center" color="secondaryInfo.main"> {modal.additionalInfo} </Typography>
                            </Box>                   
                        </DialogContent>
                        <DialogActions sx={{ pr: 0, pl: 0, pt: 0, pb: theme.spacing(3) }}>
                            {modal.displayCancelBtn && 
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '300',
                                        textTransform: 'none',
                                        ml: theme.spacing(3),
                                    }}
                                    onClick={handleClose}
                                >
                                    {t("common.cancel")}
                                </Button>
                            }
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                sx={{
                                    color: 'white',
                                    fontSize: '18px',
                                    fontWeight: '300',
                                    textTransform: 'none',
                                    ml: theme.spacing(3),
                                    mr: theme.spacing(3)
                                }}
                                type="submit"
                                onClick={handleAccept}
                            >
                                {t("common.continue")}
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            }
        </ModalContext.Provider>
    );
}