import React, { useState, useEffect, useCallback, useContext } from "react";
import TuCurriculumHeader from "../../../../../shared/components/TuCurriculumHeader/TuCurriculumHeader";
import { useTranslation } from "react-i18next";
import Add from "../../../../../assets/icons/cAdd";
import AddToCVActionButtons from "../../../../../shared/components/AddToCVActionButtons/AddToCVActionButtons";
import YourLanguages from "../../../../../assets/icons/yourLanguages";
import SelectCustom from "../../../../../shared/components/SelectCustoms/SelectCustom";
import i18next from "../../../../../i18n";
import { formatToLocalDate, 
        getIdiomasList, 
        getNivelesIdioma, 
        optionEquality, 
        returnLangId 
      } from "../../../../../shared/utils/functions";
import { FormControl, Chip, Typography, Box,useTheme,useMediaQuery } from "@mui/material";
import AntSwitch from "../../../../../shared/components/AntSwitch/antSwitch";
import DatePickerWithLabel from "../../../../../shared/components/DatePicker/datePickerWithLabel";
import AlertDialog from "../../../../../shared/components/AlertDialog/alertDialog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  addLanguage,
  updateLanguage,
  deleteLanguage,
  getCertificateLanguages,
  getDatosPersonales,
  getLanguages
}
  from "../../../../../shared/hooks/userApi";
import { MAX_CHAR_COMMENTS } from "../../../../../constants";
import InputWithLabel from "../../../../../shared/components/Inputs/InputCustom";
import styles from "./idiomasStyles";
import { useApi } from "../../../../../shared/hooks/useApi";
import { SnackContext,SnackSeverity } from "../../../../../shared/contexts/SnackHandler";
import {useNavigate, useSearchParams} from "react-router-dom";
import { languages } from "../../../auth/AuthWrapper";
import { Routes } from "../../../../../shared/enums/enums";

const idiomas_list=getIdiomasList(i18next.languages[0]);
const niveles_idioma=getNivelesIdioma(i18next.languages[0]);



function Idiomas() {
  const { t } = useTranslation();
  const callApi = useApi();
  const showSnack = useContext(SnackContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const idLanguage = searchParams.get("idIdioma");

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [idFotografia, setIdFotografia] = useState(null);
  const [fechaModificacion, setFechaModificacion] = useState<any>(null);

  const [idIdioma, setIdIdioma] = useState<any>(null);
  const [idNivelHablado, setIdNivelHablado] = useState<any>(null);
  const [idNivelEscrito, setIdNivelEscrito] = useState<any>(null);
  const [idNivelLeido, setIdNivelLeido] = useState<any>(null);
  const [tieneCertificacion, setTieneCertificacion] = useState(false);
  const [certificados, setCertificados] = useState<any>(null);
  const [comentarios, setComentarios] = useState("");

  const [newCertificados, setNewCertificados] = useState<any>([]);
  const [newCertificado, setNewCertificado] = useState<any>(null);
  const [newCertificadoDate, setNewCertificadoDate] = useState<any>(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [certificadosError, setCertificadosError] = useState(null);

  const minDate = new Date().setFullYear(Number(new Date().getFullYear()) - 90);
  const [saveWrong, setSaveWrong] = useState(false);

  useEffect(() => {
    handleDatosPersonales();
  }, []);

  useEffect(() => {
    if (newCertificados && newCertificados?.length < 1 ) {
      setTieneCertificacion(false);
    }
  }, [newCertificados]);

  useEffect(() => {
    if (idLanguage) {
      handleIdiomas(); 
    }
  }, []);

  useEffect(() => {
    if (idIdioma) {
      handleGetCertificados(idIdioma);
      setCertificados([]);
    }

  }, [idIdioma]);



  const handleIdiomas = useCallback(() => {
    callApi(getLanguages(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      const myLanguage = data.find(
        (t: any) => t.idIdioma === Number(idLanguage)
      );
      handleEditMode(myLanguage);
    }).catch(() => {
      showSnack(t("curriculum.languages_error"), SnackSeverity.ERROR);
    });
  }, []);

  const handleDatosPersonales = useCallback(() => {
    callApi(getDatosPersonales(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      handlePersonalDataAction(data);
    }).catch(() => {
      showSnack(t("curriculum.personal_data_error"), SnackSeverity.ERROR);
    });
}, []);

const handleGetCertificados = useCallback((idioma: any) => {
  callApi(getCertificateLanguages(idioma)).then((data: any) => {
    setCertificados(
      data.certificadosIdioma.map((cert:any) => ({
        id: cert.id,
        idIdioma: cert.idIdioma,
        name: cert.nombre,
      }))
    );
  }).catch((response) => {
    if (response.status !== 404) {
      setCertificadosError(t("add_language.get_certificates_error"));
    }
  });
}, []);

const addNewLanguage = (isGoBack:boolean) => {
  const data = setDataToSave();
  callApi(addLanguage(data,returnLangId(i18next.languages[0] as keyof typeof languages))).then(() => {
      if (isGoBack) {
        navigate(Routes.CURRICULUM);
      } else {
        showSnack(t("add_language.language_added"), SnackSeverity.SUCCESS);
        emptyForm();
      }
  }).catch((response) => {
    showSnack(response.message ? response.message : t("add_language.adding_error"), SnackSeverity.ERROR);

  });
};


const editLanguage = (isGoBack:boolean) => {
  const data = setDataToSave();
  callApi(updateLanguage(data,returnLangId(i18next.languages[0] as keyof typeof languages))).then(() => {
      if (isGoBack) {
        navigate(Routes.CURRICULUM);
      } else {
        showSnack(t("add_language.language_updated"), SnackSeverity.SUCCESS);
        emptyForm();
      }
  }).catch((response) => {
    showSnack(response.message ? response.message : t("add_language.update_error"), SnackSeverity.ERROR);

  });
};

const deleteLang = useCallback(() => {
  callApi(deleteLanguage(idLanguage,returnLangId(i18next.languages[0] as keyof typeof languages))).then(() => {
    navigate(Routes.CURRICULUM);
  }).catch(() => {
    showSnack(t("add_formation.delete_error"), SnackSeverity.ERROR);

  });
}, []);

 const setDataToSave = () => {
    let certificadosToSave;
    if (canAddValidCertificado()) {
      const isObject = typeof newCertificado === "object";
      const certificado = {
        idIdioma,
        nombreCertificacion: isObject ? newCertificado.name : newCertificado,
        idCertificacion: isObject ? newCertificado.id : 0,
        año: new Date(newCertificadoDate).getFullYear().toString(),
      };
      certificadosToSave = [...newCertificados, certificado];
    } else {
      certificadosToSave = [...newCertificados];
    }

    const data = {
      idIdioma,
      idNivelHablado,
      idNivelEscrito,
      idNivelLeido,
      tieneCertificacion,
      certificados: certificadosToSave,
      comentarios
    };
    return data;
  }; 

  function handlePersonalDataAction(data:any) {
    setNombre(data.nombre);
    setPrimerApellido(data.primerApellido);
    setSegundoApellido(data.segundoApellido);
    setCorreoElectronico(data.correoElectronico);
    setPrefijo(data.telefono.prefijo);
    setTelefono(data.telefono.numero);
    setIdFotografia(data.idFotografia);
    setFechaModificacion(formatToLocalDate(new Date(data.fechaModificacion)));
  }

  function handleEditMode(idioma:any) {
    setIsEditMode(true);
    setIdIdioma(idioma.idIdioma);
    setIdNivelEscrito(idioma.idNivelEscrito);
    setIdNivelHablado(idioma.idNivelHablado);
    setIdNivelLeido(idioma.idNivelLeido);
    setTieneCertificacion(idioma.tieneCertificacion);
    setNewCertificados(idioma.certificados);
    setComentarios(idioma?.comentarios);
  }

  function canAddValidCertificado() {

    if (
      !idIdioma ||
      !newCertificado ||
      !newCertificadoDate ||
      (newCertificadoDate &&
        new Date(newCertificadoDate).getFullYear() > new Date().getFullYear()) ||
      (newCertificadoDate && new Date(newCertificadoDate).getFullYear() < 1951) ||
      (newCertificadoDate && isNaN(new Date(newCertificadoDate).getTime())) ||
      newCertificados.find((x:any) => x.nombreCertificacion === newCertificado.name)
    ) {
      return false;
    }
    return true;
  } 

  function validateAddCertificado() {
    let isValid = true;
    if (!idIdioma) {
      isValid = false;
      showSnack(t("add_language.select_language_first"), SnackSeverity.ERROR);
    }
    if (!newCertificado) {
      isValid = false;
      showSnack(t("add_language.select_certificate_first"), SnackSeverity.ERROR);

    }
    if (!newCertificadoDate) {
      isValid = false;
      showSnack(t("add_language.select_date_first"), SnackSeverity.ERROR);

    }
    if (
      (newCertificadoDate &&
        new Date(newCertificadoDate).getFullYear() > new Date().getFullYear()) ||
      (newCertificadoDate && new Date(newCertificadoDate).getFullYear() < 1951)
    ) {
      isValid = false;
      showSnack(t("add_language.invalid_date_certificate"), SnackSeverity.ERROR);

    }
    if (newCertificadoDate && isNaN(new Date(newCertificadoDate).getTime())) {
      isValid = false;
      showSnack(t("add_language.invalid_date_certificate"), SnackSeverity.ERROR);

    }
    if (
      newCertificados.find((x:any) => x.nombreCertificacion === newCertificado.name)
    ) {
      isValid = false;
      showSnack(t("add_language.existing_certificate_error"), SnackSeverity.ERROR);

    }
    if (isValid) addCertificado();
  }

  function validateNewLanguage(isGoBack:any) {
    if (!idIdioma || !idNivelEscrito || !idNivelLeido || !idNivelHablado) {
      setSaveWrong(true);
      showSnack(t("common.data_error"), SnackSeverity.ERROR);

    } else if (
      tieneCertificacion &&
      newCertificados?.length === 0 &&
      (!newCertificado || !newCertificadoDate)
    ) {
      setSaveWrong(true);
      showSnack(t("add_language.certificate_empty"), SnackSeverity.ERROR);

    } else {
      isEditMode ? editLanguage(isGoBack) : addNewLanguage(isGoBack);
    }
  }

  function addCertificado() {
    const getYear = new Date(newCertificadoDate).getFullYear().toString();
    const isObject = typeof newCertificado === "object";
    const data = {
      idIdioma,
      nombreCertificacion: isObject ? newCertificado.name : newCertificado,
      idCertificacion: isObject ? newCertificado.id : 0,
      año: getYear,
    };
    setNewCertificados([...newCertificados, data]);
    setNewCertificado(null);
    setNewCertificadoDate(null);
  }

  function deleteCertificado(cert:any) {
    const auxList = [...newCertificados];
    const index = newCertificados.indexOf(cert);
    if (index !== -1) {
      auxList.splice(index, 1);
      setNewCertificados(auxList);
    }
  }

  function emptyForm() {
    setIsEditMode(false);
    setIdIdioma(null);
    setIdNivelHablado(null);
    setIdNivelLeido(null);
    setIdNivelEscrito(null);
    setNewCertificadoDate(null);
    setNewCertificado(null);
    setNewCertificados([]);
    setTieneCertificacion(false);
    setComentarios("");
  }

  function handleChangeIdioma(value:any) {
    setIdIdioma(value ? Number(value?.id) : null);
    setIdNivelHablado(null);
    setIdNivelLeido(null);
    setIdNivelEscrito(null);
    setNewCertificadoDate(null);
    setNewCertificado(null);
    setNewCertificados([]);
    setComentarios("");
  }

  function renderBasicInfo() {
    return (
      <Box sx={styles.render_basic_data_style}>
        <Box sx={styles.data_section_icon_and_title}>
          <YourLanguages />
          <Box sx={styles.data_section_title_principal}>
            {isEditMode
              ? t("add_language.title_edit")
              : t("add_language.title")}
          </Box>
        </Box>
        <Box sx={styles.basic_data_grid}>
          <FormControl variant="outlined" fullWidth>
            <SelectCustom
              useDefaultIcon
              disabled={isEditMode}
              value={idIdioma}
              setter={handleChangeIdioma}
              options={idiomas_list}
              label={t("add_language.language")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? idiomas_list.find((s:any) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel
              wrong={saveWrong && !idIdioma}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <SelectCustom
              useDefaultIcon
              value={idNivelEscrito}
              setter={(value:any) =>
                setIdNivelEscrito(value ? Number(value.id) : null)
              }
              options={niveles_idioma}
              label={t("add_language.write_level")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? niveles_idioma.find((s:any) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel
              wrong={saveWrong && !idNivelEscrito}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <SelectCustom
              useDefaultIcon
              value={idNivelLeido}
              setter={(value:any) =>
                setIdNivelLeido(value ? Number(value.id) : null)
              }
              options={niveles_idioma}
              label={t("add_language.read_level")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? niveles_idioma.find((s:any) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel
              wrong={saveWrong && !idNivelLeido}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <SelectCustom
              useDefaultIcon
              value={idNivelHablado}
              setter={(value:any) =>
                setIdNivelHablado(value ? Number(value.id) : null)
              }
              options={niveles_idioma}
              label={t("add_language.talk_level")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? niveles_idioma.find((s:any) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel
              wrong={saveWrong && !idNivelHablado}
            />
          </FormControl>
        </Box>
        <Box sx={styles.comments}>
         <InputWithLabel
           multiline
           rows={3}
           fullWidth
           label={t("add_language.comments")}
           placeholder={t("add_language.comments_placeholder")}
           value={comentarios}
           setter={(e:any)=>setComentarios(e.target.value)}
           moveLabel
           minLength={0}
           maxLength={MAX_CHAR_COMMENTS}
         />
         <Typography sx={styles.counter_chars_description}>
           {`${comentarios?.length ?? 0}/${MAX_CHAR_COMMENTS}`}
         </Typography>
       </Box>
      </Box>
    );
  }

  const handleCertificadosError = () => {
    if (certificadosError) {
      showSnack(certificadosError, SnackSeverity.ERROR);
    }
  };

  function renderCertificationData() {
    return (
      <Box sx={styles.certifications_container}>
        <Box sx={styles.certifications_grid}>
          <Box sx={styles.text_switch}>
            <Box>{t("add_language.got_certification")}</Box>
            <AntSwitch
              checked={tieneCertificacion}
              disabled={!idIdioma}
              setter={() => setTieneCertificacion(!tieneCertificacion)}
            />
          </Box>
          {tieneCertificacion && (
            <Box sx={styles.certificate}>
              <FormControl variant="outlined" fullWidth>
                <SelectCustom
                  useDefaultIcon
                  error={tieneCertificacion && !newCertificado}
                  disabled={!tieneCertificacion}
                  value={newCertificado}
                  setter={setNewCertificado}
                  options={
                    idIdioma
                      ? certificados?.filter(
                          (cert:any) => cert.idIdioma === idIdioma
                        )
                      : []
                  }
                  label={t("add_language.certification")}
                  freeSolo
                  getOption={(x:any, v:any) => optionEquality(x, v)}
                  getLabel={(x:any) => x.name || x}
                  onOpen={handleCertificadosError}
                  maxLength={50}
                  moveLabel
                />
              </FormControl>
            </Box>
          )}
          {tieneCertificacion && (
            <Box sx={styles.certificate_date}>
              <DatePickerWithLabel
                views={["year"]}
                yearOnly
                disabled={!tieneCertificacion}
                customClass={styles.date_picker_class}
                placeholder={t("add_language.certification_date")}
                value={newCertificadoDate}
                setter={(value:any) => {
                  setNewCertificadoDate(value);
                }}
                minDate={minDate}
                maxDate={new Date()}
                debug={false}
              />
              <Box
                sx={styles.add_certification_row}
                onClick={() => validateAddCertificado()}
              >
                <Add title={t("icons_tooltips.add")} />
              </Box>
            </Box>
          )}
        </Box>
        {tieneCertificacion && (
          <Box>
            {newCertificados.map((cert:any) => (
              <Chip
                key={cert.id}
                label={`${cert?.nombreCertificacion}, ${cert?.año}`}
                deleteIcon={
                  <HighlightOffIcon titleAccess={t("icons_tooltips.remove")} />
                }
                onDelete={() => deleteCertificado(cert)}
                style={{
                  backgroundColor: "#EFEFEF",
                  marginTop: "10px",
                  maxWidth: "100%",
                  borderRadius: "16px"
                }}
              />
            ))}
          </Box>
        )}
      </Box>
    );
  }

  function renderDeleteLink() {
    return (
      <Box sx={styles.delete_row}>
        <Box
          onClick={() => setIsDialogOpen(true)}
          sx={styles.delete_link}
        >
          {t("add_language.delete")}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={styles.principal_container}>
        {!matchesMobile ? (
          <TuCurriculumHeader
            nombre={nombre}
            primerApellido={primerApellido}
            segundoApellido={segundoApellido}
            correoElectronico={correoElectronico}
            prefijo={prefijo}
            telefono={telefono}
            fechaModificacion={fechaModificacion}
            idPhoto={idFotografia}
          />
        ) : (
          <Box />
        )}
        <Box sx={styles.col2}>
          <Box sx={styles.data_sections_container}>
            {renderBasicInfo()}
            {renderCertificationData()}
          </Box>
          <AddToCVActionButtons
            saveAndActionText={t("add_language.save_and_add_other")}
            onSaveClick={() => validateNewLanguage(true)}
            onSaveAndActionClick={() => validateNewLanguage(false)}
            onCancelClick={() => {
             navigate(Routes.CURRICULUM);
            }}
            isUpdate={isEditMode}
          />
          {isEditMode && renderDeleteLink()}
        </Box>
      </Box>
      <AlertDialog
        title={t("add_language.delete_confirm")}
        open={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        description={""}
        handleAccept={() => {
          deleteLang();
          setIsDialogOpen(false);
        }}
      />
      </Box>
  );
}

export default Idiomas;
