import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Typography, useMediaQuery, FormControl } from "@mui/material";
import styles from "./styles";
import { useTranslation } from 'react-i18next';
import InputWithLabel from "../../../../shared/components/Inputs/InputCustom";
import { useApi } from '../../../hooks/useApi';
import { postCompany } from '../../../hooks/userApi';
import CheckIcon from '@mui/icons-material/Check';
import { Routes } from '../../../enums/enums';
import { getProvinceOptions, getProvincias, optionEquality, sendGAEvent, validateEmail, validatePhone } from '../../../utils/functions';
import { SnackContext, SnackSeverity } from '../../../contexts/SnackHandler';
import CallIcon from '@mui/icons-material/Call';
import theme from '../../../../theme';
import { useNavigate } from 'react-router-dom';
import AutocompleteWithLabel from "../../../../shared/components/SelectCustoms/autocompleteWithLabelAnimation";
import { SPAIN } from '../../../../constants';
import i18next from "i18next";
import empresas_1 from '../../../images/empresas_1.svg';
import empresas_2 from '../../../images/empresas_2.svg';
import empresas_3 from '../../../images/empresas_3.svg';

function Empresas() {
    const { t } = useTranslation();
    const callApi = useApi();
    const showSnack = useContext(SnackContext);
    const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesTablet = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const provincias = getProvincias(i18next.languages[0]);

    useEffect(() => {
        sendGAEvent("Acceso Empresas");
    });
    
    
    useEffect(() => {
        sendGAEvent("Acceso Empresas");
    }, []);
    
    const [nombre, setNombre] = useState();
    const [correoElectronico, setCorreoElectronico] = useState();
    const [telefono, setTelefono] = useState();
    const [empresa, setEmpresa] = useState();
    const [provincia, setProvincia] = useState<any>();
    const [politicaPrivacidad, setPoliticaPrivacidad] = useState();
    const [fieldsWithErrors, setFieldsWithErrors] = useState<any>([]);
    const [disabledForm, setDisabledForm] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    function handlePostData() {
        if (!validate().length) {
            setLoading(true);
            const data = {
                personaContacto: nombre, correoElectronico, telefono, empresa, provincia: {id: Number(provincia?.id)}
            };
            callApi(postCompany({Empresa: data})).then(() => {
                sendGAEvent("Registrada empresa", {
                    userEmail: correoElectronico
                });
                setDisabledForm(true);
                setLoading(false);
            }).catch(() => setLoading(false));
        }
    }

    function validate() {
        const errors = [];
        if (!nombre || nombre === "") {
          errors.push("name");
        }
        if (!validateEmail(correoElectronico)) {
            errors.push("email");
        }
        if (!validatePhone(telefono)) {
          errors.push("phoneNumber");
        }
        if (!empresa || empresa === "") {
            errors.push("companie");
          }
        if (!provincia?.id || provincia?.id === "") {
            errors.push("province");
        }

        if (errors.length) {
            showSnack('Por favor cubra correctamente todos los campos.', SnackSeverity.ERROR);
        } else if (!politicaPrivacidad)  {
            showSnack('Es necesario aceptar la política de privacidad.', SnackSeverity.ERROR);
        }
        
        if (!politicaPrivacidad) {
            errors.push("politica");
        }   
        
        setFieldsWithErrors([...errors]);
        
        return errors;
    }

    function removeErrorFromList(field: any) {
        if (fieldsWithErrors.length > 0) {
            const newErrorsList = fieldsWithErrors.filter((error: any) => error !== field);
            setFieldsWithErrors(newErrorsList);
        }
    }

    function getButtonLabel() {
        if (loading) return "companies.sending";
        return disabledForm ? "companies.sent" : "companies.send";
    }

    function returnButtonOrTextPhoneNumber() {
        return (
            !matchesMobile ?
                <Typography sx={styles.numberFormatTex}>{t("companies.call")}</Typography>
                :
                <Box sx={{ ...styles.button_row, marginTop: "20px" }}>
                    <Button
                        variant="contained"
                        sx={styles.numberFormatButton}
                        onClick={() => {
                            if (matchesMobile) {
                                window.open("tel:+34649236685");
                            }
                        }}
                    >
                        <CallIcon sx={styles.check} />{t("companies.call")}
                    </Button>
                </Box>
        );
    }

    return (
        <Box sx={{backgroundColor:"#F2F5F8"}}>
            <Box sx={styles.main}>
                <Box sx={{...styles.mainSection,marginLeft: {md:'5%',xl:"unset"}}}>
                    {/* <Box component="img" alt="Nortempo"
                        onClick={() => window.open(Routes.FUNDACION_NORTEMPO)}
                        src="/images/fundacion_white.png"
                        sx={styles.nortempoLogo}
                        style={{ width: '160px', marginLeft: "16px" }} /> */}
                    <Box style={{display:"flex",flexDirection:"column"}}>
                        <Box sx={styles.blurBackground}>
                            <Box sx={styles.title}>
                                <b>{t("companies.title")}</b>
                                <b style={{ fontWeight: 900 }}>{t("companies.galicia")}</b>
                            </Box>
                            <Box sx={styles.header_description}>
                                <span>{t("companies.header_description")}</span>
                                <b style={{ fontWeight: 900 }}>{t("companies.formulario")}</b>
                            </Box>
                            {returnButtonOrTextPhoneNumber()}
                        </Box>
                        {!matchesTablet &&<Box component="img"sx={styles.photo_company} alt="Empresa" src={"/images/foto_empresa.png"} />}
                    </Box>
                </Box>
                <Box sx={{...styles.mainSection, justifyContent: "flex-start"}}>
                    <Box sx={styles.form}>
                        <Typography sx={styles.contactData}>{t("companies.contact_data")}</Typography>
                        <Box sx={styles.inputsRow}>
                            <Box sx={styles.input}>
                                <InputWithLabel
                                    wrong={fieldsWithErrors.includes("companie")}
                                    label={t("companies.input_label_5")}
                                    value={empresa}
                                    setter={(e: any) => {
                                        setEmpresa(e.target.value);
                                    }}
                                    onBlur={() => removeErrorFromList("companie")}
                                    moveLabel
                                    maxLength={100}
                                    disabled={disabledForm}
                                    isFromHomePage
                                />
                            </Box>
                            <Box sx={styles.input}>
                                <FormControl
                                    variant="outlined"
                                    sx={styles.autocomplete_input_container_full}
                                >
                                    <AutocompleteWithLabel
                                        disableClearable
                                        id={"provincia_autocomplete"}
                                        autoComplete={"new-password"}
                                        useDefaultIcon
                                        value={provincia}
                                        setter={(value: any) => {
                                            setProvincia(value);
                                        }}
                                        label={t("companies.input_label_6")}
                                        options={getProvinceOptions(provincias, SPAIN, null)}
                                        getOption={(x: any, v: any) => optionEquality(x, v)}
                                        getLabel={(x: any) => x?.name ?? ""}
                                        moveLabel
                                        customClassWrapper={styles.auto}
                                        onBlur={() => removeErrorFromList("province")}
                                        wrong={fieldsWithErrors.includes("province")}
                                        disabled={disabledForm}
                                        isFromHomePage
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={styles.inputsRow}>
                            <Box sx={styles.input}>
                                <InputWithLabel
                                    wrong={fieldsWithErrors.includes("email")}
                                    label={t("companies.input_label_3")}
                                    value={correoElectronico}
                                    setter={(e: any) => {
                                        setCorreoElectronico(e.target.value);
                                    }}
                                    onBlur={() => removeErrorFromList("email")}
                                    moveLabel
                                    maxLength={100}
                                    disabled={disabledForm}
                                    isFromHomePage
                                />
                            </Box>
                            <Box sx={styles.input}>
                                <InputWithLabel
                                    wrong={fieldsWithErrors.includes("phoneNumber")}
                                    label={t("companies.input_label_4")}
                                    value={telefono}
                                    setter={(e: any) => {
                                        setTelefono(e.target.value);
                                    }}
                                    onBlur={() => removeErrorFromList("phoneNumber")}
                                    moveLabel
                                    maxLength={100}
                                    disabled={disabledForm}
                                    type="number"
                                    isFromHomePage
                                />
                            </Box>
                        </Box>
                        <Box sx={{...styles.inputsRow, justifyContent: "flex-start"}}>
                            <Box sx={styles.input_two_rows}>
                                <InputWithLabel
                                    wrong={fieldsWithErrors.includes("name")}
                                    label={t("companies.input_label_1")}
                                    value={nombre}
                                    setter={(e: any) => {
                                        setNombre(e.target.value);
                                    }}
                                    onBlur={() => removeErrorFromList("name")}
                                    moveLabel
                                    maxLength={100}
                                    disabled={disabledForm}
                                    isFromHomePage
                                />
                            </Box>
                        </Box>
                        <Typography sx={styles.dataProtectionTitle}>{t("companies.data_protection")}</Typography>
                        <Box sx={{...styles.dataProtection, background: !disabledForm ? "#FFFFFF" : "rgb(0,0,0,0.05)"}}>
                            {t("companies.data_protection_information")}
                            {t("companies.data_protection_purpose")}
                            {t("companies.data_protection_legitimation")}
                            {t("companies.data_protection_receiver")}
                            {t("companies.data_protection_rights")}<a style={{textDecoration:"none", color:"#888888"}} href="mailto:nortempo@delegado-datos.com">nortempo@delegado-datos.com</a>.
                            {t("companies.data_protection_more_information")} <a style={{textDecoration:"none", color:"#888888"}} href="https://iberempleo.net/politica-de-privacidad">https://iberempleo.net/politica-de-privacidad</a> 
                        </Box>
                        <Box sx={styles.boxPoliticasPrivacidad}>
                        {disabledForm ?
                        <Box sx={{...styles.checkbox_row, margin: "6px 0"}}>
                            <CheckIcon color="success" sx={styles.check}/>
                            <Typography variant="body2">{t("companies.check_info_2")}</Typography>
                        </Box> :
                        <Box sx={styles.checkbox_row}>
                            <Checkbox
                                checked={politicaPrivacidad}
                                onChange={(e: any) => setPoliticaPrivacidad(e.target.checked)}
                                color="primary"
                                style={{color:"#C1C1C1",paddingLeft: 0 ,paddingRight:"9px"}}
                                disabled={disabledForm}
                            />
                            <Typography fontSize={"13px"} variant="body2">{t("companies.check_info")}</Typography>
                        </Box>}
                        <Box style={{paddingRight: '10px'}}>
                                <Button
                                    variant="contained"
                                    disabled={!politicaPrivacidad}
                                    sx={styles.buttonSendForm}
                                    onClick={() => {
                                        if (!loading) {
                                            disabledForm ? navigate(Routes.RETORNA) : handlePostData();
                                        }
                                    }
                                    }
                                >
                                    {loading ? <CircularProgress style={{ width: "12px", height: "12px", color: "#ffffff", marginRight: "8px" }} /> : null}{t(getButtonLabel())}
                                </Button>
                        </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            
            <Box sx={styles.section}>
                <Typography sx={styles.sectionTitle}>{t("companies.section_1_title")}</Typography>
                <Box sx={styles.sectionDescription}>
                    <Typography >{t("companies.section_1_description")}</Typography>
                </Box>
                <Box sx={{...styles.sub_section, maxWidth: "800px", alignItems: "flex-start"}}>
                    <Box sx={styles.block}>
                        <Box sx={styles.blockHeader}>
                            <Box sx={styles.icon_svg} component="img" src={empresas_3} alt="React Logo" />
                            <Typography sx={styles.icon_text}>{t("companies.block_1_title")}</Typography>
                        </Box>
                        <Box sx={styles.blockText}>{t("companies.block_1")}</Box>
                    </Box>
                    <Box sx={styles.block}>
                        <Box sx={styles.blockHeader}>
                            <Box sx={styles.icon_svg} component="img" src={empresas_2} alt="React Logo" />
                            <Typography sx={styles.icon_text}>{t("companies.block_2_title")}</Typography>
                        </Box>
                        <Box sx={styles.blockText}>{t("companies.block_2")}</Box>
                    </Box>
                    <Box sx={styles.block}>
                        <Box sx={styles.blockHeader}>
                            <Box sx={styles.icon_svg} component="img" src={empresas_1} alt="React Logo" />
                            <Typography sx={styles.icon_text}>{t("companies.block_3_title")}</Typography>
                        </Box>
                        <Box>
                            <Box sx={styles.blockText}>
                                <Typography sx={styles.numbers}>1.</Typography>
                                <Typography sx={styles.option}>{t("companies.block_3_1")}</Typography>
                            </Box>
                            <Box sx={styles.blockText}>
                                <Typography sx={styles.numbers}>2.</Typography>
                                <Typography sx={styles.option}>{t("companies.block_3_2")}</Typography>
                            </Box>
                            <Box sx={styles.blockText}>
                                <Typography sx={styles.numbers}>3.</Typography>
                                <Typography sx={styles.option}>{t("companies.block_3_3")}</Typography>
                            </Box>
                            <Box sx={styles.blockText}>
                                <Typography sx={styles.numbers}>4.</Typography>
                                <Typography sx={styles.option}>{t("companies.block_3_4")}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{...styles.button_row, marginTop: "20px"}}>
                            <Button
                            variant="contained"
                            style={{ backgroundColor: "#007BC4", marginRight: "20px", width: "220px", textTransform: "none", borderRadius: "4px", color: "white", fontSize: "14px", fontWeight: !matchesMobile ? "500" : "600"}}
                            onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
                            >
                                {t("companies.button_1")}
                            </Button>
                        </Box>
            </Box>
            <Box sx={styles.section}>
                <Typography sx={styles.sectionTitle}>{t("companies.program_title")}</Typography>
                <Box sx={styles.sub_section}>
                    <Box sx={styles.left_container}><Box sx={styles.video_text} dangerouslySetInnerHTML={{ __html: t("companies.program_text")}}></Box></Box>
                    <Box sx={{...styles.right_container}}>
                    <iframe
                        width={matchesMobile ? "90%" : "500"}
                        height={matchesMobile ? "200" : "280"}
                        src={sessionStorage.getItem("language") === "gl" ? `https://www.youtube.com/embed/GoiXMVPsWVI` : `https://www.youtube.com/embed/cI0DBNX0mQc` }
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        />
                    </Box>
                </Box>
                <Box sx={{...styles.button_row, marginTop: "20px"}}>
                            <Button
                            variant="contained"
                            style={{ backgroundColor: "#007BC4", marginRight: "20px", width: "150px", textTransform: "none", borderRadius: "4px", color: "white", fontSize: "14px", marginTop:"10px",fontWeight: !matchesMobile ? "500" : "600"}}
                            onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
                            >
                                {t("companies.button_2")}
                            </Button>
                        </Box>
            </Box>
            <Box sx={styles.footer}>
                <Box sx={{maxWidth: "200px"}}>
                    <Box component="img" alt="Nortempo" src="/images/fundacion_white.png" sx={styles.footer_logo} />
                </Box>
                <Box sx={styles.footer_section}>
                    <Typography sx={styles.footerTitle}>{t("companies.footer_title")}</Typography>
                    <Box sx={styles.footer_box}>
                        <Typography sx={styles.footerText}>{t("companies.footer_text")}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
        
    );
}

export default Empresas;