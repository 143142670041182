export const Retorna = {
    main: {
        primary: '#DA291C',
        name: 'Retorna',
        titleTranslation: 'header.titleRetorna',
        claimTranslation: 'header.claimRetorna',
        footerLogos: [
            {alt: 'Xunta de Galicia', src: '/images/v1ceii-positivo-black.png', sx: {margin: {xs:"4px 0 0 14px", md: "0 20px 0 0"}, width:'256px'}},
            {alt: 'Nortempo', src: '/images/nortempo_fundation.svg', sx: {margin: {xs:"12px 0 6px 0", md: "unset"}, width:'100px'}},
        ],
        titleAllOffersGalicia: 'offers.allOffersGalicia',
    }
};