import React from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Clear from "@mui/icons-material/ClearRounded";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  dialog_container: {
    width:"480px",
    padding: "50px",
    position: "relative",
    borderRadius: "12px",
    //height:"unset",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width:"90%",
      maxHeight:"55%",
      borderRadius: "12px",
    },
  },
  close_icon: {
    position: "absolute",
    top: 14,
    right: 14,
    cursor: "pointer",
    width: 14,
    height: 14
  },
  dialog_title: {
    marginBottom:"30px",
    padding: 0,
    fontWeight: 800,
    '& h2':{
      fontSize: "22px",
      lineHeight: "30px",
      color: "black",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop:"15px",
      maxWidth:"100%"
    }
  },
  dialog_text:{
    fontSize: "14px",
  },
  dialog_content: {
    padding: 0,
    marginBottom:"30px",
  },
  card_button_row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display:"grid",
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      'delete'
      'cancel'`
    },
  },
  button: {
    width: "48%",
    padding:"0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "20px"
    },
  },
  notContent:{
    display:"flex",
    alignItems:"center",
    padding:0,
    overflow:"hidden",
    justifyContent:"center",
   [theme.breakpoints.down("sm")]: {
    justifyContent:"unset",
    marginLeft:"12px"
  },
    
  }
}));

 function AlertDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    open,
    handleClose,
    title,
    handleAccept,
    description = null,
  } = props;


   function returnDialog() {
    return (
     <Dialog
       open={open}
       onClose={handleClose}
       classes={{ paper: classes.dialog_container }}
     >
       <div title={t(`icons_tooltips.clear`)}>
         <Clear
           color="primary"
           className={classes.close_icon}
           onClick={handleClose}
         />
       </div>
        <DialogContent className={description ? classes.dialog_content : classes.notContent}>
         <DialogTitle
           id="alert-dialog-title"
           className={classes.dialog_title}
         >
           {title}
         </DialogTitle>
         {description &&
           <DialogContentText
             id="alert-dialog-description"
             className={classes.dialog_text}
           >
             {description}
           </DialogContentText>
         }
       </DialogContent>
       <DialogActions className={classes.dialog_actions}>
         <div className={classes.card_button_row}>
           <div
             style={{ gridArea: 'cancel' }}
             className={classes.button} >
             <Button
             fullWidth
             onClick={handleClose}
             variant="outlined"
             color="primary"
             style={{ backgroundColor: "white", borderRadius: "4px", color: "#DA291C", fontSize: "12px", border:"1px solid #DA291C "}}
             >
              {t("alertDialog.cancel")}
             </Button>
           </div>
           <div
             style={{ gridArea: 'delete' }}
             className={classes.button} >
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#DA291C", borderRadius: "4px", color: "white", fontSize: "12px", fontWeight: 800 }}
                fullWidth
                onClick={handleAccept}
              >{t("alertDialog.accept")}
            </Button>
             
           </div>
         </div>
       </DialogActions>
     </Dialog>
    );
   }

  return (
    returnDialog()
  );
}
export default AlertDialog;