const styles = {
    accordionContainer: {
        padding: '0px',
        width: '100%',
        marginBottom: '10px'
    },
    switch: {
        width: "48px",
        height: "24px",
        padding: "0px",
        marginLeft: "auto",
        display: "inline-flex",
        alignItems: "center",
        '& .MuiSwitch-switchBase': {
            padding: "0px",
            paddingLeft: "2px",
            position: "absolute",
            color: "#FFFFFF",
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(24px)',
                color: '#DDDDDD',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
        },
        '& .MuiSwitch-thumb': {
            width: "20px",
            height: "20px",
            marginTop: "2px",
            boxShadow: "none"
        },
        '& .MuiSwitch-track': {
            borderRadius: "12px",
            opacity: 1,
            backgroundColor: "#DDDDDD"

        },
    }
};

export default styles;