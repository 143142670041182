import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  FormControl
} from "@mui/material";
import { useTranslation } from "react-i18next";
import InputCustom from "../../../components/Inputs/InputCustom";
import SelectCustom from "../../../components/SelectCustoms/SelectCustom";
import { TODA_ESPANHA } from "../../../enums/enums";
import styles from "./styles";
import { getProvinciasGalicia, optionEquality } from "../../../utils/functions";
import i18next from "../../../../i18n.js";

function OffersSearcher(props: any) {
  const provincias = getProvinciasGalicia(i18next.languages[0]);
  const {
    programa,
    displayInline,
    isFromHomePage
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [puestoBusqueda, setPuestoBusqueda] = useState("");
  const [provinciaBusqueda, setProvinciaBusqueda] = useState({
    id: TODA_ESPANHA,
    name: handleTitleSelectProvincias(),
  });

  const handleSearchOffers = () => {
    const puestoBusquedaWithoutSpaces = puestoBusqueda.replace(/\s+/g, ' ').trim();
    if (!props.noRedirect) {
      navigate(
        `/Retorna/busqueda?provincia=${provinciaBusqueda.id}?puesto=${puestoBusquedaWithoutSpaces}`
      );
    }
    if (props.search) {
      props.search(provinciaBusqueda.id, puestoBusquedaWithoutSpaces);
    }
  };

  function handleTitleSelectProvincias ()  {
    return programa?.titleAllOffersGalicia ? t(programa?.titleAllOffersGalicia) : t("offers.allProvinces");
  }

  return (
    <Box sx={styles.searcher2}>
      {!isFromHomePage &&
        <Box
          sx={styles.search_separator}
          style={props.whiteTitle ? { color: "white" } : {}}
        >
          {props.home ? t("offers.search_offers") : t("offers.newSearch")}
        </Box>
      }
      <Box
        sx={
          displayInline ? styles.formContainer : styles.formContainerColumn
        }
      >
        <Box sx={isFromHomePage ?  {width: {md:"332px",lg:"377px"}} :{ width: "100%"}}>
                <InputCustom
                    fullWidth
                    moveLabel
                    label={t("offers.jobSearch")}
                    value={puestoBusqueda}
                    setter={(e: any) => {
                      setPuestoBusqueda(e.target.value);
                    if (props.setterPuesto) {
                      props.setterPuesto(e.target.value);
                    }}}
                    maxLength={500}
                    customClass={styles.input}
                    whiteLabel={props.whiteLabel}
                    isFromHomePage={isFromHomePage}
                />
        </Box>
        <Box
          sx={isFromHomePage ?  {width: {md:"332px",lg:"377px"}}:{ width: "100%"}}
        >
         <FormControl sx={{width: "100%"}} variant="outlined">
            <SelectCustom
              useDefaultIcon
              value={provinciaBusqueda}
              setter={(e: any) => {
                setProvinciaBusqueda(e);
                if (props.setterProvincia) {
                  props.setterProvincia(e);
                }}}
              options={[
                { id: TODA_ESPANHA, name: handleTitleSelectProvincias() },
                ...provincias.filter((p: any) => p.id_country === "724"),
              ]}
              label={t("offers.province")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              disableClearable
              getLabel={(option: any) => option.name}
              moveLabel
              whiteLabel={props.whiteLabel}
              isFromHomePage={isFromHomePage}
            />
          </FormControl>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ ...styles.search_button, ...props.customClass} as object}
          onClick={() => handleSearchOffers()}
        >
          {isFromHomePage ? t("offers.search_offers") : t("offers.search")}
        </Button>
      </Box>
    </Box>
  );
}

export default OffersSearcher;
