import { Box, Button, Checkbox, FormControl, Link, Typography, useMediaQuery, useTheme, Icon } from "@mui/material";
import i18next from "i18next";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputWithLabel from "../../../../shared/components/Inputs/InputCustom";
import AutocompleteWithLabel from "../../../../shared/components/SelectCustoms/autocompleteWithLabelAnimation";
import { Countries, Routes } from "../../../../shared/enums/enums";
import {
    getDocTypes, 
    getProvinceOptions,
    getProvincias,
    getProvinciasGalicia,
    handleProvince,
    optionEquality,
    sendGAEvent, 
    validateDate,
    validateDocument,
    validateEmail,
    validatePhone
} from "../../../../shared/utils/functions";
import styles from "./styles";
import HeartIcon from "../../../../assets/icons/heartIcon";
import DeleteChip from "../../../../assets/icons/delete_chip";
import DatePickerWithLabel from "../../../../shared/components/DatePicker/datePickerWithLabel";
import { useApi } from "../../../../shared/hooks/useApi";
import { getCandidateDocuments, getDatosBasicos, getPuestos, saveBasicData } from "../../../../shared/hooks/userApi";
import { AuthContext, languages, returnLangId } from "../../auth/AuthWrapper";
import { useNavigate } from "react-router-dom";
import { SnackContext, SnackSeverity } from "../../../../shared/contexts/SnackHandler";
import { NIF, OTRO_TIPO_PARENTESCO, PORTAL_RETORNA, SPAIN, relationShipOptions, travelAvailabilityOptions } from "../../../../constants";
import { MaestroContext } from "../../../contexts/MaestroContext";
import { PreguntasDatosBasicos } from "./PreguntasDatosBasicos";
import AutocompleteWithChips from "../../../components/Inputs/autocompleteWithChips";
import UploadDocuments from "../../../components/UploadDocuments/UploadDocuments";
import { IDocumentType } from "../../../interfaces/DocumentsInterfaces";
import DocumentoListItem from "../Documentos/DocumentItem/DocumentoListItem";
import MoveUpGrey from "../../../../assets/icons/moveUpGrey";

export interface Telefono {
    prefijo: string;
    numero: string;
}

export interface PuestosDeseado {
    idPuesto: number;
    nombre: string;
    esPrincipal: boolean;
}

export interface IDatosBasicos {
    nombre: string;
    primerApellido: string;
    segundoApellido: string;
    tipoIdentificacion: number;
    numeroIdentificacion: string;
    telefono: Telefono;
    correoElectronico: string;
    puestosDeseados: PuestosDeseado[];
    fechaNacimiento: string;
    idPais: number;
    idProvincia: number;
    idLocalidad: number;
    ciudad: string;
    recibirInformacion: boolean;
    selectNationality: number;
    isVinculate: number;
}

function DatosBasicos() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme<any>();
    const showSnack = useContext(SnackContext);
    const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const callApi = useApi();

    const { prefijos, localidades, tipoIdentficacion } = useContext(MaestroContext);
    let { paises } = useContext(MaestroContext);
    paises = paises.filter((p: any) => Number(p.id) !== SPAIN);

    const galicianProvinces = getProvinciasGalicia(i18next.languages[0]);
    const provincias = getProvincias(i18next.languages[0]);
    const langId = returnLangId(i18next.languages[0] as keyof typeof languages);
    const documentTypes = getDocTypes(langId) as IDocumentType[];

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [surname2, setSurname2] = useState("");
    const [identificationType, setIdentificationType] = useState(
        tipoIdentficacion.find((type: any) => type.orden === "2")
    );
    const [identityDoc, setIdentityDoc] = useState("");
    const [phonePrefix, setPhonePrefix] = useState<any>(null);
    const [phoneNumber, setPhoneNumber] = useState<any>("");
    const [notificationsEmail, setNotificationsEmail] = useState("");
    const [fieldsWithErrors, setFieldsWithErrors] = useState<any>([]);
    const [employments, setEmployments] = useState<any>([]);
    const [employmentsOptions, setEmploymentsOptions] = useState<any>(null);
    const [sendComunications, setSendComunications] = useState(true);
    const [politicaPrivacidad, setPoliticaPrivacidad] = useState(false);
    const [fechaNacimiento, setFechaNacimiento] = useState(null);
    const [pais, setPais] = useState<any>(null);
    const [paisOrigen, setPaisOrigen] = useState<any>(null);
    const [saveClick, setSaveClick] = useState<boolean>(false);
    const [tempDatosBasicos, setTempDatosBasicos] = useState<any>(null);
    const [documents, setDocuments] = useState<any>([]);
    const [tempAnswers, setTempAnswers] = useState<any>({ isVinculate: 0, selectNationality: 0, spanishPassport: 0 });
    const [provincia, setProvincia] = useState<number | null>(null);
    const [localidad, setLocalidad] = useState<number | null>(null);
    const [city, setCity] = useState("");
    const [selectNationality, setSelectNationality] = useState<number | null>(null);
    const [isVinculate, setIsVinculate] = useState<number | null>(null);
    const [spanishPassport, setSpanishPassport] = useState<number | null>(null);
    const [travelAvailability, setTravelAvailability] = useState<any>(null);
    const [otherData, setOtherData] = useState<string | null>("");
    const [travelWith, setTravelWith] = useState<string | null>("");
    const [relationship, setRelationship] = useState<any>([]);
    const [lugarOrigen, setLugarOrigen] = useState<any>(null);
    const [provinciaPref, setProvinciaPref] = useState<any>(null);
    const [ciudadPref, setCiudadPref] = useState<any>(null);
    const [otherRelationship, setOtherRelationship] = useState<string | null>("");
    const { setBasicDataFilledSync } = useContext(AuthContext);
    const identificationTypesOptions = deletePropertyFromDataList(tipoIdentficacion, NIF);
    const prefixOptions = deletePropertyFromDataList(prefijos, SPAIN.toString());
    const maxDate = new Date();

    useEffect(() => {
        const defaultValueIdentification = tipoIdentficacion.find((type: any) => type.orden === "2");
        if (defaultValueIdentification) setIdentificationType(defaultValueIdentification);
    }, [tipoIdentficacion]);

    useEffect(() => {
        handleGetPuestos();
    }, []);

    useEffect(() => {
        setCiudadPref(null);
    }, [provinciaPref]);

    useEffect(() => {
        handleGetDocuments();
    }, []);

    useEffect(()=>{
        if(!relationship?.find((r: any) => (r.idParentescoGalicia || r.id) == OTRO_TIPO_PARENTESCO)){
          setOtherRelationship("");
        }
      },[relationship]);
    
      useEffect(()=>{
        handleOtherRelationShip(otherRelationship);
      },[otherRelationship]);

    useEffect(() => {
        if (paises.length && provincias.length && localidades.length && tempDatosBasicos) {
            handleSetPais(tempDatosBasicos?.idPais ? Number(tempDatosBasicos?.idPais) : null);
            const province = provincias.find(
                (provincia: any) => Number(provincia.id) === Number(tempDatosBasicos?.idProvincia)
            );
            if (province) {
                setProvincia(province?.id);
            }
            setCity(tempDatosBasicos?.ciudad);
            const localidad = localidades.find(
                (localidad: any) => Number(localidad.id) === Number(tempDatosBasicos?.idLocalidad)
            );
            setLocalidad(localidad);
            if (tempDatosBasicos?.idPaisOrigen) {
                setPaisOrigen(paises.find((pais: any) => Number(pais.id) === Number(tempDatosBasicos?.idPaisOrigen))?.id ?? null);
            }
            if (
                tempDatosBasicos?.puestosDeseados &&
                employmentsOptions &&
                employmentsOptions.length > 0
            ) {
                const data: any = [];
                tempDatosBasicos?.puestosDeseados.map((p: any) => {
                    const employ = employmentsOptions.find((e: any) => e.id === p.idPuesto);
                    data.push({
                        idPuesto: p.idPuesto,
                        nombre: employ.nombre,
                        esPrincipal: p.esPrincipal,
                    });
                });
                setEmployments(data);
            }
            const document = tempDatosBasicos?.tipoIdentificacion === 1 ?
                { "id": "1", "name": "NIF", "orden": "1" }
                :
                identificationTypesOptions.find((type: any) => parseInt(type.orden) === tempDatosBasicos?.tipoIdentificacion) || null;
            if (tempDatosBasicos?.tipoIdentificacion) {
                setIdentificationType(document);
            }
            if (document) {
                setIdentityDoc(tempDatosBasicos?.numeroIdentificacion);
            }
            if (tempDatosBasicos?.telefono?.prefijo) {
                setPhonePrefix(prefixOptions.find((item: any) => item.prefijo.trim() === tempDatosBasicos?.telefono?.prefijo.trim()) || { "name": "España", "prefix": "34" });
            }
            if (tempDatosBasicos?.telefono?.numero) {
                setPhoneNumber(parseInt(tempDatosBasicos?.telefono?.numero.trim()));
            }
            setTempDatosBasicos(null);
        }
    }, [paises, provincias, localidades]);

    useEffect(() => {
        const handlePrefixByCountrySelected = prefixOptions.find((prefix: any) => parseInt(prefix.id) === pais);
        setPhonePrefix(Number(pais?.id) === Countries.SPAIN ? { "name": "España", "prefix": "34" } : handlePrefixByCountrySelected);
    }, [pais]);

    const minDate = new Date().setFullYear(Number(new Date().getFullYear()) - 90);


    const handleGetPuestos = useCallback(() => {
        callApi(getPuestos(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data: any) => {
            setEmploymentsOptions(data.puestos);
            handleGetDatosBasicos();
        });
    }, []);

    const handleGetDatosBasicos = useCallback(() => {
        callApi(getDatosBasicos(returnLangId(i18next.languages[0] as keyof typeof languages))).then((result) => {
            if (result.tieneDatosBasicos) {
                navigate(Routes.HOME);
            }
            setTempDatosBasicos(result);
            setIsVinculate(result.datosRetorna?.idVinculadoGalicia  ?? null);
            setSelectNationality(result.datosRetorna?.idTieneNacionalidad ?? null);
            setSpanishPassport(result.datosRetorna?.idTienePasaporte ?? null);
            setTempAnswers(
                { isVinculate: result.datosRetorna?.idVinculadoGalicia || null, 
                  selectNationality: result.datosRetorna?.idTieneNacionalidad || null, 
                  spanishPassport: result.datosRetorna?.idTienePasaporte || null 
                });
            setName(result.nombre);
            setSurname(result.primerApellido);
            setSurname2(result.segundoApellido);
            setNotificationsEmail(result.correoElectronico);
            setFechaNacimiento(result.fechaNacimiento);
        });
    }, []);

    function deletePropertyFromDataList(dataList: any, id: string) {
        return dataList.filter((i: any) => i.id !== id);
    }

    function tittle() {
        return (
            <Box>
                <Box
                    display="block"
                    fontSize={36}
                    lineHeight={"46px"}
                    fontWeight="900"
                    sx={{ ml: "20px", mt: "20px" }}
                >
                    {t("basicData.thanks")}
                </Box>
            </Box>
        );
    }

    function tittle_text() {
        return (
            <Box>
                <Box
                    display="inline-block"
                    fontSize={16}
                    lineHeight={"24px"}
                    sx={{ ml: "20px" }}

                >
                    {t("basicData.intro")}
                </Box>
            </Box>
        );
    }

    const handleGetDocuments = useCallback(() => {
        callApi(getCandidateDocuments(langId, true)).then((data: any) => {
            const dataMod = buildMultiDocuments(data);
            setDocuments(dataMod.filter((item: any) => item.idTipoDocumento > 11 && documentTypes.find((doc: any) => doc.id === item.idTipoDocumento)));
        }).catch(() => {
            showSnack(t("documents.get_documents_error"), SnackSeverity.ERROR);
            setDocuments([]);
        });
    }, []);

    function buildMultiDocuments(data: any) {
        let carnetAdded = false;
        let identificacionAdded = false;
        const modData = data.map((item: any) => {
            if ((item.idTipoDocumento === 20 && !carnetAdded) || (item.idTipoDocumento === 12 && !identificacionAdded)) {
                item.nombre2 = data.find((doc: any) => doc.idTipoDocumento === item.idTipoDocumento && doc.idDocumento !== item.idDocumento)?.nombre;
                item.idDocumento2 = data.find((doc: any) => doc.idTipoDocumento === item.idTipoDocumento && doc.idDocumento !== item.idDocumento)?.idDocumento;
                if (item.idTipoDocumento === 20 && !carnetAdded) {
                    carnetAdded = true;
                } else if (item.idTipoDocumento === 12 && !identificacionAdded) {
                    identificacionAdded = false;
                    identificacionAdded = true;
                }
            }
            return item;
        });
        return modData.filter((item: any) => !((item.idTipoDocumento === 20 || item.idTipoDocumento === 12) && !item.nombre2));
    }

    function returnCountriesSection() {
        return <>
            <AutocompleteWithLabel
                id={"pais_autocomplete"}
                autoComplete={"new-password"}
                useDefaultIcon
                value={paisOrigen}
                setter={(value: any) => {
                    setPhoneNumber("");
                    handleSetPaisOrigen(value ? Number(value.id) : null);
                }}
                label={t("edit_personal_data.home_country_placeholder")}
                options={paises}
                getOption={(x: any, v: any) => optionEquality(x, v)}
                getLabel={(x: any) =>
                    typeof x === "object" && x !== null
                        ? x.name
                        : x
                            ? paises.find((n: any) => Number(n.id) === Number(x))?.name ?? ""
                            : ""
                }
                moveLabel
                wrong={showError(!paisOrigen)}
            />
            <AutocompleteWithLabel
                id={"pais_autocomplete"}
                autoComplete={"new-password"}
                useDefaultIcon
                value={pais}
                setter={(value: any) => {
                    setPhoneNumber("");
                    handleSetPais(value ? Number(value.id) : null);
                }}
                label={t("edit_personal_data.home_placeholder")}
                options={paises}
                getOption={(x: any, v: any) => optionEquality(x, v)}
                getLabel={(x: any) =>
                    typeof x === "object" && x !== null
                        ? x.name
                        : x
                            ? paises.find((n: any) => Number(n.id) === Number(x))?.name ?? ""
                            : ""
                }
                moveLabel
                wrong={showError(!pais)}
            />
            <Box sx={styles.double_input_container}>
                <Box sx={{ width: "50%" }}>
                    <FormControl
                        variant="outlined"
                        sx={styles.autocomplete_input_container_full}
                    >
                        <AutocompleteWithLabel
                            id={"provincia_autocomplete"}
                            autoComplete={"new-password"}
                            useDefaultIcon
                            disabled={!pais}
                            value={pais ? provincia : ""}
                            setter={(value: any) => {
                                setProvincia(value ? Number(value.id) : null);
                                setLocalidad(null);
                            }}
                            label={Number(pais?.id || 0) === Number(Countries.SPAIN) || !pais ? t("edit_personal_data.province_placeholder") : t("edit_personal_data.region")}
                            options={getProvinceOptions(provincias, pais, paises)}
                            getOption={(x: any, v: any) => optionEquality(x, v)}
                            getLabel={(x: any) =>
                                typeof x === "object" && x !== null
                                    ? x.name
                                    : x
                                        ? handleProvince(provincias, paises, x, pais)
                                        : ""
                            }
                            moveLabel
                            customClassWrapper={styles.auto}
                            wrong={showError(pais && !provincia)}
                        />
                    </FormControl>
                </Box>
                {Number(pais?.id) === Countries.SPAIN || !pais ?
                    <Box sx={{ width: "50%", ml: "8px" }}>
                        <AutocompleteWithLabel
                            id={"localidad_autocomplete"}
                            autoComplete={"new-password"}
                            useDefaultIcon
                            disabled={!pais || !provincia || Number(pais?.id) !== Countries.SPAIN}
                            value={localidad}
                            setter={(value: any) => {
                                setLocalidad(value ? Number(value.id) : null);
                            }}
                            label={t("edit_personal_data.city_placeholder")}
                            options={localidades.filter(
                                (loc: any) => Number(loc.id_province) === Number(provincia)
                            )}
                            getOption={(x: any, v: any) => optionEquality(x, v)}
                            getLabel={(x: any) =>
                                typeof x === "object" && x !== null
                                    ? x.name
                                    : x
                                        ? localidades.find((n: any) => Number(n.id) === Number(x))?.name ??
                                        ""
                                        : ""
                            }
                            moveLabel
                            customClassWrapper={styles.input_not_full_width}
                            wrong={showError(Number(pais?.id) === Countries.SPAIN && !localidad)}
                        />
                    </Box>
                    :
                    <Box sx={{ width: "50%", ml: "8px" }}>
                        <InputWithLabel
                            customClassWrapper={styles.input_not_full_width}
                            label={t("edit_personal_data.city_abroad")}
                            value={city}
                            setter={(e: any) => {
                                setCity(e.target.value);
                            }}
                            moveLabel
                            wrong={showError(Number(pais?.id) !== Countries.SPAIN && !city)}
                        />
                    </Box>
                }
            </Box>
        </>;
    }

    function handleDefaultEmployment(data: any, bool: any) {
        const values = employments.slice();
        const flag = employments.find((element: any) => element.esPrincipal === true);
        if (flag && flag.length !== 0) {
            for (let i = 0; i < values.length; i++) {
                if (employments[i].idPuesto === data.idPuesto) {
                    values[i].esPrincipal = bool;
                }
                if (employments[i].idPuesto === flag.idPuesto) {
                    values[i].esPrincipal = false;
                }
            }
        } else {
            for (let i = 0; i < values.length; i++) {
                if (employments[i].idPuesto === data.idPuesto) {
                    values[i].esPrincipal = bool;
                }
            }
        }
        setEmployments([...values]);
    }

    function handleDeleteEmployment(data: any) {
        if (!data.esPrincipal) {
            setEmployments(
                employments.filter((element: any) => element.idPuesto !== data.idPuesto)
            );
        } else if (employments.length > 1) {
            const newsEmployments = employments.filter(
                (element: any) => element.idPuesto !== data.idPuesto
            );
            newsEmployments[0].esPrincipal = true;
            setEmployments(newsEmployments);
        } else {
            setEmployments([]);
        }
    }

    const getValidationErrors = (showError = true) => {
        const errors = [];
        const identificationTypeNIF = "1";

        if (!name || name === "") {
            errors.push("name");
        }
        if (!surname || surname === "") {
            errors.push("surname");
        }
        if (!identificationType) {
            errors.push("identificationType");
        }
        if (identificationType?.id === identificationTypeNIF && surname2 === "") {
            errors.push("surname2");
        }
        if (
            !identityDoc ||
            !validateDocument(identityDoc, identificationType?.name)
        ) {
            errors.push("identityDoc");
        }
        if (!validatePhone(phoneNumber)) {
            errors.push("phoneNumber");
        }
        if (!validateEmail(notificationsEmail)) {
            errors.push("notificationsEmail");
        }
        if (employments?.length < 1) {
            errors.push("employments");
        }
        if (!fechaNacimiento || !validateDate(fechaNacimiento, minDate, maxDate)) {
            errors.push("fechaNacimiento");
        }
        if (!pais) {
            errors.push("pais");
        }
        if (!paisOrigen) {
            errors.push("paisOrigen");
        }
        if (pais && !provincia) {
            errors.push("provincia");
        }
        if (Number(pais?.id) !== Countries.SPAIN && !city) {
            errors.push("ciudad");
        }
        if (Number(pais?.id) === Countries.SPAIN && !localidad) {
            errors.push("localidad");
        }
        if (selectNationality === null || isVinculate === null || spanishPassport === null) {
            errors.push("preguntasRequisitos");
        }
        if (isVinculate == 3 && !otherData) {
            errors.push("otherData");
        }
        if (!politicaPrivacidad) {
            errors.push("politica");
        }
        if (relationship.find((r: any) => r.id == 4) && !otherRelationship){
            errors.push("otherRelationship");
        }
        if (!politicaPrivacidad && showError && errors.length === 1) {
            showSnack('Es necesario aceptar la política de privacidad.', SnackSeverity.ERROR);
        } else if (errors.length && showError) {
            showSnack('Por favor cubra correctamente todos los campos.', SnackSeverity.ERROR);
        }
        setFieldsWithErrors([...errors]);
        return errors;
    };

    async function handleSaveData(politicaPrivacidad = false) {
        setSaveClick(true);
        if (getValidationErrors().length === 0) {
            const data = {
                idPortal: PORTAL_RETORNA,
                nombre: name,
                primerApellido: surname,
                segundoApellido: surname2,
                tipoIdentificacion: Number(identificationType.id),
                numeroIdentificacion: identityDoc.toUpperCase(),
                telefono: {
                    prefijo: phonePrefix?.prefix,
                    numero: phoneNumber.toString(),
                },
                correoElectronico: notificationsEmail,
                puestosDeseados: employments,
                fechaNacimiento,
                idPais: Number(pais),
                idProvincia: Number(provincia),
                ciudad: city,
                recibirInformacion: sendComunications,
                politicaPrivacidad,
                datosRetorna: {
                    idTieneNacionalidad: null,
                    idPaisOrigen: Number(paisOrigen),
                    idTienePasaporte: (spanishPassport != null) ? Number(spanishPassport) : null,
                    idVinculadoGalicia: Number(isVinculate) ? Number(isVinculate) : null,
                    vinculadoGalicia: isVinculate == 3 ? otherData : null,
                    lugarOrigen,
                    idPeriodoDisponibilidadViajar: travelAvailability?.id || null,
                    idProvinciaPreferente: provinciaPref,
                    idLocalidadPreferente: ciudadPref,
                    acompanante: travelWith?.length ? travelWith : null,
                    parentescosGalicia: []
                },
            } as any;

            relationship.forEach((r: any) => {
                data.datosRetorna.parentescosGalicia.push({
                    idParentescoGalicia: r.id,
                    parentescoGalicia: r.id === 4 ? otherRelationship : null,
                });
            });
            
            if (selectNationality != null) data.datosRetorna.idTieneNacionalidad = Number(selectNationality);
            callApi(saveBasicData(data)).then(() => {
                sendGAEvent("Cubiertos datos básicos", {
                    userEmail: notificationsEmail
                });
                setBasicDataFilledSync(true).then(() => {
                    const getUserInOffer = sessionStorage.getItem("fromOferta");
                    const fromRegister = sessionStorage.getItem("fromRegister");
                    if (getUserInOffer && fromRegister) {
                        sessionStorage.removeItem("fromRegister");
                        sessionStorage.removeItem("fromOferta");
                        navigate(getUserInOffer);
                    } else {
                        const isNewUser = true;
                        navigate(Routes.DASHBOARD, { state: { isNewUser } } as any);
                    }
                });
            });
        }
    }

    function handleSetPais(value: any) {
        setPais(value);
        if (value !== Countries.SPAIN) {
            const provincia = provincias.find(
                (prov: any) => prov.id_country === value?.toString()
            );
            setProvincia(Number(provincia?.id));
            setLocalidad(
                Number(provincia?.id)
                    ? Number(
                        localidades.find((loc: any) => loc.id_province === provincia.id)?.id
                    )
                    : null
            );
            setCity("");
        } else {
            setPais({
                "id": "724",
                "name": "España",
                "prefix": "34"
            });
        }
    }

    function handleSetPaisOrigen(value: any) {
        setPaisOrigen(value);
        handleSetPais(value);
    }

    function handleSetEmployments(value: any) {
        const someDefatult = employments.find(
            (element: any) => element?.esPrincipal === true
        );
        if (
            value &&
            !employments.find(
                (element: any) => parseInt(element.idPuesto) === parseInt(value.id)
            )
        ) {
            if (!someDefatult) {
                setEmployments([
                    ...employments,
                    { idPuesto: value.id, nombre: value.nombre, esPrincipal: true },
                ]);
            } else {
                setEmployments([
                    ...employments,
                    { idPuesto: value.id, nombre: value.nombre, esPrincipal: false },
                ]);
            }
        }
    }

    function showError(condition: boolean) {
        return condition && saveClick;
    }

    function handleOtherRelationShip(value:any){
        let deleteOtroParentesco = relationship.filter((r: any) => r.id != OTRO_TIPO_PARENTESCO);
        const isOtherRelation = relationship.find((r: any) => r.id == OTRO_TIPO_PARENTESCO);
        if (isOtherRelation) {
          deleteOtroParentesco = [...deleteOtroParentesco, { ...isOtherRelation, name: `Otros ${value !== "" ? `(${value})` : ""}` }];
          setRelationship(deleteOtroParentesco);
        }
      }

    function renderForm() {
        return (
            <Box sx={styles.form_container}>
                <InputWithLabel
                    wrong={showError(!name || name === "")}
                    label={t("basicData.name")}
                    value={name}
                    setter={(e: any) => {
                        setName(e.target.value);
                    }}
                    moveLabel
                    maxLength={100}
                />
                <InputWithLabel
                    wrong={showError(!surname || surname === "")}
                    label={t("basicData.surname")}
                    value={surname}
                    setter={(e: any) => {
                        setSurname(e.target.value);
                    }}
                    moveLabel
                    maxLength={100}
                />
                <InputWithLabel
                    name="surname2"
                    label={t("basicData.surname2")}
                    value={surname2}
                    setter={(e: any) => {
                        setSurname2(e.target.value);
                    }}
                    moveLabel
                    maxLength={100}
                    wrong={showError(identificationType?.id === "1" && surname2 === "")}
                />
                {returnCountriesSection()}
                <Box sx={styles.double_input_container}>
                    <Box sx={{ width: "50%" }}>
                        <FormControl
                            variant="outlined"
                            sx={styles.autocomplete_input_container_full}
                        >
                            <AutocompleteWithLabel
                                id="autocomplete-identification-types"
                                options={identificationTypesOptions}
                                getLabel={(option: any) => option.name || ""}
                                getOption={(x: any, v: any) => optionEquality(x, v)}
                                disableClearable
                                label={t("basicData.identification_type")}
                                value={identificationType}
                                setter={(value: any) => {
                                    setIdentificationType(value);
                                    setIdentityDoc("");
                                }}
                                moveLabel
                                customClassWrapper={styles.auto}
                                wrong={showError(!identificationType)}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ ml: "8px" }}>
                        <InputWithLabel
                            wrong={showError(!identityDoc ||
                                !validateDocument(identityDoc, identificationType?.name))}
                            customClassWrapper={styles.input_not_full_width}
                            label={t("basicData.identityDoc")}
                            value={identityDoc}
                            setter={(e: any) => {
                                setIdentityDoc(e.target.value.toLocaleUpperCase());
                            }}
                            moveLabel
                            maxLength={20}
                        />
                    </Box>
                </Box>

                <Box sx={styles.double_input_container}>
                    <Box sx={{ width: "50%" }}>
                        <FormControl
                            variant="outlined"
                            sx={styles.autocomplete_input_container_full}
                        >
                            <AutocompleteWithLabel
                                id="autocomplete-phone-prefixes"
                                options={prefixOptions}
                                getLabel={(x: any) =>
                                    typeof x === "object" && x !== null
                                        ? `${x.name} +${x.prefix}`
                                        : x
                                }
                                label={t("basicData.prefix")}
                                disableClearable
                                value={phonePrefix}
                                setter={(value: any) => {
                                    setPhonePrefix(value);
                                    setPhoneNumber("");
                                }}
                                customClassWrapper={styles.auto}
                                moveLabel
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ ml: "8px", width: "50%" }}>

                        <InputWithLabel
                            wrong={showError(!validatePhone(phoneNumber))}
                            customClassWrapper={styles.input_not_full_width}
                            label={t("basicData.phoneNumber")}
                            value={phoneNumber}
                            setter={(e: any) => {
                                setPhoneNumber(e.target.value);
                            }}
                            moveLabel
                            maxLength={10}
                            type="number"
                        />
                    </Box>
                </Box>
                <InputWithLabel
                    wrong={showError(!validateEmail(notificationsEmail))}
                    label={t("basicData.email")}
                    value={notificationsEmail}
                    setter={(e: any) => {
                        setNotificationsEmail(e.target.value);
                    }}
                    moveLabel
                    maxLength={100}
                />
                <DatePickerWithLabel
                    label={t("edit_personal_data.birthdate")}
                    value={fechaNacimiento}
                    setter={(value: any) => {
                        setFechaNacimiento(value);
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    wrong={showError(!fechaNacimiento || !validateDate(fechaNacimiento, minDate, maxDate))}
                />
                <AutocompleteWithLabel
                    id="autocomplete-travel-availability-types"
                    options={travelAvailabilityOptions}
                    getLabel={(option: any) => option.name || ""}
                    getOption={(x: any, v: any) => optionEquality(x, v)}
                    label={t("basicData.travelAvailability")}
                    value={travelAvailability}
                    setter={(value: any) => {
                        setTravelAvailability(value);
                    }}
                    moveLabel
                />
                <InputWithLabel
                    name="travelWith"
                    label={t("basicData.travelWith")}
                    value={travelWith}
                    setter={(e: any) => {
                        setTravelWith(e.target.value);
                    }}
                    moveLabel
                    maxLength={100}
                />
                <AutocompleteWithLabel
                    id={"provincia_preferencia_autocomplete"}
                    autoComplete={"new-password"}
                    useDefaultIcon
                    value={provinciaPref}
                    setter={(value: any) => {
                        setProvinciaPref(value ? Number(value.id) : null);
                    }}
                    label={t("basicData.provincePref")}
                    options={galicianProvinces}
                    getOption={(x: any, v: any) => optionEquality(x, v)}
                    getLabel={(x: any) =>
                        typeof x === "object" && x !== null
                            ? x.name
                            : x
                                ? handleProvince(provincias, paises, x, pais)
                                : ""
                    }
                    moveLabel
                    customClassWrapper={styles.auto}
                />

                {provinciaPref ?
                    <AutocompleteWithLabel
                        id={"provincia_preferencia_autocomplete"}
                        autoComplete={"new-password"}
                        useDefaultIcon
                        value={ciudadPref}
                        setter={(value: any) => {
                            setCiudadPref(value ? Number(value.id) : null);
                        }}
                        label={t("basicData.cityPref")}
                        options={localidades.filter((c: any) => c.id_province == provinciaPref)}
                        getOption={(x: any, v: any) => optionEquality(x, v)}
                        getLabel={(x: any) => typeof x === "object" && x !== null
                            ? x.name
                            : localidades.find((c: any) => Number(c.id) == Number(x))?.name || ""}
                        moveLabel
                        customClassWrapper={styles.auto}
                    />
                    : <div></div>}
                <div></div>
                <Box sx={styles.radio_Button_Questions} style={{ gridColumn: "1/4" }} >
                    <Typography sx={{fontSize: "16px", fontWeight: 700}}>{t("basicData.vinculation_title")}</Typography>
                    <Box sx={styles.inner_grid}>
                        <AutocompleteWithChips
                            wrong={false}
                            options={relationShipOptions}
                            values={relationship}
                            label={t("basicData.relationship")}
                            setter={(e: any) => {
                                setRelationship(e);
                            }}
                            moveLabel
                            maxLength={100}
                            noAdd
                            required={undefined}
                            disabled={undefined}
                            placeholder={undefined}
                            setterOptions={undefined}
                            onOpen={undefined}
                            container={undefined} />
                        {relationship.find((r: any) => r.id == 4) ?
                            <Box sx={{ alignSelf: "flex-start", width: {xs: "100%", md: "unset" } }}>
                                <InputWithLabel
                                    wrong={showError(relationship.find((r: any) => r.id == 4) && !otherRelationship)}
                                    name="otherRelationship"
                                    label={t("basicData.otherRelationship")}
                                    value={otherRelationship}
                                    setter={(e: any) => {
                                        setOtherRelationship(e.target.value);
                                        handleOtherRelationShip(e.target.value);
                                    }}
                                    moveLabel
                                    maxLength={100}
                                />
                            </Box>
                            : null}
                        <Box sx={{ alignSelf: "flex-start", width: {xs: "100%", md: "unset" }, marginTop: {xs: "10px", md: "unset"} }}>
                             <InputWithLabel
                                    name="originPlace"
                                    label={t("basicData.originPlace")}
                                    value={lugarOrigen}
                                    setter={(e: any) => {
                                        setLugarOrigen(e.target.value);
                                    }}
                                    moveLabel
                                    maxLength={100}
                                />
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.radio_Button_Questions} style={{ gridColumn: "1/4" }} >
                    <Box sx={{ width: "100%", display: "flex", marginRight: "20px", flexDirection:{xs: "column", md: "row"} }}>
                        <Box sx={{ width: "20%" }}>
                            <UploadDocuments documentos={documents} getDocuments={handleGetDocuments} basicData />
                        </Box>
                        <Box>
                            <Box sx={styles.rightColumn}>
                                {documents.length ? documents.map((documento: any) => {
                                    return (<DocumentoListItem 
                                                key={documento.idDocumento} 
                                                getDocuments={handleGetDocuments} 
                                                documento={documento} 
                                                langId={langId} 
                                            />);
                                }) :
                                    <Box>
                                        <Icon color="primary" sx={styles.icon_gray}>
                                            <MoveUpGrey />
                                        </Icon>
                                    </Box>}
                            </Box>
                        </Box>

                    </Box>
                </Box>
                <Box sx={styles.radio_Button_Questions} style={{ gridColumn: "1/4" }} >
                    <PreguntasDatosBasicos
                        selectNationality={tempAnswers.selectNationality}
                        isVinculate={tempAnswers.isVinculate}
                        spanishPassport={tempAnswers.spanishPassport}
                        setSelectNationality={setSelectNationality}
                        setIsVinculate={setIsVinculate}
                        setOtherVinculadoGalicia={setOtherData}
                        otherVinculadoGalicia={otherData}
                        showErrors={fieldsWithErrors.length && isVinculate == 3 && !otherData}
                        setSpanishPassport={setSpanishPassport} />
                </Box>
                <Box sx={styles.desireJobContainer} style={{ gridColumn: "1/4" }}>
                    <Box sx={styles.widthDesireJob}>
                        <AutocompleteWithLabel
                            options={
                                employmentsOptions
                                    ? employmentsOptions.filter(
                                        (o: any) => !employments.find((e: any) => e.idPuesto === o.id)
                                    )
                                    : []
                            }
                            value={employments}
                            label={t("basicData.employments")}
                            setter={handleSetEmployments}
                            moveLabel
                            maxLength={100}
                            getLabel={(option: any) => option.nombre || ""}
                            getOption={(x: any, v: any) => optionEquality(x, v)}
                            disableClearable
                            customClassWrapper={styles.auto}
                            disabled={!employmentsOptions || employments?.length === 3}
                            wrong={showError(
                                employments?.length < 1)
                            }
                        />
                    </Box>
                    <Box sx={styles.chip}>
                        {employments.map((data: any) => {
                            return (
                                <Box
                                    sx={styles.customChip}
                                    key={`${data.nombre}, ${data.id}`}
                                >
                                    <Box sx={styles.nameChip}>{data.nombre}</Box>
                                    <Box sx={styles.iconGeneral}>
                                        {data.esPrincipal ? (
                                            <HeartIcon title={t("icons_tooltips.favorite")} fill />
                                        ) : (
                                            <HeartIcon
                                                title={t("icons_tooltips.favorite")}
                                                fill={false}
                                                onClick={() => handleDefaultEmployment(data, true)}
                                            />
                                        )}
                                    </Box>
                                    <Box sx={styles.iconGeneral}>
                                        <DeleteChip
                                            title={t("icons_tooltips.delete")}
                                            onClick={() => handleDeleteEmployment(data)}
                                        />
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>

                <Box
                    style={{ display: "flex", gridColumn: "1/4", alignItems: "center" }}
                >
                    <Checkbox
                        checked={sendComunications}
                        onChange={(e: any) => setSendComunications(e.target.checked)}
                        color="primary"
                        style={{ paddingLeft: 0, paddingRight: "9px" }}
                    />
                    <Typography variant="body1">{t("basicData.checkInfo")}</Typography>
                </Box>
                <Box
                    style={{ display: "flex", gridColumn: "1/4", alignItems: "center" }}
                >
                    <Checkbox
                        checked={politicaPrivacidad}
                        onChange={(e: any) => setPoliticaPrivacidad(e.target.checked)}
                        color="primary"
                        style={{ paddingLeft: 0, paddingRight: "9px" }}
                    />
                    <Typography sx={{ marginRight: "4px" }} variant="body1">He leído y acepto la</Typography>
                    <Link onClick={() => navigate(Routes.PRIVACY_POLICY)}>
                        Política de privacidad
                    </Link>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#DA291C", borderRadius: "4px", color: "white", fontSize: "12px" }}
                    sx={{ ...styles.save_button, ...styles.save_button_fullWidth }}
                    fullWidth={matchesMobile}
                    onClick={() =>
                        handleSaveData(true)
                    }
                >
                    {t("basicData.save")}
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={styles.body}>
            <Box sx={styles.body_container}>
                <Box sx={styles.title_container}>
                    {tittle()}
                    {tittle_text()}
                </Box>
                {renderForm()}
            </Box>
        </Box>
    );
}

export default DatosBasicos;