import React from 'react';
import { Box, Typography, useMediaQuery, ListItem, ListItemText } from "@mui/material";
import styles from "./styles";
import { useTranslation } from 'react-i18next';
import theme from '../../../../theme';
import { introductionDescription, introductionList, sections } from './diccionarySections';


function AvisoLegal() {
    const { t } = useTranslation();
    const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));

    function returnList(list: any) {
        return (list?.map((l: any) => (
            l?.name &&
            <ListItem key={l.id}>
                <ListItemText sx={{ display: 'list-item' }} primary={t(l.name)} />
            </ListItem>
        )));
    }

    function getDescriptions(section: any) {
        return (section?.map((s: any) => (
            s.name &&
            <Box style={{ paddingBottom: "10px" }} key={s.id} dangerouslySetInnerHTML={{ __html: t(s.name) }}></Box>
        )));
    }

    return (
        <Box>
            <Box sx={styles.main}>
                <Box sx={styles.mainSection}>
                    <Box sx={{ ...styles.policityBackground, borderRadius: !matchesMobile ? '10px' : "unset" }}>
                        <Typography sx={styles.title} variant="body1">{t("legalRequirements.title")}</Typography>
                        <Typography sx={styles.header_description}>{t("legalRequirements.content13")}</Typography>
                    </Box>
                </Box>
                {!matchesMobile &&
                    <Box sx={{ ...styles.mainSection, justifyContent: "flex-start" }}>
                        <Box component="img" src="/images/Politicasprivacidad.png" alt="Cabecera legal" />
                    </Box>
                }
            </Box>
            <Box sx={styles.section}>
                <Box sx={styles.sectionDescription}>
                    <Typography >{t("legalRequirements.content1")}</Typography>
                </Box>
                <Box sx={{ ...styles.sub_section, maxWidth: {xs: "unset", md: "800px"}, alignItems: "flex-start" }}>
                    {returnList(introductionList)}
                    {getDescriptions(introductionDescription)}

                </Box>
            </Box>
            {sections.map((s) => (
                <Box sx={styles.section} key={s.id}>
                    <Typography sx={styles.sectionTitle}>{t(s.title)}</Typography>
                    {s.elements?.map((e: any) => {
                        const subtitleBox = e.subtitle && <Box component="h3" sx={styles.subtitle}>{t(e.subtitle)}</Box>;
                        const descriptionBox = e.description && getDescriptions(e.description);
                         
                        return (
                            <>
                                <Box sx={subtitleBox ? styles.sectionDescription : styles.hideBox}>{subtitleBox}</Box>
                                <Box sx={descriptionBox ? styles.sub_section : styles.hideBox}>{descriptionBox}</Box>
                            </>
                        );
                    })}
                    <Box sx={{ ...styles.sub_section, maxWidth: {xs: "unset", md: "800px"}, alignItems: "flex-start" }}>
                        {getDescriptions(s.elements)}
                    </Box>
                </Box>
            ))}
        </Box>
    );
}
export default AvisoLegal;