import React from "react";
import styles from "./styles";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Routes } from "../../../../enums/enums";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={styles.footer_container}>
      <Box sx={styles.footer_init}>
        <Box sx={styles.contact}>
          <Box sx={{width: "100%", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: {xs: "center", md: "center"}, margin: {xs: "6px 0", md: "32px 0 18px 0"}}}>
            <Box component="img" alt="Nortempo" onClick={() => window.open( sessionStorage.getItem("language") === "gl" ? Routes.FUNDACION_NORTEMPO_GAL : Routes.FUNDACION_NORTEMPO)} src="/images/nortempo_fundation.svg" sx={styles.nortempoLogo} style={{ width: '130px', height: "45px" }} />
          </Box>
          <Box sx={{width: "100%", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: {xs: "center", md: "center"}, margin: {xs: "6px 0", md: "0 0 14px 0"}}}>
            <LinkedInIcon sx={{width: "24px", height: "24px",color:"#0A66C2"}} onClick={()=>window.open(Routes.LINKEDIN_FUNDACION)}/>
          </Box>
          <Box sx={{cursor: "pointer", display: "flex", mt: "6px"}}>
              <Typography sx={{fontSize: {xs: "10px", md: "14px"}}} onClick={()=>navigate(Routes.AVISO_LEGAL)}>{t("footer.legal")}</Typography>
              <Typography sx={{fontSize: {xs: "10px", md: "14px"}, marginLeft: "4px"}} onClick={()=>navigate(Routes.PRIVACY_POLICY)}>{t("footer.privacy_policy")}</Typography>
              <Typography sx={{fontSize: {xs: "10px", md: "14px"}, marginLeft: "4px"}} onClick={()=>navigate(Routes.CONDICIONES_USO)}>{t("footer.terms_use")}</Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{...styles.text, color: "rgb(20,20,20,0.8)", fontSize: "12px", mb: "30px"}}>
              {t("footer.rights_reserved")}
            </Typography>
          </Box>
        </Box> 
      </Box>
    </Box>
  );
}

export default Footer;
