 const styles = {
    text: {
        fontSize: { xs: "16px", md: "14px" },
        lineHeight: { xs: "0px", md: "21px" },
        color: { xs: "black", md: 'primary.main' },
        cursor: "pointer",
        "& + &": {
            marginLeft: "30px",
        },
        marginLeft: { xs: "10px" }
    },
    principal_container: {
        gridArea: "body_container",
        display: "grid",
        gridTemplateColumns:{ xs: "auto", md: "30% 65%" } ,
        columnGap: "20px",
    },
    col2: {
        marginTop:"0px",
        padding: {xs:"20px",md:"90px"},
        paddingTop:{md:"115px"},
        display: "grid",
        gridTemplateColumns: "1fr",
        gridAutoRows: "min-content",
        rowGap: "60px",
    },
    data_sections_container: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridAutoRows: "min-content",
        rowGap: "60px",
    },
    data_section_icon_and_title: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        marginBottom: "25px",
    },
    data_section_title: {
        fontSize: "18px",
        fontWeight: 900,
        marginBottom: "20px",
    },
    data_subsection_title: {
        fontSize: "14px",
        fontWeight: 900,
    },
    summary_icon_container: {
        display: "flex",
        alignItems: "center",
        justifyContent:{xs:"center",md: "flex-start"},
        width: "100%",
        marginBottom: "30px",
        marginTop:{xs:"70px",md:"unset"}

      },
    data_section_title_principal: {
        fontSize:{ xs:  "18px", md: "22px" } ,
        fontWeight: 900,
        marginLeft: "10px",
    },
    data_section_container: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
            maxWidth:{ md: "400px"},
            minWidth: { md: "280px"},
            marginBottom: { md: "unset"} ,
            width:"100%",
    },
    section_selector_input: {
        width: "100%",
        display: "grid",
        columnGap: "10px",
        gridTemplateColumns: ".4fr .6fr",
    },
    address_grid: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: ".20fr .20fr .20fr .20fr .20fr",
        columnGap: { xs: "10px", md: "25px"},
    },
    single_perfil_digital: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        "& + &": {
            marginTop: "20px",
        },
    },
    icon_text_perfil_digital: {
        display: "flex",
        flexDirection: "row",
    },
    fullname_grid: {
        display: "grid",
        gridTemplateColumns:{ xs: "1fr", md: ".5fr .5fr"} ,
        gridTemplateAreas: { xs: "unset", md:`
            'name .'
            'firstSurname secondSurname'`},
        columnGap: "25px",
        rowGap: "20px",
        marginBottom: "25px",
    },
    name: {
        gridArea: { xs: "unset", md: "name"},
    },
    firstName: {
        gridArea: { xs: "unset", md: "firstSurname"} ,
    },
    secondName: {
        gridArea: { xs: "unset", md: "secondSurname"} ,
    },
    basic_data_grid: {
        display: "grid",
        gridTemplateColumns: { xs: "1fr", md: ".5fr .5fr"} ,
        columnGap: "25px",
        rowGap: "20px",
        alignItems: "end",
        marginBottom: "25px",
        width: { xs:"100%"},
    },
    direction_grid: {
        display: "grid",
        gridTemplateColumns: "1fr",
        columnGap: "25px",
        rowGap: "20px",
        alignItems: "end",
        marginBottom: "25px",
    },
    section_30_70: {
        display: "grid",
        gridTemplateColumns: "0.4fr 0.6fr",
        columnGap: { xs: "10px", md:  "25px"},
        alignItems: "end",
    },
    date_picker_class: {
        width: "100%",
    },
    location_grid: {
        display: "grid",
        gridTemplateColumns: { xs: "1fr", md:  ".333fr .333fr .333fr"},
        columnGap: { xs: "20px", md:  "25px"},
        width: { xs:"100%"},
        rowGap:{xs:"15px"}
    },
    digital_profiles_grid: {
        display: "grid",
        gridTemplateColumns: { xs: "1fr",md:  ".35fr .75fr .35fr", lg:  ".25fr .75fr .35fr"} ,
        columnGap:{xs:"15px",lg:"25px"} ,
        rowGap:{ xs: "15px", md:  "30px"},
        width: { xs:"100%"},
    },
    digital_profiles_list_container: {
        display: "flex",
        flexDirection: "column",
    },
    placeholder: {
        color: "#B2B2B2",
    },
    icon_text_perfil_digital_texto: {
        textOverflow: "ellipsis",
        wordBreak: "break-all",
        padding: 0,
        margin: 0,
        marginLeft: "13px",
    },
    multiline: {
        marginBottom: "25px",
    },
    formControl: {
        height: "100%",
    },
    marginAuto: {
        marginTop: "auto"
    },
    save_button: {
        color: "#FFFFFF",
        fontWeight: "900",
        height: "36px",
        textTransform: "none",
        width: "75%",
        marginTop: {xs: "30px", md: "unset"},
        marginBottom: {xs: "30px", md: "unset"},
        fontSize: {xs: "16px", md: "unset"},
      },
      save_button_fullWidth: {
        width: "80% !important",
      },
      action_buttons_grid: {
        marginTop:"20px",
        display: "grid",
        gridTemplateColumns: {xs:"1fr",md: ".25fr .25fr .5fr"},
        columnGap: "25px",
        rowGap:{xs:"20px" ,md:"24px"},
        gridTemplateAreas: {
        md:`'cancel . save'`,
        xs:`"save save"  
             "cancel cancel"`},
        fontSize:{xs:"16px"} ,
      },
    inputRoot_textField: {
        height: "36px",
        "-webkit-appearance": "none",
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
        },
        padding: "0px 6px !important",
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "-moz-appearance": "textfield !important",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important",
        },
    },
     text_add_profiles: {
         fontSize: "14px",
         marginLeft: '5px'
     },
     button_add_profiles: {
         display: "flex",
         alignItems: "center",
         cursor: "pointer",
         paddingTop: "15px",
     },
};

export default styles;
