const limitacionResponsabilidadSection = [
    { id: 0, name: "legalRequirements.content103" },
    { id: 1, name: "legalRequirements.content104" }
];


//Pinta el titulo de cada seccion con sus parrafos
export const sections = [
    { id: 0, title: "legalRequirements.subtitle13", elements: limitacionResponsabilidadSection },
];

export const introductionList = [
    { id: 0, name: "legalRequirements.content94" },
    { id: 1, name: "legalRequirements.content95" },
    { id: 2, name: "legalRequirements.content96" },
    { id: 3, name: "legalRequirements.content97" },
    { id: 4, name: "legalRequirements.content98" },
    { id: 5, name: "legalRequirements.content99" },
];

export const introductionDescription = [
    { id: 3, name: "legalRequirements.content100" },
    { id: 4, name: "legalRequirements.content101" },
    { id: 4, name: "legalRequirements.content102" },

];