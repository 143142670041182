

const styles = {
    principal_container: {
      gridArea: "body_container",
      display: "grid",
      gridTemplateColumns: {xs:"auto",md:"30% 65%"},
      columnGap: "20px",
    },
    col2: {
      marginTop:{xs:"0px",md:"37px"},
      padding:{xs:"36px",md:"90px"},
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "60px",
    },
    data_sections_container: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "50px",
    },
    data_section_icon_and_title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      marginBottom: "20px",
      marginTop: {xs:'30px',lg:"unset"}
    },
    data_section_title_principal: {
      fontSize:{xs:"18px",md:"22px"} ,
      fontWeight: 900,
      marginLeft: "10px",
    },
    render_basic_data_style: {
      display: "flex",
      flexDirection: "column",
    },
    basic_data_grid: {
      display: "grid",
      gridTemplateColumns:{xs:"1fr",md:".5fr .5fr"} ,
      columnGap: "26px",
      rowGap: "20px",
      alignItems: "end",
    },
    certifications_container: {
      display: "flex",
      flexDirection: "column",
    },
    certifications_grid: {
      display: "grid",
      gridTemplateColumns:{xs:"1fr",md:".5fr .5fr"},
      gridTemplateAreas:{xs:"unset",md: `
              'switch .'
              'certificate date'`},
      columnGap: "26px",
      rowGap: "20px",
      alignItems: "end",
      width: "100%",
    },
    text_switch: {
      gridArea: {xs:"unset",md:"switch"},
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    add_certification_row: {
      display:"flex",
      alignItems:"center",
      paddingLeft:"27px",
      cursor: "pointer",
    },
    delete_row: {
      display:{xs:"flex",md:"grid"} ,
      gridTemplateColumns: ".5fr .5fr",
      columnGap: "25px",
      flexDirection:{xs: "column"},
      alignItems:{xs:  "center"},
      gridTemplateAreas: `'. delete'`,
    },
    delete_link: {
      justifySelf: "center",
      fontSize: "14px",
      color: "#DA291C",
      gridArea: "delete",
      cursor: "pointer",
      textDecoration: "underline",
      "&:hover": {
        opacity: "0.8",
      },
    },
    chip: {
      backgroundColor: "#EFEFEF",
      marginTop: "10px",
      maxWidth: "100%",
    },
    certificate: {
      gridArea:{xs:"unset",md:"certificate"} ,
      width: "100%",
    },
    certificate_date: {
      gridArea: {xs:"unset",md:"date"},
      width: "100%",
      display: "flex",
    },
    date_picker_class: {
      width: {xs:"94%",md:"90%"},
    },
    counter_chars_description:{
      fontSize: 12,
      color: "#D8D8D8",
    },
    comments: {
      marginTop: "20px"
    }
  };

  export default styles;