import React, { useState, useEffect, useCallback, useContext } from "react";
//import Layout from "../components/basicLayout";
//import Snackbar from "../components/customSnackBar";
import { useTheme,useMediaQuery,FormControl, Box } from "@mui/material";
import TuCurriculumHeader from "../../../../../shared/components/TuCurriculumHeader/TuCurriculumHeader";
//import Loading from "../components/loading";
import { useTranslation } from "react-i18next";
import AddToCVActionButtons from "../../../../../shared/components/AddToCVActionButtons/AddToCVActionButtons";
import YourFormation from "../../../../../assets/icons/yourFormation";
import Autocomplete from "../../../../../shared/components/SelectCustoms/autocompleteWithLabelAnimation";
import i18next from "../../../../../i18n";
import {
  optionEquality,
  returnLangId,
  formatDateInput,
  getNivelesEstudio,
  getAreasEstudio,
  formatToLocalDate,
} from "../../../../../shared/utils/functions";
import AntSwitch from "../../../../../shared/components/AntSwitch/antSwitch";
import DatePickerWithLabel from "../../../../../shared/components/DatePicker/datePickerWithLabel";
 import {
  addFormation,
  updateFormation,
  deleteFormation,
  getCertificate,
  getDatosPersonales,
  getFormations
} from "../../../../../shared/hooks/userApi";
import AlertDialog from "../../../../../shared/components/AlertDialog/alertDialog";
import InputWithLabel from "../../../../../shared/components/Inputs/InputCustom";
import styles from "./stylesFormacion";
import { useApi } from "../../../../../shared/hooks/useApi";
import { languages } from "../../../auth/AuthWrapper";
import { SnackContext,SnackSeverity } from "../../../../../shared/contexts/SnackHandler";
import {useNavigate, useSearchParams } from "react-router-dom";
import { Routes } from "../../../../../shared/enums/enums";

const niveles_de_estudio=getNivelesEstudio(i18next.languages[0]);
const areas_estudio=getAreasEstudio(i18next.languages[0]);



function Formacion() {

  const { t } = useTranslation();

  const callApi = useApi();
  const showSnack = useContext(SnackContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const idTraining = searchParams.get("idFormacion");

  const [loading, setLoading] = useState(false);
 // const [snackbarText, setSnackbarText] = useState(null);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [idFotografia, setIdFotografia] = useState(null);
  const [fechaModificacion, setFechaModificacion] = useState<any>(null);

  const [idNivelEstudios, setIdNivelEstudios] = useState(null);
  const [idAreaEstudio, setIdAreaEstudio] = useState<any>(null);
  const [titulo, setTitulo] = useState<any>(null);
  const [centro, setCentro] = useState<any>(null);
  const [esFormacionActual, setEsFormacionActual] = useState(true);
  const [fechaInicio, setFechaInicio] = useState<any>(null);
  const [fechaFin, setFechaFin] = useState<any>(null);
  const [tieneValidez, setTieneValidez] = useState(false);
  const [fechaFinValidez, setFechaFinValidez] = useState<any>(null);

  const [isAreaNeeded, setIsAreaNeeded] = useState(true);
  const [isTitleNeeded, setIsTitleNeeded] = useState(true);
  const [isCertificate, setIsCertificate] = useState(false);

  const [certificados, setCertificados] = useState<any>(null);

  const [isEditMode, setIsEditMode] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const minDate = new Date().setFullYear(Number(new Date().getFullYear()) - 90);
  const maxDate = new Date().setFullYear(Number(new Date().getFullYear()) + 30);

  const [saveWrong, setSaveWrong] = useState(false);

  const NIVEL_CERTIFICACIONES = 15;

  useEffect(() => {
    handleDatosPersonales();
    
  }, []);

  useEffect(() => {
    if (idTraining) {
      handleFormaciones();

      prueba();
    }
  }, [idTraining]);

  useEffect(() => {
    if (!certificados) {
      handleGetCertificados();
    }
  }, [certificados]);

  function prueba(){
    loading;
  }


  const handleGetCertificados = useCallback(() => {
  
    setLoading(true);
    callApi(getCertificate()).then((result) => {
      setCertificados(result.certificados);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setCertificados([]);
    });
  }, []);


  const handleDatosPersonales = useCallback(() => {
    callApi(getDatosPersonales(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      handlePersonalDataAction(data);
    }).catch(() => {
      showSnack(t("curriculum.personal_data_error"), SnackSeverity.ERROR);
    });
}, []);


  const handleFormaciones = useCallback(() => {
    callApi(getFormations(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      const myTraining = data.find(
        (t: any) => t.idFormacion === Number(idTraining)
      );
      handleEditMode(myTraining);
    }).catch(() => {
      showSnack(t("curriculum.formations_error"), SnackSeverity.ERROR);
    });
  }, []);


  function handlePersonalDataAction(data:any) {
    setNombre(data.nombre);
    setPrimerApellido(data.primerApellido);
    setSegundoApellido(data.segundoApellido);
    setCorreoElectronico(data.correoElectronico);
    setPrefijo(data.telefono.prefijo);
    setTelefono(data.telefono.numero);
    setIdFotografia(data.idFotografia);
    setFechaModificacion(formatToLocalDate(new Date(data.fechaModificacion)));
  }

  function handleSetIdNivelEstudios(value:any) {
    const idNivelEstudios =
      value && typeof value === "object" ? Number(value.id) : value;
    const nivelEstudios = niveles_de_estudio.find(
      (s:any) => Number(s.id) === Number(idNivelEstudios)
    );
    if (nivelEstudios) {
      setIsAreaNeeded(nivelEstudios.area === "Si");
      setIsTitleNeeded(nivelEstudios.title === "Si");
    } else {
      setIsAreaNeeded(true);
      setIsTitleNeeded(true);
    }
    setIsCertificate(Number(idNivelEstudios) === 15);
    setTitulo(null);
    setIdAreaEstudio(null);
    setIdNivelEstudios(idNivelEstudios);
  }

  function handleEditMode(formacion:any) {
    setIsEditMode(true);
    setIdNivelEstudios(formacion.idNivelEstudios);
    handleSetIdNivelEstudios(formacion.idNivelEstudios);
    setIdAreaEstudio(formacion.idAreaEstudios);
    setTitulo(
      formacion.idNivelEstudios === NIVEL_CERTIFICACIONES
        ? formacion.certificacion
        : formacion.titulo
    );
    setEsFormacionActual(formacion.esFormacionActual);
    setFechaInicio(formacion.fechaInicio ? formacion.fechaInicio : null);
    setFechaFin(formacion.fechaFin ? formacion.fechaFin : null);
    setTieneValidez(formacion.tieneFechaValidez);
    setFechaFinValidez(
      formacion.fechaFinValidez
        ? formatDateInput(formacion.fechaFinValidez)
        : null
    );
    setCentro(formacion.centro);
  }

  function validateStartDate() {
    const auxTime = new Date(fechaInicio);
    if (fechaInicio && !isNaN(auxTime.getTime()) && auxTime < new Date()) {
      return true;
    } else {
      return false;
    }
  }

  function validateEndDate() {
    if (fechaInicio && !esFormacionActual) {
      const auxTime = new Date(fechaInicio);
      const auxTime2 = new Date(fechaFin);
      if (
        validateStartDate() &&
        !isNaN(auxTime2.getTime()) &&
        auxTime2 >= auxTime &&
        auxTime2 < new Date()
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function validateTitle() {
    if (isTitleNeeded) {
      if (titulo) {
        if (typeof titulo === "object") {
          return true;
        } else {
          return titulo.replace(/\s/g, "") !== "";
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function validateNewFormation(isGoBack:boolean) {
    if (
      !idNivelEstudios ||
      idNivelEstudios === "" ||
      !validateTitle() ||
      (isAreaNeeded && (!idAreaEstudio || idAreaEstudio === "")) ||
      !validateStartDate() ||
      !validateEndDate() ||
      (tieneValidez && !fechaFinValidez)
    ) {
      setSaveWrong(true);
      showSnack(t("common.data_error"),SnackSeverity.ERROR);
    } else {
 
      isEditMode ? updateNewFormation(isGoBack) : addNewFormation(isGoBack);
    }
  }

  function emptyForm() {
    setIsEditMode(false);
    setIdNivelEstudios(null);
    setIdAreaEstudio(null);
    setTitulo("");
    setCentro(null);
    setEsFormacionActual(true);
    setFechaInicio(null);
    setFechaFin(null);
    setTieneValidez(false);
    setFechaFinValidez(null);
    setIsAreaNeeded(true);
    setIsTitleNeeded(true);
    setIsCertificate(false);
  }

  const newData = () => {
    const nuevoCertificado = isCertificate
      ? typeof titulo === "object"
        ? { id: titulo.id, nombre: titulo.nombre }
        : { id: 0, nombre: titulo }
      : null;
    return {
      idNivelEstudios,
      idAreaEstudios: isAreaNeeded ? idAreaEstudio : null,
      titulo: isTitleNeeded && !isCertificate ? titulo : "",
      esFormacionActual,
      fechaInicio,
      fechaFin: !esFormacionActual ? fechaFin : null,
      tieneValidez,
      fechaFinValidez: tieneValidez ? fechaFinValidez : null,
      certificacion: nuevoCertificado,
      centro,
    };
  };

 

  const addNewFormation = (isGoBack:boolean) => {
    const data = newData();
    setLoading(true);
    callApi(addFormation(data,returnLangId(i18next.languages[0] as keyof typeof languages))).then(() => {
      if (isGoBack) {
        navigate(Routes.CURRICULUM);
      } else {
        setLoading(false);
        showSnack(t("add_formation.formation_added"),SnackSeverity.SUCCESS);
        emptyForm();
        setFechaInicio(null);
      }
    }).catch((response) => {
      setLoading(false);
      showSnack(response.message?response.message:t("add_formation.adding_error"),SnackSeverity.ERROR);
    });
  };


  const updateNewFormation = (isGoBack:boolean) => {
    const data = newData();
    setLoading(true);
    callApi(updateFormation(idTraining,data,returnLangId(i18next.languages[0] as keyof typeof languages))).then(() => {
      if (isGoBack) {
        navigate(Routes.CURRICULUM);
      } else {
        setLoading(false);
        showSnack(t("add_formation.formation_added"),SnackSeverity.SUCCESS);
        emptyForm();
        setFechaInicio(null);
      }
    }).catch((response) => {
      setLoading(false);
      showSnack(response.message?response.message:t("add_formation.update_error"),SnackSeverity.ERROR);
    });
  };

  const deleteForm = useCallback(() => {
    setLoading(true);
    callApi(deleteFormation(idTraining,returnLangId(i18next.languages[0] as keyof typeof languages))).then(() => {
      navigate(Routes.CURRICULUM);
    }).catch((response) => {
      setLoading(false);
      showSnack(response.message?response.message:t("add_formation.delete_error"),SnackSeverity.ERROR);
    });
  }, []);

  function renderBasicData() {
    return (
      <Box sx={styles.render_basic_data_style}>
        <Box sx={styles.data_section_icon_and_title}>
          <YourFormation />
          <Box sx={styles.data_section_title_principal}>
            {isEditMode
              ? t("add_formation.title_edit")
              : t("add_formation.title")}
         </Box>
       </Box>
        <Box sx={styles.basic_data_grid}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={idNivelEstudios}
              setter={handleSetIdNivelEstudios}
              options={niveles_de_estudio}
              label={t("add_formation.studies_level")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              useDefaultIcon
              getLabel={(x:any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? niveles_de_estudio.find((s:any) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel
              wrong={saveWrong && !idNivelEstudios}
            />
          </FormControl>
          {isAreaNeeded && (
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                useDefaultIcon
                value={idAreaEstudio}
                setter={(value:any) => {
                  setIdAreaEstudio(value ? Number(value.id) : null);
                }}
                options={areas_estudio.filter((a:any) => Number(a.id) !== 0)}
                label={t("add_formation.studies_area")}
                getOption={(x:any, v:any) => optionEquality(x, v)}
                getLabel={(x:any) =>
                  typeof x === "object" && x !== null
                    ? x.name
                    : x
                    ? areas_estudio.find((s:any) => Number(s.id) === Number(x))
                        ?.name ?? ""
                    : ""
                }
                moveLabel
                wrong={saveWrong && !idAreaEstudio}
              />
            </FormControl>
          )}
          {isTitleNeeded &&
            (isCertificate ? (
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  useDefaultIcon
                  value={titulo}
                  setter={setTitulo}
                  freeSolo
                  options={certificados || []}
                  label={t("add_formation.studies_title")}
                  getOption={(x:any, v:any) => optionEquality(x, v)}
                  getLabel={(x:any) => x.nombre || x}
                  moveLabel
                  wrong={
                    saveWrong &&
                    (!titulo ||
                      (!titulo.nombre && titulo.replace(/\s/g, "") === ""))
                  }
                />
              </FormControl>
            ) : (
              <InputWithLabel
                fullWidth
                label={t("add_formation.studies_title")}
                value={titulo}
                setter={(e:any) => setTitulo(e.target.value)}
                moveLabel
                maxLength={150}
                wrong={
                  saveWrong && (!titulo || titulo.replace(/\s/g, "") === "")
                }
              />
            ))}
          <InputWithLabel
            fullWidth
            label={t("add_formation.studies_center")}
            value={centro}
            setter={(e:any) => setCentro(e.target.value)}
            moveLabel
            maxLength={150}
            wrong={saveWrong && (!centro || centro.replace(/\s/g, "") === "")}
          />
       </Box>
     </Box>
    );
  }

  function renderDeleteLink() {
    return (
      <Box sx={styles.delete_row}>
        <Box
          onClick={() => setIsDialogOpen(true)}
          sx={styles.delete_link}
        >
          {t("add_formation.delete")}
       </Box>
     </Box>
    );
  }

  function renderStudiesDates() {
    return (
      <Box sx={styles.dates_studies_grid}>
        <Box sx={styles.text_switch}>
          <Box>{t("add_formation.currently_studying")}</Box>
          <AntSwitch
            checked={esFormacionActual}
            setter={() => setEsFormacionActual(!esFormacionActual)}
          />
       </Box>
        <Box sx={styles.date_start}>
          <DatePickerWithLabel
            label={t("add_formation.start_date")}
            value={fechaInicio}
            setter={setFechaInicio}
            minDate={minDate}
            maxDate={new Date()}
            wrong={saveWrong && !fechaInicio}
          />
       </Box>
        {!esFormacionActual && (
          <Box sx={styles.date_end}>
            <DatePickerWithLabel
              label={t("add_formation.end_date")}
              value={fechaFin}
              setter={(value:any) => setFechaFin(value)}
              minDate={fechaInicio ? fechaInicio : minDate}
              maxDate={new Date()}
              wrong={saveWrong && !fechaFin}
            />
         </Box>
        )}
     </Box>
    );
  }

  function renderCertificateValidDate() {
    return (
      <Box sx={styles.basic_data_grid}>
        <Box sx={styles.text_switch}>
          <Box>{t("add_formation.has_validation_date")}</Box>
          <AntSwitch
            checked={tieneValidez}
            setter={() => setTieneValidez(!tieneValidez)}
          />
       </Box>
        <Box style={{ width: "100%" }}>
          <DatePickerWithLabel
            label={t("add_formation.valid_until")}
            value={tieneValidez ? fechaFinValidez : null}
            setter={(value:any) => setFechaFinValidez(value)}
            minDate={minDate}
            maxDate={maxDate}
            wrong={tieneValidez && !fechaFinValidez}
          />
       </Box>
     </Box>
    );
  }

  return (
    <Box>
      <Box sx={styles.principal_container}>
        {!matchesMobile ? (
          <TuCurriculumHeader
            nombre={nombre}
            primerApellido={primerApellido}
            segundoApellido={segundoApellido}
            correoElectronico={correoElectronico}
            prefijo={prefijo}
            telefono={telefono}
            fechaModificacion={fechaModificacion}
            idPhoto={idFotografia}
          />
        ) : (
          <Box />
        )}
        <Box sx={styles.col2}>
          <Box sx={styles.data_sections_container}>
            {renderBasicData()}
            {renderStudiesDates()}
            {isCertificate && renderCertificateValidDate()}
         </Box>
          <AddToCVActionButtons
            saveAndActionText={t("add_formation.save_and_add")}
            onSaveClick={() => validateNewFormation(true)}
            onSaveAndActionClick={() => validateNewFormation(false)}
            onCancelClick={() => {
             navigate(Routes.CURRICULUM);
            }}
            isUpdate={isEditMode}
          />
          {isEditMode && renderDeleteLink()}
       </Box>
     </Box>
      <AlertDialog
        title={t("add_formation.delete_confirm")}
        open={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        handleAccept={() => {
          deleteForm();
          setIsDialogOpen(false);
        }}
      />
  </Box>
  );
}

export default Formacion;
