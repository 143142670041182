import { ThemeProvider } from '@mui/material';
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CVRetorna from './shared/PortalCandidato/components/CV/CV';
import DatosBasicos from './shared/PortalCandidato/components/DatosBasicos/DatosBasicos';
import DatosPersonalesRetorna from './shared/PortalCandidato/components/DatosPersonales/DatosPersonales';
import OfertaRetorna from './shared/PortalCandidato/components/Oferta/Oferta';
import WorkExperience from './shared/PortalCandidato/components/CV/workExperienceCV/workExperienceCV';
import SearchOffers from './shared/PortalCandidato/components/Busqueda/searchOffers';
import CandidateDashboard from './shared/PortalCandidato/components/Dashboard/candidateDashboard';
import Formacion from './shared/PortalCandidato/components/CV/training/formacion';
import Idiomas from './shared/PortalCandidato/components/CV/languages/idiomas';
import OtrosDatos from './shared/PortalCandidato/components/CV/otherData/otrosDatos';
import CvGenerator from './shared/PortalCandidato/components/CvGenerator/cvGenerator';
import theme from './theme';
import ContextProvider from './shared/contexts/ContextProvider';
import Layout from './shared/PortalCandidato/components/shared/Layout/Layout';
import PortalCandidatoHome from './shared/PortalCandidato/PortalCandidatoHome';
import ResetPassword from './components/ResetPassword';
import { Retorna } from './components/Portales/Retorna';
import Empresas from './shared/PortalCandidato/components/Empresas/Empresas';
import PoliticasPrivacidad from './shared/PortalCandidato/components/PoliticasPrivacidad/PoliticasPrivacidad';
import AvisoLegal from './shared/PortalCandidato/components/AvisoLegal/AvisoLegal';
import CondicionesUso from './shared/PortalCandidato/components/CondicionesUso/CondicionesUso';
import Documentos from './shared/PortalCandidato/components/Documentos/Documentos';
import Register from './shared/PortalCandidato/components/Register/Register';
import Login from './shared/PortalCandidato/components/Login/Login';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
          <Routes>
          <Route path="/generadorCv" element={<CvGenerator />} />
            <Route path="/Retorna" element={<Layout programa={Retorna.main}/>}>
              <Route path="/Retorna/Login" element={<Login />} />  
              <Route path="/Retorna/Register" element={<Register />} />  
              <Route path="/Retorna/Dashboard" element={<CandidateDashboard />} />  
              <Route path="/Retorna/CV" element={<CVRetorna />} />
              <Route path="/Retorna/Busqueda" element={<SearchOffers programa={Retorna.main} />} />
              {/* <Route path="/Retorna/Candidaturas" element={<Candidaturas />} /> */}
              <Route path="/Retorna/DatosPersonales" element={<DatosPersonalesRetorna />} />
              <Route path="/Retorna/oferta" element={<OfertaRetorna />} />
              <Route path="/Retorna/work_experience" element={<WorkExperience />} />
              <Route path="/Retorna/work_experience" element={<WorkExperience />} />
              <Route path="/Retorna/idioma" element={<Idiomas />} />
              <Route path="/Retorna/Documentos" element={<Documentos />} />
              <Route path="/Retorna/otros_datos" element={<OtrosDatos />} />
              <Route path="/Retorna/formacion" element={<Formacion />} />
              <Route path="/Retorna/Empresas" element={<Empresas />} />
              <Route path="/Retorna" element={<PortalCandidatoHome programa={Retorna.main}/>} />
            </Route>  
            <Route path="/politica-de-privacidad/" element={<Layout programa={Retorna.main}/>}>
              <Route path="/politica-de-privacidad/" element={<PoliticasPrivacidad />} />
            </Route>
            <Route path="/aviso-legal/" element={<Layout programa={Retorna.main}/>}>
              <Route path="/aviso-legal/" element={<AvisoLegal />} />
            </Route>
            <Route path="/condiciones-de-uso/" element={<Layout programa={Retorna.main}/>}>
              <Route path="/condiciones-de-uso/" element={<CondicionesUso/>} />
            </Route>
            <Route path="/DatosBasicos" element={<Layout programa={Retorna.main}/>}>
              <Route path="/DatosBasicos" element={<DatosBasicos />} />
            </Route>
            <Route
                  path="/reset_password"
                  element={<ResetPassword />}
                />
            <Route path="/" element={<Navigate to="/Retorna" />} />
            <Route path="*" element={<Navigate to="/Retorna" />} />
          </Routes>
      </ContextProvider>
    </ThemeProvider>
  );
}

export default App;
