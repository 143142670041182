const styles = {
    body_container: {
        display: {xs: "grid", md: "flex"},
        justifyContent: "center",
        padding: {xs: "unset", md: "0px 15%"},
        gridArea: {xs: "body_container", md: "unset"},
        gridTemplateColumns: {xs: "1fr", md: "unset"},
        gridTemplateRows: {xs: "auto auto auto", md: "unset"},
        gridTemplateAreas: {xs: "'topFilters' 'bodyRight' 'bodyLeft'", md: "unset"},
        paddingTop: {xs: "40px", md: "unset"}
      },
      body_left_col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gridArea: "bodyLeft",
        maxWidth: {md:"275px"},
        minWidth: {md:"275px"}
      },
      body_right_col: {
        display: "flex",
        flex: 1,
        gridArea: "bodyRight",
      },
      body_right_col_container: {
        width: "100%",
        padding: "20px",
        marginBottom:"30px",
        marginLeft:{md:"60px"}
      },
      buttonFullWidth: {
        width: "100%",
        color: "#FFFFFF",
        height: "36px",
        marginTop: "31px",
        textTransform: "none",
        fontWeight: "bold",
      },
      filters_container: {
        backgroundColor: "#FBFBFB",
        padding: "0px 20px",
        gridArea: {xs: "topFilters", md: "unset"}
      },
      filter_delete: {
        textDecoration: "underline",
        cursor:"pointer"
      },
      filter_footer_button: {
        display: "inline-block",
        width: "50%",
      },
      filter_separator: {
        marginTop: "25px",
      },
      list: {
        width: "250px",
      },
      fullList: {
        width: "auto",
        height: "100vh",
      },
      modify_filter: {
        fontSize:"19px",
        paddingLeft: "8px",
      },
      modify_filter_button: {
        cursor: "inherit",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none",
        },
      },
      no_margin: {
        margin: "0px",
      },
      offer: {
        borderBottom: "1px solid #DDDDDD",
        paddingTop: "6px",
        cursor: "pointer",
      },
      offers_list: {
        paddingLeft: "0px",
        marginTop: "-16px",
        "list-style-type": "none",
      },
      offer_subtitle: {
        marginTop: "0px",
      },
      offers_showed: {
        color: "#DA291C",
        paddingBottom: "16px",
        display: "flex",
        marginRight: "6px",
        justifyContent: "flex-end"
      },
      date_offer: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        lineHeight: "2px",
        marginTop: "10px",
        fontSize: "16px",
        paddingRight: "6px",
        marginBottom: "5px"
      },
      title_desc: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start"
      },
      rotate_icon: {
        "& svg": {
          transform: "rotate(180deg)",
        },
      },
      save_search: {
        float: "right",
        marginTop: "7px",
      },
      searcher: {
        padding: "10px 20px 40px 20px",
      },
      search_container: {
        paddingBottom:{xs:"150px"},
        marginTop:{xs:"50px"}
      },
      seeMoreButton_container: {
        "&:hover, &:focus": {
          cursor: "pointer",
        },
        marginLeft: "8px",
      },
      seeMoreButton_icon: {
        display: "inline-block",
        marginRight: "10px",
        height: "24px",
      },
      seeMoreButton_text: {
        display: "inline-block",
        textDecoration: "none",
        "&:hover, &:link, &:active, &:visited": {
          textDecoration: "none",
        },
      },
      show_offers_button: {
        width: "95%",
        marginLeft: "5px",
        marginBottom: "25px",
      },
      save_filters_button: {
        width: "95%",
        marginLeft: "5px",
        marginBottom: "15px",
        marginTop: "0px",
      },
      snackBar: {
        backgroundColor: "#FFFFFF",
        color: "#000000",
      },
      summary: {
        marginBottom: "16px",
        padding: "10px",
      },
      text_float_right: {
        float: "right",
      },
      date: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        lineHeight: "2px",
        marginTop: "10px",
        fontSize: "16px",
        paddingRight: "6px",
        marginBottom: "5px"
      },
      autocomplete_input: {
        padding: "0!important",
      },
      input: {
        width: "100%",
      },
      search_button: {
        width: "100%",
        height: "36px",
        textTransform: "none",
        fontWeight: "bold",
        margin: "16px 0",
        backgroundColor: "#DA291C",
        borderRadius: "4px",
        fontSize: "16px",
      },
      search_separator: {
        marginTop: "15px",
        marginBottom: "4px",
        fontSize: {xs: "16px", md: "22px"},
        lineHeight: {xs: "21px", md: "30px"},
        fontWeight: "900",
        width: "100%",
        marginLeft:{xs:"7px"}
      },
      searcher2: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      },
      formContainer: {
        display: "grid",
        width: "100%",
        gridTemplateColumns: {xs: "1fr", md: ".35fr .35fr .30fr"},
        gridAutoRows: {xs: "min-content", md: "unset"},
        rowGap: {xs: "24px", md: "unset"},
        columnGap: "24px",
        justifyContent: "end",
        alignItems: "end",
      },
      formContainerColumn: {
        display: "grid",
        width: "100%",
        gridTemplateColumns: "1fr",
        gridAutoRows: "min-content",
        rowGap: "12px",
        justifyContent: "end",
        alignItems: "end",
      },
      marginIcon:{
        marginBottom:"7px"
      }
};

export default styles;
