import React from "react";
import { useTranslation } from "react-i18next";

function Trash(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.delete")} = props;

    return(
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-298.000000, -1787.000000)" fill="#000000" fillRule="nonzero">
                    <g id="trash-copy-3" transform="translate(298.000000, 1787.000000)">
                        <path d="M14,6 L14,14 C14,15.1 13.1,16 12,16 L12,16 L4,16 C2.9,16 2,15.1 2,14 L2,14 L2,6 L14,6 Z M11,0 C11.6,0 12,0.4 12,1 L12,1 L12,3 L16,3 L16,5 L0,5 L0,3 L4,3 L4,1 C4,0.4 4.4,0 5,0 L5,0 Z M10,2 L6,2 L6,3 L10,3 L10,2 Z" id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
  );
}

export default Trash;