export enum Routes {
  HOME = "/",  
  RETORNA = "/Retorna",
  DASHBOARD = "/Retorna/Dashboard",
  BASIC_DATA = "/DatosBasicos",
  SEARCH_OFFERS = "/Retorna/Busqueda",
  DOCUMENTS = "/Retorna/Documentos",
  CANDIDATURAS = "/Retorna/Candidaturas",
  DATOS_PERSONALES = "/Retorna/DatosPersonales",
  CURRICULUM = "/Retorna/CV",
  DATOS_CONTRATACION = "/Retorna/datos_contratacion",
  MY_DOCUMENTS = "/my_documents",
  NORTEMPO = "https://nortempo.com/",
  EMPLEO = "https://nortempo.com/empleo/",
  EMPRESAS = "https://nortempo.com/empresas/",
  NOSOTROS = "https://nortempo.com/nosotros/",
  BLOG = "https://nortempo.com/blog/",
  FORM = "https://nortempo.com/contacto/#formulario-candidatos",
  LINKEDIN_COMPANY_NORTEMPO = "https://www.linkedin.com/company/nortempo/",
  LINKEDIN_NORTEMPO = "https://www.facebook.com/nortempo",
  LINKEDIN_FUNDACION = "https://www.linkedin.com/company/fundacionortempo/",
  INSTAGRAM_NORTEMPO = "https://www.instagram.com/nortempo/",
  YOUTUBE_NORTEMPO = "https://www.youtube.com/channel/UCwEQv4DPLoj159TL_u1hr0w",
  AVISO_LEGAL = "/aviso-legal/",
  POLITICA_PRIVACIDAD = "https://nortempo.com/politica-de-privacidad/",
  CONDICIONES_USO = "/condiciones-de-uso",
  RETORNA_XUNTA = "https://xempre.gal/programa-retorna-cualifica-emprego/",
  OFERTA = "/Retorna/oferta",
  WORK_EXPERIENCE ="/Retorna/work_experience",
  TRAINING = "/Retorna/formacion",
  LANGUAGE = "/Retorna/idioma",
  OTHER_DATA ="/Retorna/otros_datos",
  GENERADOR_CV="/generadorCv",
  EMPRESAS_RETORNA="/Retorna/Empresas",
  EMPRESAS_VIDEO="https://www.youtube.com/watch?v=cI0DBNX0mQc&t=3s",
  FUNDACION_NORTEMPO = "https://nortempo.com/fundacion/",
  FUNDACION_NORTEMPO_GAL = "https://nortempo.com/fundacion-2/",
  PRIVACY_POLICY = "/politica-de-privacidad",
}

export enum Programas {
  IBEREMPLEO = 0,
  RETORNA = 1,
}

export enum MenuAction {
  OPEN = "open",
  CLOSE = "close",
}

export enum StatusOffer {
  OFFER_STATE_PUBLISH = 2
}

export enum Countries {
  SPAIN = 724
}

export const perfilesDigitalesEnum = {
  LINKEDIN : 1,
  FACEBOOK : 2,
  INSTAGRAM : 3, 
  TWITTER : 4, 
  BLOG : 5, 
  PORTFOLIO : 6,
  OTRO : 7
};

//Busqueda por provincias
export const EXTRANJERO = "-1";
export const TODA_ESPANHA = "0";
export const indicadorCv = {
  EXPERIENCIA_LABORAL: 1,
  FORMACIONES: 2,
  CONOCIMIENTOS: 3,
  IDIOMAS: 4
};

export const tiposDocumentos = {
  INFORME_FASE_RESULTADOS: 1,
  INFORME_FASE_ENTREVISTA_TELEFONICA: 2,
  INFORME_FASE_DINAMICAS: 3,
  INFORME_PRUEBAS_TECNICAS: 4,
  INFORME_PRUEBAS_PSICOTECNICAS: 5,
  INFORMES_PRUEBAS_IDIOMAS: 6,
  INFORME_FASE_ENTREVISTA_PERSONAL: 7,
  INFORME_FASE_INFORME_CANDIDATO: 8,
  INFORME_FASE_PRESENTACION_CLIENTE: 9,
  INFORME_FASE_ENTREVISTA_CLIENTE: 10,
  INFORME_FASE_PRUEBAS_CLIENTE: 11,
  IDENTIFICACION: 12,
  CERTIFICADO_CUENTA_BANCARIA: 13,
  PERMISO_TRABAJO: 14,
  CERTIFICADO_ANTECEDENTES_PENALES: 15,
  FOTOGRAFIA: 16,
  CERTIFICADO_DISCAPACIDAD: 17,
  CURRICULUM_VITAE: 18,
  TITULO_FORMACION_REGLADA: 19,
  CARNE_CONDUCIR: 20
};
