import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  a: {
    fill: "#979797",
  },
}));

function DeleteChip(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClick, title=t("icons_tooltips.delete") } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" onClick={onClick} width="18" height="18" viewBox="0 0 18 18">
            <title>{title}</title>
      <path className={classes.a} d="M328.221,228.847a9,9,0,1,0,0,12.728A9,9,0,0,0,328.221,228.847ZM324.4,239.03l-2.546-2.546-2.545,2.546-1.273-1.273,2.546-2.546-2.546-2.545,1.273-1.273,2.545,2.545,2.546-2.545,1.273,1.273-2.546,2.545,2.546,2.546Z" transform="translate(-312.858 -226.211)" />
    </svg>
  );
}

export default DeleteChip;
