import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import { AuthService } from '../../auth/AuthService';

function Register() {
    const auth = new AuthService();

    useEffect(() => sendToRegister(), []);

    function sendToRegister() {
        auth.register();
    }

    return (<Box></Box>);
}

export default Register;