import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18next from "../../../../i18n.js";
import {
  useTheme, useMediaQuery, Box,
  Link,
  Icon,
  Button,
  Drawer,
  CircularProgress
} from "@mui/material";

import PriorityLow from "../../../../assets/icons/priorityLow";
import Tune from "../../../../assets/icons/tune";
import OffersSearcher from "./offersSearcher";
import { getProvincias } from "../../../../shared/utils/functions";
import AutocompleteWithChips from "../../../../shared/components/Inputs/autocompleteWithChips";
import { Routes, TODA_ESPANHA } from "../../../../shared/enums/enums";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./styles";
import { getToken, useApi } from "../../../../shared/hooks/useApi";
import { getOffers } from "../../../../shared/hooks/userApi";
import { MaestroContext } from "../../../contexts/MaestroContext";



interface ISearchOffers {
  programa: any,
}

function SearchOffers({programa}: ISearchOffers) {
  const { t } = useTranslation();
  const callApi = useApi();

  const { paises, localidades , tipoHorario } = useContext(MaestroContext);

  const [puestoBusqueda, setPuestoBusqueda] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [provinciaBusqueda, setProvinciaBusqueda] = useState<any>(null);
  const [allOffers, setAllOffers] = useState<any>([]);
  const [filteredOffers, setFilteredOffers] = useState<any>([]);
  const [areAllFilteredOffersVisisbles, setAreAllFilteredOffersVisisbles] =
    useState<any>(false);
  const [localidadesOfertas, setLocalidadesOfertas] = useState<any>([]);
  const [localidadesOfertasFiltradas, setLocalidadesOfertasFiltradas] =
    useState<any>([]);
  const [offersJobs, setOffersJobs] = useState<any>([]);
  const [isFilterVisible, setIsFilterVisible] = useState<any>(true);
  const [provinciasFiltro, setProvinciasFiltro] = useState<any>([]);
  const [localidadesFiltro, setLocalidadesFiltro] = useState<any>([]);
  const [ciudadesFiltro, setCiudadesFiltro] = useState<any>([]);
  const [localidadAutoComplete, setLocalidadAutoComplete] = useState<any>(null);
  const [puestosFiltro, setPuestosFiltro] = useState<any>([]);
  const [sectoresFiltro, setSectoresFiltro] = useState<any>([]);
  const [tiposJornadaFiltro, setTiposJornadaFiltro] = useState<any>([]);
  const [tiposContratoFiltro, setTiposContratoFiltro] = useState<any>([]);
  const [previousFilters, setPreviousFilters] = useState<any>({});
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<any>(false);
/*   const [allSectors, setAllSectors] = useState<any>([]); */
  const [searchParams] = useSearchParams();

  const provincesAreTodaEspana = { id: TODA_ESPANHA, name: programa?.titleAllOffersGalicia ? t(programa?.titleAllOffersGalicia) : t("offers.allProvinces") };

  const theme = useTheme();
  const navigate = useNavigate();

  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));

  const prov = searchParams.get("provincia");
  const palabraUrlClave = searchParams.get("palabrasClave");

  const provincias = getProvincias(i18next.languages[0]);

  useEffect(() => {
    handleSearch();
    getUser();
  }, []);

  const handleSearch = useCallback(() => {
   const getWordOrPosition = palabraUrlClave?? prov?.split("puesto=")[1];
    handleSearchOffers(prov?.split("?")[0], getWordOrPosition);
  }, []);
    
  const getUser = useCallback(async () => {
    const data = await getToken();
    setIsUserAuthenticated(!!data);
  }, []);

  useEffect(() => {
    if (matchesMobile) {
      setIsFilterVisible(false);
    }
  }, [matchesMobile]);

  useEffect(() => {
    if (provinciasFiltro.length > 0) {
      const newLocalidadesFiltro = localidadesOfertas.filter((localidad: any) =>
        provinciasFiltro.find((p: any) => {
          return Number(p.id) === localidad.provincia;
        })
      );
      setLocalidadesOfertasFiltradas(newLocalidadesFiltro);
      setLocalidadesFiltro(
        localidadesFiltro.filter((localidad: any) =>
          provinciasFiltro.find((p: any) => {
            return (
              Number(p.id) === Number(localidad.id_province) ||
              Number(p.id) === Number(localidad.provincia)
            );
          })
        )
      );
      if (!newLocalidadesFiltro.includes(localidadAutoComplete)) {
        setLocalidadAutoComplete(null);
      }
    } else {
      setLocalidadesOfertasFiltradas(localidadesOfertas);
    }
  }, [provinciasFiltro]);

  function getProvinciaBusquedaFromUrl(provincia: any) {
    switch (provincia) {
      case TODA_ESPANHA:
        return provincesAreTodaEspana;
      default:
        return getProvinceById(Number(provincia));
    }
  }

  function getProvinceById(id: any) {
    return provincias.find((prov: any) => Number(prov.id) === id);
  }

  
  const getNombreLocalidad = (idLocalidad: any) =>
    localidades.find(
      (localidad: any) => Number(localidad?.id) === Number(idLocalidad)
    )?.name ?? "";

  const getProvinciaLocalidad = (idLocalidad: any) => {
    return Number(
      localidades.find(
        (localidad: any) => Number(localidad?.id) === Number(idLocalidad)
      )?.id_province
    ) ?? 0;
  };
   
  const getNombreTipoHorario = (idTipoHorario: any) =>
    tipoHorario.find(
      (tipoHorario: any) => Number(tipoHorario.id) === Number(idTipoHorario)
    )?.name ?? "";
  const getFormattedDate = (date: any) => {
    const d = date.split("T")[0].split("-");
    return `${d[2]}/${d[1]}/${d[0]}`;
  };

  const getNumberOfOffersByProvince =
    (provinciaId: any) =>
      allOffers.filter((off: any) => off.provincia?.id === Number(provinciaId)).length;
  const existsOfferInProvince =
    (provinciaId: any) =>
      allOffers.some((offer: any) => offer.provincia?.id === Number(provinciaId));

  const getOffersProvinces = () =>
    provincias.filter((prov: any) =>
      existsOfferInProvince(prov?.id)
    );

  const filterOffers = () => {
    const filterCities = ciudadesFiltro.map((c: any) => c.name.trim().slice(0, -3).trim());

    setFilteredOffers(
      allOffers.filter(
        (offer: any) =>
          (provinciasFiltro.length === 0 ||
            provinciasFiltro
              .map((province: any) => province.id)
              .includes(offer.provincia?.id.toString())) &&
          (localidadesFiltro.length === 0 ||
            localidadesFiltro
              .map((loc: any) => Number(loc.id))
              .includes(offer.localidad?.id)) &&
          (ciudadesFiltro.length === 0 ||
            filterCities
              .includes(offer.ciudad)) &&
          (puestosFiltro.length === 0 ||
            puestosFiltro.map((job: any) => job.id).includes(offer.puesto.id)) &&
          (sectoresFiltro.length === 0 ||
            sectoresFiltro
              .map((sector: any) => sector.id)
              .includes(offer.sector.idSector)) &&
          (tiposJornadaFiltro.length === 0 ||
            tiposJornadaFiltro
              .map((tipos: any) => tipos.id)
              .includes(offer.tipoHorario.id.toString())) &&
          (tiposContratoFiltro.length === 0 ||
            tiposContratoFiltro
              .map((tipos: any) => tipos.id)
              .includes(offer.idTipoContrato.toString()))
      )
    );
  };

  const setInitialFilters = () => {
    setProvinciasFiltro([]);
    setSectoresFiltro([]);
    setTiposJornadaFiltro([]);
    setTiposContratoFiltro([]);
  };

  function handleGetCities(result: any) {
    const cities = result
      .map((offer: any, index: any) => ({
        id: index,
        idPais: offer.idPais,
        name: offer.ciudad
      }))
      .filter((value: any, index: any, self: any) => index === self.findIndex((t: any) => (
        t.name === value.name && value.name !== null)));


    const mappedCities = cities.map((value: any) => ({
      id: value.id,
      name: `${value.name} (${result.filter((offerResult: any) => offerResult.ciudad === value.name && offerResult.ciudad !== null)
        .length
        })`,
      idPais: value.idPais
    }));
    return mappedCities;
  }

  async function handleSearchOffers(prov: any, puest: any) {

    let query = "";
    setProvinciaBusqueda(getProvinciaBusquedaFromUrl(prov));
    setPuestoBusqueda(puest);
    if (prov) {
      query = query + `&provincia=${prov}`;
    }
    if (puest) {
      const keyWords = puest.split(" ");
      keyWords.forEach((k: string) => query = query + `&palabrasClave=${k}`);
    }
    setLoading(true);
    callApi(getOffers(true, query)).then((data) => {
      (matchesMobile) && setIsFilterVisible(false);
      const reversed = data;
      setAllOffers(reversed);
      setFilteredOffers(reversed);
      handleGetCities(data);
      const localidadesOfertas = data
        .map((offer: any) => (offer.localidad) && offer.localidad?.id)
        .filter((value: any, index: any, self: any) => (value) && self.indexOf(value) === index);

        
      const mappedLocalidadesOfertas = localidadesOfertas.map((localidad: any) => ({
        id: localidad,
        name: `${getNombreLocalidad(localidad)} (${data.filter((offerResult: any) => offerResult.localidad?.id === localidad)
            .length
          })`,
        provincia: getProvinciaLocalidad(localidad),
      }));

      setLocalidadesOfertas(mappedLocalidadesOfertas);
      setLocalidadesOfertasFiltradas(mappedLocalidadesOfertas);

      const puestosOfertas = data
        .map((offer: any) => offer.puesto)
        .filter(
          (value: any, index: any, self: any) =>
            self.map((job: any) => job.id).indexOf(value.id) === index && value.id !== 743
        ).sort((a: any, b: any) => a.descripcion?.localeCompare(b.descripcion));
      setOffersJobs(puestosOfertas);
      setPuestosFiltro([]);
      setLocalidadesFiltro([]);
      setCiudadesFiltro([]);
      setInitialFilters();
      setLoading(false);
    });
    
  }
  
  const handleOfferClick = (codigo: any) => {
    navigate(Routes.OFERTA + `?codigo=${codigo}`);
  };

  function getForeignSearchProvince() {
    return programa?.titleAllOffersGalicia ? t(programa?.titleAllOffersGalicia) : t("offers.allProvinces");
  }

  function summary() {
    return (
      <Box
        display="inline-block"
        fontSize={30}
        lineHeight={"38px"}
        fontWeight="900"
        mb={"8px"}
        style={{marginLeft:"10px",paddingTop:matchesMobile ? "15px" : "40px"}}
      >
        {t("offers.offers_mayus")}{" "}
        {puestoBusqueda === "" || !puestoBusqueda ? "" : t("offers.of")}{" "}
        {puestoBusqueda} {t("offers.in")}{" "}
        {provinciaBusqueda
          ? provinciaBusqueda.name
          : getForeignSearchProvince()}
      </Box>
    );
  }

  function noOffers() {
    return (
      <Box display="inline-block" fontSize={30} fontWeight="900" mb={"8px"}>
        {t("offers.noOffers")}
      </Box>
    );
  }

  function offers() {
    return (
      <>
        <Box sx={styles.offers_showed} style={{ color: isUserAuthenticated ? "#DA291C" : "#007BC3"}}>
          {`${t("offers.showed")} ${filteredOffers.length} ${t(
            "offers.offers"
          )}`}
        </Box>
        <Box sx={styles.offers_list}>
          {filteredOffers
            .slice(
              0,
              areAllFilteredOffersVisisbles ? filteredOffers.length : 20
            )
            .map((offer: any) => (
              <li key={offer.idOferta}>
                <Box sx={styles.offer}>
                  <Box
                    onClick={() => {
                      handleOfferClick(offer.idOferta);
                    }}
                  >
                    <Box
                      sx={styles.date_offer as object}
                    >
                      {getFormattedDate(offer.fechaPublicacion)}
                    </Box>
                    <Box sx={styles.title_desc}>
                      <Box
                        display="inline-block"
                        fontSize={"22px"}
                        fontWeight="900"
                        mb={"8px"}
                      >
                        {offer.titulo}
                      </Box>
                      <Box
                        fontSize="subtitle1.fontSize"
                        mb={"8px"}
                        sx={styles.offer_subtitle}
                      >
                        {offer.comunidadAutonoma ? offer.comunidadAutonoma?.descripcion : getNombreLocalidad(offer.localidad?.id)} ·{" "}
                        {getNombreTipoHorario(offer.tipoHorario.id)}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </li>
            ))}
        </Box>
      </>
    );
  }

  function seeMoreButton(arrowVisibilty: any, iconFloatingRight: any, handleClick: any) {
    let stylesMore = { ...styles.seeMoreButton_icon };
    if (arrowVisibilty) {
      stylesMore = { ...stylesMore, ...styles.rotate_icon };
    }
    return (
      <Box
        sx={styles.seeMoreButton_container}
        onClick={handleClick}
      >
        <Box
          sx={{ ...stylesMore }}
        >
          <Box>
            <PriorityLow color={isUserAuthenticated ? "#DA291C" : "#007BC3"} title={t("icons_tooltips.expand")} />
          </Box>
        </Box>
        <Box sx={styles.seeMoreButton_text}>
          <Link component="button" style={{ color: isUserAuthenticated ? "#DA291C" : "#007BC3", textDecorationColor: isUserAuthenticated ? "#DA291C" : "#007BC3"}}>
            {arrowVisibilty ? t("offers.seeLess") : t("offers.seeMore")}
          </Link>
        </Box>
      </Box>
    );
  }

  function workdaysFilter() {
    const optionsHorario = tipoHorario
      .filter((horario: any) =>
        allOffers.some((offer: any) => offer.tipoHorario.id === Number(horario.id))
      )
      .map((tipoHorario: any) => ({
        ...tipoHorario,
        name: `${tipoHorario.name} (${allOffers.filter(
          (offer: any) => offer.tipoHorario.id === Number(tipoHorario.id)
        ).length
          })`,
      }))
      .sort((a: any, b: any) => parseInt(a.orden) - parseInt(b.orden));
    return (
      <>
        <Box
          sx={{ ...styles.filter_separator }}
        >
          <AutocompleteWithChips
            options={optionsHorario}
            values={tiposJornadaFiltro}
            label={t("offers.workdayType")}
            setter={setTiposJornadaFiltro}
            moveLabel
            maxLength={100}
            noAdd wrong={undefined} required={undefined} disabled={undefined} placeholder={undefined} setterOptions={undefined} onOpen={undefined} container={undefined} />
        </Box>
      </>
    );
  }

  function provincesFilter() {
    const optionsProvincias = getOffersProvinces().map((provincia: any) => ({
      id: provincia?.id,
      name: `${provincia.name !== "" ? provincia.name : paises.find(
        (pais: any) => Number(pais.id) === Number(provincia.id_country)
      )?.name} (${getNumberOfOffersByProvince(provincia?.id)
        })`,
      id_country: provincia.id_country,
      codigo_ine: provincia.codigo_ine,
    }));

    return (
      <Box
        sx={{ ...styles.filter_separator }}
      >
        <AutocompleteWithChips
          options={optionsProvincias}
          values={provinciasFiltro}
          label={t("offers.province")}
          setter={setProvinciasFiltro}
          moveLabel
          maxLength={100}
          noAdd wrong={undefined} required={undefined} disabled={undefined} placeholder={undefined} setterOptions={undefined} onOpen={undefined} container={undefined} />
      </Box>
    );
  }

  function locationsFilter() {
    const optionsLocalidades = localidadesOfertasFiltradas;
    return (
      <>
        <Box
          sx={{ ...styles.filter_separator }}
        >
          <AutocompleteWithChips
            options={optionsLocalidades}
            values={localidadesFiltro}
            label={t("offers.location")}
            setter={setLocalidadesFiltro}
            setterOptions={setLocalidadesOfertasFiltradas}
            moveLabel
            maxLength={100}
            noAdd wrong={undefined} required={undefined} disabled={undefined} placeholder={undefined} onOpen={undefined} container={undefined} />
        </Box>
      </>
    );
  }

  function puestosFilter() {
    return (
      <>
        <Box
          sx={{ ...styles.filter_separator }}
        >
          <AutocompleteWithChips
            options={offersJobs}
            values={puestosFiltro}
            label={t("offers.job")}
            setter={setPuestosFiltro}
            setterOptions={setOffersJobs}
            moveLabel
            maxLength={100}
            noAdd wrong={undefined} required={undefined} disabled={undefined} placeholder={undefined} onOpen={undefined} container={undefined} />
        </Box>
      </>
    );
  }

  function filters() {
    return (
      <Box sx={styles.filters_container}>
        {matchesMobile ? (
          <Box>
            <Box
              sx={styles.no_margin}
            >
              <Box
                style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-end"}}
                sx={styles.modify_filter_button as object}
                onClick={(event: any) => {
                  event.preventDefault();
                  setPreviousFilters({
                    provinces: provinciasFiltro,
                    locations: localidadesFiltro,
                    ciudad: ciudadesFiltro,
                    jobs: puestosFiltro,
                    sectors: sectoresFiltro,
                    workdayTypes: tiposJornadaFiltro,
                    contractTypes: tiposContratoFiltro,
                  });
                  setIsFilterVisible(!matchesMobile || !isFilterVisible);
                }}
              >
                <Icon  sx={styles.marginIcon}>
                  <Tune color={isUserAuthenticated ? "#DA291C" : "#007BC3"}/>
                </Icon>
                {provinciasFiltro.length > 0 ||
                  localidadesFiltro.length > 0 ||
                  puestosFiltro.length > 0 ||
                  sectoresFiltro.length > 0 ||
                  tiposJornadaFiltro.length > 0 ||
                  ciudadesFiltro.length > 0 ||
                  tiposContratoFiltro.length > 0 ? (
                  <Box sx={styles.modify_filter} style={{color:isUserAuthenticated ? "#DA291C" : "#007BC3"}}>
                    {t("offers.modifyFilter")}
                  </Box>
                ) : (
                  <Box sx={styles.modify_filter} style={{color:isUserAuthenticated ? "#DA291C" : "#007BC3"}}>
                    {t("offers.filter")}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ) : null}
        {isFilterVisible ? (
          <Box sx={{paddingTop: "1px"} as object}>
            {provincesFilter()}
            {locationsFilter()}
            {puestosFilter()}
            {/* {sectorsFilter()} */}
            {workdaysFilter()}
            {/* {contractsFilter()} */}

            <Button
              variant="contained"
              color="primary"
              style={{...styles.search_button, backgroundColor: isUserAuthenticated ? "#DA291C" : "#007BC3"} as object}
              onClick={() => {
                filterOffers();
                setIsFilterVisible(!matchesMobile);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              {t("offers.showOffers")}
            </Button>
            {matchesMobile ? (
              <Box
                sx={styles.filter_footer_button}
              >
                <Box fontSize={14} textAlign="left" mb={"8px"}>
                  <Box
                    component="a"
                    style={{color:!isUserAuthenticated ? "#007BC3" : "#DA291C"}}
                    sx={styles.filter_delete}
                    onClick={(event: any) => {
                      event.preventDefault();
                      setProvinciasFiltro(previousFilters.provinces);
                      setLocalidadesFiltro(previousFilters.locations);
                      setCiudadesFiltro(previousFilters.ciudad);
                      setPuestosFiltro(previousFilters.jobs);
                      setSectoresFiltro(previousFilters.sectors);
                      setTiposJornadaFiltro(previousFilters.workdayTypes);
                      setTiposContratoFiltro(previousFilters.contractTypes);
                      setIsFilterVisible(false);
                      filterOffers();
                      setIsFilterVisible(!matchesMobile);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    {t("offers.cancel")}
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              sx={matchesMobile ? styles.filter_footer_button : {}}
            >
              <Box
                fontSize={14}
                textAlign={matchesMobile ? "right" : "center"}
                mb={"8px"}
              >
                <Box
                  sx={styles.filter_delete}
                  style={{color: isUserAuthenticated ? "#DA291C" : "#007BC3"}}
                  onClick={(event) => {
                    event.preventDefault();
                    setProvinciasFiltro([]);
                    setLocalidadesFiltro([]);
                    setPuestosFiltro([]);
                    setSectoresFiltro([]);
                    setCiudadesFiltro([]);
                    setTiposJornadaFiltro([]);
                    setTiposContratoFiltro([]);
                    setLocalidadAutoComplete(null);
                    filterOffers();
                    setIsFilterVisible(!matchesMobile);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  {t("offers.deleteFilters")}
                </Box>
              </Box>
            </Box>
            {matchesMobile &&
            <Box sx={styles.search_container} >
              <OffersSearcher programa={programa} search={handleSearchOffers} customClass={!isUserAuthenticated ? {backgroundColor: "#007BC3"} : {}}/>
            </Box>
          }
          </Box>
        ) : null}
      </Box>
    );
  }

  const closeDrawer = () => {
    setIsFilterVisible(false);
  };

  function filterMobile() {
    return (
      <Drawer anchor={"top"} open={isFilterVisible} onClose={closeDrawer}>
        <Box
          sx={{ ...styles.list, ...styles.fullList }}
          role="presentation"
        >
          {filters()}
        </Box>
      </Drawer>
    );
  }

  return (
    <>
      {matchesMobile ? filterMobile() : null}
      <Box sx={styles.body_container}>
        {matchesMobile ? (
          <Box>
            {allOffers.length > 0 ? summary() : null}
            {filters()}
          </Box>
        ) : null}
        <Box sx={styles.body_left_col}>
          {!matchesMobile ? (
            <Box>
              {allOffers.length > 0 ? summary() : null}
              {!matchesMobile ? filters() : null}
            </Box>
          ) : null}
          {!matchesMobile && (
          <Box sx={styles.search_container}>
            <OffersSearcher programa={programa} search={handleSearchOffers}  customClass={!isUserAuthenticated ? {backgroundColor: "#007BC3"} : {}}/>
          </Box>
           )}
        </Box>
        <Box sx={styles.body_right_col}>
          <Box sx={styles.body_right_col_container}>
            {loading ?
              <CircularProgress sx={{marginTop: "50px", marginLeft: "45%"}} />
            :
            filteredOffers.length === 0 ? noOffers() : offers()}
            {filteredOffers.length < 20 || loading
              ? null
              : seeMoreButton(areAllFilteredOffersVisisbles, true, () =>
                setAreAllFilteredOffersVisisbles(
                  !areAllFilteredOffersVisisbles
                )
              )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SearchOffers;