import React, { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import styles from "./styles";
import { Box } from "@mui/system";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import { getToken } from "../../../../hooks/useApi";

interface ILayout {
  loading?: boolean,
  fullBody?: boolean,
  hasBackgroundImage?: boolean,
  programa: any,
}

function Layout({loading = false,/* programa */}: ILayout) {
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    getLogged().then((log) => setLogged(!!log));
  }, []);

  const getLogged = useCallback(async () => {
    const data = await getToken();
    return (data);
  }, []);

  
  return (
    <Box
      sx={styles.layoutContainer}
    >
      <Header
        /* programa={programa}
        history={history} */
      />
      {loading ? (
        <Box sx={styles.loading}>
          {/* <Loading display={true} height={64} width={64} /> */}
        </Box>
      ) : (
      <Box
          sx={{...styles.layoutBody, paddingTop: logged ? {xs:"unset",md:"80px"} : "84px"}}
          /* hasBackgroundImage && styles.hasBackgroundImage
        } ${styles}` */
        >
          <Outlet/>
        </Box>
      )}
      <Footer/>
    </Box>
  );
}

export default Layout;
