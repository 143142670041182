import React, { Dispatch, SetStateAction, useState, } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CookiesButtonsDialog from '../CookiesButtonsDialog/CookiesButtonsDialog';
import styles from './styles';
import { Box } from '@mui/material';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import CookiesConfigurationDialog from '../CookiesConfigurationDialog/CookiesConfigurationDialog';


interface ICookiesDialog {
  title: string,
  open: boolean,
  description: string,
  handleClose: Dispatch<SetStateAction<boolean>>,
  setConfigurateCookies: Dispatch<SetStateAction<boolean>>
  isCookies: boolean,
  isConfigurationCookies: boolean,
}

interface ICookiesConfigurationDialog {
  necessary: boolean,
  preference: boolean,
  statistics: boolean,
  marketing: boolean
}

export default function CookieDialog(props: ICookiesDialog) {
  const { title,
    open,
    description,
    handleClose,
    setConfigurateCookies,
    isCookies,
    isConfigurationCookies,
  } = props;

  const [cookieConfiguration, setCookieConfiguration] = useState<ICookiesConfigurationDialog>(
    {
      necessary: true,
      preference: false,
      statistics: false,
      marketing: false
    });

  const printButtonsCookies = () => {
    return (
      <CookiesButtonsDialog
        closeDialog={handleClose}
        setConfigurateCookies={setConfigurateCookies}
        isConfigurationCookies={isConfigurationCookies}
        cookieConfiguration={cookieConfiguration}
        setCookieConfiguration={setCookieConfiguration}
      />
    );
  };

  return (
    <div >
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={styles.dialog_cookies_container(isCookies)}
      >
        <Box sx={isCookies ? { display: "flex" } : {}}>
          {isCookies && <CookieOutlinedIcon sx={styles.cookieIcon} fontSize="large" />}
          <DialogTitle
            id="alert-dialog-title"
            sx={styles.dialog_title}>
            {title}
          </DialogTitle>
        </Box>
        <DialogContent sx={description || isConfigurationCookies ? styles.dialog_content : styles.dialog_content}>
          {(description || isConfigurationCookies) &&
            <DialogContentText
              id="alert-dialog-description"
              sx={{ fontSize: "14px" }}
            >
              {isConfigurationCookies ? <CookiesConfigurationDialog
                cookieConfiguration={cookieConfiguration}
                setCookieConfiguration={setCookieConfiguration} />
                : description}
            </DialogContentText>
          }
        </DialogContent>
        <DialogActions style={{justifyContent: 'center'}}>
          {printButtonsCookies()}
        </DialogActions>
      </Dialog>
    </div>
  );
}
