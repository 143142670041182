import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTheme,useMediaQuery,FormControl,Box } from "@mui/material";
import TuCurriculumHeader from "../../../../../shared/components/TuCurriculumHeader/TuCurriculumHeader";
import { useTranslation } from "react-i18next";
import OtherData from "../../../../../assets/icons/otherData";
import {
  optionEquality,
  returnLangId,
  getPermisosConduccion,
  getTiposCambioResidencia,
  getTiposDesplazamientos,
  getTiposSalario,
  getSalaryRange,
  getContractTypes,
  formatToLocalDate,
  optionEqualityString
} from "../../../../../shared/utils/functions";
import i18next from "../../../../../i18n";
import AntSwitch from "../../../../../shared/components/AntSwitch/antSwitch";
import Autocomplete from "../../../../../shared/components/SelectCustoms/autocompleteWithLabelAnimation";
import ButtonFull from "../../../../../shared/components/Buttons/ButtonFull";
import InputWithLabel from "../../../../../shared/components/Inputs/InputCustom";
import ButtonOutlined from "../../Dashboard/buttonOutlined";
import { actualizarOtrosDatos, 
          getOtrosDatos , 
          getPlace ,
          getDatosPersonales} 
from "../../../../../shared/hooks/userApi";
import HeartIcon from "../../../../../assets/icons/heartIcon";
import DeleteChip from "../../../../../assets/icons/delete_chip";
import styles from "./otrosDatosStyles";
import { useApi } from "../../../../../shared/hooks/useApi";
import { SnackContext,SnackSeverity } from "../../../../../shared/contexts/SnackHandler";
import { languages } from "../../../auth/AuthWrapper";
import { Routes } from "../../../../../shared/enums/enums";
import { useNavigate } from "react-router-dom";
import DatePickerWithLabel from "../../../../components/DatePicker/datePickerWithLabel";
import { MaestroContext } from "../../../../contexts/MaestroContext";

const permisos_de_conduccion = getPermisosConduccion(i18next.languages[0]);
const rangos_salarios = getSalaryRange(i18next.languages[0]);
const tipos_salario = getTiposSalario(i18next.languages[0]);
const tipos_cambio_residencia = getTiposCambioResidencia(i18next.languages[0]);
const tipos_desplazamientos = getTiposDesplazamientos(i18next.languages[0]);
const tipos_Contratos = getContractTypes(i18next.languages[0]);



function OtrosDatos(props:any) {
  const { t } = useTranslation();
  const callApi = useApi();
  const showSnack = useContext(SnackContext);
  const navigate = useNavigate();

  const { state } = props;
  const {tipoHorario } = useContext(MaestroContext);

  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [idFotografia, setIdFotografia] = useState(null);
  const [fechaModificacion, setFechaModificacion] = useState<any>(null);

  const [otrosDatos, setOtrosDatos] = useState<any>(null);
  const [permiteFirmaDigital, setPermiteFirmaDigital] = useState(false);
  const [permiteGeolocalizacion, setPermiteGeolocalizacion] = useState(false);
  const [trabajaActualmente, setTrabajaActualmente] = useState(false);
  const [cobraPrestacionParo, setCobraPrestacionParo] = useState(false);
  const [workPermit, setWorkPermit] = useState(false);
  const [workPermitDate, setWorkPermitDate] = useState(null);
  const [criminalRecord, setCriminalRecord] = useState(false);
  const [tiposCarnetConducir, setTiposCarnetConducir] = useState([]);
  const [tieneVehiculoPropio, setTieneVehiculoPropio] = useState(false);
  const [aceptaDiasAislados, setAceptaDiasAislados] = useState(false);
  const [tipoDisponibilidadViajar, setTipoDisponibilidadViajar] =
    useState<any>(null);
  const [
    tipoDisponibilidadCambioResidencia,
    setTipoDisponibilidadCambioResidencia,
  ] = useState<any>(null);
  const [salarioMinimo, setSalarioMinimo] = useState<any>(null);
  const [salarioDeseado, setSalarioDeseado] = useState<any>(null);
  const [tipoSalario, setTipoSalario] = useState<any>(null);
  const [tiposHorario, setTiposHorario] = useState([]);
  const [employments, setEmployments] = useState<any>([]);
  const [employmentsOptions, setEmploymentsOptions] = useState<any>(null);
  const [tiposContrato, setTiposContrato] = useState([]);
  const [daysNeeded, setDaysNeeded] = useState(null);
  
  useEffect(() => {
    if (state && state) {
      prueba();
    }
  }, [state]);

    useEffect(() => {
    handleDatosPersonales();
  }, []);

  useEffect(() => {
    if (!otrosDatos) {
      handleGetPlaces();
    } else {
      handleSetOtrosDatos();
    }
  }, [otrosDatos]);

  const handleDatosPersonales = useCallback(() => {
    callApi(getDatosPersonales(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      handlePersonalDataAction(data);
    }).catch(() => {
      showSnack(t("curriculum.personal_data_error"), SnackSeverity.ERROR);
    });
}, []);

const handleGetOtrosDatos = useCallback(() => {
  setLoading(true);
  callApi(getOtrosDatos(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
    setLoading(false);
    setOtrosDatos(data);
  }).catch(() => {
    setLoading(false);
    showSnack(t("other_data.error_getting_data"), SnackSeverity.ERROR);
    setOtrosDatos(null);
  });
}, []);

  function handleSetOtrosDatos() {
    setPermiteFirmaDigital(otrosDatos.permiteFirmaDigital);
    setPermiteGeolocalizacion(otrosDatos.permiteGeolocalizacion);
    setTrabajaActualmente(otrosDatos.trabajaActualmente ?? false);
    setCobraPrestacionParo(otrosDatos.cobraPrestacionParo ?? false);
    setCriminalRecord(otrosDatos.tieneCertificadoPenales ?? false);
    setWorkPermit(otrosDatos.tienePermisoTrabajo ?? false);
    setWorkPermitDate(otrosDatos.fechaFinPermisoTrabajo ?? null);
    setTieneVehiculoPropio(otrosDatos.tieneVehiculoPropio ?? false);
    setDaysNeeded(otrosDatos.diasIncorporacion ?? null);
    setAceptaDiasAislados(otrosDatos.aceptaDiasAislados ?? false);
    setTipoDisponibilidadViajar(
      otrosDatos.tipoDisponibilidadViajar
        ? tipos_desplazamientos.find(
            (desplazamiento:any) =>
              Number(desplazamiento.id) === otrosDatos.tipoDisponibilidadViajar
          )
        : null
    );
    setTipoDisponibilidadCambioResidencia(
      otrosDatos.tipoDisponibilidadCambioResidencia
        ? tipos_cambio_residencia.find(
            (cambio:any) =>
              Number(cambio.id) ===
              otrosDatos.tipoDisponibilidadCambioResidencia
          )
        : null
    );
    setSalarioMinimo(
      otrosDatos.salarioMinimo
        ? rangos_salarios.find(
            (rango:any) => Number(rango.id) === otrosDatos.salarioMinimo
          )
        : null
    );
    setSalarioDeseado(
      otrosDatos.salarioDeseado
        ? rangos_salarios.find(
            (rango:any) => Number(rango.id) === otrosDatos.salarioDeseado
          )
        : null
    );
    setTipoSalario(
      otrosDatos.tipoSalario
        ? tipos_salario.find(
            (tipo:any) => Number(tipo.id) === otrosDatos.tipoSalario
          )
        : null
    );
    setTiposCarnetConducir(
      otrosDatos.tiposCarnetConducir
        ? permisos_de_conduccion.filter((permiso:any) =>
            otrosDatos.tiposCarnetConducir.includes(Number(permiso.id))
          )
        : []
    );
    setTiposHorario(
      otrosDatos.tiposHorario
        ? tipoHorario.filter((jornada:any) =>
            otrosDatos.tiposHorario.includes(Number(jornada.id))
          )
        : []
    );
    setTiposContrato(
      otrosDatos.tiposContrato
        ? tipos_Contratos.filter((contract:any) =>
            otrosDatos.tiposContrato.includes(Number(contract.id))
          )
        : []
    );
    if (otrosDatos.puestosDeseados && employmentsOptions) {
      const data = [] as any;
      otrosDatos.puestosDeseados.map((p:any) => {
        const employ = employmentsOptions.find((e:any) => e.id === p.idPuesto);
        data.push({
          idPuesto: p.idPuesto,
          nombre: employ.nombre,
          esPrincipal: p.esPrincipal,
        });
      });
      setEmployments(data);
    }
  }

  function validateSalary() {
    return ((salarioMinimo || salarioDeseado) && tipoSalario) || (!salarioMinimo && !salarioDeseado && !tipoSalario);
  }

  async function save() {
    if (employments && employments.length > 0 && validateSalary()) {
      updateOtrosDatos();
    } else if (!employments || employments.length === 0) {
      showSnack(t("other_data.error_employments"), SnackSeverity.ERROR);
    } else if (!validateSalary()) {
      showSnack(t("other_data.error_salary"), SnackSeverity.ERROR);
    } else {
      showSnack(t("other_data.error_expired_expiration_date"), SnackSeverity.ERROR);
    }
  }

  function prueba(){
    loading;
  }

  async function updateOtrosDatos() {
    setLoading(true);
    const data = {
      permiteFirmaDigital,
      permiteGeolocalizacion,
      trabajaActualmente,
      cobraPrestacionParo,
      tieneVehiculoPropio,
      aceptaDiasAislados,
      diasIncorporacion: daysNeeded !== null ? Number(daysNeeded) : null,
      tieneCertificadoPenales: criminalRecord,
      tienePermisoTrabajo: workPermit,
      fechaFinPermisoTrabajo: workPermitDate,
      tipoDisponibilidadViajar: tipoDisponibilidadViajar
        ? Number(tipoDisponibilidadViajar.id)
        : null,
      tipoDisponibilidadCambioResidencia: tipoDisponibilidadCambioResidencia
        ? Number(tipoDisponibilidadCambioResidencia.id)
        : null,
      salarioMinimo: salarioMinimo ? Number(salarioMinimo.id) : null,
      salarioDeseado: salarioDeseado ? Number(salarioDeseado.id) : null,
      tipoSalario: tipoSalario ? Number(tipoSalario.id) : null,
      tiposCarnetConducir:
        tiposCarnetConducir.length > 0
          ? tiposCarnetConducir.map((carnet:any) => Number(carnet.id))
          : null,
      tiposHorario:
        tiposHorario.length > 0
          ? tiposHorario.map((jornada:any) => Number(jornada.id))
          : null,
      puestosDeseados: employments ? employments : null,
      tiposContrato:
        tiposContrato.length > 0
          ? tiposContrato.map((contract:any) => Number(contract.id))
          : null,
    }; 

    callApi(actualizarOtrosDatos(data,returnLangId(i18next.languages[0] as keyof typeof languages))).then(() => {
      navigate(Routes.CURRICULUM);
    }).catch((response) => {
        showSnack(response.message ? response.message : t("other_data.error_saving_data"), SnackSeverity.ERROR);
     
    });
    setLoading(false);
  }

  const handleGetPlaces = useCallback(() => {
    setLoading(true);
    callApi(getPlace(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      setEmploymentsOptions(data.puestos);
      handleGetOtrosDatos();
    }).catch((response) => {
      if (response.status === 404) {
        showSnack(t("work_experience.get_places_not_found"), SnackSeverity.ERROR);
      } else {
        showSnack(t("work_experience.get_place_error"), SnackSeverity.ERROR);
      }
    });
  }, []);


  function handleDeleteEmployment(data:any) {
    if (!data.esPrincipal) {
      setEmployments(
        employments.filter((element:any) => element.idPuesto !== data.idPuesto)
      );
    } else if (employments.length > 1) {
      const newsEmployments = employments.filter(
        (element:any) => element.idPuesto !== data.idPuesto
      );
      newsEmployments[0].esPrincipal = true;
      setEmployments(newsEmployments);
    } else {
      setEmployments([]);
    }
  }

  function handleDefaultEmployment(data:any, bool:boolean) {
    const values = employments.slice();
    const flag = employments.find((element:any) => element.esPrincipal === true);
    if (flag && flag.length !== 0) {
      for (let i = 0; i < values.length; i++) {
        if (employments[i].idPuesto === data.idPuesto) {
          values[i].esPrincipal = bool;
        }
        if (employments[i].idPuesto === flag.idPuesto) {
          values[i].esPrincipal = false;
        }
      }
    } else {
      for (let i = 0; i < values.length; i++) {
        if (employments[i].idPuesto === data.idPuesto) {
          values[i].esPrincipal = bool;
        }
      }
    }
    setEmployments([...values]);
  }

  function handleSetEmployments(value:any) {
    const someDefatult = employments.find(
      (element:any) => element.esPrincipal === true
    );
    if (
      value &&
      !employments.find(
        (element:any) => parseInt(element.idPuesto) === parseInt(value.id)
      )
    ) {
      if (!someDefatult) {
        setEmployments([
          ...employments,
          { idPuesto: value.id, nombre: value.nombre, esPrincipal: true },
        ]);
      } else {
        setEmployments([
          ...employments,
          { idPuesto: value.id, nombre: value.nombre, esPrincipal: false },
        ]);
      }
    }
  }

  function handlePersonalDataAction(data:any) {
    setNombre(data.nombre);
    setPrimerApellido(data.primerApellido);
    setSegundoApellido(data.segundoApellido);
    setCorreoElectronico(data.correoElectronico);
    setPrefijo(data.telefono.prefijo);
    setTelefono(data.telefono.numero);
    setIdFotografia(data.idFotografia);
    setFechaModificacion(formatToLocalDate(new Date(data.fechaModificacion)));
  }

  function renderPermissionData() {
    return (
      <Box sx={styles.general_grid}>
        <Box sx={styles.text_switch_grid_style}>
          <Box sx={styles.text_switch_style}>
            {t("other_data.digital_signature")}
          </Box>
          <AntSwitch
            checked={permiteFirmaDigital}
            setter={() => setPermiteFirmaDigital(!permiteFirmaDigital)}
          />
        </Box>
        <Box sx={styles.text_switch_grid_style}>
          <Box sx={styles.text_switch_style}>
            {t("other_data.geolocalization")}
          </Box>
          <AntSwitch
            checked={permiteGeolocalizacion}
            setter={() => setPermiteGeolocalizacion(!permiteGeolocalizacion)}
          />
        </Box>
      </Box>
    );
  }

  function renderCurrentSituation() {
    return (
      <Box sx={styles.general_grid}>
        <Box sx={styles.text_section_title_style}>
          {t("other_data.current_status")}
        </Box>
        <Box sx={styles.text_switch_grid_style}>
          <Box sx={styles.text_switch_style}>
            {t("other_data.currently_working")}
          </Box>
          <AntSwitch
            checked={trabajaActualmente}
            setter={() => setTrabajaActualmente(!trabajaActualmente)}
          />
        </Box>
        <Box sx={styles.text_switch_grid_style}>
          <Box sx={styles.text_switch_style}>
            {t("other_data.paid_unemployment")}
          </Box>
          <AntSwitch
            checked={cobraPrestacionParo}
            setter={() => setCobraPrestacionParo(!cobraPrestacionParo)}
          />
        </Box>
        <Box>
          <Box sx={styles.text_switch_grid_style}>
            <Box sx={styles.text_switch_style}>
              {t("other_data.work_permit_simple")}
            </Box>
            <AntSwitch
              checked={workPermit}
              setter={() => {
                setWorkPermit(!workPermit);
                setWorkPermitDate(null);
              }}
            />
          </Box>
          {workPermit && 
          <DatePickerWithLabel
          customClass={styles.date_picker_class}
            label={t("other_data.valid_date")}
            value={workPermitDate}
            setter={(value:any) => {
              setWorkPermitDate(value);
            }}
          />
          }
        </Box>
        
        <Box sx={styles.text_switch_grid_style} style={{alignSelf: "flex-start"}}>
          <Box sx={styles.text_switch_style}>
            {t("other_data.criminal_record")}
          </Box>
          <AntSwitch
            checked={criminalRecord}
            setter={() => setCriminalRecord(!criminalRecord)}
          />
        </Box>
      </Box>
    );
  }

  function renderDrivingLicenses() {
    return (
      <Box sx={styles.general_grid}>
        <Box sx={styles.text_section_title_style}>
          {t("other_data.driving_license")}
        </Box>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            value={tiposCarnetConducir}
            setter={setTiposCarnetConducir}
            options={permisos_de_conduccion}
            label={
              tiposCarnetConducir && tiposCarnetConducir.length > 0
                ? null
                : t("other_data.type")
            }
            multiple
            getOption={(x:any, v:any) => optionEqualityString(x, v)}
            getLabel={(x:any) => (typeof x === "object" && x !== null ? x.name : x)}
            useDefaultIcon
            moveLabel
          />
        </FormControl>
        <Box sx={styles.text_switch_grid_style}>
          <Box sx={styles.text_switch_style}>
            {t("other_data.own_vehicle")}
          </Box>
          <AntSwitch
            checked={tieneVehiculoPropio}
            setter={() => setTieneVehiculoPropio(!tieneVehiculoPropio)}
          />
        </Box>
      </Box>
    );
  }

  function renderAvailability() {
    return (
      <Box sx={styles.general_grid}>
        <Box sx={styles.text_section_title_style}>
          {t("other_data.availability")}
        </Box>
        <Box sx={styles.text_switch_grid_style}>
          <Box sx={styles.text_switch_style}>
            {t("other_data.work_isolated_days")}
          </Box>
          <AntSwitch
            checked={aceptaDiasAislados}
            setter={() => setAceptaDiasAislados(!aceptaDiasAislados)}
          />
        </Box>
        <InputWithLabel
                fullWidth
                label={t("other_data.days_needed")}
                value={daysNeeded}
                setter={(e: any) => setDaysNeeded(e.target.value)}
                moveLabel
                maxLength={3}
                type={"number"}
              />
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            value={tipoDisponibilidadCambioResidencia}
            setter={setTipoDisponibilidadCambioResidencia}
            options={tipos_cambio_residencia}
            label={t("other_data.residence_change_available")}
            getOption={(x:any, v:any) => optionEquality(x, v)}
            getLabel={(x:any) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                ? tipos_cambio_residencia.find(
                    (s:any) => Number(s.id) === Number(x)
                  )?.name ?? ""
                : ""
            }
            useDefaultIcon
            moveLabel
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            value={tipoDisponibilidadViajar}
            setter={setTipoDisponibilidadViajar}
            options={tipos_desplazamientos}
            label={t("other_data.travel_available")}
            getOption={(x:any, v:any) => optionEquality(x, v)}
            getLabel={(x:any) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                ? tipos_desplazamientos.find((s:any) => Number(s.id) === Number(x))
                    ?.name ?? ""
                : ""
            }
            useDefaultIcon
            moveLabel
          />
        </FormControl>
      </Box>
    );
  }

  function handleSetSalarioMinimo(salario:number) {
    setSalarioDeseado(null);
    setSalarioMinimo(salario);
  }

  function renderWorkPreferences() {
    return (
      <Box sx={styles.preferences_header_grid}>
        <Box sx={styles.text_section_title_style}>
          {t("other_data.preferences")}
        </Box>
        <Box sx={styles.preferences_grid}>
        <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={tipoSalario}
              setter={setTipoSalario}
              options={tipos_salario}
              label={t("other_data.wage_type")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? tipos_salario.find((s:any) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              useDefaultIcon
              moveLabel
              fullWidth
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={salarioMinimo}
              setter={handleSetSalarioMinimo}
              options={rangos_salarios}
              label={t("other_data.minimum_wage")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? rangos_salarios.find((s:any) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              useDefaultIcon
              moveLabel
              escapeBiggerThan
              fullWidth
            />
          </FormControl>
          
          </Box>
          <Box sx={styles.preferences_grid}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={salarioDeseado}
              setter={setSalarioDeseado}
              options={rangos_salarios.filter((x:any) =>
                salarioMinimo
                  ? Number(x.order) >= Number(salarioMinimo.order)
                  : true
              )}
              label={t("other_data.desired_wage")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? rangos_salarios.find((s:any) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              useDefaultIcon
              moveLabel
              escapeBiggerThan
              fullWidth
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={tiposHorario}
              setter={setTiposHorario}
              options={tipoHorario.sort(
                (a:any, b:any) => parseInt(a.order) - parseInt(b.order)
              )}
              multiple
              label={t("other_data.workday_type")}
              getOption={(x:any, v:any) => optionEqualityString(x, v)}
              getLabel={(x:any) =>
                typeof x === "object" && x !== null ? x.name : x
              }
              useDefaultIcon
              moveLabel
            />
          </FormControl>
          </Box>
          <Box sx={styles.preferences_grid}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={tiposContrato}
              setter={setTiposContrato}
              options={tipos_Contratos.sort(
                (a:any, b:any) => parseInt(a.order) - parseInt(b.order)
              )}
              label={t("other_data.type_contract")}
              multiple
              getOption={(x:any, v:any) => optionEqualityString(x, v)}
              getLabel={(x:any) =>
                typeof x === "object" && x !== null ? x.name : x
              }
              useDefaultIcon
              moveLabel
            />
          </FormControl>
        </Box>
        <Box sx={styles.preferences_grid_2}>
        <Autocomplete
            options={
              employmentsOptions
                ? employmentsOptions.filter(
                    (o:any) => !employments.find((e:any) => e.idPuesto === o.id)
                  )
                : []
            }
            value={employments}
            label={t("basicData.employments")}
            setter={handleSetEmployments}
            moveLabel
            maxLength={100}
            getLabel={(option:any) => option.nombre || ""}
            getOption={(x:any, v:any) => optionEquality(x, v)}
            disabled={!employmentsOptions || employments?.length === 3}
            wrong={!employments || employments.length < 1}
          />
        </Box>
        <Box>
          {employments.map((data:any) => {
            return (
              <Box
                sx={styles.customChip}
                key={`${data.nombre}, ${data.id}`}
              >
                <Box sx={styles.nameChip}>{data.nombre}</Box>
                <Box sx={styles.iconGeneral}>
                  {data.esPrincipal ? (
                    <HeartIcon title={t("icons_tooltips.favorite")} fill />
                  ) : (
                    <HeartIcon
                      title={t("icons_tooltips.favorite_do")}
                      fill={false}
                      onClick={() => handleDefaultEmployment(data, true)}
                    />
                  )}
                </Box>
                <Box sx={styles.iconGeneral}>
                  <DeleteChip
                    title={t("icons_tooltips.delete")}
                    onClick={() => handleDeleteEmployment(data)}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  function renderActionButtons() {
    return (
      <Box sx={styles.action_buttons_grid}>
        <Box style={{ gridArea: "cancel", width: "100%" }}>
          <ButtonOutlined
            size="100%"
            text={t("common.cancel")}
            onClick={() => {
             navigate(Routes.CURRICULUM);
            }}
          />
        </Box>
        <Box style={{ gridArea: "save", width: "100%" }}>
          <ButtonFull
            size="100%"
            text={t("common.save")}
            onClick={() => {
              save();
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
 
      <Box sx={styles.principal_container}>
        {!matchesMobile ? (
          <TuCurriculumHeader
            nombre={nombre}
            primerApellido={primerApellido}
            segundoApellido={segundoApellido}
            correoElectronico={correoElectronico}
            prefijo={prefijo}
            telefono={telefono}
            fechaModificacion={fechaModificacion}
            idPhoto={idFotografia}
          />
        ) : (
          <Box />
        )}
        <Box sx={styles.col2}>
          <Box sx={styles.data_sections_container}>
            <Box sx={styles.data_section_icon_and_title}>
              <OtherData />
              <Box sx={styles.data_section_title_principal}>
                {t("other_data.title")}
              </Box>
            </Box>
            {renderPermissionData()}
            {renderCurrentSituation()}
            {renderDrivingLicenses()}
            {renderAvailability()}
            {renderWorkPreferences()}
            {renderActionButtons()}
          </Box>
        </Box>
      </Box>

  );
}

export default OtrosDatos;
