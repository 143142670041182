import React from "react";
import { useTranslation } from "react-i18next";

function PriorityLow(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.expand")} = props;

  return(
    <svg width="14px" height="7px" viewBox="0 0 14 7" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="Recursos" transform="translate(-152.000000, -1792.000000)" stroke={props.color ?? "#DA291C"}>
                <g id="priority-low" transform="translate(153.000000, 1792.000000)">
                    <polyline id="Path" points="0 0.5 6 6.5 12 0.5"></polyline>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default PriorityLow;