const styles = {
    principal_container: {
      gridArea: "body_container",
      display: "grid",
      gridTemplateColumns: {xs:"auto",md:"30% 65%"},
      columnGap: "20px",
    },
    col1: {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginLeft:{md:"45%"}
    },
    backdrop: {
      zIndex: 500,
      color: "#fff",
    },
    col2: {
      marginTop:"0px",
      padding: {xs:"20px",md:"90px"},
      paddingTop:{md:"79px"},
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "60px",
    },
    data_sections_container: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "25px",
    },
    summary_icon_container: {
      display: "flex",
      alignItems: "center",
      justifyContent:{xs:"center",md: "flex-start"},
      width: "100%",
      marginBottom: "30px",
      marginTop:{xs:"45px",md:"unset"}
    },
    title_text: {
      fontSize:{xs:"24px",md:"36px"},
      fontWeight: 900,
      marginBottom: "30px",
    },
    candidate_photo_container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "127px",
      width: "127px",
      borderRadius: "6px",
      background: "linear-gradient(180deg, #EEEEEE 0%, #D8D8D8 100%)",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    },
    photo: {
      width: "100%",
      height: "100%",
      borderRadius:"6px",
      objectFit: "cover",
    },
    section_border: {
      height: "1px",
      backgroundColor: "#EFEFEF",
    },
    data_section: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: {xs:"40px",md:"24px"},
    },
    data_section_header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    data_section_header_customize: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    data_section_icon_and_title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
    },
    data_section_icon_and_title_customize: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "15px",
      alignItems: "baseline",
    },
    data_section_title: {
      fontSize:{xs:"18px",md: "22px"} ,
      fontWeight: 900,
      marginLeft: "12px",
    },
    data_section_title_customize: {
      fontSize: {xs:"18px",md: "22px"} ,
      fontWeight: 900,
      marginTop: "15px",
    },
    data_section_linkbutton: {
      color: "#DA291C",
      fontSize: "14px",
      fontWeight: 500,
      textDecoration: "underline",
      cursor: "pointer",
    },
    personal_data_section_container: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "20px",
    },
    fullname: {
      color: "rgba(0,0,0,0.87)",
      fontSize:{xs:"14px",md: "18px"} ,
      fontWeight: 900,
      wordBreak: "break-word",
    },
    personal_data_inner_sections: {
      fontSize: "14px",
      fontWeight: 600,
      color: "rgba(0,0,0,0.87)",
      wordBreak: "break-all",
      overflowWrap: "break-word",
    },
    see_notifications_button: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "14px",
      color: "#DA291C",
      cursor: "pointer",
      "&:hover": {
        textDecoration: {xs:"none",md:"underline"},
      },
    },
    rotate_icon: {
      "& svg": {
        transform: "rotate(180deg)",
      },
    },
    no_data_yet_container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    custom_button_size: {
      width:{xs:"100%",md:251},

    },
    delete_profile_linkbutton: {
      fontSize: "12px",
      textDecoration: "underline",
      cursor: "pointer",
      alignSelf:{xs:"center"}
    },
    digital_profiles_sections: {
      display: "flex",
      flexDirection: "row",
    },
    digital_profiles_button: {
      cursor: "pointer",
      "&:hover": {
        opacity: "0.8",
      },
      "& + &": {
        marginLeft: "5px",
      },
    },
    candidate_last_update: {
      display: "inline-block",
      color: "rgba(0,0,0,0.87)",
      fontSize: "12px",
      fontWeight: 500,
    },
    name_and_date_grid: {
      display: "flex",
      flexDirection: "column",
    },
    grids_formaciones: {
      display: "flex",
      flexDirection: "column",
    },
    formacion_title_grid: {
      display: "flex",
      flexDirection: "column",
      "& + &": {
        marginTop: "60px",
      },
    },
    formacion_section_title: {
      fontSize: "16px",
      fontWeight: 900,
      marginBottom: "20px",
    },
    your_data_grid: {
      display: "grid",
      gridTemplateColumns: {xs:"1f",md:".5fr .5fr"},
      columnGap: "24px",
      rowGap: {xs:"40px",md:"34px"},
      fontSize: "14px",
      gridAutoRows:{xs:"min-content"}
    },
    your_data_inner_container: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "5px",
    },
    formacion_header: {
      display: "flex",
      flexDirection: "row",
    },
    formacion_title: {
      fontSize:"14px",
      fontWeight: 900,
    },
    edit_your_single_data: {
      cursor: "pointer",
      "&:hover": {
        opacity: "0.8",
      },
      paddingLeft:"20px"
    },
    single_language_header: {
      display: "flex",
      flexDirection: "row",
      fontSize: "16px",
      fontWeight: 900,
    },
    languages_inner_grid: {
      fontSize: "14px",
      display: "grid",
      gridTemplateColumns: ".35fr .65fr",
      rowGap: "3px",
    },
    certificate_inner_list: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "100%",
      overflow: "hidden",
    },
    chip: {
      backgroundColor: "#EFEFEF",
      borderRadius: "15px",
      height: "33px",
      padding: "7px 15px 7px 15px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "97%",
      float: "left",
      margin: "5px 10px 5px 0",
      fontSize: "14px",
      lineHeight: "20px",
    },
    alignSpinner:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
    },
    container_conocimientos: {
      overflow: "hidden",  
      paddingBottom: {xs:"4px",md:"20px!important"},
      maxWidth: "100%",
    },
    experience_container: {
      fontSize: "14px",
    },
    experience_dates: {
      color: "#949494",
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "10px",
      display: "inline-flex",
      marginRight: "4px",
    },
    experience_details: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    experience: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    experience_functions: {
      display: "inline",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      marginRight: "7px",
      marginLeft: "7px",
    },
    experience_separator: {
      backgroundColor:"#DA291C",
      width: "4px",
      height: "4px",
      borderRadius: "4px",
      display: "inline-flex",
    },
    experience_edit_button: {
      cursor: "pointer",
      paddingLeft:"20px"
    },
    experience_title: {
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 900,
      marginBottom: "15px",
    },
    experience_title_container: {
      display: "flex",
    },
    referencia: {
      marginTop: "10px",
      lineHeight: "22px",
      marginLeft:"20px",
      marginBottom:{xs:"15px"}
    },
    referencia_nombre: {
      fontWeight: 400,
    },
    referencia_email: {
      color:"#DA291C",
      textDecoration: "underline",
    },
  
    see_all_otros_datos_style: {
      width: "100px",
      fontSize: "14px",
      fontWeight: 900,
      display: "flex",
      flexDirection: "row",
      color: "#DA291C!important",
      cursor: "pointer",
      "&:hover": {
        opacity: "0.8",
      },
    },
    cv_row_style: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      wordBreak: "break-all",
    },
    cv_buttons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
    },
    clickable_style: {
      cursor: "pointer",
      "& + &": {
        marginLeft: "17px",
      },
      "&:hover": {
        opacity: 0.8,
      },
    },
    hidden_input: {
      display: "none",
    },
    container_experience: {
      overflow: "hidden",
      paddingBottom: "3px",
      paddingTop: 0,
    },
    experience_ind: {
      float: "left",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "98%",
      paddingBottom: "5px",
      paddingTop: "5px",
    },
    certificate: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "100%",
      "& + &": {
        marginTop: "3px",
      },
    },
    otros_datos_row: {
      listStylePosition: "inside",
    },
    container_knowledge: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows:{xs:"auto auto",md:"auto auto"},
      gridTemplateAreas: {xs:'"select" "chips"',md:'"select selectFilled" "chips chips"'},
    },
    no_Space: {
      display: "none"
    },
    email: {
      overflow: "hidden",
      whiteSpace:"nowrap",
      textOverflow: "ellipsis",
      width:"290px",
    },
  };

export default styles;
