import { Box } from "@mui/material";
import React from "react";
import styles from "../styles";

function Subtitle({ text, icon, isBold, className }: any) {
  return (
      <Box
        style={{display: "inline-block", fontSize: 14, lineHeight: "20px", fontWeight: isBold ? 900 : ""}}
        sx={className ?? styles.no_margin}
      >
        {icon}
        {text}
      </Box>
  );
}

export default Subtitle;