import { Box, Button, Typography, Link } from "@mui/material";
import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import styles from "../styles";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import ButtonOutlined from "../../Dashboard/buttonOutlined";
import { useNavigate } from "react-router";
import { MaestroContext } from "../../../../contexts/MaestroContext";

declare global {
  interface Window { _env_: any; }
}

window._env_ = window._env_ || {};

function Enroll({ kqQuestionsLoaded, fromLogin, matchesMobile, searchParams, enrollDisabled, isUserAuthenticated, enroll, handleRegisterClick, handleLoginClick, offer}: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { localidades } = useContext(MaestroContext);
  const share_uri = window._env_.REACT_APP_SHARE_BASE_URI;

  function showReturnButton() {
    return (
      <Box style={{ gridArea: "return", width: "100%", marginBottom: "8px" }}>
      <ButtonOutlined
        size="100%"
        text={t("common.return")}
        color={!isUserAuthenticated ? "#007BC3" : "#DA291C"}
        onClick={() => navigate(fromLogin ? -5 : -1)}
      />
    </Box>
    );
  }

  return (
    <>
      {isUserAuthenticated && !kqQuestionsLoaded? (
        <Button
          variant="contained"
          color="primary"
          sx={styles.enroll_button}
          onClick={enroll}
          disabled={enrollDisabled}
        >
          {t("offerDetails.enroll")}
        </Button>
        


      ) : null}
        {(matchesMobile && isUserAuthenticated) ?showReturnButton() : null }

      {!isUserAuthenticated ? (
        <>
        <Typography sx={styles.register_or_login_text}>
          <Box
            display="inline-block"
            fontSize={14}
            lineHeight="21px"
            fontWeight={900}
            m={1}
            style={matchesMobile &&{marginTop:"15px"}}
            sx={styles.no_margin}
          >
            <Link href="#" style={{ color: "#007BC3", textDecorationColor: "#007BC3" }} onClick={handleLoginClick}>
               {t("offerDetails.login")}
            </Link>
               {t("offerDetails.or")}
            <Link href="#" style={{ color: "#007BC3", textDecorationColor: "#007BC3" }} onClick={handleRegisterClick}>
               {t("offerDetails.register")}
            </Link>
               {t("offerDetails.enroll_this_offer")}
          </Box>
        </Typography>
        {(matchesMobile && !isUserAuthenticated) ?showReturnButton() : null }
        </>
      ) : null}
      {/* <Box
        component="img"
        alt="Nortempo"
        src={"/images/nortempo_logo.svg"}
        sx={styles.header_logo}
      /> */}
      <Box sx={{...styles.retorna, color: isUserAuthenticated ? "#D9291C" : "#000000"}}>{t("header.titleRetorna")}</Box>
      <Box sx={styles.retornaClaim}>{t("header.claimRetorna")}</Box>
      <Box sx={styles.social_share_container}>
        <Typography variant="body1">{t("offerDetails.share_title")}</Typography>
        <Box sx={styles.social_share}>
          <Box sx={styles.shareIcon}>
            <LinkedinShareButton
              url={`${share_uri}${window.location.pathname}${searchParams}`}
            >
              <LinkedInIcon/>
            </LinkedinShareButton>
          </Box>
          <Box sx={styles.shareIcon}>
            <FacebookShareButton
              url={`${share_uri}${window.location.pathname}?${searchParams}`}
              quote={`En @Retorna seleccionan ${offer?.titulo} en ${localidades.find(
                (l: any) =>
                  l.id.toString().trim() === offer?.idLocalidad?.toString().trim()
              )?.name ?? ""
                }`} // Texto a mostrar por defecto en el post
              hashtag={"#Retorna"} //Hashtag que se quiere mostrar asociado al post
            >
              <FacebookIcon/>
            </FacebookShareButton>
          </Box>
          <Box sx={styles.shareIcon}>
            <TwitterShareButton
              url={`${share_uri}${window.location.pathname}?${searchParams}`}
              title={`Seleccionamos ${offer?.titulo} en ${localidades.find(
                (l: any) =>
                  l.id.toString().trim() === offer?.idLocalidad?.toString().trim()
              )?.name ?? ""
                }`} // Title of the share page
              via={"Retorna"} // it can be used to attribute the authority of the tweet if relates is used this makes no sense?
              hashtags={["Retorna", "trabajo", "empleo"]} //array of hastaghs
            >
              <TwitterIcon/>
            </TwitterShareButton>
          </Box>
          <Box sx={styles.shareIcon}>
            <EmailShareButton
              url={`${share_uri}${window.location.pathname}?${searchParams}`}
              subject={"Esta oferta de empleo te interesa"} //Subject of the mail
              body={`Echa un vistazo a esta oferta ${offer?.titulo
                } de Retorna en ${localidades.find(
                  (l: any) =>
                    l.id.toString().trim() === offer?.idLocalidad?.toString().trim()
                )?.name ?? ""
                }`} //body of the mail (the url of the offer is appended after, with the separator)
              separator={" : "} //Separator to use between body and url to share
            >
              <EmailIcon/>
            </EmailShareButton>
          </Box>
          <Box sx={styles.shareIcon}>
            <WhatsappShareButton
              url={`${share_uri}${window.location.pathname}?${searchParams}`}
              title={"Echa un vistazo a esta oferta de empleo de Retorna"} //Title of the shared page
              separator={" : "} // Separator between title and url
            /* sx={styles.social_icon_button} */
            >
              <WhatsAppIcon/>
            </WhatsappShareButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Enroll;