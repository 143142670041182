/* eslint-disable */
import { Box, Icon, Typography,CircularProgress} from '@mui/material';
import React, { useContext, useRef } from 'react';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import MoveUp from '../../../assets/icons/moveUp';
import TrashRed from '../../../assets/icons/trashRed';
import { tiposDocumentos } from '../../enums/enums';
import { SnackContext, SnackSeverity } from '../../contexts/SnackHandler';
import  { IFileUploader } from '../../interfaces/DocumentsInterfaces';

const FileUploader = (props:IFileUploader)  => {
    const {
        label,
        docType,
        disabled,
        loadingDocuments,
        documentToUpload,
        setDocumentToUpload,
        docId = 0,
        deleteDoc = null
    } = props as IFileUploader;
    const { t } = useTranslation();
    const nameFile = documentToUpload?.nombre ?? label;
    const showSnack = useContext(SnackContext);

    const refs = useRef([
        React.createRef(),
        React.createRef(),
        React.createRef(),
      ]) as any;

    const handleUploaderChange = (files: any) => {
        const reader = new FileReader() as any;
        reader.readAsDataURL(files[0]);
        if (!/\./.test(files[0].name)) {
            showSnack(t("error_docs.error_not_valid4"), SnackSeverity.ERROR);
            return;
        }
        if (files[0].size > 20971520) {
            showSnack(t("error_docs.error_not_valid5"), SnackSeverity.ERROR);
            return;
        }
        reader.onload = function () {
            const replacement = "_r.";
            setDocumentToUpload({
                nombre: files[0].name.replace(/.([^.]*)$/, replacement + "$1"),
                fichero: reader.result.substring(reader.result.indexOf(",") + 1),
            });
        };
    };

    const handleDeleteProvisionalDocument = () => {
        if (docId) {
            return deleteDoc(docId, nameFile);
        }
        setDocumentToUpload(null);
    };


    const loadOrShowRedTrash = () => {
        return (
        loadingDocuments ?
           <CircularProgress size={"18px"}/>
            :
            <Icon
                color="primary"
                sx={{cursor: "pointer"}}
                onClick={() => handleDeleteProvisionalDocument()}
            >
                <TrashRed title={t("icons_tooltips.delete")} />
            </Icon>
        );
    };

    const returnInputUploader = () => {
        return (
            <label htmlFor={label} style={{ cursor: "pointer" }}>
                {!disabled &&
                    <input
                        style={{ display: "none" }}
                        id={label}
                        ref={refs.current[1] as any}
                        name={label}
                        type="file"
                        accept={
                            docType?.id === tiposDocumentos.FOTOGRAFIA
                                ? ".jpeg, .jpg, .png"
                                : ".doc, .docx, .pdf, .jpeg, .jpg, .png"
                        }
                        onChange={(event: any) =>
                            handleUploaderChange(event.target.files)
                        }
                    />
                }
                <Icon color="primary">
                    <MoveUp title={t("icons_tooltips.upload_document")} fill={disabled ? "grey" : ""} />
                </Icon>
            </label>
        );
    };
    
    const returnUploader = () => {
        return (
            <Box sx={styles.attach_section}>
                <Typography
                    lineHeight="24px"
                    color={!disabled ? "primary" : "grey"}
                    sx={nameFile === label ? {...styles.attach_label} : {...styles.attach_label_black}}
                    onClick={() => nameFile === label && refs.current[1].current.click()}>
                    {nameFile}
                </Typography>
                <Box
                    lineHeight={"24px"}
                    style={{marginLeft: 'auto'}}
                >
                    {nameFile === label ? (
                        returnInputUploader()
                    ) :
                        loadOrShowRedTrash()
                    }
                </Box>
            </Box>
        );
    };

    return (
        returnUploader()
    );
};

export default FileUploader;