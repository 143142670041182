
const styles = {
    item: {
      marginBottom: "20px",
      width: "100%"
    },
    upperRow: {
      display: "flex",
      justifyContent: "space-between",
      height: "28px",
      marginBottom: "10px"
    },
    buttons: {
      display: "flex",
      alignItems: "center"
    },
    title: {
      fontSize: "15px",
      fontWeight: "800",
      display: "flex",
      alignItems: "center"
    },
    lowerRow: {
      fontSize: "13px",
      display: "flex",
      alignItems: "center",
    },
    lowerText: {
      fontSize: "13px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    icon: {
      width: "28px",
      height: "28px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    },
  };
  
  export default styles;
  