const styles = {
    principal_container: {
      padding:"20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginLeft:"55%"
    },
    summary_icon_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: {sm:"center" ,md:"flex-start"},
      width: "100%",
      marginBottom: "30px",
    },
    title_text: {
      fontSize: 36,
      fontWeight: 900,
      marginBottom: "30px",
    },
    personal_data_inner_sections: {
      fontSize: 16,
      fontWeight: 600,
      color: "rgba(0,0,0,0.87)",
      margin: "5px",
      wordBreak: "break-all",
      overflowWrap: "break-word",
    },
    personal_data_section_container: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "30px",
    },
    personal_data_section_negative_margin: {
      display: "flex",
      flexDirection: "column",
      margin: "-5px",
    },
    fullname: {
      color: "rgba(0,0,0,0.87)",
      fontSize: 18,
      fontWeight: 900,
      margin: "5px",
      wordBreak: "break-word",
    },
    candidate_last_update: {
      display: "inline-block",
      color: "rgba(0,0,0,0.87)",
      fontSize: 12,
      fontWeight: 500,
      margin: "5px",
    },
    email:{
      overflow: "hidden",
      whiteSpace:  "nowrap",
      textOverflow: "ellipsis",
      width: "290px",
        },

    svg_style_no_shrink: {
      height: "60px",
      width: "64px",
    },
    svg_style: {
      height:{sm:"30px",md:"60px"} ,
      width:{sm:"32px",md:"64px"} ,
    },
  };

  export default styles;