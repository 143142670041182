import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  buttonOutlined: (props) => ({
    width: props.size,
    minHeight: 36,
    maxHeight: 36,
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
  }),
  buttonOutlined_label: {
    textTransform: "none",
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "16px",
    [theme.breakpoints.up("md")]: {
      fontWeight: "18px",
    },
  },
}));

function ButtonOutlined(props) {
  const { text, onClick, startIcon = null, customClass = "" , color, backgroundColor, fontSize, fontWeight} = props;
  const classes = useStyles(props);
  return (
    <Button
      classes={{
        root: classes.buttonOutlined + " ",
        label: classes.buttonOutlined_label,
      }}
      style={{
        textTransform: "none",
        backgroundColor: backgroundColor ?? "white",
        fontSize: fontSize ?? "16px",
        lineHeight: "16px",
        color:color?? "#DA291C",
        fontWeight: fontWeight ?? 800 ,
        borderColor: color ?? "#DA291C",
        ...customClass
      }}
      variant="outlined"
      disableElevation
      onClick={onClick}
      startIcon={startIcon}
      sx={{...props.customClassSx}}
    >
      {text}
    </Button>
  );
}

export default ButtonOutlined;
