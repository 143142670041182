import React, { useCallback, useState, createContext, useMemo } from "react";
import i18n from "../../i18n";

type Props = {
    children: React.ReactNode,
};
interface ILanguageContext {
    currentLanguage?: string,
    changeLanguage?: any
}


export const LanguageContext = createContext<ILanguageContext>({} as ILanguageContext);

export const LanguageProvider = ({ children }: Props) => {

    const [currentLanguage, setCurrentLanguage] = useState<string>("es");

    const changeLanguage = useCallback((newLanguage: string, notLogged: boolean = true) => {
        (notLogged) && sessionStorage.setItem("language", newLanguage);
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    }, []);

    const value = useMemo(() => { return { currentLanguage, changeLanguage }; }, []);

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );

};

export default LanguageContext;