const firstSectionDescription = [
    { id: 0, name: "legalRequirements.content59" },
    { id: 1, name: "legalRequirements.content21" },
    { id: 2, name: "legalRequirements.content20" },
    { id: 3, name: "legalRequirements.content60" },
    { id: 4, name: "legalRequirements.content61" },
];

const secondDescriptionOne = [
    { id: 0, name: "legalRequirements.content23" },
    { id: 1, name: "legalRequirements.content24" },
    { id: 3, name: "legalRequirements.content25" },
];

const secondDescriptionTwo = [
    { id: 4, name: "legalRequirements.content64" },
    { id: 5, name: "legalRequirements.content65" },
    { id: 6, name: "legalRequirements.content68" }
];

const secondDescriptionThree = [
    { id: 7, name: "legalRequirements.content69" },
    { id: 8, name: "legalRequirements.content70" },
    { id: 9, name: "legalRequirements.content72" }
];
const secondDescriptionFour = [
    { id: 10, name: "legalRequirements.content73" },
    { id: 11, name: "legalRequirements.content74" },
    { id: 12, name: "legalRequirements.content75" },
    { id: 13, name: "legalRequirements.content76" },
    { id: 14, name: "legalRequirements.content77" }
];

const thirdSectionDescription = [
    { id: 0, name: "legalRequirements.content37" },
    { id: 1, name: "legalRequirements.content38" },
    { id: 3, name: "legalRequirements.content39" },
    { id: 4, name: "legalRequirements.content40" },
    { id: 5, name: "legalRequirements.content41" },
    { id: 6, name: "legalRequirements.content42" },
    { id: 7, name: "legalRequirements.content43" },
    { id: 8, name: "legalRequirements.content45" },
    { id: 9, name: "legalRequirements.content46" },
];

const fourthSectionDescription = [
    { id: 0, name: "legalRequirements.content47" },
    { id: 1, name: "legalRequirements.content48" },
];

const fifthSectionDescription = [
    { id: 0, name: "legalRequirements.content50" },
    { id: 1, name: "legalRequirements.content51" },
    { id: 2, name: "legalRequirements.content52" },
];

//Pinta subtitulos con sus parrafos
const secondSectionSubtitles = [
    { id: 0, subtitle: "legalRequirements.content22", description: secondDescriptionOne },
    { id: 1, subtitle: "legalRequirements.content63", description: secondDescriptionTwo },
    { id: 2, subtitle: "legalRequirements.content67", description: secondDescriptionThree },
    { id: 3, subtitle: "legalRequirements.content71", description: secondDescriptionFour },
];

//Pinta el titulo de cada seccion con sus parrafos
export const sections = [
    { id: 0, title: "legalRequirements.subtitle4", elements: firstSectionDescription },
    { id: 1, title: "legalRequirements.subtitle5", elements: secondSectionSubtitles },
    { id: 2, title: "legalRequirements.subtitle7", elements: [{ id: 0, name: "legalRequirements.content62" }] },
    { id: 3, title: "legalRequirements.subtitle8", elements: thirdSectionDescription },
    { id: 4, title: "legalRequirements.subtitle9", elements: fourthSectionDescription },
    { id: 5, title: "legalRequirements.subtitle10", elements: [{ id: 0, name: "legalRequirements.content49" }] },
    { id: 6, title: "legalRequirements.subtitle11", elements: fifthSectionDescription },
];

export const introductionList = [
    { id: 0, name: "legalRequirements.content14" },
    { id: 1, name: "legalRequirements.content15" },
    { id: 2, name: "legalRequirements.content16" },
    { id: 3, name: "legalRequirements.content17" },
    { id: 4, name: "legalRequirements.content18" },
    { id: 5, name: "legalRequirements.content19" },
];

