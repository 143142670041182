const styles = {
    layoutContainer: {
        width: "100%",
        height: "100vh",
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "min-content 1fr min-content",
        gridTemplateAreas: "'header' 'body' 'footer'",
    },
    layoutBody: {
        gridArea: "body",
        display: "flex",
        gridTemplateAreas: "'. body_container .'",
        justifyContent: {
            sm: "flex-start",
        },
        flexDirection: "column",
    },
    headerContainer: {
        display: "flex",
        backgroundColor: "#F4FBFF",
        justifyContent: "space-between",
        flexDirection: {xs: "column", md: "row"},
        alignItems: {xs: "flex-end", md: "unset"},
        maxWidth: "100%",
    },
    loading: {
        display: "flex",
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
    },
    hasBackgroundImage: {
        backgroundImage: "url(images/fondo1920x1080.jpg)",
        backgroundPosition: "center",
    },
    header: {
        gridArea: "header",
        position: "fixed",
        width: "100%",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: {xs: "column", md: "row"},
        alignItems:{xs: "center", md: "center"},
        justifyContent: "space-between",
        backgroundColor: "white",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: { header1: "1.5625rem", header2: "2.34375rem", header3: "3.125rem", md: "1.171875rem" },
        paddingRight: { header1: "1.5625rem", header2: "2.34375rem", header3: "3.125rem", md: "1.171875rem" },
        paddingTop: ".58125rem",
        paddingBottom: ".78125rem",
        zIndex: 900,
    },
    header_logged:{
        flexDirection: {xs: "row!important"},
    },
    headerIcon: {
        width: "24px",
        height: "24px",
        color: "#5D5A5A",
        marginLeft: "24px",
        cursor: "pointer",
    },
    headerLogo: {
        height: "43px",
        cursor: "pointer",
    },
    headerLogoMobile: {
        width: "119px",
        height: "26px",
    },
    headerTitleClaim:{
        display: "flex",
        flexDirection:"column", 
        width: "240px",
    },
    headerTitle: {
        lineHeight: "20px",
        color: "#D9291C",
        fontWeight:"bold",
        fontSize:"18px",
        cursor:"pointer"     
    },
    headerTitleMobile: {
        height: "24px",
        color: "#D9291C",
        fontWeight:"600",
        fontSize:"18px"
    },
    headerClaim: {
        fontSize:"12px"
    },
    headerClaimMobile: {
        height: "24px",
        fontSize:"14px"
    },
    header_buttons_grid:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    headerIconActionContainer: {
        float: "left",
        fontWeight: 700,
        fontSize: "12px",
        cursor: "pointer",
    },
    headerIconAction: {
        verticalAlign: "top",
        marginRight: "5px",
        display: "inline-block",
    },
    icon_candidates:{
        padding: '0px 15px 1px 3px'
    },
    headerIconText: {
        display: "inline-block",
    },
    actions: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "600",
    },
    drawer: {
        top: "60px!important",
    },
    paper: {
        top: "60px!important",
        width: "25%",
        padding: "24px 20px",
    },
    backdrop: {
        top: "60px!important",
        backgroundColor: "transparent",
    },
    listItem: {
        marginBottom: "12px",
        cursor: "pointer",
        borderRadius: "4px",
        "&:hover": {
            backgroundColor: "#F5F5F5",
        },
        "&:last-child": {
            marginBottom: "0px",
        },
    },
    listItemText: {
        fontSize: 16,
        fontWeight: 600,
        color: "black",
    },
    menuLangContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        marginRight: '20px'
    },
    menuContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "4px",
    },
    menuItem: {
        fontSize: { sm: 16, md: 14 },
        lineHeight:  "21px",
        color: { sm: "black", md: 'primary.main' },
        cursor: "pointer",
        "& + &": {
            marginLeft: { lg:"30px"},
        },
        marginLeft: { sm: "10px" },
        paddingLeft:{xs:"20px"},
        '@media (max-width: 1176px)': {
            fontSize: "13px",
            paddingLeft: "8px"
        },
        '@media (max-width: 1024px)': {
            fontSize: "12px",
            paddingLeft: "4px"
        }

    },
    menuItemUnlogged: {
        fontSize: { sm: 16, md: 14 },
        lineHeight: { sm: 0, md: "21px" },
        color: { sm: "black", md: 'primary.main' },
        cursor: "pointer",
        marginLeft: { sm: "30px" },
        fontWeigth: { sm: 400, md: 700 },
    },
    menuItemNl: {
        fontSize: { sm: 16, md: 14 },
        lineHeight: "21px",
        color: { sm: "black", md: 'primary.main' },
        cursor: "pointer",
        fontWeight: 900,
        textTransform: "uppercase",
        "& + &": {
            marginLeft: "30px",
        },
        marginLeft: { sm: "10px" }
    },
    mobileMenuContainer: {
        display: "flex",
        flexDirection: "column",
        height: "var(--height)",
        width: "100%",
        position: "absolute",
        left: 0,
        top: "100%",
        transition: "height .3s",
        overflow: "hidden",
        backgroundColor: "white",
        boxShadow: "0 10px 6px -6px #777",
        zIndex: 1000,
    },
    mobileMenuItemContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        paddingTop: "6px",
        paddingBottom: "6px",
        height: "32px",
    },
    footer_container: {
        display: "flex",
        width: "100%",
        gridTemplateRows: "1fr .25fr",
        backgroundColor: "#F4FBFF",
        gridArea: "footer",
        justifyContent: {sm: "center"},
        gridTemplateColumns: {sm: "1fr"},
      },
      footer_init: {
        display: "flex",
        flexDirection: {xs: "column", md: "row"},
        width: "100%",
        justifyContent: "space-between",
    },
      footer_final: {
        display: {md: "grid", sm: "block"},
        width: "100%",
        gridTemplateColumns: { sm: "1fr", md: "1fr 1fr" },
        padding: {md: "9px 40px", sm: "0 20px"},
        gridTemplateRows: { sm:  "1fr 1fr", md: "unset" },
      },
      part1: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: "20px"
      },
      part2: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: {md: "flex-end", sm:"flex-start"},
        paddingLeft: "20px"
      },
      footer_text: {
        fontSize: 12,
        lineHeight: "18px",
        color: "black",
        padding: 0,
        },
      text: {
        fontSize: 12,
        lineHeight: "18px",
        color: "black",
      },
      logo: {
        display: "flex",
        flexDirection: {xs: "column"},
        marginLeft: {xs: "unset", md: "20px"},
        alignItems: {xs: "center", md: "unset"},
      },
      nortempoLogo: {
        margin: {xs:"12px 0 6px 0", md: "unset"},
        cursor: "pointer",
        width: '100px',
        height: "44px"
      },
      xuntaLogo: {
        margin: {xs:"4px 0 6px 14px", md: "0 12px 0 0"},
        minWidth: '300px',
        height: "40px",
        cursor: "pointer"
      },
      contact: {
        width: "100%",
        alignItems: "center",
        padding: {md: "0 20px", sm: 0},
        display: "flex",
        flexDirection: {xs: "column", md: "column"},
        margin: {md: "10px 0px 0px 20px"},
        alignSelf: {xs: "center", md: "unset"}

      },
      socialMedia: {
        display: "flex",
        width: {xs: "50%", md: "30%"},
        flex: {md: 1},
        justifyContent: {xs: "space-between"},
        alignItems: {md: "flex-end"},
        margin: {xs: "10px 20px", md: "10px 0 0px 80px"},
      },
      socialIcon: {
        cursor: "pointer",
      },
      middleBar: {
        margin: "0 4px"
      },
      languages:{
        display: "flex",
        justifyContent:"flex-end",
        marginRight:{xs:'6px'},
        marginBottom:"2px",
        fontSize: "14px",
      },
      home_separator: {
        paddingLeft: "20px",
        marginLeft: "12px",
        borderLeft: "1px solid #86b5e2",
      },
      home_icon: {
        width: "26px",
        height: "26px",
        cursor: "pointer"
      },
      alignTitleLanguage:{
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex'
      }
};

export default styles;
