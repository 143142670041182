import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEn from "./assets/translations/en/translation.json";
import translationEs from "./assets/translations/es/translation.json";
import translationPt from "./assets/translations/pt/translation.json";
import translationGl from "./assets/translations/gl/translations.json";

const resources = {
  en: {
    translation: translationEn
  },
  es: {
    translation: translationEs
  },
  pt: {
    translation: translationPt
  },
  gl: {
    translation: translationGl
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources,
    debug: true,
    lng: "es",
    fallbackLng: "es", // use en if detected lng is not available

    keySeparator: ".", // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
