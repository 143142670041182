
import React, { useContext,useCallback } from "react";
import Text from "./Text";
import Subtitle from "./Subtitle";
import { Countries } from "../../../../../shared/enums/enums";
import styles from "../styles";
import { Icon ,CircularProgress} from "@mui/material";
import Location from "../../../../../assets/icons/location";
import TimeClock from "../../../../../assets/icons/timeClock";
import { useTranslation } from "react-i18next";
import Sign from "../../../../../assets/icons/sign";
import MoneyIcon from '@mui/icons-material/Money';
import i18next from "i18next";
import { findIdInDataList, getContractTypes, getProvincias, getSalaryRange, getSalaryType } from "../../../../../shared/utils/functions";
import { MaestroContext } from "../../../../contexts/MaestroContext";

function BasicData({ offer, matchesMobile}: any) {
  const { t } = useTranslation();
  const provinces = getProvincias(isLanguageGalician());
  const contractTypes = getContractTypes(isLanguageGalician ());
  const salaryRange = getSalaryRange(isLanguageGalician ());
  const salaryType = getSalaryType(isLanguageGalician ());

  const { paises,localidades, tipoHorario } = useContext(MaestroContext);

  const getTipoHorarioName = useCallback(() => {
    return tipoHorario.length
      ?
      findIdInDataList(tipoHorario, offer?.tipoHorario?.id)
      :
      <CircularProgress style={{ marginLeft: '5px' }} size={"1rem"} />;
  }, [tipoHorario]);

  function isLanguageGalician () {
    return i18next.languages[0] === "gl" && "es";
  }
  
  function getLocationNameSpain() {
    let result = "";
    if (offer.comunidadAutonoma) {
      result = offer.comunidadAutonoma?.descripcion;
    } else {
      const locationName = findIdInDataList(localidades, offer?.localidad?.id);
      const provinceName = findIdInDataList(provinces, offer?.provincia?.id);
      const coma = locationName && ",";
      result = `${locationName}${coma} ${provinceName}`;
    }
    return result;
  }

  function getLocation() {
    const provinceName = provinces.find((prov: any) => Number(prov.id) === offer?.provincia?.id)?.name;
    const country = paises.find((pais: any) => Number(pais.id) === offer?.pais?.id)?.name;
    const region = provinceName !== "" ? `, ${provinceName}` : "";
    const city = offer?.ciudad ? `, ${offer?.ciudad}` : "";

    return `${country}${region}${city}`;
  }

  function getSalaryRangeResult() {
  
    if(offer?.ocultarSalario){
      return t("offerDetails.no_avaliable_salary");
    }

    const salaryFrom = salaryRange.find(
      (salaryRanges: any) => Number(salaryRanges.id) === offer?.salarioDesde?.id
    )?.name;
    const salaryTo =  salaryRange.find(
      (salaryRanges: any) => Number(salaryRanges.id) === offer?.salarioHasta?.id
    )?.name;
    const salary_Type =  salaryType.find(
      (salaryTypes: any) => Number(salaryTypes.id) === offer?.tipoSalario?.id
    )?.name;
    
    return `${salaryFrom} - ${salaryTo} ${salary_Type}`;
  }

  function handleMarginSectorOrJobCualquiera(){
    return (offer?.sector.id !== 101 || offer?.puesto.id !== 743) ? styles.subtitle_separator : styles.subtitle_separator_cualquiera;
  }

  return (
    <>
      <Text
        fontSize={matchesMobile ? 21 : 36}
        lineHeight={matchesMobile ? "26px" : "50px"}
        fontWeight={900}
        text={offer?.titulo}
      />
      <Subtitle
        text={offer?.pais?.id == Countries.SPAIN ? getLocationNameSpain() : getLocation()}
        className={styles.subtitle_margin_bottom}
        icon={
          <Icon color="primary" sx={styles.subtitle_icon}>
            <Location title={t(`icons_tooltips.location`)} />
          </Icon>
        }
      />
      <Subtitle
        text={getTipoHorarioName()}
        className={styles.subtitle_margin_bottom}
        icon={
          <Icon color="primary" sx={styles.subtitle_icon}>
            <TimeClock title={t(`icons_tooltips.schedule_type`)} />
          </Icon>
        }
      />
      <Subtitle
        text={findIdInDataList(contractTypes, offer?.tipoContrato?.id)}
        className={styles.subtitle_margin_bottom}
        icon={
          <Icon color="primary" sx={styles.subtitle_icon}>
            <Sign title={t(`icons_tooltips.contract_type`)} />
          </Icon>
        }
      />
      <Subtitle
        text={getSalaryRangeResult()}
        className={{...styles.subtitle_margin_bottom, ...styles.subtitle_separator}}
        icon={  
         <Icon sx={styles.subtitle_icon} title={t(`icons_tooltips.salary`)}>
            <MoneyIcon sx={styles.money_Icon} fontSize="small"   />
        </Icon>
        }
      />
      <Subtitle text={offer?.sector.id !== 101 ? offer?.sector?.descripcion : ""} isBold />
      <Subtitle text={offer?.puesto.id !== 743 ? offer?.puesto?.descripcion : ""} isBold />
      <Subtitle
        text={`${offer?.numeroVacantes} ${t("offerDetails.vacancies")}`}
        className={handleMarginSectorOrJobCualquiera()}
        isBold
      />
    </>
  );
}

export default BasicData;