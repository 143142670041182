
const styles = {
    body: {
      backgroundImage: "url(/images/documents_background.jpg)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100%",
      width: "100%",
      padding: {xs: "unset", md: "60px 5% 20px 5%"},
      display: "flex",
      minHeight: "440px",
      flexDirection: {xs: "column", md: "row"},
      paddingRight: {xs: "unset", md: "200px"},
      justifyContent: "center"
    },
    leftColumn: {
      width: {xs: "100vw", md: "315px"},
      paddingTop: {xs: "80px", md: "0"},
      height: "100%",
      padding: "15px 20px",
      marginRight: {xs: "unset", md: "80px"},
    },
    rightColumn: {
      width: {xs: "100vw", md: "350px"},
      height: "100%",
      padding: {xs: "20px", md: "135px 0 0 0"}
    },
    icon: {
      height: "64px",
      display: "flex",
      alignItems: "center",
      justifyContent: {xs: "center", md: "unset"},
      width: {xs: "100%", md: "64px"},
      marginBottom: "30px"
    },
    icon_gray: {
      width: {xs: "100%", md: "200px"},
      height: "200px",
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      justifyContent: {xs: "center", md: "unset"}
    },
    title: {
      fontSize: "35px",
      fontWeight: "900",
      lineHeight: 1.3,
      marginBottom: "12px"
    },
    noDocumentsBlock: {
    },
    noDocumentsText: {
      fontSize: "14px",
      marginBottom: "20px"
    }
  };
  
  export default styles;
  