
const styles = {
    body_container: {
        display: "grid",
        gridArea: "body_container",
        padding: {md: "0 10%"},
        gridTemplateAreas: {xs:"'bodyLeft' 'bodyRight'", md:"'bodyLeft separator bodyRight'"},
        gridTemplateColumns: {xs:"1fr", md:"3fr 1fr 8fr"},
        gridTemplateRows: {xs:"auto auto", md:"1fr"},
        marginTop: {xs: "20px", md: "unset"}
      },
      body_left_col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gridArea: "bodyLeft",
        padding: "0 20px",
      },
      body_right_col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gridArea: "bodyRight",
        padding: "0 20px",
        wordBreak: "break-word",
        paddingTop:  {xs: "20px", md: "40px"},
      },
      enroll_button: {
        color: "#FFFFFF",
        height: "36px",
        textTransform: "none",
        fontWeight: "bold",
        width: "100%",
        marginTop: {xs: "20px", sm: "unset"},
        marginBottom:  {xs: "20px", sm: "20px"},
        fontSize:  {xs: "16px", sm: "unset"},
      },
      enroll_button_kq: {
        color: "#FFFFFF",
        height: "36px",
        textTransform: "none",
        fontWeight: "bold",
        width: "100%",
        marginRight: "10px",
        marginTop:  {xs: "30px", sm: "unset"},
        marginBottom:  {xs: "30px", sm: "35px"},
        fontSize:  {xs: "16px", sm: "unset"},
      },
      footer_padding: {
        paddingBottom: "50px",
      },
      no_margin: {
        margin: 0,
      },
      header_logo: {
        margin: "0 0 15px 0",
        width: {xs: "200px", sm: "unset"},
      },
      question_title_input: {
        width: "100%",
        height: "100px",
        borderRadius: "4px",
        border: "1px solid #D8D8D8",
        resize: "none",
        fontFamily: "Nunito Sans, sans-serif",
        padding: "12px",
        marginTop: "10px",
      },
      counter_chars: {
        fontSize: "12px",
        color: "#a1a1a1",
      },
      no_margin_white_space: {
        margin: "0px",
        whiteSpace: "pre-wrap",
      },
      register_or_login_text: {
        marginBottom: "30px",
      },
      lonely_text: {
        textAlign: "justify",
      },
      section_separator: {
        marginTop: {xs: "25px", sm: "30px"},
        whiteSpace: "pre-wrap",
        textAlign: "justify",
      },
      buttons_kq: {
        display: "flex",
        marginTop: "30px",
      },
      section_separator_kq: {
        marginTop: {xs: "5px", sm: "15px"},
        whiteSpace: "pre-wrap",
        textAlign: "justify",
      },
      checkbox_line: {
        display: "flex",
        alignItems: "center",
        marginLeft: "-10px",
      },
      section_title_separator: {
        marginBottom: "10px",
      },
      share: {
        marginTop: "43px",
      },
      snackBar: {
        backgroundColor: "#FFFFFF",
        color: "#000000",
      },
      subtitle_icon: {
        float: "left",
      },
      subtitle_margin_bottom: {
        marginBottom: "10px",
      },
      retorna: {
        width: {xs: "240px", md: "200px"},
        lineHeight: {xs: "26px", md: "20px"},
        color: "#D9291C",
        fontWeight:"bold",
        fontSize: {xs: "24px", md: "16px"},
        marginTop:  {xs: "14px", md: "unset"},
        cursor:"pointer"     
      },
      retornaClaim: {
        fontSize: {xs: "18px", md: "13px"},
        marginBottom: "10px"
      },
      subtitle_separator: {
        marginBottom: {xs: "25px", sm: "43px"}
      },
      subtitle_separator_cualquiera:{
        marginBottom: {xs: "25px", sm: "20px"}
      },
      title: {
        marginBottom: "24px",
      },
      social_share_container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingBottom: {sm: "40px"},
      },
      social_share: {
        display: "flex",
        width: "100%",
        marginTop: "14px",
      },
      social_icon_button: {
        cursor: "pointer",
        outline: "none",
        "& + &": {
          marginLeft: 20,
        },
      },
      shareIcon: {
        marginRight: "16px",
      },
      offer_not_found_container: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: {xs:"auto", sm:"1fr"},
        padding: {xs:"0 20px", sm:"unset"},
      },
      offer_not_found: {
        fontSize: "25px",
        fontWeight: "900px",
        padding: "0px",
        margin: "0px",
        justifySelf: "center",
        alignSelf: "center",
      },
      layout: {
        gridTemplateRows: "minMax(auto, 1fr)",
        gridTemplateColumns: "1fr 6fr 1fr",
      },
      kq_title: {
        fontWeight: 900,
      },
      compet_list: {
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: {xs:"0px", sm:"20px"},
        alignItems: "center",
        marginTop: "24px",
      },
      competence: {
        margin: "0px",
        padding: "0px",
      },
      autoEvalContainer: {
        width: "100%",
        padding: {xs:"24px 0px", sm:"14px 0px"},
      },
      compet_container: {
        display: "flex",
        flexDirection: "column",
        marginTop: "52px",
        position: "relative",
        justifyContent: "center",
      },
      action_buttons_grid: {
        marginTop: {xs:"70px", sm:"30px"},
        marginBottom: {xs:"50px", sm:"unset"},
        width: "100%",
        display: "grid",
        gridTemplateRows:{xs:"10px", sm:"unset"},
        gridTemplateColumns: {xs:"1fr", sm:".25fr .5fr .25fr"},
        gridTemplateAreas: {xs:`
        'return'`, sm:"unset"}
      },
      money_Icon:{
        marginLeft:"-1px"
      },
      minimum_Experience:{
        marginRight: "12px",
        marginLeft: "4px",
      },
      minimum_Experience_text:{
        marginTop:"20px",
      },
      chip: {
        marginTop: "8px",
      },
      title_knowledge: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '900',
        margin:"0 16px 15px 4px"
      },
      separator: {
        marginRight: "10px",
        marginBottom: "10px"
      },
      no_Space: {
        display: "none"
      },
};

export default styles;
