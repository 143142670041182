import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CurriculumIcon from "../../../assets/icons/curriculumIcon";
import styles from "./TuCurriculumHeaderStyles";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";

interface ITuCurriculumHeader{
    nombre?:any ,
    primerApellido?:any,
    segundoApellido?:any,
    correoElectronico?:string,
    prefijo?:string,
    telefono?:any,
    fechaModificacion?:any,
    isPhotoEditable?:boolean,
    idPhoto?:any
}

function TuCurriculumHeader({
    nombre,
    primerApellido,
    segundoApellido,
    correoElectronico,
    prefijo,
    telefono,
    fechaModificacion,
    isPhotoEditable = true,
    idPhoto
  }:ITuCurriculumHeader){

  const { t } = useTranslation();

  function datosPersonalesSection() {
    return (
      <Box  sx={styles.personal_data_section_container}>
        <Box sx={styles.personal_data_section_negative_margin}>
          {nombre !== null &&
            primerApellido !== null &&
            segundoApellido !== null && (
              <Box sx={styles.fullname}>
                {`${nombre} ${primerApellido} ${segundoApellido}`}
              </Box>
            )}

          {fechaModificacion !== null && (
            <Box sx={styles.candidate_last_update}>
              {`${t("candidateDashboard.last_update")} ${fechaModificacion}`}
            </Box>
          )}
          <Box sx={styles.personal_data_inner_sections}>
            {correoElectronico !== null && (
              <Box sx={styles.email}>{correoElectronico}</Box>
            )}
            {prefijo !== null && telefono !== null && (
              <Box>{`+${prefijo} ${telefono}`}</Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={styles.principal_container}>
      <Box sx={[styles.summary_icon_container,styles.svg_style ]}>
        <CurriculumIcon
          title={t("icons_tooltips.curriculum")}
          color="#DA291C"
          noShrink
        />
      </Box>
      <Box sx={styles.title_text}>{t("curriculum.title")}</Box>
      <ProfilePicture
        isEditAvailable={isPhotoEditable}
        propsMargin="0 0 30px 0"
        idFoto={idPhoto}
      /> 
      {datosPersonalesSection()}
    </Box>
  );
}

export default TuCurriculumHeader;
