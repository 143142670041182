import React, { useState, useEffect, useCallback,useContext } from "react";
import { FormControl, Typography,Box,useTheme,useMediaQuery  } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "../../../../../i18n";
import {
  getDirectManger,
  getWorkExperience,
  getKnowledge,
  getLevelExperiencie,
  getPlace,
  getSectors,
  getFunction,
  getStaffInCharge,
  getEtts,
  updateWorkExperience,
  addWorkExperience,
  deleteWorkExperience,
  getDatosPersonales
} from "../../../../../shared/hooks/userApi";
import {
  returnLangId,
  validateDate,
  optionEquality,
  getLabelForSelectComponent,
  getSalaryRange,
  getTiposSalario,
  filterSalaryRangeOptions,
  formatToLocalDate
} from "../../../../../shared/utils/functions";
import YourExperience from "../../../../../assets/icons/yourExperience";
import AntSwitch from "../../../../../shared/components/AntSwitch/antSwitch";
import InputDate from "../../../../../shared/components/DatePicker/datePickerWithLabel";
import AlertDialog from "../../../../../shared/components/AlertDialog/alertDialog";
import AutocompleteWithChips from "../../../../../shared/components/AutocompleteWithChips/autocompleteWithChips";
import AutocompleteWithLabel from "../../../../../shared/components/SelectCustoms/autocompleteWithLabelAnimation";
import TuCurriculumHeader from "../../../../../shared/components/TuCurriculumHeader/TuCurriculumHeader";
import InputWithLabel from "../../../../../shared/components/Inputs/InputCustom";
import { EMPLOYEE, 
         INTERMEDIATE, 
         INTERN, 
         MAX_CHAR_DESCRIPTION, 
         SPECIALIST 
        } from "../../../../../constants";
import styles from "./stylesExperience";
import { useApi } from "../../../../../shared/hooks/useApi";
import { languages } from "../../../auth/AuthWrapper";
import { SnackContext,SnackSeverity } from "../../../../../shared/contexts/SnackHandler";
import { useSearchParams,useNavigate } from "react-router-dom";
import { Routes } from "../../../../../shared/enums/enums";
import AddToCVActionButtons from "../../../../../shared/components/AddToCVActionButtons/AddToCVActionButtons";
import { MaestroContext } from "../../../../contexts/MaestroContext";


function WorkExperience(props:any) {
  const { t } = useTranslation();
  const theme = useTheme();
  const showSnack = useContext(SnackContext);
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));
  const callApi = useApi();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const { prefijos } = useContext(MaestroContext);
  
  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [idFotografia, setIdFotografia] = useState(null);
  const [fechaModificacion, setFechaModificacion] = useState(null as any);

  const [loading, setLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allFunctions, setAllFunctions] = useState([]);
  const [allKnowledges, setAllKnowledges] = useState([]);
  const [allPlaces, setAllPlaces] = useState([]);
  const [allSectors, setAllSectors] = useState([]);
  const [allEtts, setAllEtts] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [allManagers, setAllManagers] = useState([]);

  const [sector, setSector] = useState(null as any);
  const [ett, setEtt] = useState(null as any);
  const [isEtt, setIsEtt] = useState(false);
  const [job, setJob] = useState(null as any);
  const [company, setCompany] = useState("");
  const [functions, setFunctions] = useState([]);
  const [isWorkingHere, setIsWorkingHere] = useState(false);
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null as any);
  const [knowledges, setKnowledges] = useState([]);
  const [references, setReferences] = useState<any>([]);
  const [hasReferences, setHasReferences] = useState(false);
  const [referenceName, setReferenceName] = useState("");
  const [referencePhonePrefix, setReferencePhonePrefix] = useState(
    prefijos.find((prefix:any) => prefix.prefix === "34")
  );
  const [level, setlevel] = useState(null as any);
  const [referencePhoneNumber, setReferencePhoneNumber] = useState("");
  const [referenceEmail, setReferenceEmail] = useState("");
  const [addNewExperience, setAddNewExperience] = useState(false);
  const [minSalary,setMinSalary] = useState(null as any);
  const [maxSalary,setMaxSalary] = useState(null as any);
  const [typeSalary,setTypeSalary] = useState(null as any);
  const [staffInCharge, setStaffInCharge] = useState(null as any);
  const [directManger, setDirectManger] = useState(null as any); 
  const [descripcionPuesto,setDescripcionPuesto] = useState(""); 

  const [knowledgeError, setKnowledgeError] = useState(null);
  const [functionsError, setFunctionsError] = useState(null);
  const [saveWrong, setSaveWrong] = useState(false);
  const [saveWrongReferences, setSaveWrongReferences] = useState(false);
  const [levelsError, setLevelsError] = useState(null as any);
  const [staffError, setStaffError] = useState(null);
  const [managerError, setManagerError] = useState(null);
  const idExperiencie = searchParams.get("id");

  const rangos_salarios = getSalaryRange(i18next.languages[0]);
  const tipos_salarios = getTiposSalario(i18next.languages[0]);

  useEffect(() => {
    handleGetEtts();
    handleGetDirectManagers();
    handleGetKnowledges();
    handleGetLevels();
    handleGetPlaces();
    handleGetSectors();
    handleGetFunctions();
    handleGetStaff();
    handleDatosPersonales();
    handleGetExperience(Number(idExperiencie));
  }, []);


  function handlePersonalDataAction(data:any) {
    setNombre(data.nombre);
    setPrimerApellido(data.primerApellido);
    setSegundoApellido(data.segundoApellido);
    setCorreoElectronico(data.correoElectronico);
    setPrefijo(data.telefono.prefijo);
    setTelefono(data.telefono.numero);
    setIdFotografia(data.idFotografia);
    setFechaModificacion(formatToLocalDate(new Date(data.fechaModificacion)));
  }


  const handleDatosPersonales = useCallback(() => {
    callApi(getDatosPersonales(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      handlePersonalDataAction(data);
    }).catch(() => {
      showSnack(t("curriculum.personal_data_error"), SnackSeverity.ERROR);
    });
}, []);

  async function handleGetExperience(experienceId: number) {
    setLoading(true);
    if (experienceId) {
      let workExperience = "";

      callApi(getWorkExperience(returnLangId(i18next.languages[0] as keyof typeof languages))).then((result) => {
        workExperience = result.find(
          (exp: any) => exp.idExperienciaLaboral === experienceId
        );
        fillWorkExperienceData(workExperience);
      }).catch((response) => {
        if (response.status === 404) {
          showSnack(t("work_experience.get_work_experience_not_found"), SnackSeverity.ERROR);
        } else {
          showSnack(t("work_experience.get_functions_error"), SnackSeverity.ERROR);
        }
      });

      if (workExperience) {
        fillWorkExperienceData(workExperience);
      }
    }
    setLoading(false);
  }

  const returnLabel = useCallback((x:any) =>  {
    return x.nombre || x.name || "";
  }, []);

  const handleGetFunctions = useCallback(() => {
    setLoading(true);
    callApi(getFunction()).then((result) => {
      setAllFunctions(result.funciones);
    }).catch((response)=>{
      if (response.status !== 404) {
        setFunctionsError(t("work_experience.get_functions_error"));
      }
    });
    setLoading(false);
}, []);


  const handleGetEtts = useCallback(() => {
    setLoading(true);
    callApi(getEtts()).then((result) => {
      setAllEtts(result);
    }).catch((response)=>{
      if (response.status !== 404) {
        setFunctionsError(t("work_experience.get_functions_error"));
      }
    });
    setLoading(false);
}, []);

  
  const handleGetKnowledges = useCallback(() => {
    setLoading(true);
    callApi(getKnowledge()).then((result) => {
      setAllKnowledges(result.conocimientos);
    }).catch((response)=>{
      if (response.status !== 404) {
        setKnowledgeError(t("work_experience.get_knowledge_error"));
      }
    });
    setLoading(false);
}, []);

  function getTypeOfLevel(){
    return level && 
           level?.nombre !== INTERN && 
           level?.nombre !== EMPLOYEE;
  }

  const handleGetDirectManagers = useCallback(() => {
    setLoading(true);
    callApi(getDirectManger()).then((result) => {
      setAllManagers(result.nivelesResponsable);
    }).catch(()=>{
      setManagerError(t("work_experience.get_manager_error"));
    });
    setLoading(false);
}, []);

const handleGetLevels = useCallback(() => {
  setLoading(true);
  callApi(getLevelExperiencie()).then((result) => {
    setAllLevels(result.niveles);
  }).catch(()=>{
    setLevelsError(t("work_experience.get_level_error"));
  });
  setLoading(false);
}, []);

  const handleGetPlaces = useCallback(() => {
    setLoading(true);
    callApi(getPlace(returnLangId(i18next.languages[0] as keyof typeof languages))).then((result) => {
      setAllPlaces(result.puestos);
    }).catch((response) => {
      if (response.status === 404) {
          showSnack(t("work_experience.get_places_not_found"), SnackSeverity.ERROR);
      }else{
          showSnack(t("work_experience.get_place_error"), SnackSeverity.ERROR);
      }
    });
    setLoading(false);
  }, []);


  const handleGetStaff = useCallback(() => {
    setLoading(true);
    callApi(getStaffInCharge()).then((result) => {
      setAllStaff(result.personalACargo);
    }).catch(()=>{
      setStaffError(t("work_experience.get_staff_error"));
    });
    setLoading(false);
  }, []);


  const handleGetSectors = useCallback(() => {
    setLoading(true);
    callApi(getSectors(returnLangId(i18next.languages[0] as keyof typeof languages))).then((result) => {
      setAllSectors(result.sectores);
    }).catch((response) => {
      if (response.status === 404) {
          showSnack(t("work_experience.get_sectors_not_found"), SnackSeverity.ERROR);
      }else{
          showSnack(t("work_experience.get_sectors_error"), SnackSeverity.ERROR);
      }
    });
    setLoading(false);
  }, []);

  const minDate = new Date().setFullYear(
    Number(new Date().getFullYear()) - 1000
  );

  const validateTypeSalary = () => {
    return (minSalary || maxSalary) && !typeSalary;
  };

  const validateStaff_Manager = () => {
    const noExistStaff = !staffInCharge && level?.nombre != SPECIALIST;
    const noExitsManager = !directManger && level?.nombre == SPECIALIST;
    const isIntermediate = (!staffInCharge || !directManger) && level?.nombre == INTERMEDIATE;

    return getTypeOfLevel() && noExistStaff || noExitsManager || isIntermediate;
  };

  const getValidationErrors = (isReferenceValidation = false) => {
    if (!isReferenceValidation) {
      if (
        !sector ||
        !job ||
        job.id === 0 ||
        !company ||
        company.replace(/\s/g, "") === "" ||
        !startDate ||
        (startDate && isNaN(new Date(startDate).getTime())) ||
        (!isWorkingHere && !endDate) ||
        (!isWorkingHere && endDate && isNaN(new Date(endDate).getTime())) ||
        (!isWorkingHere && new Date(startDate) > new Date(endDate)) ||
        startDate > new Date() ||
        (!isWorkingHere && endDate > new Date()) ||
        validateTypeSalary() ||
        validateStaff_Manager()
      ) {
        setSaveWrong(true);
        if (hasReferences && references.length < 1) {
          setSaveWrongReferences(true);
        }
        return true;
      }
    } else if (
      hasReferences &&
      (referenceName.replace(/\s/g, "") === "" ||
        referencePhoneNumber.replace(/\s/g, "") === "") &&
      (referenceName.replace(/\s/g, "") === "" ||
        referenceEmail.replace(/\s/g, "") === "")
    ) {
      setSaveWrongReferences(true);
      return true;
    }
    setSaveWrongReferences(false);
    return false;
  };

  const fillWorkExperienceData = (workExperience:any) => {
    workExperience.sector.id = workExperience.sector.idSector;
    delete workExperience.sector.idSector;
    setSector(workExperience.sector);
    setJob(workExperience.puesto);
    setlevel(workExperience.nivel);
    setIsEtt(workExperience.esEtt);
    setEtt(workExperience.ett);
    setCompany(workExperience.empresa);
    setMinSalary(workExperience.salarioMinimo);
    setMaxSalary(workExperience.salarioMaximo);
    setTypeSalary(workExperience.tipoSalario);
    setStaffInCharge(workExperience.personalACargo);
    setDirectManger(workExperience.nivelResponsable);
    setDescripcionPuesto(workExperience.descripcionPuesto);
    workExperience.funciones = workExperience.funciones.map((f:any) => {
      f.id = f.idFuncion;
      delete f.idFuncion;
      return f;
    });
    setFunctions(workExperience.funciones);
    setIsWorkingHere(workExperience.trabajoActual);
    setStartDate(
      workExperience.fechaInicio ? workExperience.fechaInicio : null
    );
    if (
      workExperience.fechaFin?.split("T")[0].split("-")[0] 
    ) {
      setEndDate(
        workExperience.fechaFin ? workExperience.fechaFin : null
      );
    }
    workExperience.conocimientos = workExperience.conocimientos.map((c:any) => {
      c.id = c.idConocimiento;
      delete c.idConocimiento;
      return c;
    });
    setKnowledges(workExperience.conocimientos);
    setHasReferences(workExperience.referencias.length > 0);
    setReferences(workExperience.referencias);
  };

  const clearFields = (onlyReferences = false) => {
    setReferenceName("");
    setReferencePhonePrefix(
      prefijos.find((prefix:any) => prefix.prefix === "34")
    );
    setReferencePhoneNumber("");
    setReferenceEmail("");
    if (!onlyReferences) {
      setSector(null);
      setJob(null);
      setlevel(null);
      setMaxSalary(null);
      setMinSalary(null);
      setTypeSalary(null);
      setCompany("");
      setFunctions([]);
      setIsWorkingHere(false);
      setStartDate(null);
      setEndDate(null);
      setKnowledges([]);
      setReferences([]);
      setHasReferences(false);
      setStaffInCharge(null);
      setDirectManger(null);
      setDescripcionPuesto("");
    }
  };

  const addReference = (fromText = false) => {
    if (fromText) {
      setAddNewExperience(true);
    }
    const errors = getValidationErrors(true);
    if (!errors) {
      const newReference = {
        nombre: referenceName,
        telefono:
          referencePhoneNumber && referencePhoneNumber.trim() !== ""
            ? {
                prefijo: referencePhonePrefix.prefix,
                numero: referencePhoneNumber,
              }
            : null,
        correoElectronico: referenceEmail.trim() !== "" ? referenceEmail : null,
      };
      const updatedReferences = [...references, newReference];
      setReferences(updatedReferences);
      clearFields(true);
      return updatedReferences;
    } else {
      return references;
    }
  };


  const handleDeleteWorkExperience = useCallback(() => {
    setLoading(true);
    callApi(deleteWorkExperience(returnLangId(i18next.languages[0] as keyof typeof languages),idExperiencie)).then(() => {
      navigate(Routes.CURRICULUM);
    }).catch((response) => {
      switch (response.status) {
        case 400:
          showSnack(t("work_experience.delete_error_incorrect_entry_data"), SnackSeverity.ERROR);
          break;
        case 404:
          showSnack(t("work_experience.delete_error_candidate_not_found"), SnackSeverity.ERROR);
          break;
        default:
          showSnack(t("work_experience.error"), SnackSeverity.ERROR);
          break;
      }
    });
    setLoading(false);
  }, []);

  function updateOrAddExperience(addMore:boolean){
    if (addMore) {
      showSnack(t("work_experience.save_success"),SnackSeverity.SUCCESS);

      clearFields();
      if (idExperiencie) {
        navigate(Routes.WORK_EXPERIENCE);
      }

      setLoading(false);
    } else {
      navigate(Routes.CURRICULUM);
    }
  }


  function updateOrAddExperienceErrors(response:any){
    switch (response.status) {
      case 400:
        showSnack(t("work_experience.save_error_incorrect_entry_data"), SnackSeverity.ERROR);
        break;
      case 404:
        showSnack(t("work_experience.save_error_candidate_not_found"), SnackSeverity.ERROR);
        break;
      default:
        showSnack(t("work_experience.error"), SnackSeverity.ERROR);

        break;
    }
  }

  async function handleSaveData(addMore = false) {
    if (!getValidationErrors()) {
      setLoading(true);

      let newReferences;
      if (hasReferences) {
        if (referenceName.trim() !== "") {
          newReferences = addReference();
        } else {
          newReferences = references;
        }
      }
      const data = {
        idSector: Number(sector?.id),
        idPuesto: Number(job?.id),
        idEtt: ett ? ett.id : null,
        empresa: company,
        funciones: functions.map((fun: any) =>
          fun.id
            ? { idFuncion: fun.id, nombre: fun.nombre }
            : { idFuncion: 0, nombre: fun.nombre }
        ),
        idNivel: Number(level?.id),
        conocimientos: knowledges.map((k: any) =>
          k.id
            ? { idConocimiento: k.id, nombre: k.nombre }
            : { idConocimiento: 0, nombre: k.nombre }
        ),
        referencias: hasReferences ? newReferences : null,
        fechaInicio: startDate,
        fechaFin: isWorkingHere ? null : endDate,
        trabajoActual: isWorkingHere,
        idSalarioMinimo: Number(minSalary?.id),
        idSalarioMaximo: Number(maxSalary?.id),
        idTipoSalario: Number(typeSalary?.id),
        idPersonalACargo: Number(staffInCharge?.id),
        idNivelResponsable: Number(directManger?.id),
        descripcionPuesto
      };

      if (idExperiencie) {
        callApi(updateWorkExperience(returnLangId(i18next.languages[0] as keyof typeof languages), idExperiencie, data)).then(() => {
          updateOrAddExperience(addMore);

        }).catch((response) => {
          updateOrAddExperienceErrors(response);
          setLoading(false);
        });
      } else {
        callApi(addWorkExperience(returnLangId(i18next.languages[0] as keyof typeof languages), data)).then(() => {
          updateOrAddExperience(addMore);

        }).catch((response) => {
          updateOrAddExperienceErrors(response);
          setLoading(false);
        });
      }

    } else {
      showSnack(t("common.data_error"), SnackSeverity.ERROR);
    }
  }
  


  function handleDelete(index:number) {
    const referencesAux = [...references];
    if (index !== -1) {
      referencesAux.splice(index, 1);
      setReferences(referencesAux);
      if (referencesAux.length === 0) {
        setHasReferences(false);
      }
    }
  }

  function gelLabelPhonePrefixe(x: any) {
    if (x) {
      return (
        `+${prefijos.find(
          (pr: any) => pr.prefix.trim() === x.trim()
        )
          ? prefijos.find(
            (pr: any) => pr.prefix.trim() === x.trim()
          )?.prefix ?? ""
          : ""
        }`);
    } else {
      return `+${x}`;
    }
  }

  function referenceForm(values?:any, key?:any, readOnly?:boolean) {
    return (
      <>
        <Box sx={styles.reference_grid} key={key}>
          <Box sx={styles.form_grid_nombre}>
            <InputWithLabel
              fullWidth
              wrong={
                saveWrongReferences &&
                hasReferences &&
                referenceName === "" &&
                !readOnly
              }
              customClass={styles.input}
              label={t("work_experience.name")}
              value={values?.nombre}
              setter={(e:any) =>  setReferenceName(e.target.value)}
              moveLabel
              disabled={readOnly}
              maxLength={250}
            />
          </Box>
          <Box sx={styles.form_grid_telefono}>
            <FormControl variant="outlined">
              <AutocompleteWithLabel
                disabled={readOnly}
                disableClearable
                value={values ? values.telefono?.prefijo : referencePhonePrefix}
                setter={(value:any) => {
                  setReferencePhonePrefix(value ? value.prefix.trim() : null);
                }}
                options={prefijos}
                getOption={(x:any, v:any) => optionEquality(x, v)}
                getLabel={(x:any) => 
                  typeof x === "object" && x !== null
                    ? `+${x.prefix}`
                    :gelLabelPhonePrefixe(x)
                }
                useDefaultIcon
                moveLabel
              />
            </FormControl>
            <InputWithLabel
              fullWidth
              wrong={
                saveWrongReferences &&
                hasReferences &&
                referenceEmail.replace(/\s/g, "") === "" &&
                referencePhoneNumber.replace(/\s/g, "") === "" &&
                !readOnly
              }
              customClass={styles.input}
              label={t("work_experience.phoneNumber")}
              value={values?.telefono?.numero}
              setter={(e:any) =>  setReferencePhoneNumber(e.target.value)}
              moveLabel
              disabled={readOnly}
              maxLength={10}
              type={"number"}
            />
          </Box>
          <Box sx={styles.form_grid_email}>
            <InputWithLabel
              fullWidth
              wrong={
                saveWrongReferences &&
                hasReferences &&
                referenceEmail.replace(/\s/g, "") === "" &&
                referencePhoneNumber.replace(/\s/g, "") === "" &&
                !readOnly
              }
              customClass={styles.input}
              label={t("work_experience.email")}
              value={values?.correoElectronico}
              setter={(e:any) =>  setReferenceEmail(e.target.value)}
              moveLabel
              disabled={readOnly}
              maxLength={100}
            />
          </Box>
        </Box>
        <Box sx={styles.add_delete_grid}>
          {(!readOnly ||
            (references.length === key + 1 && !addNewExperience)) && (
            <Box sx={styles.form_grid_add}>
              <Typography
                sx={styles.add_another_reference_link}
                onClick={() => addReference(true)}
              >
                {t("work_experience.add_another_reference")}
              </Typography>
            </Box>
          )}
          {readOnly && (
            <Box
              sx={{...styles.delete }}
              onClick={() => handleDelete(key)}
            >
              {t("common.delete")}
            </Box>
          )}
        </Box>
      </>
    );
  }

  function referencesSection() {
    return (
      <Box sx={styles.form_grid_referencias_container}>
        <Box>
          {references.map((r:any, index:number) =>
            referenceForm(
              {
                nombre: r.nombre,
                telefono: r.telefono?.prefijo
                  ? {
                      prefijo: prefijos.find(
                        (pref:any) =>
                          pref.prefix.trim() === r.telefono.prefijo.trim()
                      ),
                      numero: r.telefono.numero.trim(),
                    }
                  : { prefijo: null, numero: "" },
                correoElectronico: r.correoElectronico,
              },
              index,
              true
            )
          )}
          {references.length < 1 || addNewExperience
            ? referenceForm({
                nombre: referenceName,
                telefono: {
                  prefijo: referencePhonePrefix,
                  numero: referencePhoneNumber,
                },
                correoElectronico: referenceEmail,
              })
            : null}
        </Box>
      </Box>
    );
  }




  function handleSetCompany(value:string) {
    const regex = new RegExp(
      /^[\wÀ-ÿ\u00f1\u00d1]+(\s*[\wÀ-ÿ\u00f1\u00d1$&+,:;=?@#|'<>.^*()%!-]*)*[\wÀ-ÿ\u00f1\u00d1]*$/
    );
    if (value.trim() === "") {
      setCompany("");
    } else {
      if (regex.test(value)) {
        setCompany(value);
      }
    }
  }

  const handleErrors = (error:any) => {
    if (error) {
      props.enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  function renderForm() {
    return (
      <Box sx={styles.form_container}>
        <Box sx={styles.form_grid_sector}>
          <FormControl
            variant="outlined"
            sx={styles.autocomplete_input_container}
          >
            <AutocompleteWithLabel
              value={sector}
              setter={setSector}
              options={allSectors}
              label={t("work_experience.sector")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>  getLabelForSelectComponent(x, "nombre")}
              wrong={saveWrong && !sector}
              useDefaultIcon
              moveLabel
            />
          </FormControl>
        </Box>
        {
          isEtt ? (
            <Box sx={styles.form_grid_ett}>
              <FormControl
                variant="outlined"
                sx={{...styles.autocomplete_input_container}}
              >
                <AutocompleteWithLabel
                  value={ett}
                  setter={setEtt}
                  options={allEtts}
                  label={t("work_experience.ett")}
                  multiple={false}
                  freeSolo={false}
                  getOption={(x:any, v:any) => optionEquality(x, v)}
                  getLabel={(x:any) =>  getLabelForSelectComponent(x, "nombre")}
                  useDefaultIcon
                  moveLabel
                />
              </FormControl>
            </Box>
          ) : null
        }
        <Box sx={styles.form_grid_puesto}>
          <FormControl
            variant="outlined"
            sx={styles.autocomplete_input_container}
          >
            <AutocompleteWithLabel
              value={job}
              setter={setJob}
              options={allPlaces}
              label={t("work_experience.job")}
              multiple={false}
              freeSolo={false}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>  getLabelForSelectComponent(x, "nombre")}
              wrong={saveWrong && (!job || job.id === 0)}
              useDefaultIcon
              moveLabel
            />
          </FormControl>
          {matchesMobile ? (
            <Box sx={styles.form_grid_empresa}>
              <InputWithLabel
                fullWidth
                wrong={
                  saveWrong && (!company || company.replace(/\s/g, "") === "")
                }
                customClass={styles.input}
                label={t("work_experience.company")}
                value={company}
                setter={(e:any) =>  handleSetCompany(e.target.value)}
                moveLabel
                maxLength={100}
              />
            </Box>
          ) : null}
        </Box>
        {!matchesMobile ? (
          <Box sx={styles.form_grid_empresa}>
            <InputWithLabel
              fullWidth
              wrong={
                saveWrong && (!company || company.replace(/\s/g, "") === "")
              }
              customClass={styles.input}
              label={t("work_experience.company")}
              value={company}
              setter={(e:any) =>  handleSetCompany(e.target.value)}
              moveLabel
              maxLength={100}
            />
          </Box>
        ) : null}
        <Box sx={styles.form_grid_nivel}>
          <FormControl
            variant="outlined"
            sx={styles.autocomplete_input_container}
          >
            <AutocompleteWithLabel
              value={level}
              setter={(e:any) => {
                            setlevel(e);
                            setDirectManger(null);
                            setStaffInCharge(null);
                      }}
              options={allLevels}
              label={t("work_experience.level")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={(x:any) =>  getLabelForSelectComponent(x, "nombre")}
              useDefaultIcon
              moveLabel
              onOpen={() => handleErrors(levelsError)}
            />
          </FormControl>
          </Box>
        {getTypeOfLevel() &&
          <>
            <Box sx={{...(level?.nombre == INTERMEDIATE ? styles.form_grid_responsableDirecto 
                                                        : styles.form_grid_personalCargo)}}>
              {(level?.nombre == SPECIALIST || level?.nombre == INTERMEDIATE) &&
                <FormControl
                  variant="outlined"
                  sx={styles.autocomplete_input_container}
                >
                  <AutocompleteWithLabel
                    value={directManger}
                    setter={setDirectManger}
                    options={allManagers}
                    label={t("work_experience.levelDirectSuperior")}
                    getOption={(x:any, v:any) => optionEquality(x, v)}
                    getLabel={(x:any) =>  getLabelForSelectComponent(x, "nombre")}
                    wrong={saveWrong && validateStaff_Manager()}
                    useDefaultIcon
                    moveLabel
                    onOpen={() => handleErrors(managerError)}
                  />
                </FormControl>
              }
            </Box>
            {level?.nombre != SPECIALIST &&
              <Box sx={styles.form_grid_personalCargo}>
                <FormControl
                  variant="outlined"
                  sx={styles.autocomplete_input_container}
                >
                  <AutocompleteWithLabel
                    value={staffInCharge}
                    setter={setStaffInCharge}
                    options={allStaff}
                    label={t("work_experience.staffInCharge")}
                    getOption={(x:any, v:any) => optionEquality(x, v)}
                    getLabel={(x:any) =>  getLabelForSelectComponent(x, "nombre")}
                    wrong={saveWrong && validateStaff_Manager()}
                    useDefaultIcon
                    moveLabel
                    onOpen={() => handleErrors(staffError)}
                  />
                </FormControl>
              </Box>
            }
          </>
        }
        <Box sx={{...styles.form_grid_funciones,
          ...(level?.nombre != INTERMEDIATE) ? styles.space_functions: {}}}>
          <AutocompleteWithChips
            options={allFunctions ? allFunctions : []}
            fieldName={"functions"}
            values={functions}
            label={t("work_experience.functions")}
            setter={setFunctions}
            setterOptions={setAllFunctions}
            onOpen={() => handleErrors(functionsError)}
            moveLabel
            maxLength={250}
          />
        </Box>
        <Box sx={styles.form_grid_tipoSalario}>
          <AutocompleteWithLabel
             value={typeSalary}
             setter={setTypeSalary}
             options={tipos_salarios}
             label={t("work_experience.type_Salary")}
             getOption={(x:any, v:any) => optionEquality(x, v)}
             getLabel={returnLabel}
             wrong={saveWrong && validateTypeSalary()}
             useDefaultIcon
             moveLabel
          />
        </Box> 
        <FormControl
            variant="outlined"
            sx={styles.form_grid_salarioMin}
          >
            <AutocompleteWithLabel
              value={minSalary}
              setter={(value:any) => {
                setMinSalary(value);
                setMaxSalary(null);
              }}
              options={rangos_salarios}
              label={t("work_experience.minSalary")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={returnLabel}
              useDefaultIcon
              moveLabel
            />
          </FormControl>
          <FormControl
            variant="outlined"
            sx={styles.form_grid_salarioMax}
          >
            <AutocompleteWithLabel
              value={maxSalary}
              setter={setMaxSalary}
              options={filterSalaryRangeOptions(rangos_salarios, minSalary)}
              label={t("work_experience.maxSalary")}
              getOption={(x:any, v:any) => optionEquality(x, v)}
              getLabel={returnLabel}
              useDefaultIcon
              moveLabel
            />
          </FormControl>
         
        <Box sx={styles.form_grid_descripcion_Puesto}>
        <InputWithLabel
          multiline
          rows={3}
          fullWidth
          label={t("work_experience.jobDescription")}
          value={descripcionPuesto}
          setter={(e:any) =>  setDescripcionPuesto(e.target.value)}
          moveLabel
          minLength={0}
          maxLength={4000}
        />
        <Typography sx={styles.counter_chars_description}>
          {`${descripcionPuesto?.length ?? 0}/${MAX_CHAR_DESCRIPTION} (${t("work_experience.maxChar")} ${MAX_CHAR_DESCRIPTION} ${t("work_experience.char")})`}
        </Typography>
        </Box>
        <Box
          sx={{...styles.switch_with_label,...styles.form_grid_trabaja_aqui}}
        >
          <Typography>{t("work_experience.is_working")}</Typography>
          <AntSwitch
            disabled={false}
            checked={isWorkingHere}
            setter={() => setIsWorkingHere(!isWorkingHere)}
          />
        </Box>
        <Box sx={styles.form_grid_fecha_inicio}>
          <Box>
            <Typography sx={styles.label}>
              {t("work_experience.startDate")}
            </Typography>
            <InputDate
              value={startDate}
              setter={setStartDate}
              minDate={minDate}
              maxDate={new Date()}
              canHideIcon={false}
              wrong={saveWrong && !startDate}
            />
          </Box>
          {matchesMobile ? (
            <Box sx={styles.form_grid_fecha_fin}>
              {!isWorkingHere ? (
                <Box>
                  <Typography sx={styles.label}>
                    {t("work_experience.endDate")}
                  </Typography>
                  <InputDate
                    value={endDate}
                    setter={setEndDate}
                    canHideIcon={false}
                    minDate={
                      startDate !== "" && validateDate(minDate, 0, startDate)
                        ? startDate
                        : minDate
                    }
                    maxDate={new Date()}
                    wrong={saveWrong && !isWorkingHere && !endDate}
                  />
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Box>
        {!matchesMobile ? (
          <Box sx={styles.form_grid_fecha_fin}>
            {!isWorkingHere ? (
              <Box>
                <Typography sx={styles.label}>
                  {t("work_experience.endDate")}
                </Typography>
                <InputDate
                  value={endDate}
                  setter={setEndDate}
                  canHideIcon={false}
                  minDate={
                    startDate !== "" && validateDate(minDate, 0, startDate)
                      ? startDate
                      : minDate
                  }
                  maxDate={new Date()}
                  wrong={saveWrong && !isWorkingHere && !endDate}
                />
              </Box>
            ) : null}
          </Box>
        ) : null}
        <Box sx={styles.form_grid_conocimientos}>
          <Typography sx={styles.label}>
            {t("work_experience.knowledges")}
          </Typography>
          <AutocompleteWithChips
            options={allKnowledges}
            values={knowledges ? knowledges : []}
            placeholder={t("work_experience.name")}
            setter={setKnowledges}
            setterOptions={setAllKnowledges}
            onOpen={() => handleErrors(knowledgeError)}
            maxLength={150}
          />
        </Box>
        <Box
          sx={{...styles.switch_with_label, ...styles.form_grid_tengo_referencias}}
        >
          <Typography>{t("work_experience.has_references")}</Typography>
          <AntSwitch
            disabled={false}
            checked={hasReferences}
            setter={() => setHasReferences(!hasReferences)}
          />
        </Box>
        {hasReferences ? referencesSection() : null}
        <Box sx={styles.form_grid_botonera}>
        <AddToCVActionButtons
            saveAndActionText={
              idExperiencie
                ? t("work_experience.update_and_add")
                : t("work_experience.save_and_add")
            }
            onSaveClick={() => handleSaveData()}
            onSaveAndActionClick={() => {
              handleSaveData(true);
            }}
            onCancelClick={() => {
              navigate(Routes.CURRICULUM);
            }}
            isUpdate={idExperiencie}
          />
        </Box>
       {idExperiencie ? (
          <Box
            sx={{...styles.delete_center ,...styles.delete}}
            onClick={() => setIsDialogOpen(true)}
          >
            {t("common.delete")}
          </Box>
        ) : null}
      </Box>
    );
  }

  function tittle() {
    return (
      <Box sx={styles.title_container}>
        <YourExperience />
        <Box style={{ marginLeft: "12px" }}>
          <Typography sx={styles.title}>
            {t("work_experience.add_experience")}
          </Typography>
        </Box>
      </Box>
    );
  }    

  return !loading ?(
    <>
      <Box sx={styles.principal_container}>
        {!matchesMobile ? (
          <TuCurriculumHeader
            nombre={nombre}
            primerApellido={primerApellido}
            segundoApellido={segundoApellido}
            correoElectronico={correoElectronico}
            prefijo={prefijo}
            telefono={telefono}
            fechaModificacion={fechaModificacion}
            idPhoto={idFotografia}
          />
        ) : (
          <Box />
        )}
        <Box sx={styles.body_container}>
          {tittle()}
          {renderForm()}
        </Box>
      </Box>
      <AlertDialog
        title={t("work_experience.delete_confirm_message")}
        open={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        handleAccept={() => {
          handleDeleteWorkExperience();
          setIsDialogOpen(false);
        }}
      />
    </>
  ): <Box></Box>;
}

export default WorkExperience;
