import React, { useEffect, useState } from "react";
import Down from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Wrapper from "../InputWrapper/InputWrapper";
//import styles from "./selectStyles";
//import { Box } from "@mui/material";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  inputBase: {
    flex: 1,
    paddingRight: "0px",
    height: "100%",
    marginTop: "auto",
  },
  label: {
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
    fontSize: "12px",
    lineHeight: "16px",
    marginBottom: "4px",
  },
  paper: {
    maxHeight: "200px!important",
    overflow: "auto",
  },
  customChip: {
    background: "none",
    height: "unset",
    "&::after": {
      content: "','",
    },
  },
  customChip_deleteIcon: {
    display: "none",
  },
  customChip_label: {
    padding: "unset",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "19px",
    },
    fontSize: "12px",
    lineHeight: "16px",
  },
  input_autocomplete: {
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    fontSize: "12px",
    lineHeight: "16px",
    textOverflow: "ellipsis",
    "-webkit-appearance": "none",
    backgroundColor: "transparent",
    "&::-webkit-clear-button": {
      display: "none",
      "-webkit-appearance": "none",
    },
    "&::-webkit-inner-spin-button": {
      display: "none",
    },
    "&::-webkit-outside-spin-button": {
      display: "none",
    },
    "&::-webkit-calendar-picker-indicator": {
      display: "none",
    },
    "&::placeholder": {
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: "19px",
      },
      fontSize: "12px",
      lineHeight: "16px",
      color: "#C4C4C4",
      opacity: 1,
    },
    height: "32px",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },

    "-moz-appearance": "textfield !important",
    padding: "2px 6px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
  },
  },
  root: (props) => ({
    padding: "0px",
    //height: "36px",
    height: props.multiple ? "auto" : "32px",
    "& .MuiAutocomplete-inputRoot": {
      //height: "36px",
      height: props.multiple ? "auto" : "32px",
      padding: "0px",
    },
    "& .MuiIconButton-root": {
      padding: "4px",
    },
    "& .MuiInputBase-input": {
      padding: "8px 12px",
      "-moz-appearance": "textfield",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: "13px",
      },
      fontSize: "14px",
      lineHeight: "16px",
      color: props.whiteLabel ? "white" : "black",
      transform: "translate(4px, -20px) scale(1)",
      marginBottom: "4px",
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      transform: "translate(10px, 10px) scale(1)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "90%",
      paddingBottom: "1px"
    },
    "& label.Mui-focused": {
      color: "#c4c4c4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#c4c4c4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4",
        borderWidth: "1px",
        top: 0,
      },
      "& legend": {
        display: "none",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        top: 0,
      },
      "&.Mui-focused legend": {
        display: "none",
      },
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: "0px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: "0px",
    },
    "& .MuiAutocomplete-clearIndicator": {
      display: "none",
    },
  }),
  section_with_title: {
    fontSize: "14px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  section_with_title_title: {
    marginBottom: "5px",
    fontSize: "14px",
    width: "max-content",
  },
  option: {
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    fontSize: "12px",
    lineHeight: "16px",
  },
}));

function SelectCustom(props) {
  const {
    placeholder = "",
    value = "",
    setter = () => {
      return true;
    },
    getLabel = (x) => x,
    getOption = (x, v) => x === v,
    label = "",
    options= null,
    disabled = false,
    freeSolo = false,
    whiteLabel = false,
    multiple = false,
    customClass = "",
    customClassWrapper = null,
    show = "notApply",
    selectorFunction = () => {
      return true;
    },
    load = false,
    onBlur = () => {
      return true;
    },
    filterOptions = null,
    groupBy = null,
    showIcon = "notApply",
    required = false,
    border,
    wrong = false,
    useDefaultIcon = false,
    onOpen,
    moveLabel = false,
    customClassLabel,
    getOptionDisabled = () => {
      return false;
    },
    disableClearable = false,
    maxLength = false,
    type = "text",
    escapeBiggerThan = false,
    isFromHomePage = false
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({ multiple, whiteLabel });
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
   
    if (!value) {
      setInputValue("");
    }
  }, [value]);

  function handleMaxLength(value) {
      if (value.length <= maxLength) {
        setInputValue(value);
      
    }
  }

  function handleBiggerThanNumber(value) {
    setInputValue(value.replace(/[^0-9>]/g, ""));
  }

  function handleInputchange(value){
   return (
    escapeBiggerThan
    ? handleBiggerThanNumber(value)
    : setInputValue(value));
  }

function renderAutcomplete() {
  return (
    <Autocomplete
      whiteLabel
      autoSelect={freeSolo}
      freeSolo={freeSolo}
      className={`${classes.inputBase} ${customClass}`}
      options={options}
      popupIcon={
        useDefaultIcon ? (
          <div title={t(`icons_tooltips.expand`)}>
            <Down color="yellow" />
          </div>
        ) : (
          <div title={t(`icons_tooltips.expand`)}>
            <Down color="primary" />
          </div>
        )
      }
      value={value}
      inputValue={inputValue}
      onChange={(e, value) => {
        setter(value);
      }}
      onInputChange={(e, value) =>
        maxLength
          ? handleMaxLength(value)
          : handleInputchange(value)
      }
      noOptionsText={t("basicData.no_options")}
      multiple={multiple}
      disabled={disabled}
      autoHighlight
      blurOnSelect
      onBlur={onBlur}
      getOptionLabel={getLabel}
      isOptionEqualToValue={getOption}
      loading={load}
      onOpen={onOpen}
      loadingText={
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Box/>
        </div>
      }
      ChipProps={{
        classes: {
          root: classes.customChip,
          deleteIcon: classes.customChip_deleteIcon,
          label: classes.customChip_label,
        },
      }}
      classes={{ listbox: classes.paper }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          onChange={(e) => selectorFunction(e.target.value)}
          InputProps={{
            ...params.InputProps,
            autoComplete: "disabled",
            classes: {
              input: classes.input_autocomplete,
              root: `${classes.input_autocomplete} ${customClassLabel}`,
            },
          }}
          classes={{ root: `${classes.root}` }}
          label={moveLabel ? label : null}
          error={wrong}
          required={required}
          type={type}
          onKeyDown={(evt) =>
            type === "number"
              ? (evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "." ||
                  evt.key === "," ||
                  evt.key === "+") &&
                evt.preventDefault()
              : null
          }
        />
      )}
      {...(filterOptions ? { filterOptions } : {})}
      {...(groupBy ? { groupBy } : {})}
      {...(show !== "notApply" ? { open: show } : {})}
      {...(showIcon !== "notApply" ? { forcePopupIcon: showIcon } : {})}
      getOptionDisabled={getOptionDisabled}
      disableClearable={disableClearable}
    />
  );
}

 
  return (
    <Wrapper
      label={moveLabel ? null : label}
      customClass={customClassWrapper}
      type="autocomplete"
      disabled={disabled}
      required={required}
      border={border}
      wrong={wrong}
      moveLabel={moveLabel}
      multiple={multiple}
      hasValue={value}
      isFromHomePage={isFromHomePage}
    >
      {renderAutcomplete()}
    </Wrapper>
  );
}

export default React.memo(SelectCustom, (prevProps, nextProps) => {
  const equals = JSON.stringify(prevProps) === JSON.stringify(nextProps);
  return equals;
});