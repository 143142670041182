import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = {
    shrinked_camara: {
        position: "relative",
        top: "75%",
        left: "75%",
        opacity: 0.5,
        width: 24,
        height: 21
    },
    camara: {
        width: 60,
        height: 55
    }
};

function Camera(props) {
    const classes = useStyles;
    const { t } = useTranslation();
    const { title = t("icons_tooltips.upload_picture"), shrink = true } = props;
    return (
        <svg className={shrink ? classes.shrinked_camara : classes.camara} viewBox="0 0 60 55" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <defs>
                <filter id="filter-1">
                    <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0.960000 0"></feColorMatrix>
                </filter>
            </defs>
            <g id="Portal-de-empleo---móvil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="camera" transform="translate(-54.000000, -303.000000)" filter="url(#filter-1)">
                    <g transform="translate(54.000000, 303.000000)">
                        <path d="M23,33 C22.4477153,33 22,32.5522847 22,32 C22.0060624,26.4796656 26.4796656,22.0060624 32,22 C32.5522847,22 33,22.4477153 33,23 C33,23.5522847 32.5522847,24 32,24 C27.5837781,24.0049599 24.0049599,27.5837781 24,32 C24,32.5522847 23.5522847,33 23,33 Z" id="Path" fill="#000000" fillRule="nonzero"></path>
                        <path d="M54,8.99999503 L45.535,8.99999503 L39.835,0.445 C39.6488866,0.166044092 39.3353409,-0.00105510513 39,-4.96703135e-06 L25,-4.96703135e-06 C24.6657215,-4.96703135e-06 24.3535246,0.16693028 24.168,0.445 L18.465,8.99999503 L6,8.99999503 C2.68766243,9.00330738 0.00330737898,11.6876624 0,14.999995 L0,48.999995 C0.00330737898,52.3123376 2.68766243,54.9966926 6,54.999995 L54,54.999995 C57.3123376,54.9966926 59.9966926,52.3123376 60,48.999995 L60,14.999995 C59.9966926,11.6876624 57.3123376,9.00330738 54,8.99999503 Z M10.5,22 C9.11928813,22 8,20.8807119 8,19.5 C8,18.1192881 9.11928813,17 10.5,17 C11.8807119,17 13,18.1192881 13,19.5 C13,20.8807119 11.8807119,22 10.5,22 Z M32,47 C23.7157288,47 17,40.2842712 17,32 C17,23.7157288 23.7157288,17 32,17 C40.2842712,17 47,23.7157288 47,32 C47,40.2842712 40.2842712,47 32,47 L32,47 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Camera;