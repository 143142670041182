import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material/';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './styles';
import Switch from '@mui/material/Switch';


interface ICookiesConfigurationDialogProps {
  cookieConfiguration: any,
  setCookieConfiguration: Dispatch<SetStateAction<any>>
}

const CookiesConfigurationDialog = (props: ICookiesConfigurationDialogProps) => {
  const { cookieConfiguration,
    setCookieConfiguration } = props;
  const { t } = useTranslation();


  const changeCookieSettings = (key: string, value: boolean) => {
    setCookieConfiguration((oldValue: any) => {
      const newValue = { ...oldValue };
      newValue[key] = !value;
      return { ...newValue };
    });
  };

  const optionsConfiguration = [
    {
      id: 0,
      title: t("cookies.necessary_cookie"),
      isRequired: true,
      message: t("cookies.necessary_message"),
      value: cookieConfiguration.necessary,
      setterValue: () => changeCookieSettings("necessary", cookieConfiguration.necessary)
    },
    {
      id: 1,
      title: t("cookies.preference_cookie"),
      isRequired: false,
      message: t("cookies.preference_message"),
      value: cookieConfiguration.preference,
      setterValue: () => changeCookieSettings("preference", cookieConfiguration.preference)
    },
    {
      id: 2,
      title: t("cookies.statistics_cookie"),
      isRequired: false, message: t("cookies.statistics_message"),
      value: cookieConfiguration.statistics,
      setterValue: () => changeCookieSettings("statistics", cookieConfiguration.statistics)
    },
    {
      id: 3,
      title: t("cookies.marketing_cookie"),
      isRequired: false,
      message: t("cookies.marketing_message"),
      value: cookieConfiguration.marketing,
      setterValue: () => changeCookieSettings("marketing", cookieConfiguration.marketing)
    }
  ];

  const isRequiredSelecction = (isRequired: boolean) => {
    return isRequired ? t("cookies.always_active") : t("cookies.optional");
  };

  const printConfigurationOptions = () => {
    return <Box style={{ maxHeight: "500px" }}>
      {optionsConfiguration.map(config => {
        return (
          <Accordion key={config.id} style={styles.accordionContainer}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ display: "flex",paddingBottom: '10px' }}
            >
              {`${config.title} (${isRequiredSelecction(config.isRequired)})`}
              <Switch
                color={"info"}
                checked={config.value}
                disabled={config.isRequired}
                onChange={config.setterValue}
                sx={styles.switch}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {config.message}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>;
  };

  return (
    <>
      {printConfigurationOptions()}
    </>
  );
};
export default CookiesConfigurationDialog;