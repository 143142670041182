const styles = {
    container: {
        width: "100%",
        height: "100vh",
        background: "#FFFFFF",
        /* [theme.breakpoints.up("md")]: {
          height: "100vh",
        }, */
        display: "grid",
        gridTemplateColumns: "minmax(0, 1fr)",
        gridTemplateRows: "90px minmax(0, auto)",
        rowGap: "30px",
        gridTemplateAreas: "'header' 'body'",
      },
      body: {
        gridArea: "body",
        display: "grid",
        overflow: "auto",
        gridTemplateColumns: {xs: "0% minMax(0, 1fr) 0%", md:
          "minMax(0,0.01fr) clamp(1200px, 1300px, 1400px) minMax(0, 1fr)"},
        gridTemplateAreas: "'leftMarg content rightMarg'",
        gridTemplateRows: {xs: "auto", md: "unset"},
        marginLeft:{sm:"10px"}
      },
      body_container: {
        display: "grid",
        gridArea: "content",
        gridTemplateAreas: "'titleArea filled' 'formArea filled' 'filled2 filled'",
        gridTemplateRows: {xs: "auto", md: "auto auto minmax(0, 1fr)"},
        gridTemplateColumns: {xs: "1fr", md: "none"},
        marginBottom:{md:"90px"}
      },
      title_container: {
        gridArea: "titleArea",
        marginBottom: {md:"32px"},
        padding: {xs: "32px 20px 0 0",md: "0 20px 0 0", lg: "unset"} ,
      },
      form_container: {
        display:{xs:"flex",md: "grid"},
        flexDirection:{xs:"column"},
        gridArea: "formArea",
        width: "100%",
        height: "auto",
        pl: "20px",
        gridTemplateColumns: {xs: "1fr", md: "1fr 1fr 1fr"},
        gridTemplateRows: {xs: "auto", md: 
          "minmax(36px, auto) minmax(36px, auto) minmax(36px, auto)"},
        gridColumnGap: "25px",
        gridRowGap: "25px",
        padding: {xs:"20px"},
        alignItems: {xs:"initial",md:"flex-end"},
      },
    
      autocomplete_input_container: {
        width: "50%",
      },
      autocomplete_input_container_full: {
        width: "100%",
      },
    
    widthDesireJob:{
      width: {xs:"100%",md:"300px"}
    },
    widthNationality:{
      width: {xs:"100%",md:"32%"}
    },
      input_not_full_width: {
        width: "50%",
        marginLeft: "10px",
      },
    
      no_margin: {
        margin: "0px",
      },
      save_button: {
        color: "#FFFFFF",
        fontWeight: "900",
        height: "36px",
        textTransform: "none",
        width: "75%",
        marginTop: {xs: "30px", md: "unset"},
        marginBottom: {xs: "30px", md: "35px"},
        fontSize: {xs: "16px", md: "unset"},
      },
      save_button_fullWidth: {
        width:{xs:"100% !important",md:"80% !important"} ,
      },
      double_input_container: {
        display: "flex",
        alignItems: "flex-end",
      },
      auto: {
        marginTop: "auto",
      },
      inner_grid: {
        marginTop: "10px",
        width: "100%",
        display: {xs: "flex", md: "grid"},
        flexDirection: {xs: "column", md: "unset"},
        gridTemplateColumns: "1fr 1fr 1fr",
        gridColumnGap: "25px"
      },
      customChip: {
        fontSize: "0.8125rem",
        display: "inline-flex",
        height: "32px",
        boxSizing: "border-box",
        padding: 0,
        alignItems: "center",
        borderRadius: "16px",
        margin: "10px 5px 0px 5px",
        backgroundColor: "#EFEFEF",
        maxWidth: "98%",
      },
      nameChip: {
        paddingLeft: "12px",
        paddingRight: "12px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      iconGeneral: {
        margin: "0 5px 0 -3px",
        width: 22,
        display: "flex",
        cursor: "pointer",
      },
      chip: {
        paddingTop: "8px",
      },
      desireJobContainer:{
        display: "flex",
        flexDirection:{xs:"column",md:"row"}
      },
      radio_Button_Questions:{
        display: "flex",
        flexDirection:"column",
        alignItems:{xs:"flex-start"}
      },
      icon_gray: {
        width: {xs: "100%", md: "50px"},
        height: "50px",
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        justifyContent: {xs: "center", md: "unset"}
      },
      noDocumentsText: {
        fontSize: "14px",
        marginBottom: "20px"
      },
      rightColumn: {
        width: {xs: "100%", md: "350px"},
        height: "100%",
        marginLeft: "40px",
        padding: {xs: "20px", md: "50px 0 0 0"}
      },
};

export default styles;
