import i18next from "../../../i18n";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../shared/enums/enums";
import { getToken, useApi } from "../../../shared/hooks/useApi";
import { getDatosBasicos, postUser } from "../../../shared/hooks/userApi";
import LanguageContext from "../../contexts/LanguageContext";

type Props = {
    children: React.ReactNode
};

export enum languages {
    es = 88,
    en = 21,
    pt = 74,
}

const unauthorizedRoutes = [Routes.BASIC_DATA, 
                            Routes.PRIVACY_POLICY,
                            Routes.AVISO_LEGAL,
                            Routes.CONDICIONES_USO];

export function returnLangId(lang: keyof typeof languages) {
    return languages[lang] || languages.es;
}

export const AuthContext = createContext<any>(() => { });

export default function AuthWrapper({ children }: Props) {
    const location = useLocation();
    const callApi = useApi();
    const navigate = useNavigate();
    const navigationPromise : React.MutableRefObject<any> = useRef(null);
    const {changeLanguage} = useContext(LanguageContext);

    const [logged, setLogged] = useState<boolean|undefined>(undefined);
    const [basicDataFilled, setBasicDataFilled] = useState(false);

    const getLanguagePublicPage = () => {
        return changeLanguage(sessionStorage.getItem("language"));
    };

    useEffect(() => {
        getLogged();
        getLanguagePublicPage();
    }, []);
    

    const setBasicDataFilledSync = useCallback((basicDataFilled: any) => { 
        setBasicDataFilled(basicDataFilled);
        return new Promise<any>((resolve) => { navigationPromise.current = resolve; });    
    }, [setBasicDataFilled]);

    const auth = useMemo(() => { return { setBasicDataFilledSync, basicDataFilled }; }, [setBasicDataFilledSync, basicDataFilled]);

    useEffect(() => {        
        if (navigationPromise.current !== null)
        {
            navigationPromise.current();
            navigationPromise.current = null;
        }

    }, [basicDataFilled]);

    useEffect(() => {
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
        });
      }, [location]);

    const getLogged = useCallback(async () => {  
        const getUserInOffer = sessionStorage.getItem("fromOferta");  
        const fromRegister = sessionStorage.getItem("fromRegister");  

        const data = await getToken();
        setLogged(!!data);

        if (!basicDataFilled && !unauthorizedRoutes.find((r) => r === location.pathname) && data) {
            handleGetDatosContacto();
        }

        if(getUserInOffer && !fromRegister){
            sessionStorage.removeItem("fromOferta");
            navigate(getUserInOffer);
        }

        if(data){
           changeLanguage("es",false);
        }

        return (data);
    }, []);

    const handleCreateUser = useCallback(() => {
        callApi(postUser()).then(() => {
            navigate(Routes.BASIC_DATA);
        });
    }, [callApi]);

    const handleGetDatosContacto = useCallback(() => {
        callApi(getDatosBasicos(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data: any) => {
            if (data.tieneDatosBasicos) {
                setBasicDataFilled(true);
            } else {
                navigate(Routes.BASIC_DATA);
            }
        }).catch((data) => {
            if (data.status === 404) {
                handleCreateUser();
            }
        });
    }, [callApi]);

    return (
        <AuthContext.Provider value={auth}>
            { (basicDataFilled || logged === false || unauthorizedRoutes.find((r) => r === location.pathname)) && children }
        </AuthContext.Provider>
    );
}