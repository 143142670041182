const styles = {
    principal_container: {
      gridArea: "body_container",
      display: "grid",
      gridTemplateColumns: {xs:"auto",md:"30% 65%"},
      columnGap: "20px",
    },
    body_container: {
      marginTop:{xs:"0px", md:"37px"},
      display: "grid",
      gridTemplateAreas: {xs:"'titleArea' \
                              'bodyContainer'",
                          md:"'titleArea' 'formArea' 'filled2'"},
      gridTemplateColumns: "1fr",
      gridTemplateRows: {xs:"auto 1fr",md:"auto auto minmax(0, 1fr)"},
      padding:{xs:"36px",md:"90px"},
    },
    title_container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      marginBottom: "30px",
      padding: {xs:"0 20px"},
      marginTop: {xs:'30px',lg:"unset"}
    },
    form_container: {
      display: "grid",
      gridArea: {xs:"bodyContainer",md:"formArea"},
      width: "100%",
      height: "auto",
      gridTemplateColumns:{xs:"1fr",md:"1fr 1fr"} ,
      gridTemplateAreas:{xs: "'sector' \
                               'ett' \
                               'puesto' \
                               'empresa' \
                               'tipoSalario' \
                               'salarioMin' \
                               'salarioMax' \
                               'nivel' \
                               'personalCargo' \
                               'responsableDirecto' \
                               'funciones' \
                               'descripPuesto'\
                               'trabajaAqui' \
                               'fechaInicio' \
                               'fechaFin' \
                               'conocimientos' \
                               'conocimientosChips' \
                               'tengoReferencias' \
                               'references' \
                               'addReference' \
                               'referencesList' \
                               'botonera' \
                               'delete'",
 md:'"sector ett" \
                          "puesto empresa" \
                          "tipoSalario empty9"\
                          "salarioMin salarioMax"\
                          "nivel personalCargo"\
                          "responsableDirecto ."\
                          "funciones ." \
                          "descripPuesto descripPuesto"\
                          "trabajaAqui empty3" \
                          "fechaInicio fechaFin" \
                          "conocimientos empty4" \
                          "conocimientosChips empty5" \
                          "tengoReferencias empty6" \
                          "references references" \
                          "addReference empty8" \
                          "referencesList referencesList" \
                          "botonera botonera" \
                          "emptyDelete delete"'},
      gridColumnGap:{xs:"0px",md: "25px"},
      gridRowGap: "25px",
      alignItems: "end",
      gridTemplateRows: {xs:"auto"},
    },
    autocomplete_input_container: {
      width: "100%",
      backgroundColor: "#FFFFFF",
    },
    input: {
      width:  "100%",
    },
    switch_with_label: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
    title: {
      fontSize: {xs:"18px",md:"22px"},
      lineHeight: {xs:"25px",md:"30px"},
      fontWeight: 900,
    },
    form_grid_sector: {
      gridArea: "sector",
    },
    form_grid_ett: {
      gridArea: "ett",
    },
    form_grid_puesto: {
      gridArea: "puesto",
    },
    form_grid_nivel: {
      gridArea: "nivel",
    },
    form_grid_descripcion_Puesto: {
      gridArea: "descripPuesto",
    },
    form_grid_personalCargo: {
      gridArea: "personalCargo",
    },
    form_grid_responsableDirecto: {
      gridArea: "responsableDirecto",
    },
    form_grid_salarioMin: {
      gridArea: "salarioMin",
    },
    form_grid_salarioMax: {
      gridArea: "salarioMax",
    },
    form_grid_tipoSalario: {
      gridArea: "tipoSalario",
    },
    form_grid_empresa: {
      gridArea: "empresa",
      marginTop:{xs: "40px"},
    },
    form_grid_funciones: {
      gridArea: "funciones",
      display: "grid",
      gridTemplateColumns: "minmax(0, 1fr)",
    },
    form_grid_trabaja_aqui: {
      gridArea: "trabajaAqui",
    },
    form_grid_fecha_inicio: {
      gridArea: "fechaInicio",
    },
    form_grid_fecha_fin: {
      gridArea: "fechaFin",
      marginTop:{xs: "20px"},
    },
    form_grid_conocimientos: {
      gridArea: "conocimientos",
      display: "grid",
      gridTemplateColumns: "minmax(0, 1fr)",
    },
    form_grid_tengo_referencias: {
      gridArea: "tengoReferencias",
    },
    form_grid_referencias_container: {
      gridArea: "references",
      display: "grid",
      gridTemplateAreas: '"reference" \
                          "addReference"',
      gridRowGap: "20px",
    },
    reference_grid: {
      display: "grid",
      gridArea: "reference",
      gridTemplateColumns: {xs:"1fr",md:"1fr 1fr"},
      gridTemplateAreas: {xs: '"nombre" \
                               "telefono" \
                               "email"',
                          md:'"nombre ." \
                              "telefono email"'},
      gridColumnGap: "25px",
      gridRowGap: "25px",
      marginBottom: "20px",
      alignItems: "end",
    },
    add_delete_grid: {
    display: {xs:"flex",md:"grid"},
    gridArea: "reference",
    gridTemplateColumns: {xs:"1fr",md:"1fr 1fr"},
    gridTemplateAreas: {md:'"add delete"'},
    gridColumnGap: "25px",
    gridRowGap: "20px",
    marginBottom: "20px",
    flexDirection:{xs:"columns"},
    },
    form_grid_nombre: {
      gridArea: "nombre",
    },
    form_grid_telefono: {
      gridArea: "telefono",
      display: "grid",
      gridTemplateColumns: ".4fr .6fr",
      columnGap: "10px",
    },
    form_grid_email: {
      gridArea: "email",
    },
    form_grid_add: {
      gridArea: "add",
    },
    form_grid_delete: {
      gridArea: "delete",
    },
    form_grid_botonera: {
      gridArea: "botonera",
    },
    add_another_reference_link: {
      fontSize: "14px",
      color: "#DA291C",
      cursor: "pointer",
      textDecoration: "underline",
      width: "180px",
    },
    delete: {
      gridArea: "delete",
      color: "#DA291C",
      textAlign: "center",
      textDecoration: "underline",
      display: "flex",
      cursor: "pointer",
    },
    delete_center: {
      justifyContent: "center",
    },
    delete_right: {
      justifyContent: "flex-end",
    },
    label: {
      fontSize: "14px",
      lineHeight: "18px",
      marginBottom: "10px",
    },
    space_functions:{
      marginTop: "-20px"
    },
    counter_chars_description:{
      fontSize: "12px",
      color: "#D8D8D8",
    },
    save_button: {
      color: "#FFFFFF",
      height: "36px",
      textTransform: "none",
      width:{md:"251px!important",xs:"100%"},
      marginTop: {xs: "30px", sm: "unset"},
      marginBottom: {xs: "30px", sm: "unset"},
      fontSize: {xs: "16px", sm: "unset"},
      fontWeight: 800,
    },
    action_buttons_grid: {
      marginTop:"20px",
      display: "grid",
      gridTemplateColumns: {xs:"1fr",md:".25fr .25fr .5fr"},
      columnGap: "25px",
      rowGap:{xs:"20px" ,md:"24px"},
      gridTemplateAreas: {
      md:`'cancel . save'
      '. . save_and_attach'`,
      xs:`'save'
          'save_and_attach'
          'cancel'`},
      fontSize:{xs:"16px"} ,
    },
};
 export default styles;