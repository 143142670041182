import React, { useState, useEffect, useRef, useCallback ,useContext} from "react";
import { useTranslation } from "react-i18next";
import CurriculumIcon from "../../../../assets/icons/curriculumIcon";
import PriorityLow from "../../../../assets/icons/priorityLow";
import { Box ,useTheme,useMediaQuery,CircularProgress } from "@mui/material";
import { SnackContext, SnackSeverity } from "../../../../shared/contexts/SnackHandler";
import EditIcon from "../../../../assets/icons/editIcon";
import ButtonOutlined from "../../components/Dashboard/buttonOutlined";
import {
  returnLangId,
  formatToLocalDate,
  renderSwitchDigitalProfile,
  validateFile,
  returnMimeData,
  base64ToArrayBuffer,
  getYear,
  getProvincias,
  getNationalityOptions,
  getDiscapacityOptions,
  getNivelesEstudio,
  getAreasEstudio,
  getIdiomasList,
  getNivelesIdioma,
  getPermisosConduccion,
  getSalaryRange,
  getTiposCambioResidencia,
  getTiposDesplazamientos,
  getContractTypes,
  normalizeString
} from "../../../../shared/utils/functions";
import { indicadorCv, Routes } from "../../../../shared/enums/enums";
import {
  getDatosPersonales,
  getFormations,
  getLanguages,
  getWorkExperience,
  getCandidateKnowledge,
  getOtrosDatos,
  getCandidateDocuments,
  getKnowledge,
  downloadDocumentInform,
  addCandidateKnowledge,
  deleteCandidateKnowledge,
  uploadCandidateDocuments,
  getPlace,
  updateIndicadoresCvCandidato,
  deleteProfileCandidate,
  deleteCandidateDocuments,
} from "../../../../shared/hooks/userApi";
import { tiposDocumentos } from "../../../../shared/enums/enums";
import i18next from "../../../../i18n.js";
import ProfilePicture from "../../../../shared/components/ProfilePicture/ProfilePicture";
import OtherData from "../../../../assets/icons/otherData";
import YourCV from "../../../../assets/icons/yourCV";
import YourExperience from "../../../../assets/icons/yourExperience";
import YourFormation from "../../../../assets/icons/yourFormation";
import YourKnowledge from "../../../../assets/icons/yourKnowledge";
import YourLanguages from "../../../../assets/icons/yourLanguages";
import Download from "../../../../assets/icons/download";
import AutocompleteWithChips from "../../../../shared/components/AutocompleteWithChips/autocompleteWithChips";
import TrashRed from "../../../../assets/icons/trashRed";
import DownloadDocument from "../../../../assets/icons/downloadDocument";
import { useApi } from "../../../../shared/hooks/useApi";
import CandidatePhoto from "../../../../assets/icons/candidatePhoto";
import styles from "./styles";
import { languages } from "../../auth/AuthWrapper";
import { useNavigate } from "react-router-dom";
import AntSwitch from "../../../../shared/components/AntSwitch/antSwitch";
import AlertDialog from "../../../../shared/components/AlertDialog/alertDialog";
import PersonalData from "../../../../assets/icons/personalData";
import { AuthService } from "../../auth/AuthService";
import { MaestroContext } from "../../../contexts/MaestroContext";
import { idAplicacionGestor } from "../../../../constants";

const nationalityOptions = getNationalityOptions(i18next.languages[0]);
const discapacityOptions = getDiscapacityOptions(i18next.languages[0]);
const provinces = getProvincias(i18next.languages[0]);
const niveles_de_estudio = getNivelesEstudio(i18next.languages[0]);
const areas_estudio = getAreasEstudio(i18next.languages[0]);
const idiomas_list = getIdiomasList(i18next.languages[0]);
const niveles_idioma = getNivelesIdioma(i18next.languages[0]);
const permisos_de_conduccion = getPermisosConduccion(i18next.languages[0]);
const rangos_salarios = getSalaryRange(i18next.languages[0]);
const tipos_cambio_residencia = getTiposCambioResidencia(i18next.languages[0]);
const tipos_desplazamientos = getTiposDesplazamientos(i18next.languages[0]);
const tipos_Contratos = getContractTypes(i18next.languages[0]);




function Curriculum() {
  const { t } = useTranslation();
  const auth = new AuthService();
  const showSnack = useContext(SnackContext);
  const {localidades,tipoIdentficacion, tipoHorario } = useContext(MaestroContext);

  const navigate = useNavigate();
  const [loadingKnowledgesCandidato, setLoadingKnowledgesCandidato] =
    useState(false);

  const [datosPersonales, setDatosPersonales] = useState("" as any);
  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [presentacion, setPresentacion] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("" as any);
  const [nacionalidad, setNacionalidad] = useState("");
  const [tipoIdentificacion, setTipoIdentificacion] = useState("");
  const [identificacion, setIdentificacion] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [prefijoOtroTelefono, setPrefijoOtroTelefono] = useState("");
  const [otroTelefono, setOtroTelefono] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [provincia, setProvincia] = useState("");
  const [discapacidad, setDiscapacidad] = useState("");
  const [idFotografia, setIdFotografia] = useState(null);
  const [perfilesDigitales, setPerfilesDigitales] = useState(""as any);
  const [noTieneExpLaboral, setNoTieneExpLaboral] = useState(false);
  const [noTieneFormacion, setNoTieneFormacion] = useState(false);
  const [noTieneIdiomas, setNoTieneIdiomas] = useState(false);
  const [noTieneConocimientos, setNoTieneConocimientos] = useState(false);

  const [isSwitchDisable, setIsSwitchDisable] = useState(false);

  const [experience, setExperience] = useState([]);

  const [cvFile, setCvFile] = useState("" as any);
  const [isCvFileLoading, setIsCvFileLoading] = useState<boolean>(false);
  const [isCvActionLoading, setIsCvActionLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [otherData, setOtherData] = useState("" as any);

  const [showAllOtrosDatos, setShowAllOtrosDatos] = useState(false);
  const [seeAllPersonalData, setSeeAllPersonalData] = useState(false);
  const [showAllExperiences, setShowAllExperiences] = useState(false);
  const [showAllCertificaciones, setShowAllCertificaciones] = useState(false);
  const [showAllFormacionesRegladas, setShowAllFormacionesRegladas] =
    useState(false);
  const [showAllIdiomas, setShowAllIdiomas] = useState(false);

  const [allKnowledges, setAllKnowledges] = useState([] as any);
  const [candidateKnowledges, setCandidateKnowledges] = useState("" as any);

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const inputRef = useRef("" as any);

  const [formaciones, setFormaciones] = useState("" as any);
  const [idiomas, setIdiomas] = useState("" as any);

  const innerBody = useRef(null);

  const [knowledgeError, setKnowledgeError] = useState(null);
  const [typeDialog, setTypeDialog] = useState("deleteDoc");
  const [employmentsOptions, setEmploymentsOptions] = useState("" as any);
  const [refPicture, setRefPicture] = useState("" as any);

  const callApi = useApi();

  const NIVEL_CERTIFICACIONES = 15;

  useEffect(() => {
    if (!refPicture && idFotografia && datosPersonales) {
      getProfilePic(datosPersonales.idFotografia);
    }
  }, [idFotografia]);

  useEffect(() => {
    if (!datosPersonales) {
      handleDatosPersonales();
    } else {
      handleSetDatosPersonales();
    }
    handleGetExperienciaLaboral();
  }, [datosPersonales]);

  useEffect(() => {
    if (!formaciones) {
     handleFormaciones();
    }
  }, [formaciones]);

  useEffect(() => {
    handleGetCandidateKnowledge();
    handleGetKnowledges();
    handleGetPlaces();
  }, []);

  useEffect(() => {
    if (!idiomas) {
      handleIdiomas();
    }
  }, [idiomas]);


 useEffect(() => {
    handleGetDocuments();
    handleGetOtrosDatos();
  }, []); 


  const handleGetPlaces = useCallback(() => {
    callApi(getPlace(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      setEmploymentsOptions(data.puestos);
    }).catch((e)=>{
      switch (e.status) {
        case 404:
          showSnack(t("work_experience.get_places_not_found"), SnackSeverity.ERROR);
          break;
        default:
          showSnack(t("work_experience.get_place_error"), SnackSeverity.ERROR);
          break;
      }
    });
}, []);


  const handleGetDocuments = useCallback(() => {
    callApi(getCandidateDocuments(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      const cv = data.find(
        (file: any) =>
          Number(file.idTipoDocumento) ===
          Number(tiposDocumentos.CURRICULUM_VITAE)
      );
      setCvFile(cv);
    }).catch(() => {
      showSnack(t("curriculum.cv_error"), SnackSeverity.ERROR);
      setCvFile(null);
    });
  }, []);

  function handleSelectFile(files:any) {
    if (files[0]) {
      if (validateFile(files[0]) && files[0].name.length <= 80 && /\./.test(files[0].name)) {
        setIsCvFileLoading(true);
        const reader = new FileReader() as any;
        reader.readAsDataURL(files[0]);
        reader.onload = function () {
          const replacement = ".";
          handleSaveDocument({
            nombre: files[0].name.replace(/.([^.]*)$/, replacement + "$1"),
            fichero: reader.result.substring(reader.result.indexOf(",") + 1),
          }); 
        };
      }else {
        if (files[0].size === 0) {
          showSnack(t("error_docs.error_not_valid2"), SnackSeverity.ERROR);
        } else if (files[0].name.length > 80) {
          showSnack(t("error_docs.error_not_valid3"), SnackSeverity.ERROR);
        } else if (!/\./.test(files[0].name)) {
          showSnack(t("error_docs.error_not_valid4"), SnackSeverity.ERROR);
        } else if (files[0].size > 20971520) {
          showSnack(t("error_docs.error_not_valid5"), SnackSeverity.ERROR);
        } else {
          showSnack(t("error_docs.error_not_valid"), SnackSeverity.ERROR);
        }
      }
    }
  }
  
  const handleSaveDocument = useCallback((file: any) => {
  
    callApi(uploadCandidateDocuments(
      returnLangId(i18next.languages[0] as keyof typeof languages),
      { idTipoDocumento: tiposDocumentos.CURRICULUM_VITAE, documentos: [file] })).then((result) => {
        const uploadedCv = result
          ? {
            idDocumento: result.documentos[0].idDocumento,
            idTipoDocumento: result.idTipoDocumento,
            nombre: result.documentos[0].nombre,
          }
          : null;
        setCvFile(uploadedCv);
        setIsCvFileLoading(false);
      }).catch((response) => {
        switch (response.status) {
          case 400:
            showSnack(t("curriculum.error_bad_request_update_documents"), SnackSeverity.ERROR);
            break;
          case 404:
            showSnack(t("curriculum.error_not_found_update_documents"), SnackSeverity.ERROR);

            break;
          default:
            showSnack(t("curriculum.update_error"), SnackSeverity.ERROR);
            break;
        }
        setIsCvFileLoading(false);
      });
      
  }, []);



  const handleDeleteDocument = useCallback((document:any) => {
    setIsCvFileLoading(true);
    callApi(deleteCandidateDocuments(returnLangId(i18next.languages[0] as keyof typeof languages),document.idDocumento)).then(() => {
      setCvFile(null);
      setIsCvFileLoading(false);
    }).catch((response) => {
      switch (response.status) {
        case 400:
          showSnack(t("curriculum.error_bad_request_delete_documents"), SnackSeverity.ERROR);
          break;
        case 404:
          showSnack(t("curriculum.error_not_found_delete_documents"), SnackSeverity.ERROR);

          break;
        default:
          showSnack(t("curriculum.delete_error"), SnackSeverity.ERROR);
          break;
      }
      setIsCvFileLoading(false);
    });
}, []);


  const handleDownloadDocument = useCallback(() => {
    setIsCvActionLoading(true);
    callApi(downloadDocumentInform(cvFile.idDocumento)).then((result) => {
        const url = URL.createObjectURL(
            new Blob([base64ToArrayBuffer(result)], {
                type: returnMimeData(cvFile.nombre),
            })
        );
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.download = cvFile.nombre;
        fileLink.click();
        setIsCvActionLoading(false);
      }).catch(() => {
        showSnack(t("curriculum.error_download"), SnackSeverity.ERROR);
        setIsCvActionLoading(false);
    });
   
}, [cvFile]);

  const handleDatosPersonales = useCallback(() => {
    callApi(getDatosPersonales(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
        setDatosPersonales(data);
        setIdFotografia(data.idFotografia);
    }).catch(() => {
      showSnack(t("curriculum.personal_data_error"), SnackSeverity.ERROR);
      setDatosPersonales(null);
    });
}, []);

  const handleFormaciones = useCallback(() => {
    callApi(getFormations(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      setFormaciones(data);
    }).catch(() => {
      showSnack(t("curriculum.formations_error"), SnackSeverity.ERROR);
      setFormaciones(null);
    });
  }, []);

  const handleIdiomas = useCallback(() => {
    callApi(getLanguages(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      setIdiomas(data);
    }).catch(() => {
      showSnack(t("curriculum.languages_error"), SnackSeverity.ERROR);
      setIdiomas(null);
    });
  }, []);

  const handleGetExperienciaLaboral = useCallback(() => {
    callApi(getWorkExperience(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
        setExperience(data.sort(orderExperienceCandidate));
    }).catch((response) => {
       switch (response.status) {
        case 400:
          showSnack( t("curriculum.get_work_experience_bad_request"), SnackSeverity.ERROR);
          break;
        case 404:
          showSnack(t("curriculum.get_work_experience_not_found"), SnackSeverity.ERROR);
          break;
        default:
          showSnack(t("curriculum.get_work_experience_error"), SnackSeverity.ERROR);
          break;
      }
      });
}, []);
    
const handleGetCandidateKnowledge = useCallback(() => {
  callApi(getCandidateKnowledge(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      setCandidateKnowledges(
          data.map((r:any) => {
            return {
              id: r.idConocimiento,
              nombre: r.nombre,
            };
          })
        );
        setLoadingKnowledgesCandidato(false);
  }).catch((response) => {
    setLoadingKnowledgesCandidato(false);
    setCandidateKnowledges(null);
    switch (response.status) {
      case 404:
        showSnack(t("curriculum.candidate_not_found"), SnackSeverity.ERROR);
        break;
      default:
        showSnack(t("curriculum.get_candidate_knowledge_error"), SnackSeverity.ERROR);
        break;
    }
  });
}, []);


  const handleGetOtrosDatos = useCallback(() => {
    callApi(getOtrosDatos(returnLangId(i18next.languages[0] as keyof typeof languages))).then((data) => {
      setOtherData(data);
    }).catch(() => {
      showSnack(t("other_data.error_getting_data"), SnackSeverity.ERROR);
      setOtherData(null);
    });
  }, []);

  const handleGetKnowledges = useCallback(() => {
    callApi(getKnowledge()).then((data) => {
      setAllKnowledges(data.conocimientos);
    }).catch((response) => {
      if (response.status !== 404) {
        setKnowledgeError(t("curriculum.get_knowledge_error"));
      }
    });
  }, []);

  function getTipoDisponibilidadViajar() {
    return otherData.tipoDisponibilidadViajar
      ? tipos_desplazamientos.find(
          (desplazamientos:any) =>
            Number(desplazamientos.id) === otherData.tipoDisponibilidadViajar
        )
      : tipos_desplazamientos.find(
          (desplazamientos:any) => Number(desplazamientos.id) === 4
        );
  }

  function getTipoDisponibilidadCambioResidencia() {
    return otherData.tipoDisponibilidadCambioResidencia
      ? tipos_cambio_residencia.find(
          (cambio_residencia:any) =>
            Number(cambio_residencia.id) ===
            otherData.tipoDisponibilidadCambioResidencia
        )
      : tipos_cambio_residencia.find(
          (cambio_residencia:any) => Number(cambio_residencia.id) === 1
        );
  }

  function getSalarioMinimo() {
    return otherData.salarioMinimo
      ? rangos_salarios.find(
          (rango:any) => Number(rango.id) === otherData.salarioMinimo
        )
      : null;
  }

  function getSalarioDeseado() {
    return otherData.salarioDeseado
      ? rangos_salarios.find(
          (rango:any) => Number(rango.id) === otherData.salarioDeseado
        )
      : null;
  }

  function getTiposCarnetConducir() {
    return otherData.tiposCarnetConducir
      ? permisos_de_conduccion.filter((permiso:any) =>
          otherData.tiposCarnetConducir.includes(Number(permiso.id))
        )
      : [];
  }

  function getTiposHorario() {
    return otherData.tiposHorario
      ? tipoHorario.filter((jornada:any) =>
          otherData.tiposHorario.includes(Number(jornada.id))
        )
      : [];
  }

  function getPuestoDeseadoFavorito() {
    return otherData.puestosDeseados && employmentsOptions
      ? employmentsOptions.find(
          (e:any) =>
            otherData.puestosDeseados?.find((p:any) => p.esPrincipal)?.idPuesto ===
            e.id
        )
      : "";
  }
  
  function getTiposContrato() {
    return otherData.tiposContrato
      ? tipos_Contratos.filter((c:any) =>
          otherData.tiposContrato.includes(Number(c.id))
        )
      : [];
  }

  function setterKnowledgeOptions(knowledge:any) {
    const newKnowledge = knowledge[knowledge.length - 1];
    if (typeof newKnowledge === "string") {
      const foundKnowledge = allKnowledges.find(
        (knowledge:any) =>
          knowledge?.nombre?.toUpperCase() === newKnowledge.toUpperCase()
      );
      if (foundKnowledge) {
        knowledge.pop();
      }
    }
    setAllKnowledges(knowledge);
  }

  function setterKnowledge(knowledge:any) {
    const newKnowledge = knowledge[knowledge.length - 1];
    if (typeof newKnowledge === "string") {
      const foundKnowledge = allKnowledges.find(
        (knowledge:any) =>
          knowledge?.nombre?.toUpperCase() === newKnowledge.toUpperCase()
      );
      if (foundKnowledge) {
        const index = candidateKnowledges.findIndex(
          (knowledge:any) =>
            knowledge?.nombre?.toUpperCase() === newKnowledge.toUpperCase()
        );
        if (index < knowledge.length - 1) {
          knowledge.pop();
        } else {
          knowledge[knowledge.length - 1] = foundKnowledge;
        }
      }
    }
    setCandidateKnowledges(knowledge);
  }

  async function saveCandidateKnowledge(value: any) {
    setLoadingKnowledgesCandidato(true);
    let data;
    if (typeof value === "string") {
      data = {
        nombre: value,
        idConocimiento: 0,
      };
    } else if (value?.id === 0) {
      const foundKnowledge = allKnowledges.find(
        (knowledge: any) =>
          normalizeString(knowledge?.nombre) === normalizeString(value?.nombre)
      );

      if (foundKnowledge) {
        data = {
          nombre: foundKnowledge.nombre,
          idConocimiento: foundKnowledge.id,
        };
      } else {
        data = {
          nombre: value?.nombre,
          idConocimiento: value?.id,
        };
      }
    } else {
      data = {
        nombre: value?.nombre,
        idConocimiento: value?.id,
      };
    }
    callApi(addCandidateKnowledge(returnLangId(i18next.languages[0] as keyof typeof languages), data)).then(() => {
      handleGetCandidateKnowledge();
      handleGetKnowledges();
    }).catch(() => setLoadingKnowledgesCandidato(false));
  }


  const deleteKnowledge = useCallback((value:any) => {
    setLoadingKnowledgesCandidato(true);
    callApi(deleteCandidateKnowledge(returnLangId(i18next.languages[0] as keyof typeof languages),value.id)).then(() => {
        handleGetCandidateKnowledge();
    }).catch(() => {  setLoadingKnowledgesCandidato(false);});
}, []);


  function setIndicadorStateValue(indicador:any, value:boolean) {
    switch (indicador) {
      case indicadorCv.EXPERIENCIA_LABORAL:
        setNoTieneExpLaboral(value);
        break;
      case indicadorCv.CONOCIMIENTOS:
        setNoTieneConocimientos(value);
        break;
      case indicadorCv.FORMACIONES:
        setNoTieneFormacion(value);
        break;
      case indicadorCv.IDIOMAS:
        setNoTieneIdiomas(value);
        break;
    }
  }


  async function handleUpdateIndicadoresCvCandidato(indicador:any, value:any) {
    setIsSwitchDisable(true);
   const data = {
      idIndicador: indicador,
      valorIndicador: !value,
    };
    setIndicadorStateValue(indicador, value);

    callApi(updateIndicadoresCvCandidato(returnLangId(i18next.languages[0] as keyof typeof languages), data)).then(() => {
    }).catch((response) => { setIndicadorStateValue(indicador, !value);
      switch (response.status) {
        case 404:
          showSnack(t("curriculum.candidate_not_found"), SnackSeverity.ERROR);
          break;
        default:
          showSnack(t("curriculum.switch_error"), SnackSeverity.ERROR);
          break;
      }
    });
    setIsSwitchDisable(false);
  }

  const handleDeleteProfile = useCallback(() => {
    callApi(deleteProfileCandidate()).then(() => {
      auth.removeUser();
      auth.logout();
    }).catch(() => {setOtherData(null);});
}, []);

  function handleSetDatosPersonales() {
    setNombre(datosPersonales.nombre ? datosPersonales.nombre.trim() : "");
    setPrimerApellido(
      datosPersonales.primerApellido
        ? datosPersonales.primerApellido.trim()
        : ""
    );
    setSegundoApellido(
      datosPersonales.segundoApellido
        ? datosPersonales.segundoApellido.trim()
        : ""
    );
    setPresentacion(
      datosPersonales.presentacion
        ? datosPersonales.presentacion.trim().length > 370
          ? `${datosPersonales.presentacion.slice(0, 370).trim()}...`
          : datosPersonales.presentacion
        : null
    );
    setFechaNacimiento(
      datosPersonales.fechaNacimiento
        ? formatToLocalDate(new Date(datosPersonales.fechaNacimiento.trim()))
        : null
    );
    setNacionalidad(
      datosPersonales.nacionalidad
        ? nationalityOptions
            .find(
              (element:any) => Number(element.id) === datosPersonales.nacionalidad
            )
            ?.name.trim() ?? ""
        : null
    );
    setTipoIdentificacion(
      datosPersonales.tipoIdentificacion
        ? tipoIdentficacion
            .find(
              (element:any) =>
                Number(element.id) === datosPersonales.tipoIdentificacion
            )
            ?.name.trim() ?? ""
        : ""
    );
    setIdentificacion(
      datosPersonales.identificacion
        ? datosPersonales.identificacion.trim()
        : ""
    );
    setCorreoElectronico(
      datosPersonales.correoElectronico
        ? datosPersonales.correoElectronico.trim()
        : ""
    );
    setTelefono(
      datosPersonales.telefono
        ? datosPersonales.telefono.numero
          ? datosPersonales.telefono.numero.trim()
          : ""
        : null
    );
    setPrefijo(
      datosPersonales.telefono
        ? datosPersonales.telefono.prefijo
          ? datosPersonales.telefono.prefijo.trim()
          : ""
        : null
    );
    setOtroTelefono(
      datosPersonales.otroTelefono
        ? datosPersonales.otroTelefono.numero
          ? datosPersonales.otroTelefono.numero.trim()
          : ""
        : null
    );
    setPrefijoOtroTelefono(
      datosPersonales.otroTelefono
        ? datosPersonales.otroTelefono.prefijo
          ? datosPersonales.otroTelefono.prefijo.trim()
          : ""
        : null
    );
    setProvincia(
      datosPersonales.provincia
        ? provinces
            .find((element:any) => Number(element.id) === datosPersonales.provincia)
            ?.name.trim() ?? ""
        : ""
    );
    setLocalidad(
      datosPersonales.localidad
        ? localidades
            .find((element:any) => Number(element.id) === datosPersonales.localidad)
            ?.name.trim() ?? ""
        : null
    );
    setCodigoPostal(
      datosPersonales.codigoPostal ? datosPersonales.codigoPostal.trim() : ""
    );
    setDiscapacidad(
      datosPersonales.idDiscapacidad && datosPersonales.idDiscapacidad !== 1
        ? discapacityOptions
            .find((element:any) => element.id === datosPersonales.idDiscapacidad)
            ?.name.trim() ?? ""
        : null
    );
    setIdFotografia(
      datosPersonales.idFotografia ? datosPersonales.idFotografia : null
    );
    setPerfilesDigitales(
      datosPersonales.perfilesDigitales
        ? datosPersonales.perfilesDigitales
        : null
    );
    setNoTieneExpLaboral(
      datosPersonales.tieneExpLaboral ? !datosPersonales.tieneExpLaboral : true
    );
    setNoTieneFormacion(
      datosPersonales.tieneFormacion ? !datosPersonales.tieneFormacion : true
    );
    setNoTieneIdiomas(
      datosPersonales.tieneIdiomas ? !datosPersonales.tieneIdiomas : true
    );
    setNoTieneConocimientos(
      datosPersonales.tieneConocimientos
        ? !datosPersonales.tieneConocimientos
        : true
    );
  }


  const getProfilePic = useCallback((idPhoto: any) => {
    callApi(downloadDocumentInform(idPhoto)).then((result: any) => {
      const obj_url = URL.createObjectURL(
        new Blob([base64ToArrayBuffer(result)], {
          type: `application/image/*`,
        })
      );
      setRefPicture(obj_url);
    }).catch(() => {
      showSnack(t("candidateDashboard.error_loading_img"), SnackSeverity.ERROR);
    });
  }, []);

  function profilePicture() {
    return  refPicture ? (
        <Box
        component="img" src={refPicture} sx={styles.photo} alt="" />
    ) : (
      <CandidatePhoto title={t("icons_tooltips.candidate_picture")} />
    );
  }

  function tuCurriculumHeader() {
    return (
      <Box sx={styles.col1}>
        <Box sx={styles.summary_icon_container}>
          <CurriculumIcon
            title={t("icons_tooltips.curriculum_space")}
            color="#DA291C"
            noShrink
          />
        </Box>
        <Box sx={styles.title_text}>{t("curriculum.title")}</Box>
        {!matchesMobile && (
          <Box sx={styles.candidate_photo_container}>
            {profilePicture()}
          </Box>
        )}
        {datosPersonalesSection()}
      </Box>
    );
  }

  function dataSectionHeader(
    icon:any,
    title:string,
    linkText:any, 
    isPersonalData = false as boolean,
    onClick = null as any,
    hideButton = false as boolean
  ) {
    return (
      <Box
        sx={
          isPersonalData && !matchesMobile
            ? styles.data_section_header_customize
            : styles.data_section_header
        }
      >
        <Box
          sx={
            isPersonalData && !matchesMobile
              ? styles.data_section_icon_and_title_customize
              : styles.data_section_icon_and_title
          }
        >
          {icon}
          <Box
            sx={
              isPersonalData && !matchesMobile
                ? styles.data_section_title_customize
                : styles.data_section_title
            }
          >
            {title}
          </Box>
        </Box>
        {!hideButton && (
          <Box component="a" sx={styles.data_section_linkbutton} onClick={onClick}>
            {linkText}
          </Box>
        )}
      </Box>
    );
  }

  function renderShowAll(showAllVar:any, onClick:any, marginTop = 0 as any) {
    return (
      <Box
        sx={styles.see_all_otros_datos_style}
        onClick={onClick}
        style={{ marginTop }}
      >
       <Box sx={[showAllVar ? styles.rotate_icon : {}]}>
          <PriorityLow title={t("icons_tooltips.expand")} />
        </Box>
        <Box style={{ marginLeft: "9px" }}>
          {`${showAllVar ? t("common.see_less") : t("common.see_all")}`}
        </Box>
      </Box>
    );
  }

  function personalData() {
    return (
      <Box sx={styles.personal_data_section_container}>
        <Box sx={styles.name_and_date_grid}>
          <Box sx={styles.fullname}>
            {`${nombre} ${primerApellido} ${segundoApellido}`}
          </Box>
        </Box>
        {seeAllPersonalData ? (
          <Box sx={styles.personal_data_section_container}>
            {presentacion && (
              <Box
                sx={styles.personal_data_inner_sections}
              >{`"${presentacion}"`}</Box>
            )}
            <Box sx={styles.personal_data_inner_sections}>
              <Box sx={styles.email}>{correoElectronico}</Box>
              <Box>{prefijo && `${"+" + prefijo} ${telefono && telefono}`}</Box>
              {prefijoOtroTelefono && otroTelefono && (
                <Box>
                  {prefijoOtroTelefono &&
                    `+${prefijoOtroTelefono} ${otroTelefono}`}
                </Box>
              )}
              {fechaNacimiento && <Box>{fechaNacimiento}</Box>}
            </Box>
            <Box sx={styles.personal_data_inner_sections}>
              {localidad && <Box>{localidad}</Box>}
              <Box>{`${codigoPostal && codigoPostal} ${provincia}`}</Box>
              {nacionalidad && (
                <Box>{`${t("curriculum.nationality")} ${nacionalidad}`}</Box>
              )}
              <Box>{`${tipoIdentificacion && tipoIdentificacion} ${
                identificacion && identificacion
              }`}</Box>
              {discapacidad && (
                <Box>{`${t("curriculum.disability")} ${discapacidad}`}</Box>
              )}
            </Box>
            <Box sx={styles.digital_profiles_sections}>
              {perfilesDigitales.map((profile:any) => (
                <Box
                  key={profile.id}
                  sx={styles.digital_profiles_button}
                  onClick={() => window.open(profile.url)}
                >
                  {renderSwitchDigitalProfile(profile.tipoPerfilDigital)}
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Box sx={styles.personal_data_inner_sections}>
            <Box>{correoElectronico}</Box>
            <Box> {prefijo && `+${prefijo} ${telefono}`}</Box>
          </Box>
        )}
        <Box
          sx={styles.see_notifications_button}
          onClick={() => setSeeAllPersonalData(!seeAllPersonalData)}
        >
          <Box>
            {`${
              seeAllPersonalData
                ? t("curriculum.see_less")
                : t("curriculum.see_all")
            }`}
          </Box>
         <Box sx={[seeAllPersonalData && styles.rotate_icon]}>
            <PriorityLow />
          </Box>
        </Box>
      </Box>
    );
  }

  function datosPersonalesSection() {
    return (
      <Box sx={styles.data_section}>
        {dataSectionHeader(
         matchesMobile && <PersonalData />,
          t("curriculum.personalData"),
          t("curriculum.edit"),
          true,
          () => navigate(Routes.DATOS_PERSONALES)
        )}
        {matchesMobile && <ProfilePicture
                          idFoto={idFotografia} />}
        {personalData()}
      </Box>
    );
  }

  function noDataYet(text:any,checked:boolean, setter:any) {
    checked;
    setter;
    return (
      <Box sx={styles.no_data_yet_container}>
        <Box>{text}</Box>
       <AntSwitch
          checked={checked}
          setter={setter}
          disabled={isSwitchDisable}
        /> 
      </Box>
    );
  }

  function orderExperienceCandidate(a:any, b:any) {
    if (a.fechaInicio && b.fechaInicio && a.fechaFin && b.fechaFin) {
      if (
        a.fechaFin === "0001-01-01T00:00:00" &&
        b.fechaFin === "0001-01-01T00:00:00"
      ) {
        
        return new Date(b.fechaInicio).valueOf() - new Date(a.fechaInicio).valueOf();
      } else {
        if (a.fechaFin === "0001-01-01T00:00:00") {
          return -1;
        }
        if (b.fechaFin === "0001-01-01T00:00:00") {
          return 1;
        }
      }
      return new Date(b.fechaInicio).valueOf() - new Date(a.fechaInicio).valueOf();
    }
  }

  function renderEtt(exp:any) {
    if (exp.esEtt) return ` - ${exp.ett.nombre}`;
    else return null;
  }

  const handleMinOrMaxSalary = (exp:any) => {
    return (
      <>
        {exp.salarioMinimo ?
          <>
            {`${t("curriculum.minSalaryReceived")}
              ${exp.salarioMinimo.nombre} ${exp.tipoSalario.nombre}`}
          </>
          :
          <>
            {`${t("curriculum.maxSalaryReceived")}
              ${exp.salarioMaximo.nombre} ${exp.tipoSalario.nombre}`}
          </>
        }
      </>
    );
  };

  function printReceivedSalary(experience:any) {
    return (
      experience.tipoSalario &&
      (experience.salarioMinimo || experience.salarioMaximo) && (
        <>
          <Box>
            {experience.salarioMinimo && experience.salarioMaximo ?
              <>
                {`${t("curriculum.salaryReceived")}:
                  ${experience.salarioMinimo.nombre} - ${experience.salarioMaximo.nombre} ${experience.tipoSalario.nombre}`}
              </>
              : handleMinOrMaxSalary(experience)}
          </Box>
        </>
      )
    );
  }

  function handleStaffOrManager(msg:string, managerOrStaff:any) {
    return (
      <Box>
        {managerOrStaff &&
          `${msg}: ${managerOrStaff.nombre}`
        }
      </Box>
    );
  }

  function printLevelExperience(experiencie:any) {
    return experiencie.nivel &&
      <Box>
        {`${t("curriculum.levelExperience")}: ${experiencie?.nivel.nombre}`}
      </Box>;
  }

  const experiencias = () =>
  
    experience
      .slice(0, showAllExperiences ? experience.length : 2)
      .map((exp:any) => (
        <Box key={exp.id} sx={styles.experience_container}>
          <Box sx={styles.experience_title_container}>
            <Box sx={styles.experience_title}>{exp.puesto.nombre}</Box>
            <Box
              onClick={() =>
                
                  navigate(`${Routes.WORK_EXPERIENCE}?id=${exp.idExperienciaLaboral}`)
              }
              sx={styles.experience_edit_button}
            >
              <EditIcon title={t("icons_tooltips.edit_experience")} />
            </Box>
          </Box>
          <Box sx={styles.experience_details}>
            {exp.empresa} ({exp.sector.nombre}) {renderEtt(exp)}
          </Box>
          <Box sx={styles.container_experience}>
            {exp.funciones.map((fun:any) => (
              <Box sx={styles.experience_ind} key={fun.id}>
                <Box
                  sx={styles.experience_separator}
                  style={{ margin: "3px 0px"}}
                ></Box>
                <Box
                  key={fun.id}
                  sx={[styles.experience_details ,styles.experience_functions]}
                >
                  {fun.nombre}
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={[exp.tipoSalario && (exp.salarioMinimo || exp.salarioMaximo)  ? {} : styles.no_Space]}>
            <Box sx={styles.container_experience}>
              {printReceivedSalary(exp)}
            </Box>
          </Box>
         <Box sx={[!exp.nivel ? styles.no_Space : {}]}>
            <Box sx={styles.container_experience}>
              {printLevelExperience(exp)}
              {handleStaffOrManager(t("curriculum.directManager"), exp.nivelResponsable)}
              {handleStaffOrManager(t("curriculum.staffInCharge"), exp.personalACargo)}
            </Box>
          </Box>
          <Box>
            <Box sx={styles.experience_dates}>
              {new Date(exp.fechaInicio).getFullYear()} -
            </Box>
            <Box sx={styles.experience_dates}>
              {exp.fechaFin && new Date(exp.fechaFin).getFullYear() !== 1
                ? new Date(exp.fechaFin).getFullYear()
                : t("curriculum.actually")}
            </Box>
          </Box>
          <Box sx={exp.conocimientos.length>0?styles.container_conocimientos:styles.hidden_input}>
            {exp.conocimientos.map((con:any) => (
              <Box key={con.id} sx={styles.chip}>
                {con?.nombre}
              </Box>
            ))}
          </Box>
          <Box
            sx={
              exp.referencias.length > 0
                ? styles.experience_details
                : styles.experience
            }
          >
            {exp.referencias.length > 0 ? t("curriculum.references") : ""}
            {exp.referencias.map((r:any) => (
              <Box key={r.id} sx={styles.referencia}>
                <Box sx={styles.referencia_nombre}>{r.nombre}</Box>
                {r.telefono.numero ? (
                  <Box>
                    {t("curriculum.phone")}: +{r.telefono.prefijo}{" "}
                    {r.telefono.numero}
                  </Box>
                ) : (
                  <Box />
                )}
                <Box sx={styles.referencia_email}>
                  {r.correoElectronico}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ));

  function experienciaSection() {
    return (
      <>
        <Box sx={styles.data_section}>
          {dataSectionHeader(
            <YourExperience />,
            t("curriculum.experience"),
            t("curriculum.add"),
            false,
            () => navigate(`${Routes.WORK_EXPERIENCE}`),
            noTieneExpLaboral
          )}
          {experience ? (
            <>
              {experience.length > 0 ? (
                experiencias()
              ) : (
                noDataYet(
                  t("curriculum.no_experience"),
                  noTieneExpLaboral,
                  () => {
                    handleUpdateIndicadoresCvCandidato(
                      indicadorCv.EXPERIENCIA_LABORAL,
                      !noTieneExpLaboral
                    );
                  }
                )
              )}
            </>
          ) : (
            <Box style={{ color: "#DA291C" }}>
              {t("curriculum.section_error")}
            </Box>
          )}
        </Box>
        {experience &&
          experience.length > 2 &&
          renderShowAll(showAllExperiences, () =>
            setShowAllExperiences(!showAllExperiences)
          )}
      </>
    );
  }

  function editarFormacion(formacion:any) {
    navigate(`${Routes.TRAINING}?idFormacion=${formacion.idFormacion}`);
  }

  const nivelEstudio = (idNivelEstudios:number) => {
    const nivel = niveles_de_estudio.find(
      (nivel:any) => Number(nivel.id) === Number(idNivelEstudios)
    );
    return nivel ? nivel.name : "";
  };

  const areaEstudio = (idAreaEstudios:number) => {
    const area = areas_estudio.find(
      (area:any) => Number(area.id) === Number(idAreaEstudios)
    );
    return area ? area.name : "";
  };

  function renderSingleFormacionReglada(formacion:any) {
    return (
      <Box
        sx={styles.your_data_inner_container}
        key={formacion.idFormacion}
      >
        <Box sx={styles.formacion_header}>
          <Box sx={styles.formacion_title}>
            {nivelEstudio(formacion.idNivelEstudios)}
          </Box>
          <Box
            onClick={() => editarFormacion(formacion)}
            sx={styles.edit_your_single_data}
          >
            <EditIcon title={t("icons_tooltips.edit_formation")} />
          </Box>
        </Box>
        <Box>{formacion.titulo}</Box>
        <Box>{areaEstudio(formacion.idAreaEstudios)}</Box>
        {formacion.centro && formacion.centro.trim() !== "" && (
          <Box>{formacion.centro}</Box>
        )}
        <Box>{`${getYear(formacion.fechaInicio)} - ${
          formacion.esFormacionActual
            ? t("curriculum.currently_studying")
            : getYear(formacion.fechaFin)
        }`}</Box>
      </Box>
    );
  }

  function renderSingleCertificacion(formacion:any) {
    return (
      <Box key={formacion.idFormacion}>
        <Box sx={styles.formacion_header}>
          <Box sx={styles.formacion_title}>
            {formacion.certificacion.nombre}
          </Box>
          <Box
            onClick={() => editarFormacion(formacion)}
            sx={styles.edit_your_single_data}
          >
            <EditIcon title={t("icons_tooltips.edit_certificate")} />
          </Box>
        </Box>
        {formacion.centro && formacion.centro.trim() !== "" && (
          <Box>{formacion.centro}</Box>
        )}
        {formacion.tieneFechaValidez && formacion.fechaFinValidez && (
          <Box>{`${t("curriculum.valid_until")} ${getYear(
            formacion.fechaFinValidez
          )}`}</Box>
        )}
      </Box>
    );
  }

  function renderGridFormaciones(isCertificados:boolean) {
    const certificados = formaciones.filter(
      (formacion:any) => formacion.idNivelEstudios === NIVEL_CERTIFICACIONES
    );
    const formacionesRegladas = formaciones.filter(
      (formacion:any) => formacion.idNivelEstudios !== NIVEL_CERTIFICACIONES
    );
    return (
      <Box sx={styles.formacion_title_grid}>
        <Box sx={styles.formacion_section_title}>
          {isCertificados
            ? t("curriculum.certifications")
            : t("curriculum.formation")}
        </Box>
        {isCertificados ? (
          <>
            <Box sx={styles.your_data_grid}>
              {certificados
                .slice(0, showAllCertificaciones ? certificados.length : 2)
                .map((formacion:any) => renderSingleCertificacion(formacion))}
            </Box>
            {certificados.length > 2 &&
              renderShowAll(
                showAllCertificaciones,
                () => setShowAllCertificaciones(!showAllCertificaciones),
                "20px"
              )}
          </>
        ) : (
          <>
            <Box sx={styles.your_data_grid}>
              {formacionesRegladas
                .slice(
                  0,
                  showAllFormacionesRegladas ? formacionesRegladas.length : 2
                )
                .map((formacion:any) => renderSingleFormacionReglada(formacion))}
            </Box>
            {formacionesRegladas.length > 2 &&
              renderShowAll(
                showAllFormacionesRegladas,
                () =>
                  setShowAllFormacionesRegladas(!showAllFormacionesRegladas),
                "20px"
              )}
          </>
        )}
      </Box>
    );
  }

  function renderFormaciones() {
    return (
      <Box sx={styles.grids_formaciones}>
        {formaciones.filter(
          (formacion:any) => formacion.idNivelEstudios !== NIVEL_CERTIFICACIONES
        ).length > 0 && renderGridFormaciones(false)}
        {formaciones.filter(
          (formacion:any) => formacion.idNivelEstudios === NIVEL_CERTIFICACIONES
        ).length > 0 && renderGridFormaciones(true)}
      </Box>
    );
  }

  function formacion() {
    return (
      <Box sx={styles.data_section}>
        {dataSectionHeader(
          <YourFormation />,
          t("curriculum.education"),
          t("curriculum.add"),
          false,
          () => navigate(Routes.TRAINING),
          noTieneFormacion
        )}
        {formaciones ? (
          formaciones.length > 0 ? (
            renderFormaciones()
          ) : (
            noDataYet(t("curriculum.no_education"), noTieneFormacion, () =>
              handleUpdateIndicadoresCvCandidato(
                indicadorCv.FORMACIONES,
                !noTieneFormacion
              )
            )
          )
        ) : (
          <Box style={{ color: "#DA291C" }}>
            {t("curriculum.section_error")}
          </Box>
        )}
      </Box>
    );
  }

  function editarIdioma(idIdioma:number) {
    navigate(`${Routes.LANGUAGE}?idIdioma=${idIdioma}`);
  }

  const getNombreIdioma = (idIdioma:any) => {
    const result = idiomas_list.find(
      (lang:any) => Number(lang.id) === Number(idIdioma)
    );
    return result ? result.name : "";
  };

  const getNombreNivel = (idNivel:number) => {
    const result = niveles_idioma.find((n:any) => Number(n.id) === Number(idNivel));
    return result ? result.name : "";
  };

  function renderSingleIdioma(idioma:any) {
    return (
      <Box sx={styles.your_data_inner_container} key={idioma.idIdioma}>
        <Box sx={styles.single_language_header}>
          <Box>{getNombreIdioma(idioma.idIdioma)}</Box>
          <Box
            onClick={() => editarIdioma(idioma.idIdioma)}
            sx={styles.edit_your_single_data}
          >
            <EditIcon title={t("icons_tooltips.edit_language")} />
          </Box>
        </Box>
        <Box sx={styles.languages_inner_grid}>
          <Box style={{ fontWeight: 600 }}>{`${t("curriculum.written")}:`}</Box>
          <Box>{getNombreNivel(idioma.idNivelEscrito)}</Box>
          <Box style={{ fontWeight: 600 }}>{`${t("curriculum.readed")}:`}</Box>
          <Box>{getNombreNivel(idioma.idNivelLeido)}</Box>
          <Box style={{ fontWeight: 600 }}>{`${t("curriculum.spoken")}:`}</Box>
          <Box>{getNombreNivel(idioma.idNivelHablado)}</Box>
          {idioma.certificados && idioma.certificados.length > 0 && (
            <Box style={{ fontWeight: 600 }}>{`${t(
              "curriculum.certificates"
            )}:`}</Box>
          )}
          {idioma.certificados && idioma.certificados.length > 0 && (
            <Box sx={styles.certificate_inner_list}>
              {idioma.certificados.map((certificado:any) => (
                <Box
                 key={certificado.id}
                  sx={styles.certificate}
                >{`${certificado.nombreCertificacion} (${certificado.año})`}</Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  function renderIdiomas() {
    return (
      <>
        <Box sx={styles.your_data_grid}>
          {idiomas
            .slice(0, showAllIdiomas ? idiomas.length : 2)
            .map((idioma:any) => renderSingleIdioma(idioma))}
        </Box>
        {idiomas &&
          idiomas.length > 2 &&
          renderShowAll(showAllIdiomas, () =>
            setShowAllIdiomas(!showAllIdiomas)
          )}
      </>
    );
  }

  function tusIdiomas() {
    return (
      <Box sx={styles.data_section}>
        {dataSectionHeader(
          <YourLanguages />,
          t("curriculum.languages"),
          t("curriculum.add"),
          false,
          () => navigate(Routes.LANGUAGE),
          noTieneIdiomas
        )}
        {idiomas ? (
          idiomas.length > 0 ? (
            renderIdiomas()
          ) : (
            noDataYet(t("curriculum.no_languages"), noTieneIdiomas, () =>
              handleUpdateIndicadoresCvCandidato(
                indicadorCv.IDIOMAS,
                !noTieneIdiomas
              )
            )
          )
        ) : (
          <Box style={{ color: "#DA291C" }}>
            {t("curriculum.section_error")}
          </Box>
        )}
      </Box>
    );
  }

  const handleKnowledgeError = () => {
    if (knowledgeError) {
      showSnack(knowledgeError, SnackSeverity.ERROR);
    }
  };

  function conocimientos() {
    return (
      <Box sx={styles.data_section}>
        {dataSectionHeader(
          <YourKnowledge />,
          t("curriculum.knowledge"),
          t("curriculum.add"),
          false,
          null,
          true
        )}
        {candidateKnowledges ? (
          <>
            {candidateKnowledges.length > 0
              ? null
              : noDataYet(
                  t("curriculum.no_knowledge"),
                  noTieneConocimientos,
                  () =>
                    handleUpdateIndicadoresCvCandidato(
                      indicadorCv.CONOCIMIENTOS,
                      !noTieneConocimientos
                    )
                )}
            {!noTieneConocimientos && (
              <AutocompleteWithChips
                options={allKnowledges}
                values={candidateKnowledges ? candidateKnowledges : []}
                label={t("curriculum.knowledges")}
                setter={setterKnowledge}
                setterOptions={setterKnowledgeOptions}
                saveEvent={saveCandidateKnowledge}
                deleteEvent={deleteKnowledge} 
                loadingChips={loadingKnowledgesCandidato}
                useGrid
                onOpen={handleKnowledgeError}
                moveLabel
                maxLength={150}
                container={styles.container_knowledge}
              />
            )}
          </>
        ) : (
          <Box style={{ color: "#DA291C" }}>
            {t("curriculum.section_error")}
          </Box>
        )}
      </Box>
    );
  }

  function otrosDatos() {
    return (
      <Box sx={styles.data_section}>
        {dataSectionHeader(
          <OtherData />,
          t("curriculum.otherData"),
          t("curriculum.edit"),
          false,
          () => navigate(Routes.OTHER_DATA)
        )}
        {otherData ? (
          <>
            <Box component="li" sx={styles.otros_datos_row}>
              {printTiposCarnetConducir()}
            </Box>
            <Box component="li" sx={styles.otros_datos_row}>
              {printTieneVehiculoPropio()}
            </Box>
            <Box component="li" sx={styles.otros_datos_row}>
              {printTrabajandoActualmente()}
            </Box>
            <Box component="li" sx={styles.otros_datos_row}>
              {printTipoDisponibilidadViajar()}
            </Box>
            {showAllOtrosDatos && (
              <>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printTipoDisponibilidadCambioResidencia()}
                </Box>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printTiposHorario()}
                </Box>

                <Box component="li" sx={styles.otros_datos_row}>
                  {printPermiteFirmaDigital()}
                </Box>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printPermiteGeolocalizacion()}
                </Box>
                <Box component="li"  sx={styles.otros_datos_row}>
                  {printTiposContrato()}
                </Box>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printCobraPrestacionParo()}
                </Box>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printAceptaDiasAislados()}
                </Box>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printSalarioMinimo()}
                </Box>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printSalarioDeseado()}
                </Box>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printPuestoDeseadoFavorito()}
                </Box>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printPermisoTrabajo()}
                </Box>
                <Box component="li" sx={styles.otros_datos_row}>
                  {printCertificadoPenales()}
                </Box>
              </>
            )}
            {renderShowAll(showAllOtrosDatos, () =>
              setShowAllOtrosDatos(!showAllOtrosDatos)
            )}
          </>
        ) : (
          <Box style={{ color: "#DA291C" }}>
            {t("curriculum.section_error")}
          </Box>
        )}
      </Box>
    );
  }


  function printTipoDisponibilidadViajar() {
    return  `${t("curriculum.travel_available")} ${
     getTipoDisponibilidadViajar().name
    }`;
  }

  function printTiposCarnetConducir() {
    return otherData.tiposCarnetConducir.length > 0
      ? `${t("curriculum.driving_lincese")} ${getTiposCarnetConducir().map(
          (carnet:any) => ` ${carnet.name}`
        )}`
      : t("curriculum.no_driving_lincese");
  }

  function printTieneVehiculoPropio() {
    return otherData.tieneVehiculoPropio
      ? t("curriculum.own_car")
      : t("curriculum.no_own_car");
  }

  function printTrabajandoActualmente() {
    return otherData.trabajaActualmente
      ? t("curriculum.currently_working")
      : t("curriculum.currently_not_working");
  }

  function printTipoDisponibilidadCambioResidencia() {
    return `${t("curriculum.residence_change_available")} ${
      getTipoDisponibilidadCambioResidencia().name
    }`;
  }

  function printTiposHorario() {
    return otherData.tiposHorario.length > 0
      ? `${t("curriculum.workday_type")} ${getTiposHorario().map(
          (horario:any) => ` ${horario.name}`
        )}`
      : t("curriculum.no_workday_type");
  }

  function printPermiteFirmaDigital() {
    return otherData.permiteFirmaDigital
      ? t("curriculum.digital_signature")
      : t("curriculum.no_digital_signature");
  }

  function printPermiteGeolocalizacion() {
    return otherData.permiteGeolocalizacion
      ? t("curriculum.geolocalization")
      : t("curriculum.no_geolocalization");
  }

  function printCobraPrestacionParo() {
    return otherData.cobraPrestacionParo
      ? t("curriculum.paid_unemployment")
      : t("curriculum.no_paid_unemployment");
  }

  function printAceptaDiasAislados() {
    return otherData.aceptaDiasAislados
      ? t("curriculum.work_isolated_days")
      : t("curriculum.no_work_isolated_days");
  }

  function printSalarioMinimo() {
    return otherData.salarioMinimo
      ? `${t("curriculum.minimum_wage")} ${getSalarioMinimo().name}`
      : t("curriculum.no_minimum_wage");
  }

  function printSalarioDeseado() {
    return otherData.salarioDeseado
      ? `${t("curriculum.desired_wage")} ${getSalarioDeseado().name}`
      : t("curriculum.no_desired_wage");
  }

  function printPuestoDeseadoFavorito() {
    return `${t("curriculum.employe_desire")}: ${getPuestoDeseadoFavorito().nombre ?? ""}`;
  }
  
  function printTiposContrato() {
    return otherData.tiposContrato?.length > 0
      ? `${t("curriculum.type_contract")} ${getTiposContrato().map(
          (c:any) => ` ${c.name}`
        )}`
      : t("curriculum.no_type_contract");
  }

  function printPermisoTrabajo() {  
    return `${t("other_data.work_permit_simple")}: ${otherData.tienePermisoTrabajo ? "Si" : "No"}`;
  }
  function printCertificadoPenales() {
    return `${t("other_data.criminal_record")}: ${otherData.tieneCertificadoPenales ? "Si" : "No"}`;
  }


  const handleInputRef = useCallback(() => {
    inputRef.current.click();
  }, []);


  function cv() {
    return (
      <Box sx={styles.data_section}>
        {dataSectionHeader(
          <YourCV />,
          t("curriculum.title"),
          t("curriculum.uploadFile"),
          false,
          handleInputRef,
          cvFile || isCvFileLoading
        )} 
        {isCvFileLoading ? (
        <Box sx={styles.alignSpinner}>
         <CircularProgress size={"2rem"} />
         </Box>
        ) : !cvFile ? (
          <Box>
            {t("curriculum.no_document")}
          </Box>
        ) : (
          <Box sx={styles.your_data_grid}>
            <Box sx={styles.cv_row_style}>
              <Box>{cvFile.nombre}</Box>
              {!isCvActionLoading && (
                <Box sx={styles.cv_buttons}>
                  <Box
                    sx={styles.clickable_style}
                    onClick={() => handleDownloadDocument()} 
                  >
                    <DownloadDocument title={t("icons_tooltips.download")} />
                  </Box>
                  {cvFile.idAplicacion !== idAplicacionGestor ? <Box
                    sx={styles.clickable_style}
                    onClick={() => {
                      setTypeDialog("deleteDoc");
                      setIsDialogOpen(true);
                    }}
                  >
                    <TrashRed title={t("icons_tooltips.delete")} />
                  </Box> : null}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleSelectFile(e.target.files);
    }, []); 



  function getContentAlert() {
    if (typeDialog === "deleteProfile") {
      return (
        <Box>
          <p>{t("curriculum.delete_profile_body_alert")}</p>
          <p>{t("curriculum.delete_profile_body_alert2")}</p>
        </Box>
      );
    }
    return null;
  }

  return (
    <>
        <Box sx={styles.principal_container} ref={innerBody}>
          {tuCurriculumHeader()}
          <Box sx={styles.col2}>
            <Box sx={styles.data_sections_container}>
              <Box sx={styles.section_border} />
              {experienciaSection()}
              <Box sx={styles.section_border} />
              {formacion()}
              <Box sx={styles.section_border} />
              {tusIdiomas()}
              <Box sx={styles.section_border} />
              {conocimientos()}
              <Box sx={styles.section_border} />
              {otrosDatos()}
              <Box sx={styles.section_border} />
              {cv()}
              <Box sx={styles.section_border} />
            </Box>
             <ButtonOutlined
              text={t("curriculum.create_document")}
              size={!matchesMobile ? "251px" : "100%"}
              disabled={
                !datosPersonales ||
                !experience ||
                !formaciones ||
                !idiomas ||
                !candidateKnowledges ||
                !allKnowledges ||
                !otherData
              }
              onClick={() =>
                  navigate(
                    Routes.GENERADOR_CV,
                    {
                       state: { 
                        datosPersonales,
                        experience,
                        formaciones,
                        idiomas,
                        candidateKnowledges,
                        allKnowledges,
                        otherData }
                    }
                  )}
              startIcon={
                <Download title={t(`icons_tooltips.create_document`)} />
              }
            />
            <Box component="a"
              sx={styles.delete_profile_linkbutton}
              onClick={() => {
                setTypeDialog("deleteProfile");
                setIsDialogOpen(true);
              }}
            >
              {t("curriculum.delete_profile")}
            </Box>
          </Box>
        </Box>
        <Box sx={styles.hidden_input}>
        <input
          type="file"
          ref={inputRef}
          accept=".doc, .docx, .pdf"
          onChange={handleOnChange}
          onClick={(event:any) => {
            event.target.value = null;
          }}
        />
        </Box>

        <AlertDialog
          title={
            typeDialog === "deleteDoc"
              ? t("curriculum.delete_confirm_message")
              : t("curriculum.delete_profile_tittle_alert")
          }
          description={getContentAlert()}
          open={isDialogOpen}
          handleClose={() => {
            setIsDialogOpen(false);
          }}
          handleAccept={() => {
            typeDialog === "deleteDoc"
              ? handleDeleteDocument(cvFile)
              : handleDeleteProfile();
            setIsDialogOpen(false);
          }}
        />
    </>
  );
}

export default Curriculum;
