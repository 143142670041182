import { Box } from "@mui/material";
import React from "react";
import styles from "../styles";
import Text from "./Text";
  
  function Section({ header, body, matchesMobile }: any) {
  
      return (
        <Box sx={styles.section_separator}>
            <Text
            fontSize={matchesMobile ? 18 : 22}
            lineHeight={matchesMobile ? "28px" : "30px"}
            fontWeight={900}
            text={header}
            styles={styles}
            className={styles.section_title_separator}
            />
            <Text fontSize={14} styles={styles} lineHeight={"20px"} text={body} />
      </Box>
      );
  }
  
  export default Section;