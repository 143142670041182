import { useCallback, useContext, useState } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { SnackContext, SnackSeverity } from "../contexts/SnackHandler";
import { SpinnerContext } from "../contexts/SpinnerHandler";
import { Routes } from "../enums/enums";
import { AuthService } from "../PortalCandidato/auth/AuthService";
import { getPrograma } from "../utils/functions";

const auth = new AuthService();

type ApiParams = {
    url: string,
    method: ApiMethod,
    body: object,
    candidatos?: boolean,
    maestros?: boolean,
    ignoreSnackbar?: boolean,
    noSpinner?: boolean,
    noAuth?: boolean,
};

type ApiError = {
    message: string,
    status: number
} | null;

type ApiResponse = any;

/**
 * Hook para la gestión centralizada de peticiones a una API.
 * Expone unicamente el metodo "callApi", que recibe una url, un tipo de llamada (POST, GET, PUT...) y un body en caso de necesitarlo para
 * realizar todas las operaciones relativas a la llamada de forma automática y reducir la replicación de código inutil.
 * Es importante incluir algo similar en los proyectos para evitar el control de error en cada una de las llamadas y la logica que pueda
 * estar derivada de ciertos tipos de respuestas, que pueden ser costosos de modificar una vez la aplicación ha crecido.
 * 
 * Ejemplos de uso: ver componente Login,
 */
export const useApi = () => {
    const showSnack = useContext(SnackContext);
    const {increaseLoader, decreaseLoader} = useContext(SpinnerContext);
    const navigate = useNavigate();
    const {pathname} = useLocation();
    
    const [renewedToken, setRenewedToken] = useState<boolean>(false);

    /**
     * Funcion que se encarga de gestionar las llamadas erroneas. Por defecto, muestra un snack con un error genérico.
     * @param message: mensaje de error a mostrar
     * @param status: código de estado http de la respuesta. 
     * En caso de recibir un mensaje ya definido (por ejemplo, del back) en el parametro message, lo muestra.
     * Para algunos status codes, se predefinen mensajes y/o comportamientos, como redirigir al login en caso
     * de carecer de autorización.
     */
    const errorHandler = useCallback(function handleErrors(message: string, status: number): ApiError {
        switch(status){
            case 401:
                if(matchPath(pathname, '/login')){
                    showSnack('Las credenciales no son correctas.', SnackSeverity.ERROR);
                } else {
                      auth.login();
                }
                break;
            case 403:
                navigate(-1);
                break;
            case 404:
                showSnack('No se ha podido encontrar el recurso que estas buscando :(', SnackSeverity.ERROR);
                break;
            case 409:
                showSnack(message || 'Ha ocurrido un error al hacer la solicitud.', SnackSeverity.ERROR);
                break;
            default:
                if (!message)
                    message = 'Ha ocurrido un error al hacer la solicitud.';
                showSnack(message, SnackSeverity.ERROR);
        }
        return {message, status};
    }, [showSnack, navigate, pathname]);

    /**
     * Función que lleva toda el flujo de la llamada a la API
     * @param url: url a la que debe llamar
     * @param method: metodo de la llamada. Se usa ApiMethod.
     * @param body: objeto que se envia como cuerpo de la llamada en caso de POST o PUT
     */

    const callApi = useCallback(async function callApi({url, method, body, candidatos, maestros, ignoreSnackbar = false, noSpinner = false, noAuth = false}: ApiParams) {
        let call = null;
        const programa = getPrograma(pathname);
        (!noSpinner) && increaseLoader();
        const urlMaestros = maestros ?  window._env_.REACT_APP_API_URL_MAESTROS : window._env_.REACT_APP_API_URL;
        const apiUrl = `${candidatos ? window._env_.REACT_APP_API_URL_CANDIDATOS : urlMaestros}${url}`;
        switch(method){
            case ApiMethod.POST:
                call = post(apiUrl, programa, noAuth, body);
                break;
            case ApiMethod.GET:
                call = get(apiUrl, programa, noAuth);
                break;
            case ApiMethod.DELETE:
                call = remove(apiUrl, programa, noAuth);
                break;
            case ApiMethod.PUT:
                call = put(apiUrl, programa, noAuth, body);
                break;
            default:
                return;
        }

        let data: ApiResponse = null;
        let resultError: ApiError = null;

        /**
         * Gestion de la petición en si misma. Se utiliza .then y await al mismo tiempo para poder
         * controlar con comodidad el flujo en caso de error.
         */
        data = await call.then(async (response) => {
            
            if(response.status === 500) {
                return Promise.reject({message:'Error en la petición.', status: response.status});
            }

            if(response.status === 401) {
                if (await getToken() && !renewedToken) {
                    auth.renewToken().then(() => {
                        setRenewedToken(true);
                        callApi({url, method, body, candidatos, ignoreSnackbar});
                    });
                } else {
                     increaseLoader();
                     sessionStorage.setItem("origin:login", (location.pathname === Routes.HOME || location.pathname === Routes.RETORNA ) 
                                            ? Routes.DASHBOARD : location.pathname );
                     auth.removeUser();
                     auth.login();
                }
            }

            if(response.status !== 200) {
                const error = await response.json();
                return Promise.reject({message: error?.errorMessage ? error?.errorMessage : data, status:response.status});
            }

            if(response.headers.get('Content-Type')?.includes('application/json')){
                return await response.json();
            }

            setRenewedToken(false);
        }).catch(error => {
            resultError = !ignoreSnackbar ? errorHandler(error.message, error.status) : {...error} as ApiError;
        }).finally(() => {
            (!noSpinner) &&  decreaseLoader();
        });

        /**
         * Es importante controlar si ha ocurrido un error y devolver un throw o un Promise.reject de forma que cuando
         * se invoque la función callApi y se haga un .then solo se ejecute si la petición ha tenido exito. De esta forma,
         * no hay que controlar los errores cada vez que se utiliza callApi y se pueden recibir en el .catch del .then.
         */
        if(resultError){
            return Promise.reject(resultError);
        }
        return data;
    }, [increaseLoader, decreaseLoader, errorHandler]);

    

    return callApi;
};

export enum ApiMethod {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

//#region Funciones base para las peticiones a la api

export async function getToken() {
    const user = await auth.getUser() as any;
    return await user?.access_token;
}

async function get(url: string, programa: any, noAuth: boolean) {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': noAuth ? `` : `Bearer ${await getToken()}`,
            'Programa': programa,
        }
    } as object);
    return response;
}

async function post(url: string, programa: any, noAuth: boolean, body: object = {}) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': noAuth ? `` : `Bearer ${await getToken()}`,
            'Programa': programa,
        },
        body: JSON.stringify(body)
    } as object);
    return response;
}

async function put(url: string, programa: any, noAuth: boolean, body: object = {}) {
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': noAuth ? `` : `Bearer ${await getToken()}`,
            'Programa': programa,
        },
        body: JSON.stringify(body)
    } as object);
    return response;
}

async function remove(url: string, programa: any, noAuth: boolean) {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': noAuth ? `` : `Bearer ${await getToken()}`,
            'Programa': programa,
        },
    } as object);
    return response;
}

//#endregion