import React, { useCallback, useState } from "react";
import { TextField } from "@mui/material";
import InputWrapper from "../InputWrapper/InputWrapper";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: "100%",
    "& .MuiInputBase-input": {
      padding: "8px 12px",
      "-moz-appearance": "textfield",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: "13px",
      },
      fontSize: "14px",
      lineHeight: "16px",
      color: props.whiteLabel ? "white" : "black",
      transform: "translate(4px, -20px) scale(1)",
      marginBottom: "4px",
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      transform: "translate(10px, 10px) scale(1)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "90%",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#c4c4c4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: "1px",
        top: 0,
      },
      "& legend": {
        display: "none",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        top: 0,
      },
      "&.Mui-focused legend": {
        display: "none",
      },
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: "0px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: "0px",
    },
  }),
  section_with_title: {
    fontSize: "14px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  section_with_title_title: {
    marginBottom: "5px",
    fontSize: "14px",
  },
  inputRoot_textField: (props) => ({
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "19px",
    },
    width:'100%',
    fontSize: "12px",
    lineHeight: "16px",
    height: "100%",
    minHeight: "32px",
    "-webkit-appearance": "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
    padding: props.moveLabel
      ? getPadding(props.multiline)
      : "0px !important",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  }),
}));

function getPadding(multiLine) {
  return multiLine
  ? "6px 0px 6px 6px !important"
  : "2px 6px !important";
}

function InputWithLabel(props) {
  const {
    placeholder = "",
    value = "",
    setter = () => {
      return true;
    },
    onBlur = null,
    label = "",
    type = "text",
    wrong = false,
    disabled = false,
    whiteLabel = false,
    multiline = false,
    required = false,
    InputProps,
    autoComplete = "off",
    variant = "outlined",
    fullWidth,
    customClass,
    rows,
    moveLabel = false,
    border,
    customClassWrapper,
    customClassWrapperSx,
    maxLength = false,
    onKeyDown = false,
    isFromHomePage = false
  } = props;
  const numericPattern = new RegExp("^[0-9]+$");
  const [lastKeyCode, setLastKeyCode] = useState(null);

  function handleSet(event) {
    let isInputValid = true;

    if (maxLength != !isInputValid && event.target.value.length > maxLength) {
      isInputValid = false;
    }
    if (type === "tel" || type === "number") {
      if (
        event.target.value != "" &&
        !numericPattern.test(event.target.value)
      ) {
        event.target.value = value;
        isInputValid = false;
      } else if (
        lastKeyCode == 229 &&
        (event.target.value?.length < value?.length - 1 || !value)
      ) {
        event.target.value = value;
      }
    }

    if (isInputValid) {
      setter(event);
    }
  }

  function checkKeyInvalid(evt) {
    const isInvalid =
      evt.keyCode != 8 &&
      evt.keyCode != 9 &&
      evt.keyCode != 229 &&
      evt.keyCode != 37 &&
      evt.keyCode != 39 &&
      evt.keyCode != 46 &&
      evt.keyCode != 86 &&
      (!numericPattern.test(evt.key) ||
        evt.keyCode === 38 ||
        evt.keyCode === 40);
    setLastKeyCode(evt.keyCode);
    return isInvalid;
  }

  const classes = useStyles({
    moveLabel,
    multiline,
    whiteLabel,
  });

  const handleSetter = useCallback((value) => {
    handleSet(value);
}, []);

function isTypeTelOrNum(evt){
  return(
    type === "tel" || type === "number"
    ? checkKeyInvalid(evt) && evt.preventDefault()
    : null
  );
}


const handleOnKeyDown = useCallback((evt) => {
  onKeyDown
    ? onKeyDown(evt)
    : isTypeTelOrNum(evt);
}, []);


  function renderInput() {
    return (
      <TextField
        whiteLabel
        size="small"
        classes={{ root: `${classes.root} ${customClass}` }}
        disabled={disabled}
        value={value ?? ""}
        type={type}
        onBlur={onBlur}
        onInput={handleSetter}
        placeholder={placeholder}
        required={required}
        InputProps={
          InputProps
            ? InputProps
            : {
                classes: {
                  root: classes.inputRoot_textField,
                  input: classes.inputRoot_textField,
                },
               
              }
        }
        multiline={multiline}
        autoComplete={autoComplete}
        variant={variant}
        fullWidth={fullWidth}
        error={wrong}
        label={moveLabel ? label : null}
        rows={rows}
        onKeyDown={handleOnKeyDown}
      />
    );
  }
  return (
    <InputWrapper
      label={moveLabel ? null : label}
      wrong={wrong}
      customClass={customClassWrapper}
      customClassSx={customClassWrapperSx}
      disabled={disabled}
      required={required}
      border={border}
      moveLabel={moveLabel}
      multiline={multiline}
      rows={rows}
      hasValue={value && value !== ""}
      isFromHomePage={isFromHomePage}
    >
      {renderInput()}
    </InputWrapper>
  );
}

export default React.memo(InputWithLabel, (prevProps, nextProps) => {
  const equals = JSON.stringify(prevProps) === JSON.stringify(nextProps);
  return equals;
});