import { Box, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageContext from '../../contexts/LanguageContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    image: { width: "15px", height: "15px", marginRight: "5px" },
    text: {
        '&:hover': {
            color: 'rgb(0, 123, 196)',
        },
        cursor: "pointer",
        fontWeight: '600',
    }

}));

export const Languages = (props: any) => {
    const { changeLanguage } = useContext(LanguageContext);
    const { t } = useTranslation();
    const styles = useStyles();
    const lngs = [
        { code: 'gl', img: "/images/gallego.png", nativeName: 'Galego' },
        { code: 'es', img: "/images/espanol.png", nativeName: 'Castellano' },
    ] as any;

    return (
        <Stack
            direction="column"
            spacing={0}>
            {lngs.map((lng: any) => (
                <Box key={lng.code} sx={{ display: "flex",alignItems:{xs:"center",md:"center"}, marginLeft: props.matchesMobile ? "8px !important" : "unset"}} >
                    <Box key={lng.code} component="img" src={lng.img} className={styles.image}></Box>
                    <Box
                        title={t("icons_tooltips.changeLanguage")}
                        component="a"
                        key={lng.code}
                        className={styles.text}
                        style={{ color: "#86b5e2" }}
                        onClick={() => changeLanguage(lng.code)
                        }>{lng.nativeName}</Box>
                    <Box component="img"></Box>
                </Box>
            ))}
        </Stack>
    );
};