const styles = {
    buttonContainer: {
        display: "flex",
        gap: "8px",
        flexDirection:{xs:"column",sm:"row"},
        width: {xs:"100%",sm:"unset"} 
    },
    buttons: {
        textTransform: "none",
        fontWeight: 800,
        fontSize: "16px",
        alignItems: "center",
    },
    politicasContainer:{
        display: "flex",
         gap: "8px", 
         cursor: "pointer",
         justifyContent: 'center',
         marginTop:"10px",
        flexDirection:{xs:"column",sm:"row"}

    },
    politicas:{
        color: 'rgb(49, 115, 177)',
    },
};
export default styles;