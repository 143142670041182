import React from 'react';
import { Box, Typography, useMediaQuery, ListItem, ListItemText } from "@mui/material";
import styles from "./styles";
import { useTranslation } from 'react-i18next';
import theme from '../../../../theme';
import { introductionDescription, introductionList, sections } from './diccionarySections';


function CondicionesUso() {
    const { t } = useTranslation();
    const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));

    function returnList(list: any) {
        return (list?.map((l: any) => (
            l?.name &&
            <ListItem key={l.id}>
                <ListItemText sx={{ display: 'list-item' }} primary={t(l.name)} />
            </ListItem>
        )));
    }

    function getDescriptions(section: any) {
        return (section?.map((s: any) => (
            s.name &&
            <Box style={{ paddingBottom: "10px" }} key={s.id}>{t(s.name)}</Box>
        )));
    }

    return (
        <Box>
            <Box sx={styles.main}>
                <Box sx={styles.mainSection}>
                    <Box sx={{ ...styles.policityBackground, borderRadius: !matchesMobile ? '10px' : "unset" }}>
                        <Typography sx={styles.title} variant="body1">{t("legalRequirements.title3")}</Typography>
                        <Typography sx={styles.header_description}>{t("legalRequirements.content13")}</Typography>
                    </Box>
                </Box>
                {!matchesMobile &&
                    <Box sx={{ ...styles.mainSection, justifyContent: "flex-start" }}>
                        <Box component="img" src="/images/Politicasprivacidad.png" alt="Cabecera legal" />
                    </Box>
                }
            </Box>
            <Box sx={styles.section}>
                <Box sx={styles.sectionDescription}>
                    <Typography >{t("legalRequirements.content92")}</Typography>
                    <Typography style={{marginTop:"10px"}} >{t("legalRequirements.content93")}</Typography>
                </Box>
                <Box sx={{ ...styles.sub_section, maxWidth: {xs: "unset", md: "800px"}, alignItems: "flex-start" }}>
                    {returnList(introductionList)}
                    {getDescriptions(introductionDescription)}
                </Box>
            </Box>
            {sections.map((s) => (
                <Box sx={styles.section} key={s.id}>
                    <Typography sx={styles.sectionTitle}>{t(s.title)}</Typography>
                    <Box sx={{ ...styles.sub_section, maxWidth: {xs: "unset", md: "800px"}, alignItems: "flex-start" }}>
                        {getDescriptions(s.elements)}
                    </Box>
                </Box>
            ))}
        </Box>
    );
}
export default CondicionesUso;