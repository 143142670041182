const styles = {
    principal_container: {
      gridArea: "body_container",
      display: "grid",
      gridTemplateColumns:{xs:"auto",md: "30% 65%"},
      columnGap: "20px",
    },
    col2: {
      marginTop:{xs:"0px",md:"37px"},
      padding:{xs:"36px",md:"90px"},
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "60px",

    },
    data_sections_container: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "40px",
    },
    data_section_icon_and_title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      marginBottom: "20px",
      marginTop: {xs:'30px',lg:"unset"}
    },
    data_section_title_principal: {
      fontSize:{xs:"18px",md:"22px"} ,
      fontWeight: 900,
      marginLeft: "10px",
    },
    render_basic_data_style: {
      display: "flex",
      flexDirection: "column",
    },
    basic_data_grid: {
      display: "grid",
      gridTemplateColumns: {xs:"1fr",md:".5fr .5fr"},
      columnGap: "26px",
      rowGap:{xs:"20px",md:"25px"},
      alignItems: "end",
    },
    date_start: {
      gridArea: {xs:"unset",md:"date_start"},
    },
    date_end: {
      gridArea:{xs:"unset",md:"date_end"} ,
    },
    dates_studies_grid: {
      display: "grid",
      gridTemplateColumns:{xs:"1fr",md:".5fr .5fr"},
      columnGap: "26px",
      rowGap: "20px",
      gridTemplateAreas:{xs:"unset",
      md: `
      'is_currently .'
      'date_start date_end'`},
    },
    text_switch: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    delete_row: {
      display:{xs:"flex",md:"grid"} ,
      gridTemplateColumns: ".5fr .5fr",
      columnGap: "25px",
      gridTemplateAreas: `'. delete'`,
      flexDirection:{xs: "column"},
      alignItems: {xs:"center"},
    },
    delete_link: {
      justifySelf: "center",
      fontSize: 14,
      color: "#DA291C",
      gridArea: "delete",
      cursor: "pointer",
      textDecoration: "underline",
      "&:hover": {
        opacity: "0.8",
      },
    },
  };

  export default styles;