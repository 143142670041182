import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Icon, Typography } from "@mui/material";
import styles from "./styles";
import Folder from '../../../../assets/icons/folder';
import { useTranslation } from 'react-i18next';
import { getCandidateDocuments } from '../../../hooks/userApi';
import { languages, returnLangId } from '../../auth/AuthWrapper';
import i18next from "../../../../i18n.js";
import { useApi } from '../../../hooks/useApi';
import { SnackContext, SnackSeverity } from '../../../contexts/SnackHandler';
import DocumentoListItem from './DocumentItem/DocumentoListItem';
import MoveUpGrey from '../../../../assets/icons/moveUpGrey';
import UploadDocuments from '../../../components/UploadDocuments/UploadDocuments';
import { IDocument, IDocumentType } from '../../../interfaces/DocumentsInterfaces';
import { getDocTypes } from '../../../utils/functions';

function Documentos() {
    const { t } = useTranslation();
    const callApi = useApi();
    const showSnack = useContext(SnackContext);
    
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const langId = returnLangId(i18next.languages[0] as keyof typeof languages);
    const documentTypes = getDocTypes(langId) as IDocumentType[];

    useEffect(() => {
        handleGetDocuments();
    }, []);

    function buildMultiDocuments(data: IDocument[]) {
        let carnetAñadido = false;
        let identificacionAñadida = false;
        const modData = data.map((item: IDocument) => {
            if ((item.idTipoDocumento === 20 && !carnetAñadido) || (item.idTipoDocumento === 12 && !identificacionAñadida)) {
                item.nombre2 = data.find((doc: IDocument) => doc.idTipoDocumento === item.idTipoDocumento && doc.idDocumento !== item.idDocumento)?.nombre;
                item.idDocumento2 = data.find((doc: IDocument) => doc.idTipoDocumento === item.idTipoDocumento && doc.idDocumento !== item.idDocumento)?.idDocumento;
                if (item.idTipoDocumento === 20 && !carnetAñadido) {
                    carnetAñadido = true;
                } else if (item.idTipoDocumento === 12 && !identificacionAñadida) {
                    identificacionAñadida = false;
                    identificacionAñadida = true;
                }
            }
            return item;
        });
        
        return modData.filter((item: IDocument) => !((item.idTipoDocumento === 20 || item.idTipoDocumento === 12) && !item.nombre2));
    }

    const handleGetDocuments = useCallback(() => {
        callApi(getCandidateDocuments(langId, true)).then((data: IDocument[]) => {
            const dataMod = buildMultiDocuments(data);
            setDocuments(dataMod.filter((item)  => item.idTipoDocumento > 11 && documentTypes.find((doc) => doc.id === item.idTipoDocumento)));
        }).catch(() => {
          showSnack(t("documents.get_documents_error"), SnackSeverity.ERROR);
          setDocuments([]);
        });
    }, []);

    return (
        <Box sx={styles.body}>
            <Box sx={styles.leftColumn}>
                <Icon color="primary" sx={styles.icon}>
                    <Folder />
                </Icon>
                <Typography sx={styles.title}>
                    {t("documents.title")}
                </Typography>
                <UploadDocuments documentos={documents} getDocuments={handleGetDocuments}/>
            </Box>
            <Box sx={styles.rightColumn}>
                {documents.length ? <>
                    {documents.map((documento: IDocument) => {
                    return (<DocumentoListItem key={documento.idDocumento} getDocuments={handleGetDocuments} documento={documento} langId={langId}/>);
                })}
                </> : 
                <Box sx={styles.noDocumentsBlock}>
                    <Typography sx={styles.noDocumentsText}>
                        {t("documents.no_documents_text1")}
                    </Typography>
                    <Typography sx={styles.noDocumentsText}>
                        {t("documents.no_documents_text2")}
                    </Typography>
                    <Icon color="primary" sx={styles.icon_gray}>
                        <MoveUpGrey />
                    </Icon>
                </Box>}
            </Box>
        </Box>
    );
}
export default Documentos;