import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import { AuthService } from '../../auth/AuthService';

function Login() {
    const auth = new AuthService();

    useEffect(() => sendToLogin(), []);

    function sendToLogin() {
        auth.login();
    }

    return (<Box></Box>);
}

export default Login;