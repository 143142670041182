
const styles = {
  main: {
    width: "100%",
    height: {xs: "unset", md:"700px"},
    margin: "0",
    padding: "0",
    WebkitBoxSizing: "border-box",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: {xs: "bottom", xl: "right center"},
    backgroundAttachment: "scroll",
    display: "flex",
    justifyContent: "center",
    flexDirection: {xs: "column", md: "row"},
    alignItems: {xs:'center',md:"baseline"},
    paddingTop:{xs:"unset",md:'60px'}
  },
  header_description: {
    color:{xs:"white",md:"#3173B1"},
    fontSize: {xs:"22px",md:"18px"},
    fontWeight: 500,
    width:{xs:"100%",md:"70%"}
  },
  mainSection: {
    padding: {xs: "unset", md: "20px"},
    width: { md: "50%"},
    display: "flex",
    justifyContent: "flex-end",
  },
  blurBackground: {
    backgroundColor: {xs: "#007BC4", md: "unset"},
    display: "flex",
    padding: {xs: "40px 30px 20px 30px", md: "unset"},
    flexDirection: "column",
    justifyContent: {xs: "center", md: "flex-end"},
    maxWidth:{md:"634px"},
    textAlign:{md:"left"},
  },
  buttonSendForm: {
      marginBottom: "4px",
      backgroundColor: "#3173B1",
      textTransform: "none",
      borderRadius: "3px",
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
      width:"164px",
      '&:hover': {
        backgroundColor: 'transparent',
        border:{xs:"1px solid #3173B1",md:"1px solid white"},
        color:{xs:"#3173B1",md:"white"},
        boxShadow: "none"
    },
  },
  title: {
    color:{xs:"white",md:"#3173B1"},
    fontSize: {xs:"35px",md:"32px"},
    fontWeight: "800",
    marginBottom: "10px",
    width: {xs:"100%",sm:"90%",xl:'76%'},

  },
  nortempoLogo: {
    position: "absolute",
    top: "110px",
    left: "6px",
    display: {xs: "none", md: "unset"},
    cursor:"pointer"
  },
  description: {
    color: "white",
    fontSize: "16px",
    marginBottom: "10px"
  },
  section: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: '7% 0',
    textAlign: 'center'
  },
  boxPoliticasPrivacidad:{
    display:"flex", 
    marginTop: '14px',
    justifyContent: 'space-between'
  },
  sub_section: {
    width: {xs:"unset",md:"100%"},
    display: "flex",
    flexDirection: {xs: "column", md: "row"},
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px"
  },
  blockHeader: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0"
  },
  numbers: {
    fontWeight: "700",
    fontSize: "12px",
    marginRight: "2px"
  },
  footer: {
    backgroundColor: "#0177BE",
    display: "flex",
    flexDirection: {xs: "column", md: "row"},
    justifyContent: "center",
    alignItems: "center", 
    padding: "5px 0 15px 0",
    margin: "40px 0"
  },
  option: {
    fontSize: "12px"
  },
  sectionTitle: {
    color: "#007BC3",
    fontSize: "35px",
    fontWeight: "800",
    marginBottom: "10px",
    textAlign: {xs: "center", md: "unset"}
  },
  sectionDescription: {
    maxWidth: "800px",
    padding: {xs: "0 20px", md: "unset"},
  },
  block: {
    width: {xs: "100%", md: "33.33%"},
    padding: {xs: "0 20px", md: "0 5px"},
    marginBottom: {xs: "20px", md: "unset"}
  },
  blockText: {
    alignItems: "flex-start",
    fontSize: "12px",
    display: "flex"
  },
  itemList: {
    display: "flex",
    fontSize: "14px",
    color: "white",
  },
  check: {
    width: "18px",
    marginRight: "4px"
  },
  icon_svg: {
    width: "40px",
    fill: "#007DC9",
    marginRight: "10px"
  },
  form: {
    width: {xs: "100%", md: "533px"},
    height: {xs: "100%", md: "90%"},
    justifyContent: {xs: "center", md: "flex-start"},
    backgroundColor: {xs:"white",md:"unset"},
    borderRadius: {xs:"8px",md:"unset"},
    padding: {xs:'10px'},
    display: "flex",
    flexDirection: "column",
    maxWidth: "570px",
    maxHeight: "675px",
    overflow: "auto",
  },
  dataProtectionTitle: {
    fontSize: "14px",
    paddingLeft: "10px",
    marginTop: "10px",
    marginBottom: "4px",
  },
  contactData: {
    paddingLeft: "10px",
    fontSize: {xs:"14px",md:"17px"},
    marginTop: "10px",
    fontWeight: "600",
    color:{md:"#3173B1"}
  },
  dataProtection: {
    padding: {xs:"10px",md:"15px",lg:"25px"},
    justifySelf: "center",
    alignSelf: "center",
    fontSize: {xs:"14px",md:"12px"},
    border: "1px solid #DDDDDD",
    borderRadius: "5px",
    width: "96%",
    height: "271px",
    color:"#888888",
    overflow: {xs:'auto',sm:"unset"}
  },
  auto: {
    marginTop: "auto",
  },
  autocomplete_input_container_full: {
    width: "100%",
  },
  inputsRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "10px"
  },
  input: {
    width: "50%",
    padding: "0 10px"
  },
  input_two_rows:{
    width: "100%",
    padding: "0 10px"
  },
  checkbox_row: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    color:"#434343",
  },
  numberFormatTex:{
    color: {sm:"white" ,md:"#D50000"}, 
    fontSize: "28px", 
    fontWeight: "900" 
  },
  numberFormatButton: {
    cursor: "pointer",
    backgroundColor: "white",
    marginRight: "20px",
    width: "170px",
    textTransform: "none",
    borderRadius: "4px",
    color: "#000000",
    fontSize: "14px",
    fontWeight: "600",
  },
  button_row: {
    display: "flex",
    justifyContent: "center"
  },
  left_container: {
    width: {xs: "100%", md: "50%"},
    padding: {xs: "0 20px", md: "unset"},
    display: "flex",
    maxWidth: {xs: "800px", md: "400px"},
    justifyContent: "flex-end"
  },
  photo_company:{
    width: {md:"84%",xl:'538px'},
    height: {md:"100%",xl:'550px'},
    position: {xs:"unset",xl:'absolute'},
    top: {xs:"unset",md:'330px'},
  },
  video_text: {
    fontSize: "14px",
    textAlign:"justify"
  },
  video_text_bold: {
    fontSize: "13px",
    fontWeight: "800"
  },
  right_container: {
    width: {xs: "100%", md: "50%"},
    maxWidth: "400px",
    display: "flex",
    justifyContent: {xs: "center", md: "flex-start"},
    marginLeft: {xs: "0", md: "20px"},
    marginTop: {xs: "20px", md: "unset"},
  },
  footerTitle: {
    color: "white",
    fontSize: "30px",
    fontWeight: "800"
  },
  footerText: {
    color: "white",
    fontSize: "12px"
  },
  footer_section: {
    maxWidth: "620px", 
    paddingLeft: {xs: "10px", md: "40px"},
    paddingRight: {xs: "10px", md: "0px"},
  },
  footer_box: {
    padding: "8px 16px",
    backgroundColor: "#3395D0"
  },
  icon_text: {
    fontSize: "14px",
    fontWeight: "700"
  },
  footer_logo: {
    width:'160px',
    marginLeft: {xs: "0px", md: "40px"},
    paddingTop: {xs: "15px", md: "unset"},
  }
};

export default styles;
