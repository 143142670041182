import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  mask_path: {
    fill: "none",
    stroke: "white",
    strokeWidth: 50,
    strokeDasharray: "280px",
    strokeDashoffset: "var(--offset)",
  },
}));

function ProfileProgressPath(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.progress_path"), percentage = 0 } = props;
  const classes = useStyles();

  function generateMask() {
    const numPoints = 100;

    const mypath = document.querySelector("#Path-for-fill");
    const mypolygon = document.querySelector("#poly");
    const pathLength = mypath.getTotalLength();
    const polygonPoints = [];

    for (let i = 0; i < numPoints; i++) {
      const p = mypath.getPointAtLength((i * pathLength) / numPoints);
      polygonPoints.push(p.x);
      polygonPoints.push(p.y);
    }

    mypolygon.setAttribute("points", polygonPoints.slice(0, 120).join(","));
  }

  useEffect(() => {
    generateMask();
  }, []);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="101" height="95">
      <title>{title}</title>
      <defs>
        <mask id="mask">
          <polyline
            id="poly"
            style={{ "--offset": 280 - 280 * percentage }}
            className={classes.mask_path}
            points=""
          />
        </mask>
      </defs>
      <path
        id="Path-for-fill"
        d="M100.94.056C89.263-.19 74.049.263 62.578 3.19 21.36 13.719-4.956 43.769.784 67.959c5.734 24.195 42.989 33.958 83.196 21.828 6.394-1.936 11.335-3.733 17.02-6.447 0 0-7.3-18.66-7.446-18.805-3.037 1.214-5.153 1.929-8.396 2.9-31.807 9.618-60.855 3.678-64.87-13.26-4.014-16.92 14.062-38.749 46.707-47.81 8.04-2.225 21.177-4.97 33.587-4.99.065.015.332-1.319.358-1.319z"
        fill="#DDDDDD"
      />
      <path
        d="M100.94.056C89.263-.19 74.049.263 62.578 3.19 21.36 13.719-4.956 43.769.784 67.959c5.734 24.195 42.989 33.958 83.196 21.828 6.394-1.936 11.335-3.733 17.02-6.447 0 0-7.3-18.66-7.446-18.805-3.037 1.214-5.153 1.929-8.396 2.9-31.807 9.618-60.855 3.678-64.87-13.26-4.014-16.92 14.062-38.749 46.707-47.81 8.04-2.225 21.177-4.97 33.587-4.99.065.015.332-1.319.358-1.319z"
        fill="#DA291C"
        mask="url(#mask)"
      />
    </svg>
  );
}

export default ProfileProgressPath;
