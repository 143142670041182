import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Button from "@mui/material/Button";
import styles from './styles';

interface ICookiesButtonsDialogProps {
    closeDialog: Function,
    setConfigurateCookies: Dispatch<SetStateAction<boolean>>
    isConfigurationCookies: boolean,
    cookieConfiguration: any,
    setCookieConfiguration: Dispatch<SetStateAction<any>>
}

const CookiesButtonsDialog = (props: ICookiesButtonsDialogProps) => {
    const { closeDialog,
        setConfigurateCookies,
        isConfigurationCookies,
        cookieConfiguration,
        setCookieConfiguration
    } = props;
    const { t } = useTranslation();

    const handleClose = (cookie: any) => {
        localStorage.setItem("cookie", JSON.stringify(cookie));
        closeDialog();
    };

    const handleCookieConfiguration = () => {
        setConfigurateCookies(true);
        closeDialog();
    };

    const printButtons = [
        { id: 0, name: t("cookies.accept"), function: () => handleClose(true), variant: "contained", color: "info" },
        { id: 1, name: t("cookies.reject"), function: () => handleClose(false), variant: "outlined", color: "info" },
        { id: 2, name: t("cookies.cookie_config"), function: () => handleCookieConfiguration(), variant: "outlined", color: "info" },

    ];

    const printPoliticas = [
        { id: 0, name: t("cookies.cookie_policy"), web: "https://nortempo.com/uso-de-cookies/" },
        { id: 1, name: t("cookies.privacy_policy"), web: "https://iberempleo.net/politica-de-privacidad" },
    ];

    const printButtonsConfiguration = [
        { id: 0, name: t("cookies.save_configuration"), function: () => handleClose(cookieConfiguration), variant: "outlined", color: "info" },
        { id: 1, name: t("cookies.accept_all"), function: () => handleSaveAll(), variant: "contained", color: "info" },
    ];

    const handleSaveAll = () => {
        setCookieConfiguration((oldValue: any) => {
            const newValue = { ...oldValue };
            Object.keys(newValue).forEach((item) => { newValue[item] = true; });
            localStorage.setItem("cookie", JSON.stringify(newValue));
            return { ...newValue };
        });
        closeDialog();
    };

    function handleButtonsConfigCookie() {
        return (
            <Box sx={styles.buttonContainer}>
                {printButtonsConfiguration.map((button: any) => {
                    return (
                        <Box key={button.id} sx={styles.buttons}>
                            <Button
                                variant={button.variant}
                                color={button.color}
                                fullWidth
                                onClick={button.function}
                                style={{ textTransform: "none", fontWeight: 800, fontSize: "13px" }}
                            >
                                {button.name}
                            </Button>
                        </Box>
                    );
                })
                }
            </Box>
        );
    }

    function handleButtonsCookie() {
        return (
            <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box sx={styles.buttonContainer}>
                    {printButtons.map((button: any) => {
                        return (
                            <Box key={button.id}>
                                <Button
                                    variant={button.variant}
                                    color={button.color}
                                    fullWidth
                                    onClick={button.function}
                                    style={{ textTransform: "none", fontWeight: 800, fontSize: "14px" }}
                                >
                                    {button.name}
                                </Button>
                            </Box>
                        );
                    })
                    }
                </Box>
                {handlePoliticas()}
            </Box>
        );
    }

    function handlePoliticas() {
        return <Box sx={styles.politicasContainer}>
            {printPoliticas.map((policy: any) => {
                return (
                    <Box
                        sx={styles.politicas}
                        key={policy.id}
                        component="a"
                        href={policy.web}
                    >
                        {policy.name}
                    </Box>
                );
            })}
        </Box>;
    }

    return (
        <>
            {!isConfigurationCookies ? handleButtonsCookie() : handleButtonsConfigCookie()}
        </>
    );
};
export default CookiesButtonsDialog;