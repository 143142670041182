const styles = {
    profile_photo_container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height:{xs:128,md:156},
      width: {xs:128,md:156},
      borderRadius: "6px",
      background: "linear-gradient(180deg, #EEEEEE 0%, #D8D8D8 100%)",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    },
    editable_container: {
      cursor: "pointer",
      "&:hover": {
        opacity: "0.8",
      },
    },
    photo_container: {
      position: "relative",
      height: "100%",
      width: "100%",
    },
    photo: {
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "6px",
      objectFit: "cover",
    },
    hidden_input: {
      display: "none",
    },
  };

  export default styles;