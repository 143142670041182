import { Box, Button, Checkbox, Chip, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { getMinimumExperience, getNivelesEstudio, sendGAEvent } from "../../../../shared/utils/functions";
import i18next from "i18next";
import Enroll from "./Subcomponents/Enroll";
import Text from "./Subcomponents/Text";
import Section from "./Subcomponents/Section";
import Subtitle from "./Subcomponents/Subtitle";
import BasicData from "./Subcomponents/BasicData";
import { getToken, useApi } from "../../../../shared/hooks/useApi";
import { useLocation, useSearchParams } from "react-router-dom";
import { enrollToOffer, getKQ, getOffer, isCandidateEnrollInOffer } from "../../../../shared/hooks/userApi";
import { AuthService } from "../../auth/AuthService";
import ButtonOutlined from "../Dashboard/buttonOutlined";
import { useNavigate } from "react-router-dom";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { SnackContext, SnackSeverity } from "../../../contexts/SnackHandler";

const auth = new AuthService();

const MAX_CHARS_QUESTION = 500;

function OfertaRetorna() {
  const theme = useTheme<any>();
  const { t } = useTranslation();
  const callApi = useApi();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const showSnack = useContext(SnackContext);

  const [offer, setOffer] = useState<any>(null);
  const [fromLogin, setFromLogin] = useState<boolean>(false);
  const [killerQuestions, setKillerQuestions] = useState<any>([]);
  const [userEnrolled, setUserEnrolled] = useState<boolean>(true);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(false);
  const [kqQuestionsLoaded, setKqQuestionsLoaded] = useState<boolean>(false);

  const enrollOffer = useCallback(() => handleEnroll(offer.idOferta, {})
    , [offer]);

  function isAnswer(answer: any, question: any) {
    const answers = [
      "respuesta1Texto",
      "respuesta2Texto",
      "respuesta3Texto",
      "respuesta4Texto",
      "respuesta5Texto",
    ];
    return answers.includes(answer) && question[answer];
  }

  function isChecked(key: any, indexQuestion: any, killerQuestions: any) {
    return killerQuestions[indexQuestion].checked === key;
  }

  const handleSetRespuesta = (
    value: any,
    killerQuestions: any,
    indexQuestion: any,
    setKillerQuestions: any
  ) => {
    const tempKQ = [...killerQuestions];
    tempKQ[indexQuestion].respuesta = value;
    setKillerQuestions(tempKQ);
  };

  function handleEnroll(offerId: any, data: any) {
    let kq = [] as any;
    callApi(getKQ(offerId)).then((kqs) => {
        kq = [...kqs];
        if (kq.length) {
          setKillerQuestions(kq ?? []);
          setKqQuestionsLoaded(true);
        } else {
          callApi(enrollToOffer(offerId,
            data)).then(() => {
              setUserEnrolled(true);
            });
        }
    });
    
  }

  const login = useCallback(() => {
    sessionStorage.setItem("fromOferta", `${location.pathname}${location.search}`);
    sessionStorage.setItem("fromLogin", "true");
    sendGAEvent("Acceso candidatos (login o registro)");
    auth.login();
  }, []);

  const Register = useCallback(() => {
    sessionStorage.setItem("fromOferta", `${location.pathname}${location.search}`);
    sessionStorage.setItem("fromRegister", "true");
    sendGAEvent("Click botón Regístrate");
    auth.register();
  }, []);

  useEffect(() => {
    const id = searchParams.get("codigo");
    getUser();
    handleGetOffer(id ?? "");
    if (sessionStorage.getItem("fromLogin")) {
      sessionStorage.removeItem("fromLogin");
      setFromLogin(true);
    }
  }, []);

  useEffect(() => {
    handleGetIsCandidateEnroll();
  }, [offer]);

  const handleGetOffer = useCallback((id: string) => {
    callApi(getOffer(id)).then((data) => {
      setOffer(data);
    });
  }, [callApi]);

  const getUser = useCallback(async () => {
    const data = await getToken();
    setIsUserAuthenticated(!!data);
  }, []);

  function handleGetIsCandidateEnroll() {
    if (isUserAuthenticated && offer && offer.idEstado !== 3) {
      callApi(isCandidateEnrollInOffer(offer?.idOferta)).then((data) => {
        setUserEnrolled(data);
      });
    }
  }

  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const minimumExperience = getMinimumExperience(i18next.languages[0]);
  const minimumStudies = getNivelesEstudio(i18next.languages[0]);

  function getGeneralRequirements() {
    const experienciaMinima = minimumExperience.find(
      (minimumExperiences: any) => Number(minimumExperiences.id) === offer?.experienciaMinima?.id
    )?.name;

    return `${experienciaMinima}`;
  }

  function getStudies() {
    const minStudies = minimumStudies.find(
      (studies:any) => Number(studies.id) === offer.formacion?.id
    )?.name;
    return `${minStudies}`;
  }

  const handleCheckBoxChange = (key: any, indexQuestion: any) => {
    const tempKQ = [...killerQuestions];
    tempKQ[indexQuestion].checked = key;
    setKillerQuestions(tempKQ);
  };

  function handleCancel() {
    setKillerQuestions([]);
    setKqQuestionsLoaded(false);
  }

  function processData(offerId: any) {
    const incompleteData = killerQuestions.find(
      (q: any) => !q.checked && !q.respuesta
    );
    const incompleteTextArea = killerQuestions.find(
      (q: any) => !q.checked && q.respuesta && q.respuesta.trim().length < 10
    );
    if (incompleteData) {
      showSnack(t("offerDetails.incomplete_data"), SnackSeverity.ERROR);
    } else if (incompleteTextArea) {
       showSnack(t("offerDetails.incomplete_textarea"), SnackSeverity.ERROR);
    } else {
      const dataFormatted = [] as any;

      killerQuestions.map((q: any) => {
        const value = q?.checked?.slice(0, -5) + 'Valor';
        const obj = {
          Pregunta: q.textoPregunta || "",
          Orden: q.orden,
          Respuesta: q.idTipoPregunta === 2 ? q[q.checked].toString() || "" : q.respuesta,
          Valor: q.idTipoPregunta === 2 ? q[value] : null,
        };
        dataFormatted.push(obj);
      });
      callApi(enrollToOffer(offerId,
        {RespuestasKQ: dataFormatted})).then(() => {
          setUserEnrolled(true);
          setKillerQuestions([]);
          setKqQuestionsLoaded(false);
      });
    }
  }

  return offer?.idOferta ? (
    <Box sx={{ display: "flex", width: "100vw", mt: 5 }}>
      <Box sx={styles.body_container}>
        <Box sx={styles.body_left_col}>
          {<BasicData offer={offer} />}

          {!matchesMobile ? (
            <Enroll 
              kqQuestionsLoaded={kqQuestionsLoaded} 
              fromLogin={fromLogin} 
              searchParams={searchParams} 
              enrollDisabled={userEnrolled} 
              isUserAuthenticated={isUserAuthenticated} 
              enroll={enrollOffer} 
              handleRegisterClick={Register} 
              handleLoginClick={login} />
          ) : null}
          {!matchesMobile ? (
            <Box sx={styles.footer_padding}></Box>
          ) : null}
        </Box>

        {killerQuestions.length ? (
          <Box sx={styles.body_right_col}>
            <Text
              fontSize={20}
              classes={styles}
              text={t("offerDetails.kq_title")}
              sx={styles.kq_title}
            />
            {killerQuestions.map((q: any, index: any) => {
              return (
                <Box key={q.orden + q.idTipoPregunta + q.textoPregunta}>
                  <div>
                    <Box sx={styles.section_separator_kq}>
                      {
                        <Text
                          fontSize={matchesMobile ? 12 : 16}
                          fontWeight={700}
                          text={q.textoPregunta}
                          classes={styles}
                        />
                      }
                    </Box>
                    {q.idTipoPregunta === 2 ? (
                      Object.keys(q).map(function (keyObj) {
                        if (isAnswer(keyObj, q)) {
                          return (
                            <Box key={keyObj} sx={styles.checkbox_line}>
                              <Checkbox
                                size="small"
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={isChecked(keyObj, index, killerQuestions)}
                                onChange={() => handleCheckBoxChange(keyObj, index)}
                              />
                              {
                                <Box style={{ paddingBottom: 2 }}>
                                  <Text
                                    fontSize={14}
                                    lineHeight={"20px"}
                                    text={q[keyObj]}
                                    classes={styles}
                                  />
                                </Box>
                              }
                            </Box>
                          );
                        }
                      })
                    ) : (
                      <div style={{ marginRight: 10 }}>
                        <textarea
                          placeholder="Introduzca la respuesta"
                          style={styles.question_title_input as object}
                          value={q.respuesta}
                          onChange={(e) =>
                            handleSetRespuesta(
                              e.target.value,
                              killerQuestions,
                              index,
                              setKillerQuestions
                            )
                          }
                          maxLength={MAX_CHARS_QUESTION}
                        ></textarea>
                        <Typography variant="body1" sx={styles.counter_chars}>
                          {`${q.respuesta?.trim().length || 0
                            }/${MAX_CHARS_QUESTION} (mínimo 10 caracteres)`}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Box>
              );
            })}
            <Box sx={styles.buttons_kq}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#000000",
                  color: "white",
                }}
                sx={styles.enroll_button_kq}
                onClick={() => handleCancel()}
              >
                {t("offerDetails.cancel")}
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#5D5A5A",
                  color: "white",
                }}
                sx={styles.enroll_button_kq}
                onClick={() => processData(offer.idOferta)}
              >
                {t("offerDetails.enroll")}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={styles.body_right_col}>
            <Text
              fontSize={14}
              classes={styles}
              lineHeight={"20px"}
              text={offer?.descripcion}
              className={styles.lonely_text}
            />
           <Section
              header={t("offerDetails.requirements")}
              body={offer?.requisitos}
            />
            <Box sx={{ marginTop:"20px" }}>
            {offer?.formacion?.id &&
              <Box sx={{ display: "flex" }}>
                <Subtitle text={`${t("offerDetails.minimumStudies")}`}
                  isBold
                  className={styles.minimum_Experience}
                  sx={ {width:"135px"}}
                />
                <Subtitle text={`${getStudies()}`}/>
              </Box>
            }
            {offer?.experienciaMinima?.id &&
              <Box sx={{ display: "flex" }}>
                <Subtitle text={`${t("offerDetails.minimumExperience")}`}
                  isBold
                  className={styles.minimum_Experience}
                />
                <Subtitle text={`${getGeneralRequirements()}`}
                />
              </Box>
            }
            </Box>
            <Box sx={offer?.conocimientos?.length ? styles.chip : styles.no_Space}>
              {offer?.conocimientos?.length &&
                  <Typography sx={styles.title_knowledge}>
                    {t("offerDetails.necessary_Knowledge")}
                  </Typography>
              }
              {offer?.conocimientos?.map((c: any) => (
                <Chip
                  key={c}
                  label={c}
                  sx={styles.separator}
                />
              ))}
            </Box>
            <Section
              header={t("offerDetails.isOffered")}
              body={offer?.seOfrece}
            />
            {
              !matchesMobile ? (
                <Box sx={styles.footer_padding}></Box>
              ) : null /* Eliminar cuando se meta el footer*/
            }
            {matchesMobile ?
              (
                <Enroll 
                  kqQuestionsLoaded={kqQuestionsLoaded} 
                  fromLogin={fromLogin} 
                  matchesMobile 
                  searchParams={searchParams} 
                  enrollDisabled={userEnrolled} 
                  userEnrolled={userEnrolled} 
                  isUserAuthenticated={isUserAuthenticated} 
                  enroll={enrollOffer} 
                  handleRegisterClick={Register} 
                  handleLoginClick={login} />
              ) : null}
            {!matchesMobile && window.history.length > 1 ? <Box sx={styles.action_buttons_grid}>
              <Box style={{ gridArea: "return", width: "100%", marginBottom: "10px" }}>
                <ButtonOutlined
                  size="100%"
                  text={t("common.return")}
                  color={!isUserAuthenticated ? "#007BC3" : "#DA291C"}
                  onClick={() => navigate(fromLogin ? -5 : -1)}
                />
              </Box>
            </Box> : null}
          </Box>
        )}
      </Box>
    </Box>
  ) : <Box></Box>;
}

export default OfertaRetorna;