import React, { useCallback, useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Camera from "../../../assets/icons/camera";
import { validateFile } from "../../utils/functions";
import i18next from "../../../i18n.js"; 
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "./ProfilePictureStyles";
import { useApi } from "../../hooks/useApi";
import { downloadDocumentInform, uploadCandidateDocuments ,deleteCandidateDocuments} from "../../hooks/userApi";
import { base64ToArrayBuffer, returnLangId, returnMimeData } from "../../utils/functions";
import {CircularProgress } from "@mui/material";
import { languages } from "../../PortalCandidato/auth/AuthWrapper";
function ProfilePicture(props: any) {
  const { t } = useTranslation();
  const { isEditAvailable = true, setter = null, propsMargin = 0 } = props;
  const callApi = useApi();
  const [anchorElement, setAnchorElement] = useState(null);
  const [docImg, setDocImg] = useState(null);
  const [loadingPic, setLoadingPic] = useState(false);
  const [refPicture, setRefPicture] = useState("" as any);
  const [idFotografia, setIdFotografia] = useState("" as any);

  const [file, setFile] = useState("" as any);
  const [blob, setBlob] = useState(null);
  const [doc, setDoc] = useState("" as any);
  const inputRef = useRef("" as any);
  const {idFoto} = props;
  

  useEffect(() => {
      if (idFoto) {
        setIdFotografia(idFoto);
      }

  }, [idFoto]);

  useEffect(() => {
    if (!refPicture && idFotografia) {
      getProfilePic(idFotografia);
    }
  }, [idFotografia]);

  useEffect(() => {
    if (doc && blob) {
      uploadProfilePic();
    }
  }, [doc, blob]);

  useEffect(() => {
    if (file) {
      handleFiles();
    }
  }, [file]);

   function handleSelectFile(files:any) {
    if (files[0]) {
      if (validateFile(files[0]) && files[0].name.length <= 80) {
        setFile(files[0]);
      } else {
        if (files[0].size === 0) {
          props.enqueueSnackbar(t("error_docs.error_not_valid2"), {
            variant: "error",
          });
        } else if (files[0].name.length > 80) {
          props.enqueueSnackbar(t("error_docs.error_not_valid3"), {
            variant: "error",
          });
        } else {
          props.enqueueSnackbar(t("error_docs.error_not_valid"), {
            variant: "error",
          });
        }
      }
    }
  } 

  async function getProfilePic(idFotografia:any) {
    setLoadingPic(true);
    callApi(downloadDocumentInform(idFotografia)).then((data) => {
      const obj_url = URL.createObjectURL(
        new Blob([base64ToArrayBuffer(data)], {
          type: `application/image/*`,
        })
      );
      setLoadingPic(false);
      setDocImg(idFotografia);
      setRefPicture(obj_url);
      setter && setter(obj_url);
    }).catch(() => {
      setDocImg(null);
      setLoadingPic(false);
    });
  } 

  function handleFiles() {
    setLoadingPic(true);
    const reader = new FileReader() as any;
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBlob(reader.result.substring(reader.result.indexOf(",") + 1));
      setDoc(file);
    };
  }

  function handleOpenMenu(event: any) {
    setAnchorElement(event.currentTarget);
  }

  function handleCloseMenu() {
    if(docImg){
      deleteProfilePic(); 
    }
    setAnchorElement(null);
  }

  const uploadProfilePic = useCallback(() => {
    const data = {
      idTipoDocumento: 16,
      documentos: [
        {
          nombre: doc.name,
          fichero: blob,
        },
      ],
    };
    callApi(uploadCandidateDocuments(returnLangId(i18next.languages[0] as keyof typeof languages),data)).then((result) => {
      const obj_url = URL.createObjectURL(
        new Blob([base64ToArrayBuffer(blob)], {
          type: `application/${returnMimeData(doc.name)}`,
        })
      );
      setRefPicture(obj_url);
      setter && setter(obj_url);
      setDocImg(result?.documentos[0]?.idDocumento);
      setFile(null);
      setBlob(null);
      setDoc(null);
      setLoadingPic(false);
    }).catch(() => {
      setBlob(null);
      setDoc(null);
      setLoadingPic(false);
    });
  }, [doc]);
  
const deleteProfilePic = useCallback(() => {
  setLoadingPic(true);
    callApi(deleteCandidateDocuments(returnLangId(i18next.languages[0] as keyof typeof languages), docImg)).then(() => {
      setFile(null);
      setDocImg(null);
      setRefPicture(null);
      setter && setter(null);
      setLoadingPic(false);

    }).catch(() => {
      setLoadingPic(false);
    });

  }, [docImg]); 


  const handleSetCloseMenu = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const handleMenuEditPic = useCallback(() => {
    handleCloseMenu();
    inputRef.current.click();
  }, []);


 const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
  handleSelectFile(e.target.files);
  }, []); 

  const handleOnClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.innerHTML="";
   }, []); 
  


  return (
<>    
      <Menu
        id="profile-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleSetCloseMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {docImg ? (
          <>
            <MenuItem
            sx={{margin:"8px!important"}}
              onClick={() => {
                handleCloseMenu();
              }}
            >
              {t("profile_picture.profile_pic_menu1")}
            </MenuItem>
            <Box></Box>
            <MenuItem onClick={handleMenuEditPic}
             sx={{margin:"8px!important"}}>
              {t("profile_picture.profile_pic_menu2")}
            </MenuItem>
          </>
        ) : (
          <MenuItem onClick={handleMenuEditPic}
          sx={{margin:"8px!important"}}>
            {t("profile_picture.profile_pic_menu3")}
          </MenuItem>
        )}
      </Menu>
      <Box
        sx={[styles.profile_photo_container,
        isEditAvailable && styles.editable_container]}

        onClick={(event) => {
          isEditAvailable && !loadingPic && handleOpenMenu(event);}}
        style={{ margin: propsMargin }}
      >
        {loadingPic ? (
          <>
          <CircularProgress size={"2rem"} />
          </>
        ) : refPicture ? (
          <Box sx={styles.photo_container}>
            <Box component="img"
              src={refPicture}
              sx={styles.photo} alt="" />
            {isEditAvailable && (
              <Camera
                title={t("icons_tooltips.upload_picture")}
                shrink
              />
            )}
          </Box>
        ) : (
          <Camera title={t("icons_tooltips.upload_picture")} />
        )}
      </Box>
      <Box sx={styles.hidden_input}>
      <input
        type="file"
        ref={inputRef}
        accept=".jpg, .jpeg, .png"
        onChange={handleOnChange}
        onClick={handleOnClick}
        />
      </Box>
    </>
  );
}
export default ProfilePicture;
