//Country
export const SPAIN = 724;

//Experience level
export const INTERN = "Becario/a - Prácticas";
export const EMPLOYEE = "Empleado/a";
export const INTERMEDIATE = "Mando intermedio";
export const SPECIALIST = "Especialista";

//Job description size
export const MAX_CHAR_DESCRIPTION = 4000;

//Comments languages
export const MAX_CHAR_COMMENTS = 200;

//Type identifications
export const NIF = "1";

export const idAplicacionGestor = 3;


// Travel availability Options
export const travelAvailabilityOptions = [
    { id: 1, name: "Inmediata" },
    { id: 2, name: "15-30 días" },
    { id: 3, name: "45-60 días" },
    { id: 4, name: "A partir de 3 meses" },
];

// RelationShipOptions

export const relationShipOptions = [
    { id: 1, name: "Hijo/a" },
    { id: 2, name: "Nieto/a" },
    { id: 3, name: "Bisnieto/a" },
    { id: 4, name: "Otros" },
];

//Portals
export const PORTAL_RETORNA = 3;

//Ciudades gallegas
export const ciudadesGallegas = ["15","27","32","36"];

//Parenteco
export const OTRO_TIPO_PARENTESCO = 4;